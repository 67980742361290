import { ToastType } from 'react-toastify';

import {
  CampaignLabelMap,
  CampaignState,
  Goal,
  isEmailCampaign,
  Targetable,
} from '@feathr/blackbox';
import { toast } from '@feathr/components';
import { logUserEvents } from '@feathr/hooks';
import type { IBaseAttributes, Model } from '@feathr/rachis';

import type { ISaveProps as ISaveButtonProps } from './SaveCampaignButton';

interface ISaveProps extends ISaveButtonProps {
  /** Sometimes we need to sneaky save without alerting the user (eg: upon initial drip campaign creation) */
  showSuccessToast?: boolean;
  /** Sometimes we need to force validation since it's not always a save as draft button that can save */
  forceValidation?: boolean;
}

export async function save({
  campaign,
  childModels,
  grandchildModels,
  preSave,
  postSave,
  shouldChangeState,
  t,
  accountId,
  showSuccessToast = true,
  forceValidation = false,
}: ISaveProps): Promise<void> {
  /*
   * If draft => save as draft + (if valid) publish
   * if published => save changes + stop campaign
   */
  try {
    const state = campaign.get('state', CampaignState.Draft);
    /*
     * Skip validation for targetables to let them be saved as
     * incomplete while campaign is in draft, but will be validated
     * within the targetables step and when publishing
     */
    const shouldSkipValidation = state === CampaignState.Draft && !shouldChangeState;

    // TODO: Move setting campaign.is_enabled on publish to backend
    if (shouldChangeState && [CampaignState.Draft, CampaignState.Stopped].includes(state)) {
      campaign.set({ is_enabled: true });
    }
    if (preSave) {
      await preSave(campaign);
    }
    await campaign.patchDirty();
    // Throw an error if the campaign is errored and we're not skipping validation
    if (campaign.isErrored && (!shouldSkipValidation || forceValidation)) {
      throw campaign.error;
    }

    const saveModel = async (model: Model<IBaseAttributes>): Promise<void> => {
      if (model.isEphemeral || !model.id) {
        await model.collection!.add(model, {
          // Prevents re-rendering Goals when they're ephemeral.
          refreshApiCache: model instanceof Goal ? false : true,
          validate: model instanceof Targetable && shouldSkipValidation ? false : true,
        });
      } else if (model.isDirty) {
        await model.save();
      }
      if (model.isErrored) {
        throw model.error;
      }
    };

    await (grandchildModels
      ? Promise.all(grandchildModels.map((childModel) => saveModel(childModel)))
      : Promise.resolve());
    await Promise.all(childModels.map((childModel) => saveModel(childModel)));

    if (shouldChangeState) {
      if ([CampaignState.Draft, CampaignState.Stopped].includes(state)) {
        logUserEvents({ 'Published campaign': { campaign_id: campaign.id } });
        await campaign.publish();
      } else {
        await campaign.stop();
      }
      if (campaign.isErrored) {
        throw campaign.error;
      }
    } else if (
      !isEmailCampaign(campaign) &&
      [CampaignState.Published, CampaignState.Publishing].includes(state)
    ) {
      await campaign.publish();
      if (campaign.isErrored) {
        logUserEvents({ 'Failed to publish campaign': { campaign_id: campaign.id } });
        throw campaign.error;
      } else {
        logUserEvents({ 'Published campaign': { campaign_id: campaign.id } });
      }
    }

    function getMessage(currentState: CampaignState): string {
      if (!shouldChangeState) {
        return t('Campaign updated');
      }
      if ([CampaignState.Draft, CampaignState.Stopped].includes(currentState)) {
        return t('Campaign published');
      }
      return t('Campaign stopped');
    }

    // Concatenates to campaign type + campaign published/stopped/updated
    logUserEvents({
      [`${CampaignLabelMap.get(campaign.get('_cls'))} ${getMessage(state)}`]: {
        account_id: accountId,
        campaign_id: campaign.id,
      },
    });

    if (postSave) {
      await postSave(campaign, shouldChangeState);
    }

    if (showSuccessToast) {
      toast(getMessage(state), {
        type: shouldChangeState ? ToastType.SUCCESS : ToastType.INFO,
      });
    }

    // If err is instance of Error, it should be of type any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    logUserEvents({
      [`Failed to publish ${CampaignLabelMap.get(campaign.get('_cls'))}`]: {
        account_id: accountId,
        campaign_id: campaign.id,
      },
    });
    if (e.errors) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e.errors.map((error: any) => {
        const { message } = error;
        toast(t('Something went wrong: {{- message}}', { message }), {
          type: ToastType.ERROR,
        });
      });
    } else {
      toast(e.message || t('An unknown error occurred!'), { type: ToastType.ERROR });
    }
  }
}
