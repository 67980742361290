import type { JSX } from 'react';
import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { IPerson } from '@feathr/blackbox';

import PersonContents from './PersonContents';

function PersonOption<T extends IPerson>(props: OptionProps<T>): JSX.Element {
  const { data } = props;

  return (
    <components.Option {...props}>
      <PersonContents data={data} size={'medium'} />
    </components.Option>
  );
}

export default PersonOption;
