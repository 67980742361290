export type { IParticipation } from './participation';
export { ReportModel } from './report';
export type { IReportAttributes, TReportKey } from './report';

/**
 * Enum representing the singular class names of various models. e.g. `Campaign` or `Segment`.
 *
 * Note: There are additional collection classnames, but these are the ones that are currently used.
 * TODO: Remove this enum when `assertId` is refactored to not need a className.
 */
export enum ECollectionClassName {
  Account = 'Account',
  BannersnackCreative = 'BannersnackCreative',
  BlackbaudContactMapping = 'BlackbaudContactMapping',
  BlackbaudRaisersEdgeIntegration = 'BlackbaudRaisersEdgeIntegration',
  Campaign = 'Campaign',
  CustomField = 'CustomField',
  DataRequest = 'DataRequest',
  Domain = 'Domain',
  EmailVerification = 'EmailVerification',
  FacebookDisplayCreative = 'FacebookDisplayCreative',
  FacebookImageCreative = 'FacebookImageCreative',
  FacebookPage = 'FacebookPage',
  FacebookVideoCreative = 'FacebookVideoCreative',
  GoogleAdsCustomer = 'GoogleAdsCustomer',
  GoogleAdsSmartCampaign = 'GoogleAdsSmartCampaign',
  ImisIntegration = 'ImisIntegration',
  Integration = 'Integration',
  Partner = 'Partner',
  Person = 'Person',
  PinpointEmailBaseCampaign = 'PinpointEmailBaseCampaign',
  ReferralBannerCreative = 'ReferralBannerCreative',
  Segment = 'Segment',
  Template = 'Template',
  User = 'User',
  UserNotification = 'UserNotification',
  UserRole = 'UserRole',
}
