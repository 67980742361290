import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { TPinpointSubtype } from '@feathr/blackbox';
import type { IRadioOption } from '@feathr/components';
import { CardV2 as Card, Radios, Well } from '@feathr/components';

interface ITriggerTypeRadiosProps {
  context?: 'drip' | 'pinpoint';
  description?: string;
  disabled: boolean;
  handleSubtypeChange: (newValue?: string) => void;
  subtype: TPinpointSubtype;
  title?: string;
  triggerTypeOptions: Array<IRadioOption<TPinpointSubtype>>;
}

function TriggerTypeRadios({
  context = 'pinpoint',
  description,
  disabled,
  handleSubtypeChange,
  subtype,
  title,
  triggerTypeOptions,
}: ITriggerTypeRadiosProps): JSX.Element {
  const { t } = useTranslation();

  const inputs = (
    <Radios
      dataName={'subtype'}
      disabled={disabled}
      helpText={description ?? t('Choose the type of trigger you want to use for this email.')}
      label={title ?? t('Trigger type')}
      layout={'block'}
      onChange={handleSubtypeChange}
      options={triggerTypeOptions}
      required={context === 'pinpoint'}
      value={subtype}
    />
  );

  return context === 'pinpoint' ? (
    <Card width={'full'}>
      {!title && <Card.Header title={t('Auto Send Type')} />}
      <Card.Content>{inputs}</Card.Content>
    </Card>
  ) : (
    <Well layout={'vertical'} theme={'white'}>
      {inputs}
    </Well>
  );
}

export default TriggerTypeRadios;
