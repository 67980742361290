import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { EmptyState } from '@feathr/components';
import TemplateMergefieldDefaults from '@feathr/extender/App/TemplatePage/TemplateMetadataForm/TemplateMergefieldDefaults';
import { StoresContext } from '@feathr/extender/state';

interface IMergeDataInputsProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
}

/* TODO: Get working once we have a template to work with in #3704, just showing a placeholder for now */
function MergeDataInputs({ campaign, disabled }: Readonly<IMergeDataInputsProps>): JSX.Element {
  const { t } = useTranslation();
  const { Templates } = useContext(StoresContext);

  const templateId = campaign.get('template_id');
  const template = templateId ? Templates.get(templateId) : undefined;

  return template && !!template.get('used_mergefields')?.length ? (
    <TemplateMergefieldDefaults context={'drip'} disabled={disabled} template={template} />
  ) : (
    <EmptyState label={t('No merge tags to display')} />
  );
}

export default MergeDataInputs;
