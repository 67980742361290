import classNames from 'classnames';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ISorted } from '@feathr/components';
import { ContextMenu } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

interface IProps {
  onChange: (newValue: ISorted) => void;
  value: ISorted;
}

const sortMap: Record<string, ISorted> = {
  nameAsc: { id: 'name' },
  nameDesc: { id: 'name', desc: true },
  createdAsc: { id: 'date_created' },
  createdDesc: { id: 'date_created', desc: true },
  startAsc: { id: 'date_start' },
  startDesc: { id: 'date_start', desc: true },
  endAsc: { id: 'date_end' },
  endDesc: { id: 'date_end', desc: true },
};

function currentSort(sort: ISorted): string | undefined {
  return Object.keys(sortMap).find(
    (key) => sortMap[key].id === sort.id && sortMap[key].desc === sort.desc,
  );
}

function EventsPageSort({ onChange, value }: IProps): JSX.Element {
  const { t } = useTranslation();

  function getHandleClick(sort: ISorted) {
    return () => onChange(sort);
  }

  return (
    <ContextMenu buttonType={'icon-outlined'} iconName={'sort'} position={'left-start'}>
      <ContextMenu.Label>{t('Name')}</ContextMenu.Label>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'nameAsc' })}
        onClick={getHandleClick(sortMap.nameAsc)}
        suffix={getIconForAction('stringAsc')}
      >
        {t('Alpha (asc)')}
      </ContextMenu.Item>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'nameDesc' })}
        onClick={getHandleClick(sortMap.nameDesc)}
        suffix={getIconForAction('stringDesc')}
      >
        {t('Alpha (desc)')}
      </ContextMenu.Item>
      <ContextMenu.Divider />
      <ContextMenu.Label>{t('Date')}</ContextMenu.Label>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'createdAsc' })}
        onClick={getHandleClick(sortMap.createdAsc)}
        suffix={getIconForAction('numberAsc')}
      >
        {t('Oldest')}
      </ContextMenu.Item>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'createdDesc' })}
        onClick={getHandleClick(sortMap.createdDesc)}
        suffix={getIconForAction('numberDesc')}
      >
        {t('Newest')}
      </ContextMenu.Item>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'startAsc' })}
        onClick={getHandleClick(sortMap.startAsc)}
        suffix={getIconForAction('numberAsc')}
      >
        {t('Start date (asc)')}
      </ContextMenu.Item>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'startDesc' })}
        onClick={getHandleClick(sortMap.startDesc)}
        suffix={getIconForAction('numberDesc')}
      >
        {t('Start date (desc)')}
      </ContextMenu.Item>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'endAsc' })}
        onClick={getHandleClick(sortMap.endAsc)}
        suffix={getIconForAction('numberAsc')}
      >
        {t('End date (asc)')}
      </ContextMenu.Item>
      <ContextMenu.Item
        className={classNames({ active: currentSort(value) === 'endDesc' })}
        onClick={getHandleClick(sortMap.endDesc)}
        suffix={getIconForAction('numberDesc')}
      >
        {t('End date (desc)')}
      </ContextMenu.Item>
    </ContextMenu>
  );
}

export default EventsPageSort;
