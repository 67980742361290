import {
  faArrowRotateLeft,
  faArrowsRotate,
  faEye,
  faFloppyDisk,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons';
import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Template, TemplateClass } from '@feathr/blackbox';
import {
  Button,
  ButtonValid,
  Chip,
  ContextMenu,
  Drawer,
  FullScreenModal,
  Icon,
  Spinner,
  Time,
  toast,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { flattenErrors, TimeFormat } from '@feathr/hooks';

import TemplatePreview from '../TemplatePreview';
import { TemplateSelectTable } from '../TemplateSelect';
import TemplateSendTestForm from '../TemplateSendTestForm';
import TemplateUnsavedChangesPrompt from '../TemplateUnsavedChangesPrompt/TemplateUnsavedChangesPrompt';
import TemplateSelectAndEditModalEditor from './TemplateSelectAndEditModalEditor';

import * as styles from './TemplateSelectAndEditModal.css';

export type TDisclosure = readonly [
  boolean,
  {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  },
];

export interface ITemplateSelectAndEditModalProps {
  disclosure: TDisclosure;
  initialState?: TTemplateSelectAndEditState;
  onChange: (templateId?: string) => Promise<void> | void;
  templateClass: TemplateClass;
  templateId?: string;
}

export type TTemplateSelectAndEditState =
  // Select a template; templateId is undefined, or we are changing template
  | 'selecting'
  // Display loading spinner; async create the template
  | 'loading'
  // Edit template; templateId is set
  | 'editing'
  // Preview email
  | 'previewing'
  // Configure test email in drawer on top of preview
  | 'sending'
  // Unsaved changes; saving the template
  | 'saving'
  // Closing the modal
  | 'closing';

export type TStateAction =
  // When force is true, we don't need to check template state
  | { state: TTemplateSelectAndEditState; force: true }
  // Check template for unsaved changes
  | { state: 'closing' | 'saving'; isTemplateDirty: boolean | undefined }
  // Check if a template is selected
  | { state: 'selecting' | 'editing'; templateId: string | undefined }
  // Other states
  | { state: Exclude<TTemplateSelectAndEditState, 'selecting' | 'editing' | 'closing' | 'saving'> };

/*
 * - While in 'selecting' state, if we select a template, onChange is triggered and we go
 *   to 'editing' state
 * - When we discard template changes or close the modal, we go to 'closing' state
 */
export function stateReducer(
  state: TTemplateSelectAndEditState,
  action: TStateAction,
): TTemplateSelectAndEditState {
  if ('force' in action) {
    // Bypass logic to change state based on template state
    return action.state;
  }

  switch (action.state) {
    case 'selecting':
    // If a template is set, we go to 'editing' state instead

    case 'editing':
      // If a template is not set, we go to 'selecting' state instead
      return action.templateId ? 'editing' : 'selecting';

    case 'saving':
    // Same as closing

    case 'closing':
      // If there are unsaved changes we go to 'saving' state instead
      return action.isTemplateDirty ? 'saving' : 'closing';

    default:
      return action.state;
  }
}

function TemplateSelectAndEditModal({
  disclosure,
  initialState,
  onChange,
  templateClass,
  templateId,
}: Readonly<ITemplateSelectAndEditModalProps>): JSX.Element {
  const [isModalOpen, { close: closeModal }] = disclosure;

  const { Templates } = useContext(StoresContext);
  const { t } = useTranslation();

  const [isDrawerOpen, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);

  // Set template before checking for initialState changes
  const template = templateId ? Templates.get(templateId) : undefined;
  const isTemplateDirty = !!template?.isDirty;

  function getInitialState(): TTemplateSelectAndEditState {
    return initialState ?? (templateId ? 'editing' : 'selecting');
  }

  // Initial state is initialState is set, or 'selecting' or 'editing' based on templateId
  const [state, dispatchState] = useReducer(stateReducer, getInitialState());

  function getAction(state: TTemplateSelectAndEditState, force: boolean = false): TStateAction {
    if (force) {
      return { state, force };
    }

    switch (state) {
      case 'selecting':
      // Same as editing

      case 'editing':
        return { state, templateId };

      case 'saving':
      // Same as closing

      case 'closing':
        return { state, isTemplateDirty };

      default:
        return { state };
    }
  }

  function setState(state: TTemplateSelectAndEditState, force?: boolean): void {
    dispatchState(getAction(state, force));
  }

  useEffect(() => {
    setState('selecting');
    // When templateId changes, update state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  // When initialState changes, update state
  useEffect(
    () => {
      if (initialState) {
        setState(initialState, true);
      }
    },
    // Do not include templateId and templateIsDirty in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialState],
  );

  function resetAndCloseModal(): void {
    // When modal is closed, reset state to initialState
    setState(getInitialState(), true);
    closeModal();
  }

  function handleClose(): void {
    if (isDrawerOpen) {
      closeDrawer();
      return;
    }

    // Because state is updated asynchronously, derive the next state manually
    const nextState = stateReducer(state, getAction('closing'));
    setState('closing');

    // Reducer will set state to 'saving' if there are unsaved changes
    if (nextState !== 'closing') {
      return;
    }

    resetAndCloseModal();
  }

  function handleChangeTemplate(): void {
    setState('selecting');
  }

  function handleConfigureTestEmail(): void {
    setState('sending');
    openDrawer();
  }

  function handlePreviewEmail(): void {
    setState('previewing');
  }

  function handleViewEditor(): void {
    setState('editing');
  }

  function isBannerTemplate(template: Template): boolean {
    return template.isBanner && !!template.get('bannersnack_enabled');
  }

  async function handleSave(): Promise<void> {
    if (!template) {
      return;
    }

    const response = await template.patchDirty();

    if (response.isErrored) {
      toast(t('Something went wrong. Please try again.'), { type: ToastType.ERROR });
      return;
    }

    if (!isBannerTemplate(template)) {
      toast(
        t('Changes saved. Domains linked in this email were added to your domain allow list.'),
        {
          type: ToastType.SUCCESS,
        },
      );
    } else {
      toast(t('Changes saved.'), { type: ToastType.SUCCESS });
    }
  }

  async function handleSaveAndClose(): Promise<void> {
    await handleSave();
    resetAndCloseModal();
  }

  async function handleTemplateChange(templateId?: string): Promise<void> {
    if (onChange) {
      setState('loading');
      await onChange(templateId);
      setState('selecting');
    }
  }

  function handleDiscardAndClose(): void {
    template?.discardDirty();
    setState('closing');
    // Force state to closing since we just cleaned up template

    // dispatchState({ state: 'closing', isTemplateDirty: false, force: true });
    resetAndCloseModal();
  }

  function getTitle(state: TTemplateSelectAndEditState): ReactNode {
    switch (state) {
      case 'saving':
      // Same as editing

      case 'editing':
        return (
          <>
            {t('Design your email')}
            <span className={styles.delimiter}>/</span>
            <Chip>{template!.name}</Chip>
          </>
        );

      case 'previewing':
        return (
          <>
            {t('Preview email')}
            <span className={styles.delimiter}>/</span>
            <Chip>{template!.name}</Chip>
          </>
        );

      default:
        return t('Pick your template');
    }
  }

  const description =
    state === 'selecting'
      ? t('From the options below select a template that suits your needs')
      : undefined;

  const savingActions = (
    <Button onClick={handleViewEditor} prefix={<Icon icon={faArrowRotateLeft} />}>
      {t('Back to editor')}
    </Button>
  );
  const editingActions = (
    <>
      <ContextMenu iconName={'ellipsis'} position={'top-end'}>
        <ContextMenu.Item onClick={handleChangeTemplate} prefix={<Icon icon={faArrowsRotate} />}>
          {t('Change template')}
        </ContextMenu.Item>
        <ContextMenu.Item onClick={handleConfigureTestEmail} prefix={<Icon icon={faSliders} />}>
          {t('Test email')}
        </ContextMenu.Item>
        <ContextMenu.Item onClick={handlePreviewEmail} prefix={<Icon icon={faEye} />}>
          {t('Preview email')}
        </ContextMenu.Item>
      </ContextMenu>
      <Button onClick={handleSave} prefix={<Icon icon={faFloppyDisk} />} type={'primary'}>
        {t('Save changes')}
      </Button>
    </>
  );
  const previewActions = (
    <>
      <Button
        onClick={handleConfigureTestEmail}
        prefix={<Icon icon={faSliders} />}
        type={'primary'}
      >
        {t('Test email')}
      </Button>
      <Button
        onClick={handleViewEditor}
        prefix={<Icon icon={faArrowRotateLeft} />}
        type={'primary'}
      >
        {t('Back to editor')}
      </Button>
    </>
  );

  const actions = ((state: TTemplateSelectAndEditState): ReactNode => {
    switch (state) {
      case 'saving':
        return savingActions;

      case 'editing':
        return editingActions;

      case 'previewing':
      // Same as sending

      case 'sending':
        return previewActions;

      default:
        return undefined;
    }
  })(state);

  const theme = ((state: TTemplateSelectAndEditState): 'default' | 'centered' | 'flush' => {
    switch (state) {
      case 'editing':
        return 'flush';

      case 'saving':
        return 'centered';

      default:
        return 'default';
    }
  })(state);

  return (
    <>
      <FullScreenModal
        description={description}
        leftActions={
          state === 'editing' && (
            <span>
              <strong>Last Saved:</strong>{' '}
              <Time
                format={TimeFormat.shortWeekdayDateTime}
                timestamp={template!.get('date_last_modified')}
                wrapperClassName={styles.lastSaved}
              />
            </span>
          )
        }
        onClose={handleClose}
        opened={isModalOpen}
        rightActions={actions}
        theme={theme}
        title={getTitle(state)}
        tooltip={t('Back to builder')}
        // Hide close button in closing or saving state
        withCloseButton={state !== 'closing' && state !== 'saving'}
      >
        {state === 'selecting' && (
          <TemplateSelectTable
            label={t('Pick your template')}
            onChange={handleTemplateChange}
            templateClass={templateClass}
            value={templateId}
          />
        )}
        {state === 'loading' && (
          <div>
            <Spinner size={20} /> {t('Creating your email...')}
          </div>
        )}
        {state === 'editing' && <TemplateSelectAndEditModalEditor template={template!} />}
        {(state === 'previewing' || state === 'sending') && (
          <TemplatePreview template={template!} visible={true} />
        )}
        {state === 'saving' && (
          <TemplateUnsavedChangesPrompt
            onDiscard={handleDiscardAndClose}
            onSave={handleSaveAndClose}
          />
        )}
      </FullScreenModal>
      <Drawer
        isOpen={isDrawerOpen}
        name={'drawer-test-email'}
        onClose={closeDrawer}
        title={t('Test Email')}
      >
        <TemplateSendTestForm template={template!}>
          {(form, validate, send): JSX.Element => {
            function handlePreviewEmailValidate(): string[] {
              const validationErrors = validate();
              return validationErrors ? flattenErrors(validationErrors) : [];
            }

            return (
              <>
                <Drawer.Content addVerticalGap={true}>{form}</Drawer.Content>
                <Drawer.Actions className={styles.drawerActions}>
                  <Button onClick={closeDrawer}>{t('Cancel')}</Button>
                  <ButtonValid
                    errors={handlePreviewEmailValidate()}
                    onClick={send}
                    type={'primary'}
                  >
                    {t('Send')}
                  </ButtonValid>
                </Drawer.Actions>
              </>
            );
          }}
        </TemplateSendTestForm>
      </Drawer>
    </>
  );
}

export default observer(TemplateSelectAndEditModal);
