import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ObjectId } from 'bson';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign, IGeoFencingPlace, Targetable, Targeting } from '@feathr/blackbox';
import { CampaignClass, CampaignState, TargetableClass } from '@feathr/blackbox';
import {
  Button,
  Card,
  DatePicker,
  Fieldset,
  FileUpload,
  Input,
  Label,
  NumberInput,
  Select,
  toast,
  Tooltip,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { flattenError, moment, momentToDate, TimeFormat } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import GeoFenceTargetingMap from './GeoFenceTargetingMap';

import * as rootStyles from '../CampaignEdit.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

interface IUnitsOption {
  id: 'kilometers' | 'meters';
  name: string;
}

interface IErrors extends TValidateGrouped {
  radius?: string[];
}

async function parseFile(csvFile: File, targetable: Targetable): Promise<void> {
  const Papa = await import(/* webpackChunkName: "papaparse" */ 'papaparse');
  Papa.parse<{ latitude: number; longitude: number; name: string }>(csvFile, {
    header: true,
    complete: (results) => {
      const uploadedPlaces: IGeoFencingPlace[] = results.data
        .filter((row) => {
          if (row.latitude > 90 || row.latitude < -90) {
            toast(`"${row.name}" has invalid latitude - must be between -90° and 90°.`, {
              type: 'error',
            });
            return false;
          }
          if (row.longitude > 180 || row.longitude < -180) {
            toast(`"${row.name}" has invalid longitude - must be between -180° and 180°.`, {
              type: 'error',
            });
            return false;
          }
          return !!row.latitude && !!row.longitude;
        })
        .map((row) => ({
          lat: row.latitude,
          lng: row.longitude,
          name: row.name,
          id: new ObjectId().toHexString(),
        }));
      runInAction(() => {
        targetable.set({
          places: targetable.get('places', []).concat(uploadedPlaces),
        });
      });
    },
  });
}

function getMaxDate(): Date {
  const now = moment.utc();
  return momentToDate(now.subtract(1, 'month').endOf('day'));
}

function getMinDate(): Date {
  const now = moment.utc();
  return momentToDate(now.subtract(1, 'year').endOf('day'));
}

function GeoFenceTargeting({ campaign, targeting, onRemove }: IProps): JSX.Element {
  const { Targetables } = React.useContext(StoresContext);

  function handleRadiusChange(newValue?: number): void {
    const units = targetable.get('units');
    targetable.set({
      radius: units === 'meters' ? Number(newValue) : Number(newValue) * 1000,
    });
  }

  let targetable: Targetable;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      _cls: TargetableClass.factual,
      name: 'New Geofence Targeting',
      radius: 1000,
      units: 'meters',
      is_historical: campaign.get('_cls') === CampaignClass.MobileGeoFenceRetargeting,
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
      places: [],
    });
    targeting.set({ target_data: targetable.get('id') });
  }

  let endMoment: Moment | undefined;
  let endDate: Date | undefined;
  let startMoment: Moment | undefined;
  let startDate: Date | undefined;
  if (targetable.get('date_end')) {
    endMoment = moment.utc(targetable.get('date_end'));
    endDate = momentToDate(endMoment);
  }
  if (targetable.get('date_start')) {
    startMoment = moment.utc(targetable.get('date_start'));
    startDate = momentToDate(startMoment);
  }
  const minDate = getMinDate();
  const maxDate = getMaxDate();

  const validationErrors = targetable.validate<IErrors>(['radius'], false, 'grouped').errors;

  return (
    <>
      <Card
        actions={[
          <Tooltip key={'remove'} title={'Remove'}>
            <Button onClick={() => onRemove(targeting)} type={'naked'}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
      >
        <section>
          <GeoFenceTargetingMap campaign={campaign} targetable={targetable} />
        </section>
        <section>
          {targetable.get('file_url') ? (
            <>
              <Label>File</Label>
              <p>{targetable.get('file_name')}</p>
            </>
          ) : (
            <>
              <FileUpload
                attribute={'file_url'}
                disabled={campaign.get('state') === CampaignState.Published}
                helpText={
                  <span>
                    Optional: Upload a <code>.csv</code> file of your target locations. Each
                    location should be specified as latitude/longitude coordinates.{' '}
                    <a
                      href={
                        'https://feathr-static-assets.s3.amazonaws.com/data/places_template.csv'
                      }
                    >
                      Click here{' '}
                    </a>
                    to download an example file to use as a template.
                  </span>
                }
                label={'Upload CSV'}
                model={targetable}
                name={'upload_csv'}
                onUpload={(key, container, file, filename) => {
                  const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
                  targetable.set({
                    file_url: url,
                    file_name: filename,
                  });
                  parseFile(file as File, targetable);
                }}
                pickerOptions={{
                  accept: ['text/comma-separated-values', 'text/csv', 'application/csv', '.csv'],
                  maxFiles: 1,
                  exposeOriginalFile: true,
                  storeTo: {
                    location: 's3',
                    container: 'feathr-import-data',
                    access: 'public',
                    region: 'us-east-1',
                  },
                }}
                wrapperClassName={rootStyles.fieldset}
              />
            </>
          )}
          <Input
            attribute={'name'}
            disabled={campaign.get('state') === CampaignState.Published}
            label={'Name'}
            model={targetable}
            name={'name'}
            required={true}
            type={'text'}
            wrapperClassName={rootStyles.fieldset}
          />
          <Fieldset className={rootStyles.fieldset} direction={'column'}>
            <NumberInput
              disabled={campaign.get('state') === CampaignState.Published}
              helpText={
                <span>
                  The area around your target location to target ads to. Minimum: 1,000 meters.
                  Maximum: 30,000 meters.
                </span>
              }
              label={'Radius'}
              max={targetable.get('units') === 'meters' ? 30000 : 30}
              min={targetable.get('units') === 'meters' ? 1000 : 1}
              name={'radius'}
              onChange={handleRadiusChange}
              validationError={flattenError(validationErrors.radius)}
              value={
                targetable.get('units') === 'meters'
                  ? targetable.get('radius', 0)
                  : targetable.get('radius', 0) / 1000
              }
            />
            <Select<IUnitsOption>
              disabled={campaign.get('state') === CampaignState.Published}
              label={'Units'}
              name={'units'}
              onSelectSingle={(option) => targetable.set({ units: option.id })}
              options={[
                { id: 'kilometers', name: 'kilometers' },
                { id: 'meters', name: 'meters' },
              ]}
              value={{ id: targetable.get('units'), name: targetable.get('units') }}
            />
          </Fieldset>
        </section>
        {campaign.get('_cls') === CampaignClass.MobileGeoFenceRetargeting && (
          <section>
            <Fieldset className={rootStyles.fieldset} direction={'column'}>
              <DatePicker
                autoComplete={'off'}
                disabled={campaign.get('state') === CampaignState.Published}
                label={'Start Date'}
                maxDate={maxDate}
                minDate={minDate}
                name={'date_start'}
                onDateStrChange={(dateStr) => {
                  const patch: { date_start: string; date_end?: string } = {
                    date_start: moment(dateStr).utc().format(TimeFormat.isoDate),
                  };
                  const newStartMoment = moment.utc(dateStr, moment.ISO_8601);
                  if (endMoment && newStartMoment.isAfter(endMoment.subtract(3, 'days'))) {
                    patch.date_end = newStartMoment
                      .startOf('day')
                      .add(2, 'days')
                      .format(TimeFormat.isoDate);
                  }
                  targetable.set(patch);
                }}
                selected={startDate}
              />
              <DatePicker
                autoComplete={'off'}
                disabled={campaign.get('state') === CampaignState.Published}
                label={'End Date'}
                maxDate={maxDate}
                minDate={startDate}
                name={'date_end'}
                onDateStrChange={(dateStr) => {
                  targetable.set({ date_end: moment(dateStr).utc().format(TimeFormat.isoDate) });
                }}
                selected={endDate}
              />
            </Fieldset>
          </section>
        )}
      </Card>
    </>
  );
}

export default observer(GeoFenceTargeting);
