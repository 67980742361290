import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Chip, Skeleton, Tooltip, Well } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import * as styles from './RecommendedBudget.css';

interface IProps {
  isLoading?: boolean;
  budget: string;
  onClick: () => void;
}

function RecommendedBudget({ isLoading = false, budget, onClick }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Well className={styles.root} layout={'vertical'}>
        <Skeleton height={24} width={'80%'} />
        <Skeleton height={32} width={'50%'} />
        <Skeleton height={32} />
      </Well>
    );
  }

  return (
    <Well className={styles.root} layout={'vertical'}>
      <Chip
        className={styles.chipFix}
        suffix={
          <Tooltip
            title={t(
              "The recommended budget is produced by analyzing your campaign's target audience, filters, optimization settings and duration.",
            )}
          >
            {getIconForAction('info')}
          </Tooltip>
        }
        theme={'sky'}
      >
        {t('Recommended for you')}
      </Chip>
      <span className={styles.bigNumber}>{budget}</span>
      <Button name={'use_recommended_budget'} onClick={onClick} type={'primary'}>
        {t('Use recommended budget')}
      </Button>
    </Well>
  );
}

export default RecommendedBudget;
