import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { ReactNode, Ref } from 'react';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionMeta, ValueType } from 'react-select';
import type ReactSelect from 'react-select';

import type { TEmailHealth } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';

import * as styles from './EmailHealthSelect.css';

interface IEmailHealthSelectOption extends ISelectOption {
  id: TEmailHealth;
}

interface IEmailHealthSelectProps {
  className?: string;
  isLoading?: boolean;
  label?: ReactNode;
  onChange: (value?: TEmailHealth) => void;
  onClear: () => void;
  placeholder?: string;
  value?: TEmailHealth;
}

function EmailHealthSelect(
  {
    className,
    isLoading,
    label,
    onChange,
    onClear,
    placeholder,
    value,
    ...additionalProps
  }: IEmailHealthSelectProps,
  ref: Ref<ReactSelect<IEmailHealthSelectOption>>,
) {
  const { t } = useTranslation();

  const options: IEmailHealthSelectOption[] = [
    { id: 'healthy', name: t('In good standing') },
    { id: 'review', name: t('Under review') },
    { id: 'suspended', name: t('Suspended') },
  ];

  function handleChange(
    newValue: ValueType<IEmailHealthSelectOption>,
    action: ActionMeta<IEmailHealthSelectOption>,
  ) {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (newValue) {
        onChange((newValue as IEmailHealthSelectOption).id);
      } else {
        onClear();
      }
    }
  }

  const selectedOption = value ? options.find((option) => option.id === value) : undefined;

  return (
    <Select<IEmailHealthSelectOption>
      {...additionalProps}
      innerRef={ref}
      isMulti={false}
      label={label}
      name={'email-health'}
      onChange={handleChange}
      onClear={onClear}
      options={options}
      placeholder={placeholder || 'Select status...'}
      value={selectedOption}
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(forwardRef(EmailHealthSelect));
