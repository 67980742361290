import classNames from 'classnames';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';

import type { ITag, TTagContext } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './FilterTags.css';

interface IProps {
  className?: string;
  context: TTagContext;
  onChange: (values: string[]) => void;
  value: string[];
  wrapperClassName?: string;
}

function FilterTags({
  className,
  context,
  onChange,
  value,
  wrapperClassName,
}: IProps): JSX.Element {
  const { Tags } = useContext(StoresContext);

  const valueTags = value.map((tagId) => Tags.get(tagId!).toJS());

  return (
    <AsyncSelect<ITag>
      aria-label={'Filter by tags'}
      className={classNames(styles.root, className)}
      data-name={'filter_tags'}
      defaultOptions={true}
      isMulti={true}
      loadOptions={async (inputValue: string) => {
        const tags = Tags.list({
          filters: { context, name__icontains: inputValue },
        });
        await when(() => !tags.isPending);
        return tags.models.map((tag) => tag.toJS());
      }}
      name={'filter-tags'}
      onSelectMulti={(newValues) => {
        onChange(newValues.map((newValue) => newValue.id!));
      }}
      placeholder={'Tags'}
      value={valueTags}
      wrapperClassName={wrapperClassName}
    />
  );
}

export default observer(FilterTags);
