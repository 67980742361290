import debounce from 'debounce-promise';
import capitalize from 'lodash.capitalize';
import uniqBy from 'lodash/uniqBy';
import type { JSX } from 'react';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import type { IBreadcrumbSuggestion } from '@feathr/blackbox';
import { Flavors } from '@feathr/blackbox';
import type { ISuggestion } from '@feathr/components';
import { InputElement, Popover, Suggestions } from '@feathr/components';
import { hiddenFlavors, StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import * as styles from './PredicateTextValueInput.css';

interface IProps {
  onChange: (value: string) => void;
  placeholder?: string;
  name?: string;
  value?: string;
  getPath: () => string;
  disabled: boolean;
}

function PredicateTextValueInput({
  disabled,
  getPath,
  placeholder,
  onChange,
  name,
  value,
}: IProps): JSX.Element {
  const { Breadcrumbs } = useContext(StoresContext);
  const path = getPath();
  const isSearchingFlavors = path.includes('flvr');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string | null | undefined>(value);
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

  /*
   * Add a cool-down as a user searches, 600ms
   * useMemo is used so that React remembers the debounced function between renders.
   */
  const debounceGetOptions = useMemo(() => debounce(getOptions, DEFAULT_DEBOUNCE_WAIT), []);
  const debounceOnChange = useMemo(() => debounce(onChange, DEFAULT_DEBOUNCE_WAIT), []);

  useEffect(() => {
    getOptions('');
  }, []);

  // Filter the results by excluding hidden Flavors
  function filterResults(results: IBreadcrumbSuggestion[]): IBreadcrumbSuggestion[] {
    return results.filter(
      (result: IBreadcrumbSuggestion) =>
        result.value && (isSearchingFlavors ? !hiddenFlavors.includes(result.value) : true),
    );
  }

  // Take in the result and if it is a Flavor, return pretty Flavor or clean up the raw value, else ignore
  function polishLabels(result: string): string {
    if (isSearchingFlavors) {
      if (result?.trim() in Flavors) {
        return Flavors[result];
      } else {
        return capitalize(result?.replace(/_+/g, ' '));
      }
    }
    // Not a Flavor, so don't polish it, e.g. URLs
    return result;
  }

  // Replaces values with Flavors in-place.
  function valueToFlavor(value: string): string {
    if (isSearchingFlavors && value in Flavors) {
      return Flavors[value];
    } else {
      return value;
    }
  }

  // Convert a Flavor value to the key
  function flavorToKey(value: string): string {
    return Object.keys(Flavors).find((flavorKey: string) => Flavors[flavorKey] === value) || value;
  }

  // Search Flavors by a partial string, e.g. 'click' returns 'Clicked ad', 'Clicked email', etc.
  function findFlavorByPartialString(value: string): string[] {
    return Object.values(Flavors).filter((flavorValue) =>
      flavorValue.toLocaleUpperCase().includes(value.toLocaleUpperCase()),
    );
  }

  // Process the results from the server into an array of objects that contain values and labels.
  function labelResults(results: IBreadcrumbSuggestion[]): ISuggestion[] {
    return results.map((result: IBreadcrumbSuggestion) => ({
      value: result.value,
      label: polishLabels(result.value), // Polish the labels if searching for Flavors.
    }));
  }

  /*
   * Convert the user's input to a Flavor query that will return results, e.g. "Clicked ad" to "ad_click"
   * will also search Flavors by a partial string, e.g. 'click' returns 'Clicked ad', 'Clicked email', etc.
   */
  function flavorTranslator(value: string): string {
    return (
      (isSearchingFlavors &&
        Object.keys(Flavors).find(
          (flavorKey: string) =>
            Flavors[flavorKey].localeCompare(value, undefined, { sensitivity: 'accent' }) === 0,
        )) ||
      value
    );
  }

  function findMatchingFlavor(value: string): ISuggestion[] {
    const results = findFlavorByPartialString(value);
    return results.map((result: string) => ({
      value: flavorToKey(result),
      label: result,
    }));
  }

  // Load the options from Blackbox
  async function getOptions(inputValue?: string): Promise<void> {
    setIsLoading(true);

    const query = inputValue ?? '';
    const translatedQuery = flavorTranslator(query);

    const response = await Breadcrumbs.getSuggestions(path, translatedQuery);
    const arrResponse = [...response];

    const filteredResults = filterResults(arrResponse);
    const labeledResults = labelResults(filteredResults);

    if (!isSearchingFlavors) {
      setSuggestions(labeledResults);
    } else {
      const matchingFlavors = findMatchingFlavor(query);
      const combinedResults = uniqBy([...labeledResults, ...matchingFlavors], 'value');
      setSuggestions(combinedResults);
    }
    setIsLoading(false);
  }

  // User types in a search on the inputElement
  function handleOnChange(value: string | undefined): void {
    setTextValue(value);
    debounceGetOptions(value);
    debounceOnChange(value ?? '');
  }

  // User clicks on an option from the suggestionsElement
  function handleOnClick(value: string): void {
    const clickedValue = polishLabels(value);
    setTextValue(clickedValue);
    debounceGetOptions(value);
    debounceOnChange(value);
  }

  // Where the user enters their input
  const inputElement = (
    <InputElement
      aria-label={'search field'}
      disabled={disabled}
      name={name}
      onChange={handleOnChange}
      placeholder={placeholder}
      value={valueToFlavor(textValue ? valueToFlavor(textValue) : '')}
    />
  );

  // Where the suggestions are displayed, i.e. dropdown of options to choose from.
  const suggestionsElement = (
    <Suggestions
      isLoading={isLoading}
      onClickSuggestion={handleOnClick}
      suggestions={suggestions}
    />
  );

  // An aggregate of the inputElement and suggestionsElement, creates a pseudo-Select component.
  const popoverElement = (
    <Popover
      align={'start'}
      arrow={false}
      className={styles.popover}
      position={'bottom'}
      toggle={'onFocus'}
    >
      {inputElement}
      {suggestionsElement}
    </Popover>
  );

  return !disabled ? popoverElement : inputElement;
}

export default PredicateTextValueInput;
