import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { ContextMenu } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import PersonActivityDrawer from '../../PersonActivityDrawer';

interface IProps {
  original: Person;
}

function PersonOptions({ original: person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const [isDrawerOpen, toggleDrawerOpen] = useToggle(false);

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item onClick={toggleDrawerOpen}>{t('View activity')}</ContextMenu.Item>
        <ContextMenu.Item link={localUrl(person.getItemUrl('profile'))}>
          {t('Edit person')}
        </ContextMenu.Item>
      </ContextMenu>
      <PersonActivityDrawer isOpen={isDrawerOpen} onClose={toggleDrawerOpen} person={person} />
    </>
  );
}

export default PersonOptions;
