import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Campaign } from '@feathr/blackbox';
import { CampaignClass, CampaignLabelMap, CampaignState } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { ButtonValid, Fieldset, Form, Input, Select } from '@feathr/components';
import { useAccount, useLocalUrl, useUser } from '@feathr/extender/state';

interface IProps {
  onNext: () => void;
  campaign: Campaign;
}

export function validateStepOne(campaign: Campaign): string[] {
  return campaign.validate(['name'], false).errors;
}

const NextStepButton = observer(({ campaign, onNext }: IProps) => {
  const validationErrors = validateStepOne(campaign);
  return (
    <ButtonValid errors={validationErrors} name={'next_step'} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

const campaignHelpContentMap: Partial<Record<CampaignClass, string>> = {
  [CampaignClass.Segment]:
    'https://help.feathr.co/hc/en-us/articles/360039050094-Best-Practices-for-Retargeting-Campaigns',
  [CampaignClass.EmailList]:
    'https://help.feathr.co/hc/en-us/articles/360039050194-Best-Practices-for-Email-Mapping-Campaigns',
  [CampaignClass.Search]:
    'https://help.feathr.co/hc/en-us/articles/360039555013-Best-Practices-for-Search-Keyword-Campaigns',
  [CampaignClass.MobileGeoFencing]:
    'https://help.feathr.co/hc/en-us/articles/360039055674-Best-Practices-for-Geofencing-Campaigns',
  [CampaignClass.MobileGeoFenceRetargeting]:
    'https://help.feathr.co/hc/en-us/articles/360046571714-Best-Practices-for-Historical-Geofencing-Campaigns',
};

function CampaignEditStepOne({ campaign, onNext }: IProps): JSX.Element {
  const account = useAccount();
  const user = useUser();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const helpDeskLink = (): JSX.Element | undefined => {
    const campaignLabel = CampaignLabelMap.get(campaign.get('_cls'));
    const url = campaignHelpContentMap[campaign.get('_cls')];
    if (campaignLabel && url) {
      return (
        <p>
          <a href={url} target={'_blank'}>
            {t(
              'Check out our help desk to learn about {{campaignLabel}} campaign best practices.',
              { campaignLabel },
            )}
          </a>
        </p>
      );
    }
    return undefined;
  };
  const advOptions = (account.get('ttd')?.subadvertisers || []).map((advertiser) => ({
    id: advertiser.subadv_id,
    name: advertiser.name || '',
  }));
  const TTDCampaignClasses: CampaignClass[] = [
    CampaignClass.Affinity,
    CampaignClass.EmailList,
    CampaignClass.Lookalike,
    CampaignClass.MobileGeoFenceRetargeting,
    CampaignClass.MobileGeoFencing,
    CampaignClass.Search,
    CampaignClass.SeedSegment,
    CampaignClass.Segment,
  ];
  const showAdvertiserSelect =
    !campaign.isMonetization &&
    TTDCampaignClasses.includes(campaign.get('_cls')) &&
    (account.get('is_agency') || user.isSudoer);

  function handleSelectAdvertiser(option: ISelectOption): void {
    campaign.set({ subadvertiser: option.id });
  }

  return (
    <Form
      actions={[<NextStepButton campaign={campaign} key={'next'} onNext={onNext} />]}
      description={
        <>
          <p>
            {t(
              'Provide a descriptive name for your campaign so you can find it later. It helps to put the targets and goals, if any, of the campaign in the name; E.g., "Cart Abandoners - Registration".',
            )}
          </p>
          {helpDeskLink()}
        </>
      }
      label={'Edit Campaign: Name'}
    >
      <Fieldset>
        <Input
          attribute={'name'}
          helpText={'Name your campaign'}
          label={'Name'}
          model={campaign}
          required={true}
          type={'text'}
        />
        {showAdvertiserSelect && (
          <Select
            disabled={campaign.get('state') !== CampaignState.Draft}
            helpText={
              <>
                <Trans t={t}>
                  <p>
                    If this campaign is to advertise on behalf of another organization, please
                    choose that organization here. This setting cannot be changed after the campaign
                    is published.
                  </p>
                  <p>
                    Add organizations to this list on the{' '}
                    <Link to={localUrl('settings/account/advertisers')}>advertisers page</Link> in
                    your account settings. Learn more about advertisers on our{' '}
                    <a
                      href={'https://help.feathr.co/hc/en-us/articles/4410253428375'}
                      target={'_blank'}
                    >
                      help desk.
                    </a>
                  </p>
                </Trans>
              </>
            }
            label={'Advertiser'}
            name={'advertiser'}
            onSelectSingle={handleSelectAdvertiser}
            options={advOptions}
            value={advOptions.find(
              (advertiser: ISelectOption) => advertiser.id === campaign.get('subadvertiser'),
            )}
          />
        )}
      </Fieldset>
    </Form>
  );
}

export default observer(CampaignEditStepOne);
