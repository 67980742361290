import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { BannersnackCreative, IBannersnackUrl } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { ContextMenu } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

interface IDownloadBannersnackFileMenuItemProps {
  creative: BannersnackCreative;
  type: IBannersnackUrl['type'];
}

function DownloadBannersnackFileMenuItem({
  creative,
  type,
}: IDownloadBannersnackFileMenuItemProps): JSX.Element | null {
  const { t } = useTranslation();

  const creativeType = creative.get('_cls');
  if (creativeType !== CreativeClass.DisplayBannersnack) {
    return null;
  }

  const urls =
    creativeType === CreativeClass.DisplayBannersnack
      ? creative.get('banner_urls')
      : observable([] as IBannersnackUrl[]);
  const { url } = urls.find((u) => u.type === type) || {};

  const fileType = type === 'animated-gif' ? 'GIF' : type.toUpperCase();

  return (
    <ContextMenu.Item
      disabled={creative.isUpdating || !url}
      href={url}
      prefix={getIconForAction('download')}
      target={'_blank'}
    >
      {t('Download {{file_type}}', { file_type: fileType })}
    </ContextMenu.Item>
  );
}

export default observer(DownloadBannersnackFileMenuItem);
