export default function search(value: any, options = {}) {
  const validatorOptions = {
    present: true,
    pattern: /.+/gm,
    message: 'is invalid.',
    ...options,
  };
  const { present, pattern, message } = validatorOptions;
  if (present === true) {
    if (value.search(pattern) === -1) {
      return message;
    }
  } else if (present === false) {
    if (value.search(pattern) > -1) {
      return message;
    }
  }
  return undefined;
}
