import { concatPath } from '@feathr/hooks';
import type { Attributes, IBaseAttributes, TConstraints, TRachisEmpty } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, DisplayModel, wretch } from '@feathr/rachis';

import type { Account } from './accounts';
import type { IBankAccountSource, TSource } from './sources';

export interface ILegacyAddress {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

export interface IStripe {
  id: string;
  /** Set to stripe token to updated source. */
  _token?: string | null;
  source?: TSource;
}

export interface IBillable extends IBaseAttributes {
  name: string;
  email: string;
  description?: string;
  // TODO: Once data in DB is migrated to standard address format, use IAddress
  address?: ILegacyAddress;
  intacct: {
    contactname: string;
  };
  stripe: IStripe;
}

export type TVerifyAmounts = [number, number];

export class Billable extends DisplayModel<IBillable> {
  public readonly className = 'Billable';

  public collection: Billables<this> | null = null;

  public get constraints(): TConstraints<IBillable> {
    return {
      email: {
        presence: { allowEmpty: false },
        email: true,
      },
      name: {
        presence: { allowEmpty: false },
      },
      'address.line1': {
        presence: {
          allowEmpty: false,
          message: '^Address Line 1 must be set.',
        },
      },
      'address.city': {
        presence: {
          allowEmpty: false,
          message: '^City must be set.',
        },
      },
      'address.state': {
        presence: {
          allowEmpty: false,
          message: '^State must be set.',
        },
      },
      'address.postal_code': {
        presence: {
          allowEmpty: false,
          message: '^Postal Code must be set.',
        },
      },
      'address.country': {
        presence: {
          allowEmpty: false,
          message: '^Country must be set.',
        },
      },
      /*
       * 'stripe.source': {
       *   presence: {
       *     allowEmpty: false,
       *     message: '^Payment method must be set.',
       *   },
       * },
       * 'stripe.source.status': {
       *   inclusion: {
       *     // chargeable is for credit card, verified is for bank account
       *     within: ['chargeable', 'verified'],
       *     message: '^Payment method must be set.',
       *   },
       * },
       */
    };
  }

  public getDefaults(): Partial<IBillable> {
    return {
      integrations: [],
      stripe: { id: 'invalid' },
    };
  }

  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Billable';
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`settings/billing/configurations/${this.id}`, pathSuffix);
  }

  public async deleteSource(): Promise<void> {
    const stripe = this.shadowAttributes.stripe
      ? this.shadowAttributes.stripe
      : this.get('stripe', {} as IStripe);
    const { source } = stripe;

    if (this.collection && this.id && source && source.id) {
      const url = `${this.collection.url()}${this.id}/sources/${source.id}`;
      await wretch<TRachisEmpty>(url, {
        method: 'DELETE',
        headers: Collection.prototype.getHeaders(),
      });
    }

    this.set({ stripe: { ...this.get('stripe'), source: undefined } });
  }

  public verify(amounts: TVerifyAmounts): Promise<this> {
    if (this.collection && this.id) {
      return this.collection.verify(this.id, amounts);
    }

    throw new Error('ID is required to verify bank account');
  }

  public isPrimary(account: Account): boolean {
    const license = account.get('license');
    const billableId = license.billable;

    return this.id === billableId;
  }
}

export class Billables<Model extends Billable = Billable> extends Collection<Model> {
  public getModel(attributes: Partial<Attributes<Model>>): Model {
    return new Billable(attributes) as Model;
  }

  public url(): string {
    return `${this.getHostname()}billing/billables/`;
  }

  public getClassName(): string {
    return 'billables';
  }

  public async verify(id: string, amounts: TVerifyAmounts): Promise<Model> {
    const model = this.modelsById.get(id);
    if (
      model &&
      model.get('stripe') &&
      (model.get('stripe').source! as IBankAccountSource).bank_name
    ) {
      model.isUpdating = true;
      const body = this.patchToString({ amounts });

      const response = await wretch<IBillable>(
        `${this.getHostname()}billing/billables/${id}/verify-ach`,
        {
          method: 'POST',
          body,
          headers: this.getHeaders(),
        },
      );
      if (isWretchError(response)) {
        this.processErrorResponse(model, response.error);
        return model;
      } else {
        return this.processJSONResponse(response);
      }
    }

    throw new Error('Billable must have a valid bank account source');
  }
}
