import type { JSX } from 'react';
import React from 'react';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { IPerson } from '@feathr/blackbox';

import PersonContents from './PersonContents';

function PersonSingleValue<T extends IPerson>(props: SingleValueProps<T>): JSX.Element {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <PersonContents data={data} size={'small'} />
    </components.SingleValue>
  );
}

export default PersonSingleValue;
