import type { URLSearchParams } from 'url';

import { toQueryString } from '@feathr/hooks';
import type { IBaseAttributes, IRachisMessage, TConstraints } from '@feathr/rachis';
import { Collection, isWretchError, Model, wretch } from '@feathr/rachis';

import type { TBaseUnitOfTime } from './campaigns';
import type { IAttributions } from './stats';

interface IGeoIP {
  lat: number;
  lng: number;
  postal_code: number;
  country_code: string;
  administrative_area_code: string;
}

export interface ICampaignAttribution {
  campaign: string;
  score: number;
}

export interface IConversionCategoryFilters {
  d_c__gte?: string;
  d_c__lte?: string;
  state?: string;
  goals__in?: string[];
  events?: string;
  flights?: string;
  campaigns?: string;
  category__in?: string[];
}

export type TAttribution = IAttributions<ICampaignAttribution[]>;

export type TUnitOfTimeConversions = Exclude<TBaseUnitOfTime, 'weeks'>;

export interface IObjectsTouched {
  e_id: string[];
  p_id: string[];
  t_id: string[];
  crv_id: string[];
}

export interface IConversion extends IBaseAttributes {
  d_c: string;
  goals: string[];
  goal_segments: string[];
  campaigns: string[];
  cookie: string;
  person: string;
  objects_touched: Partial<IObjectsTouched>;
  attribution: TAttribution;
  source_target_segments: string[];
  geoip: IGeoIP;
  domains: string[];
  state: string;
  date_enter_funnel: string;
  platform: string;
  category: string;
}

interface ICategoriesResponse extends Record<string, unknown> {
  categories: string[];
}

export enum EConversionCategories {
  Campaigns = 'campaign',
  Flights = 'flight',
  Projects = 'project',
}

export interface IRerunScopeResponse extends Record<string, unknown> {
  id: string;
  type: EConversionCategories;
  name: string;
}

export type TRerunScopeVariant = 'project' | 'campaign' | 'flight' | 'segment';

export class Conversion extends Model<IConversion> {
  public readonly className = 'Conversion';

  public get constraints(): TConstraints<IConversion> {
    return {};
  }
}
export class Conversions extends Collection<Conversion> {
  public getClassName(): string {
    return 'conversions';
  }

  public getModel(attributes: Partial<IConversion>): Conversion {
    return new Conversion(attributes);
  }

  public async export(params: URLSearchParams): Promise<IRachisMessage> {
    const headers = this.getHeaders();
    const url = `${this.getHostname()}conversions/export?${params.toString()}`;
    const response = await wretch<IRachisMessage>(url, {
      method: 'GET',
      headers,
    });

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async rerun(params: URLSearchParams): Promise<IRachisMessage> {
    const headers = this.getHeaders();
    const url = `${this.getHostname()}conversions/rerun?${params.toString()}`;
    const response = await wretch<IRachisMessage>(url, {
      method: 'GET',
      headers,
    });
    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public async rerunScope(
    variant: TRerunScopeVariant,
    value: string,
  ): Promise<IRerunScopeResponse[]> {
    const headers = this.getHeaders();
    const url = `${this.getHostname()}conversions/rerun_scope?${variant}=${value}`;
    const response = await wretch<IRerunScopeResponse[]>(url, {
      method: 'GET',
      headers,
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public async getCategories(params: IConversionCategoryFilters): Promise<string[]> {
    const headers = this.getHeaders();
    const query = toQueryString(this.parseJSONParams({ filters: params }));
    const url = `${this.getHostname()}conversions/categories?${query}`;
    const response = await wretch<ICategoriesResponse>(url, {
      method: 'GET',
      headers,
    });

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data.categories ?? [];
  }
}
