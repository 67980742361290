import { observer } from 'mobx-react-lite';
import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionMeta, ValueType } from 'react-select';
import type ReactSelect from 'react-select';

import { APIKeyServiceNames } from '@feathr/blackbox';
import type { IFormElementProps, ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import { enumKeys } from '@feathr/hooks';

interface IAPIKeyServiceSelectOption extends ISelectOption {
  id: APIKeyServiceNames;
  name: APIKeyServiceNames;
}

interface IAPIKeyServiceSelectProps extends IFormElementProps {
  availableServices: APIKeyServiceNames[];
  disabled?: boolean;
  isLoading?: boolean;
  onChange: (value?: APIKeyServiceNames) => void;
  onClear: () => void;
  placeholder?: string;
  value: APIKeyServiceNames;
}

function APIKeyServiceSelect(
  {
    availableServices,
    disabled,
    onChange,
    onClear,
    placeholder,
    value,
    ...additionalProps
  }: IAPIKeyServiceSelectProps,
  ref: Ref<ReactSelect<IAPIKeyServiceSelectOption>>,
) {
  const { t } = useTranslation();
  const options = enumKeys(APIKeyServiceNames)
    .filter((key) => availableServices.includes(key as APIKeyServiceNames))
    .map(
      (key) =>
        ({
          id: key as APIKeyServiceNames,
          name: key as APIKeyServiceNames,
        }) as IAPIKeyServiceSelectOption,
    );

  function handleChange(
    newValue: ValueType<IAPIKeyServiceSelectOption>,
    action: ActionMeta<IAPIKeyServiceSelectOption>,
  ) {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (newValue) {
        onChange((newValue as IAPIKeyServiceSelectOption).id);
      } else {
        onClear();
      }
    }
  }

  const selectedOption = value ? options.find((option) => option.id === value) : undefined;
  return (
    <Select<IAPIKeyServiceSelectOption>
      {...additionalProps}
      disabled={disabled}
      innerRef={ref}
      isMulti={false}
      name={'api-key-service'}
      onChange={handleChange}
      onClear={onClear}
      options={options}
      placeholder={placeholder || t('Select a service...')}
      value={selectedOption}
    />
  );
}

export default observer(forwardRef(APIKeyServiceSelect));
