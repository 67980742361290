import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EIntegrationTypes } from '@feathr/blackbox';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useFlags } from '@feathr/extender/state';

import type { IBaseIntegrationCardProps } from './BaseIntegrationsCard';
import IntegrationsCard from './IntegrationsCard';

import blackbaudLogo from 'images/integrations/blackbaud.png';
import googleAdsLogo from 'images/integrations/google_ads.png';
import iMISLogo from 'images/integrations/iMIS.png';
import metaLogo from 'images/zapier_brands/meta.png';

export interface INativeIntegrationCardProps {
  logo?: string;
  name: string;
  /** The primary URL of the card. */
  url: string;
  /** Hide the card. For example, a card should be hidden when the account doesn't have a Pixel implemented. */
  isCardHidden?: boolean;
  props?: Partial<IBaseIntegrationCardProps>;
}

enum EIntegrationsNames {
  iMIS = 'iMIS',
  BlackbaudRaisersEdge = "Blackbaud Raiser's Edge NXT",
}

function NativeIntegrations(): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();

  const { hasImis, hasBlackbaudRaisersEdge } = useFlags();

  const { isPlatformAdvanced, isPlatformEnterprise } = account;
  const hasDirectIntegrations = isPlatformAdvanced || isPlatformEnterprise;

  const showImis = hasDirectIntegrations || hasImis;
  const showBlackbaudRaisersEdge = hasDirectIntegrations || hasBlackbaudRaisersEdge;
  const showGoogleAds = useGoogleAdsPermissions({ requireConnectedCustomer: false });

  const isImisConflicting = account.conflictingIntegrations.includes(EIntegrationTypes.Imis);
  const isBlackbaudRaisersEdgeConflicting = account.conflictingIntegrations.includes(
    EIntegrationTypes.BlackbaudRaisersEdge,
  );

  const integrationNotAvailableMessage = t(
    'This integration is not available with your current Feathr license.',
  );

  function getConflictingIntegrationMessage(
    integrationName: EIntegrationsNames,
    currentIntegrationName: EIntegrationsNames,
  ): string {
    return t(
      'You have a conflicting integration. You must disconnect from {{integrationName}} to be able to connect to {{currentIntegrationName}}.',
      {
        integrationName,
        currentIntegrationName,
      },
    );
  }

  const nativeIntegrations: INativeIntegrationCardProps[] = [
    {
      name: 'API Keys',
      url: '/settings/integrations/api-keys',
      isCardHidden: !account.hasPixelImplementation,
    },
    {
      name: "Blackbaud Raiser's Edge NXT",
      logo: blackbaudLogo,
      url: '/settings/integrations/raisers-edge',
      props: {
        tooltip:
          (!showBlackbaudRaisersEdge && integrationNotAvailableMessage) ||
          (isBlackbaudRaisersEdgeConflicting &&
            getConflictingIntegrationMessage(
              EIntegrationsNames.iMIS,
              EIntegrationsNames.BlackbaudRaisersEdge,
            )),
        disabled: !showBlackbaudRaisersEdge || isBlackbaudRaisersEdgeConflicting,
      },
    },
    {
      name: 'Forms / Implementations',
      url: '/data/pixel/implementations',
      isCardHidden: !account.hasPixelImplementation,
    },
    {
      name: 'Google Ads',
      logo: googleAdsLogo,
      url: '/settings/integrations/google-ads',
      props: {
        disabled: !showGoogleAds,
        tooltip: !showGoogleAds && integrationNotAvailableMessage,
      },
    },
    {
      name: 'iMIS',
      logo: iMISLogo,
      url: '/settings/integrations/imis',
      props: {
        tooltip:
          (!showImis && integrationNotAvailableMessage) ||
          (isImisConflicting &&
            getConflictingIntegrationMessage(
              EIntegrationsNames.BlackbaudRaisersEdge,
              EIntegrationsNames.iMIS,
            )),
        disabled: !showImis || isImisConflicting,
      },
    },
    {
      name: 'Meta',
      logo: metaLogo,
      url: '/settings/integrations/meta',
    },
  ];

  return (
    <>
      {nativeIntegrations
        .filter((integration) => !integration.isCardHidden)
        .map((integration) => (
          <IntegrationsCard item={integration} key={integration.name} {...integration.props} />
        ))}
    </>
  );
}

export default NativeIntegrations;
