import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

import type { IStats } from './stats';

export interface ISendRequest extends IBaseAttributes {
  campaign: string;
  date_created: string;
  date_sent: string;
  date_to_send: string;
  from_: string;
  mode: string;
  state: string;
  stats: IStats;
  subject: string;
  target: string;
  template: string;
  to: string;
}

export class SendRequest extends Model<ISendRequest> {
  public readonly className = 'SendRequest';

  public get constraints(): TConstraints<ISendRequest> {
    return {};
  }
}

export class SendRequests extends Collection<SendRequest> {
  public getClassName(): string {
    return 'send_requests';
  }

  public getModel(attributes: Partial<ISendRequest>): SendRequest {
    return new SendRequest(attributes);
  }
}
