import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { UserRole } from '@feathr/blackbox';
import { ContextMenu } from '@feathr/components';
import { useAccount, useLocalUrl } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

import ArchiveRoleModal from './ArchiveRoleModal';
import ViewReadOnlyRoleModal from './ViewReadOnlyRoleModal';

interface IRoleOptionsProps {
  role: UserRole;
}

function RoleOptions({ role }: Readonly<IRoleOptionsProps>): JSX.Element {
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isViewReadOnlyRoleOpen, toggleViewReadOnlyRoleOpen] = useToggle(false);
  const account = useAccount();

  const localUrl = useLocalUrl();
  const isDefault = role.isDefault;

  const { t } = useTranslation();

  function handleClose(): void {
    toggleArchiveModalOpen();
    account.reload();
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} name={'role-options'} position={'left-start'}>
        {isDefault && (
          <ContextMenu.Item
            name={'view-role'}
            onClick={toggleViewReadOnlyRoleOpen}
            prefix={getIconForAction('view')}
          >
            {t('View')}
          </ContextMenu.Item>
        )}
        <ContextMenu.Item
          disabled={isDefault}
          link={!isDefault ? localUrl(role.getItemUrl('edit')) : undefined}
          name={'edit-role'}
          prefix={getIconForAction('edit')}
          tooltip={isDefault ? t('Default roles cannot be edited') : undefined}
          tooltipPosition={'left'}
        >
          {t('Edit')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={isDefault}
          name={'delete-role'}
          onClick={toggleArchiveModalOpen}
          prefix={getIconForAction('delete')}
          theme={'danger'}
          tooltip={isDefault ? t('Default roles cannot be deleted') : undefined}
          tooltipPosition={'left'}
        >
          {t('Delete')}
        </ContextMenu.Item>
      </ContextMenu>
      {isArchiveModalOpen && <ArchiveRoleModal onClose={handleClose} role={role} />}
      {isViewReadOnlyRoleOpen && (
        <ViewReadOnlyRoleModal onClose={toggleViewReadOnlyRoleOpen} role={role} />
      )}
    </>
  );
}

export default observer(RoleOptions);
