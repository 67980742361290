import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { BlackbaudRaisersEdgeIntegration } from '@feathr/blackbox';
import { Button, toast } from '@feathr/components';

export type TMappingDisabledReason = 'required' | 'provisioning';

export interface IDeleteMappingButtonProps {
  disabled?: boolean;
  /**
   * Whether or not to provide a standardized tooltip based on the reason for the button being disabled.
   * If provided, the tooltip prop will be overridden.
   */
  disabledReason?: TMappingDisabledReason;
  id: string;
  integration: BlackbaudRaisersEdgeIntegration;
  tooltip?: string;
}

function DeleteMappingButton({
  disabled = false,
  disabledReason,
  id,
  integration,
  tooltip,
}: Readonly<IDeleteMappingButtonProps>): JSX.Element {
  const { t } = useTranslation();

  async function deleteMapping(): Promise<void> {
    try {
      // TODO: Refactor to use ContactMapping.delete() as part of #2650.
      await integration.deleteContactMapping(id);
      toast(t('Successfully deleted mapping.'), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necessary with the implementation of #2650.
      window.location.reload();
    } catch (error) {
      toast(t('Failed to delete mapping.'), {
        type: ToastType.ERROR,
      });
    }
  }

  const getTooltipMessage = (disabledReason: TMappingDisabledReason): string | null => {
    const map: Record<TMappingDisabledReason, string> = {
      required: t('This contact mapping cannot be deleted because it is required.'),
      provisioning: t('This contact mapping cannot be deleted because it is being provisioned.'),
    };

    return map[disabledReason];
  };

  return (
    <Button
      disabled={disabled}
      name={'delete-contact-mapping'}
      onClick={deleteMapping}
      tooltip={disabled && disabledReason ? getTooltipMessage(disabledReason) : tooltip}
      tooltipPosition={'top-end'}
      type={'icon'}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  );
}

export default observer(DeleteMappingButton);
