import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export type GoalType = 'custom' | 'ad_click' | 'form_submission' | 'page_view';

export interface IGoal extends IBaseAttributes {
  conv_type: GoalType;
  conv_value: number;
  date_last_modified: string;
  name: string;
  /**
   * Determines whether conversions should count more than
   * once per person.
   */
  multi_conversions: boolean;
  parent: string;
  segment?: string;
  kind?: 'campaign' | 'flight';
}

export const MAX_GOALS = 5;

export class Goal extends Model<IGoal> {
  public readonly className = 'Goal';

  public get constraints(): TConstraints<IGoal> {
    return {
      conv_value: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          greaterThan: -1,
          notValid: '^The value must be 0 or greater',
        },
      },
      segment: {
        presence: {
          allowEmpty: false,
          message: '^You must provide a valid group or remove the goal',
        },
      },
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      conv_type: {
        presence: {
          allowEmpty: false,
        },
        inclusion: {
          within: ['custom', 'ad_click', 'form_submission', 'page_view'],
          message: '^The goal is not of a valid type',
        },
      },
    };
  }

  public getDefaults(): Partial<IGoal> {
    return {
      conv_value: 0,
      conv_type: 'custom',
    };
  }
}

export class Goals extends Collection<Goal> {
  public getClassName(): string {
    return 'goals';
  }

  public getModel(attributes: Partial<IGoal>): Goal {
    return new Goal(attributes);
  }
}
