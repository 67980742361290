import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from '@feathr/components';
import Page from '@feathr/extender/App/Page';

import NativeIntegrations from './NativeIntegrations';
import ZapierIntegrationCards from './ZapierIntegrationCards';

import * as styles from './IntegrationsDashboardPage.css';

/**
 * A Page Component that renders information on Feathr's available integration services and Cards that link to internal and external integrations
 */
function IntegrationsDashboard(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Page title={t('Integrations')}>
      <Section
        contentClassName={styles.grid}
        description={t('Native integrations within the Feathr platform.')}
        layout={'vertical'}
        title={t('Feathr Integrations')}
      >
        <NativeIntegrations />
      </Section>
      <Section
        contentClassName={styles.grid}
        description={t(
          'Feathr seamlessly connects with these apps and many more through Zapier, a powerful integration platform. Explore the possibilities by clicking on each app to view its dedicated Zapier page. For additional guidance, simply follow the provided integration guides to get detailed information for each integration.',
        )}
        layout={'vertical'}
        title={t('Third-Party Integrations')}
      >
        <ZapierIntegrationCards />
      </Section>
    </Page>
  );
}

export default observer(IntegrationsDashboard);
