import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { FieldDataType } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { Fieldset, Input, ModalV1, Select, toast } from '@feathr/components';
import { FieldOption } from '@feathr/extender/components/SelectOptions';
import { StoresContext, useAccount } from '@feathr/extender/state';
import { validate } from '@feathr/rachis';

import DataToggle from './DataToggle';

import defaultBreadcrumbFields from '@feathr/blackbox/fixtures/defaultBreadcrumbFields.json';
import defaultPersonFields from '@feathr/blackbox/fixtures/defaultPersonFields.json';

interface IAttrOption {
  id: string;
  name: string;
  type: FieldDataType;
}

interface IProps {
  fields: string[];
  onChangeFields: (fields: string[], headers: string[]) => void;
  onClose: () => void;
  onConfirm: (email: string) => Promise<void>;
  title: string;
  mode: 'persons' | 'breadcrumbs';
  onChangeMode: (mode: 'persons' | 'breadcrumbs') => void;
}

function SegmentExportModal({
  fields,
  onChangeFields,
  onClose,
  onConfirm,
  title,
  mode,
  onChangeMode,
}: IProps): JSX.Element {
  const account = useAccount();

  const { CustomFields } = useContext(StoresContext);
  const [email, setEmail] = useState<string>('');
  const [isEmailDirty, setIsEmailDirty] = useState<boolean>(false);
  const [isFieldsDirty, setIsFieldsDirty] = useState(false);
  const { t } = useTranslation();

  const customFields = CustomFields.list({
    filters: {
      collection: mode === 'persons' ? FieldCollection.Person : FieldCollection.Breadcrumb,
    },
  });
  const dataAttrOptions: IAttrOption[] = !customFields.isPending
    ? [
        ...(mode === 'persons' ? defaultPersonFields : defaultBreadcrumbFields),
        ...customFields.models.map((field) => ({
          id:
            mode === 'persons'
              ? `custom.${field.get('f_key')}`
              : `cust_params.${field.get('f_key')}`,
          name: field.get('u_key'),
          type: field.get('data_type'),
        })),
      ]
    : [];
  const value = dataAttrOptions.filter((option) => fields.includes(option.id));

  function validation(): Record<'fields' | 'email', string[]> {
    return validate(
      { email, fields },
      {
        email: !!email && isEmailDirty ? { email: true } : undefined,
        fields: isFieldsDirty
          ? {
              length: {
                minimum: 1,
                // TODO: translate validation strings.
                tooShort: '^Please choose at least one field to export.',
              },
            }
          : undefined,
      },
    );
  }

  const validationErrors = validation();

  function handleEmailChange(newValue?: string): void {
    setEmail(newValue ?? '');
    setIsEmailDirty(true);
  }

  async function handleOnConfirm(): Promise<void> {
    await onConfirm(email);
    toast(t('Your export is on the way! Check your email inbox in a few minutes.'), {
      type: 'success',
    });
    onClose();
  }

  function handleSelectMulti(values: IAttrOption[]): void {
    onChangeFields(
      values.map(({ id }) => id),
      values.map(({ name }) => name),
    );
    setIsFieldsDirty(true);
  }

  return (
    <>
      <ModalV1
        confirmButtonText={t('Export')}
        confirmDisabled={validation() !== undefined}
        controlled={true}
        onClose={onClose}
        onConfirm={handleOnConfirm}
        t={t}
        title={title}
      >
        <Trans t={t}>
          <p>
            Export the data in the chosen group as a <code>.csv</code> file. Choose the columns you
            want to include from the selection below. When your export file is ready, we will send
            you an email with a download link. Please allow up to 30 minutes for this process to
            complete, depending on the size of your group and the number of columns selected.
          </p>
        </Trans>
        <Fieldset>
          <DataToggle label={t('Export type')} mode={mode} onChangeMode={onChangeMode} />
          <Select<IAttrOption>
            components={{ Option: FieldOption }}
            isLoading={customFields.isPending}
            isMulti={true}
            label={t('Fields')}
            name={'fields'}
            onSelectMulti={handleSelectMulti}
            options={dataAttrOptions}
            validationError={validationErrors?.fields}
            value={value}
          />
          <Input
            helpText={t(
              'If you want to send the export to an email address other than the one associated with your Feathr User, input an email address.',
            )}
            label={t('Email address')}
            onChange={handleEmailChange}
            optional={true}
            placeholder={account.get('email')}
            type={'text'}
            validationError={validationErrors?.email}
            value={email}
          />
        </Fieldset>
      </ModalV1>
    </>
  );
}

export default observer(SegmentExportModal);
