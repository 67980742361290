import debounce from 'debounce-promise';
import { set, when } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Campaign, DisplayCreative, ICreativeAttributes } from '@feathr/blackbox';
import { CampaignClass, CreativeClass } from '@feathr/blackbox';
import { AsyncSelect, Fieldset, Input, ModalV1 } from '@feathr/components';
import { CreativeOption, CreativeSingleValue } from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

interface IProps {
  creative?: DisplayCreative;
  onConfirm: (creative: DisplayCreative) => void;
  onClose: () => void;
  campaign?: Campaign;
  eventId: string;
  remainingBanners?: number;
}

interface ICreativeCloneAttributes {
  name: string;
  event: string;
  _parent?: string;
}

function CloneCreativeModal({
  eventId,
  campaign,
  creative,
  onClose,
  onConfirm,
  remainingBanners,
}: IProps) {
  const { Creatives } = useContext(StoresContext);
  const store = useLocalObservable(() => ({
    creativeId: !!creative ? creative.id : '',
    name: !!creative ? creative.name : '',
    isValid: () => !!store.creativeId && !!store.name,
  }));
  const { t } = useTranslation();

  async function handleConfirm() {
    const attributes: ICreativeCloneAttributes = {
      name: store.name,
      event: eventId,
    };
    if (campaign) {
      attributes._parent = campaign.id;
    }
    const model = await Creatives.clone(store.creativeId, attributes);
    onConfirm(model as DisplayCreative);
    onClose();
  }

  async function loadOptions(inputValue: string) {
    const classList = [
      CreativeClass.DisplayImage,
      CreativeClass.DisplayVideo,
      CreativeClass.DisplayAdTag,
    ];
    // Only allow cloning bannersnack creatives into a campaign if you have not reached your limit
    if (campaign && remainingBanners && remainingBanners > 0) {
      classList.push(CreativeClass.DisplayBannersnack);
    }
    // Do not allow cloning adtags and bannersnack creatives into geofence campaign types
    if (
      campaign &&
      [CampaignClass.MobileGeoFencing, CampaignClass.MobileGeoFenceRetargeting].includes(
        campaign.get('_cls'),
      )
    ) {
      classList.splice(2, 2);
    }
    const response = Creatives.list({
      filters: {
        name__icontains: inputValue,
        _cls__in: classList,
      },
      pagination: {
        page_size: 30,
      },
      ordering: ['-date_created'],
    });
    await when(() => !response.isPending);
    return response.models.map((crv) => crv.toJS());
  }

  const debouncedLoadOptions = debounce(loadOptions, DEFAULT_DEBOUNCE_WAIT);

  function handleChangeName(newValue?: string) {
    set(store, { name: newValue });
  }

  function handleSelectCreative(option: ICreativeAttributes) {
    set(store, {
      name: option.name,
      creativeId: option.id,
    });
  }

  const helpText =
    campaign && remainingBanners && remainingBanners <= 10 ? (
      <Trans t={t}>
        <p>
          Your remaining Banner creatives: <em>{{ count: Math.max(remainingBanners, 0) }}</em>.
          <br />
          You will only be able to add a Bannersnack creative to this campaign if you have not met
          your monthly limit for HTML creatives yet. Adding a Bannersnack creative to this campaign
          will contribute toward your monthly limit.
        </p>
      </Trans>
    ) : undefined;

  return (
    <ModalV1
      confirmButtonText={t('Clone')}
      confirmDisabled={!store.isValid()}
      controlled={true}
      onClose={onClose}
      onConfirm={handleConfirm}
      t={t}
      title={t('Clone existing creative')}
    >
      <Fieldset helpText={helpText}>
        {!creative && (
          <AsyncSelect
            components={{ Option: CreativeOption, SingleValue: CreativeSingleValue }}
            defaultOptions={true}
            label={t('Choose existing creative')}
            loadOptions={debouncedLoadOptions}
            name={'creative-select'}
            onSelectSingle={handleSelectCreative}
            placeholder={'Select or search by creative name...'}
          />
        )}
        {!!store.creativeId && (
          <Input
            helpText={t(
              'Provide a name to use for the new creative to distinguish it from the existing one.',
            )}
            label={t('Name')}
            onChange={handleChangeName}
            type={'text'}
            value={store.name}
          />
        )}
      </Fieldset>
    </ModalV1>
  );
}

export default observer(CloneCreativeModal);
