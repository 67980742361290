interface IValidateOptions {
  regex?: RegExp;
  message?: string;
}

/**
 * Custom validator to validate urls without protocol or path by default
 *
 * Standard url validator from validate.js does not allow urls without protocol
 */
function urlWithoutProtocol(value: string, options: IValidateOptions): string | undefined {
  if (!value) {
    return undefined;
  }

  /**
   * Protocols are not allowed (ex. http://, https://, ftp://)
   * www. is allowed
   * Anything following the TLD is not allowed (ex. subdomain.test.com/something)
   */
  const defaultPattern = /^[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}$/;

  const validatorOptions = {
    regex: defaultPattern,
    message: `'${value}' is not a valid url`,
    ...options,
  };

  const isValid = validatorOptions.regex.exec(value) !== null;
  return isValid ? undefined : validatorOptions.message;
}

export default urlWithoutProtocol;
