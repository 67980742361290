import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Importer } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { ContextMenu, TableColumnHeader } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow {
  original: Importer;
}

const OptionsCell = observer(({ original }: IRow) => {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const isComplete = original.get('state') === 'complete';
  const isDraft = original.get('state') === 'draft';
  const isError = original.get('errors_key');

  return (
    <ContextMenu buttonType={'icon'} position={'left-start'}>
      <ContextMenu.Item
        disabled={!isComplete}
        link={localUrl(`/data/segments/${original.get('segment')}/people`)}
        type={'link'}
      >
        {t('View Contacts')}
      </ContextMenu.Item>
      <ContextMenu.Item disabled={isDraft} href={original.downloadUrl} type={'link'}>
        {t('Download File')}
      </ContextMenu.Item>
      {isError && (
        <ContextMenu.Item href={original.downloadErrorUrl} type={'link'}>
          {t('Download Errors')}
        </ContextMenu.Item>
      )}
    </ContextMenu>
  );
});

export default (): IColumn<Importer> => ({
  id: 'options',
  checkboxLabel: 'Options',
  Header: TableColumnHeader({
    title: 'Options',
  }),
  width: 80,
  sortable: false,
  className: tableStyles.cellCenter,
  Cell(row): JSX.Element {
    return <OptionsCell {...row} />;
  },
});
