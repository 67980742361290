import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { DropResult } from 'react-smooth-dnd';
import { Container, Draggable, smoothDnD } from 'react-smooth-dnd';

import type { Campaign, Flight } from '@feathr/blackbox';
import { Card, ContextMenu, Input, Tooltip } from '@feathr/components';
import AddCampaignButton from '@feathr/extender/App/EventsPage/CampaignsPage/AddCampaignButton';
import CampaignIcons from '@feathr/extender/components/CampaignIcons';
import { StoresContext } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

import CampaignCard from '../CampaignCard';
import LegModalAddExistingCampaign from '../LegModalAddExistingCampaign';
import LegModalArchiveCampaigns from './LegModalArchiveCampaigns';
import LegModalMoveCampaigns from './LegModalMoveCampaigns';
import LegModalRemove from './LegModalRemove';
import SkeletonLeg from './SkeletonLeg';

import * as styles from './Leg.css';

smoothDnD.useTransformForGhost = true;

interface IProps {
  displayMode?: 'default' | 'condensed';
  flight: Flight;
  legId: number;
  onDrop: (params: DropResult & { legId: number }) => void;
  removeLeg: (index: number) => void;
}

function Leg({
  displayMode = 'default',
  flight,
  legId,
  onDrop,
  removeLeg,
}: IProps): JSX.Element | null {
  const { Campaigns } = useContext(StoresContext);
  const [name, setName] = useState<string | undefined>();
  const [showAddExistingModal, toggleAddExistingModal] = useToggle(false);
  const [showArchiveModal, toggleArchiveModal] = useToggle(false);
  const [showMoveModal, toggleMoveModal] = useToggle(false);
  const [showRemoveModal, toggleRemoveModal] = useToggle(false);
  const { t } = useTranslation();

  const legs = flight.get('legs');
  const leg = legs[legId];

  useEffect(() => {
    if (leg.name !== name) {
      setName(leg.name);
    }
  }, [leg.name]);

  // Make sure leg is still available.
  if (!leg) {
    return null;
  }

  const campaigns = Campaigns.list({
    filters: {
      flight: flight.id,
    },
  });

  if (campaigns.isPending) {
    return <SkeletonLeg displayMode={displayMode} />;
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.key !== 'Enter') {
      return;
    }
    if (event.currentTarget.value !== legs[legId].name) {
      legs[legId].name = event.currentTarget.value;
      flight.setAttributeDirty('legs');
      flight.patchDirty();
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>): void {
    if (event.target.value !== legs[legId].name) {
      legs[legId].name = event.target.value;
      flight.setAttributeDirty('legs');
      flight.patchDirty();
    }
  }

  function handleChange(newValue?: string): void {
    setName(newValue);
  }

  function handleDrop(params: DropResult): void {
    onDrop({ ...params, legId });
  }

  function handleRemoveLeg(): void {
    if (leg.campaigns.length) {
      toggleRemoveModal();
    } else {
      removeLeg(legId);
    }
  }

  function getChildPayload(childIndex): string | undefined {
    return leg.campaigns[childIndex];
  }

  async function postAddCampaignCallback(campaign: Campaign): Promise<void> {
    await runInAction(async () => {
      legs[legId].campaigns.push(campaign.id);
      flight.setAttributeDirty('legs');
      flight.patchDirty();
    });
  }

  const campaignsInLeg: Campaign[] = leg.campaigns
    .map((campaignId) => campaigns.models.find((campaign) => campaign.id === campaignId))
    .filter((campaign) => !!campaign) as Campaign[];

  // Need to dedupe campaigns in case dnd saved multiple times.
  const uniqueCampaignsInLeg: Campaign[] = campaignsInLeg.filter(
    (item, i) => campaignsInLeg.indexOf(item) === i,
  );

  return (
    <>
      <Card
        className={classNames(styles.root, {
          [styles.condensed]: displayMode === 'condensed',
        })}
        contentClassName={styles.content}
        nested={displayMode === 'condensed'}
      >
        {/* Dont remove this key or the child `Input` component wont re-render correctly. Thanks defaultValue. */}
        <div className={styles.header} key={leg.name}>
          {displayMode === 'condensed' ? (
            <h3>{leg.name}</h3>
          ) : (
            <Input
              design={'naked'}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type={'text'}
              value={name}
            />
          )}
          {displayMode === 'default' && (
            <span className={styles.actions}>
              <AddCampaignButton
                className={styles.button}
                flightId={flight.id}
                postSave={postAddCampaignCallback}
                // Set to null instead of undefined to override and remove default prefix.
                prefix={null}
                redirect={false}
                tooltip={t('Add new campaign')}
                type={'icon'}
              >
                <FontAwesomeIcon icon={faPlus} />
              </AddCampaignButton>
              <Tooltip title={t('More actions')}>
                <ContextMenu buttonType={'icon'}>
                  <ContextMenu.Item
                    onClick={toggleAddExistingModal}
                    prefix={getIconForAction('add')}
                  >
                    {t('Add existing campaigns')}
                  </ContextMenu.Item>
                  <ContextMenu.Divider />
                  <ContextMenu.Item
                    disabled={!leg.campaigns.length}
                    onClick={toggleMoveModal}
                    prefix={getIconForAction('move')}
                  >
                    {t('Move all campaigns in this leg')}
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    disabled={!leg.campaigns.length}
                    onClick={toggleArchiveModal}
                    prefix={getIconForAction('archive')}
                    theme={'danger'}
                  >
                    {t('Archive all campaigns in this leg')}
                  </ContextMenu.Item>
                  <ContextMenu.Divider />
                  <ContextMenu.Item
                    onClick={handleRemoveLeg}
                    prefix={getIconForAction('remove')}
                    theme={'danger'}
                  >
                    {t('Remove leg')}
                  </ContextMenu.Item>
                </ContextMenu>
              </Tooltip>
            </span>
          )}
        </div>
        {displayMode === 'default' ? (
          <Container
            dragClass={styles.drag}
            dropPlaceholder={{ className: styles.placeholder, showOnTop: true }}
            getChildPayload={getChildPayload}
            groupName={'primary'}
            onDrop={handleDrop}
          >
            {uniqueCampaignsInLeg.map((campaign) => (
              <Draggable key={campaign.id}>
                <CampaignCard campaign={campaign} />
              </Draggable>
            ))}
          </Container>
        ) : (
          <CampaignIcons campaigns={uniqueCampaignsInLeg} legIndex={legId} />
        )}
      </Card>
      {showAddExistingModal && (
        <LegModalAddExistingCampaign
          flight={flight}
          legId={legId}
          onClose={toggleAddExistingModal}
        />
      )}
      {showArchiveModal && (
        <LegModalArchiveCampaigns flight={flight} legId={legId} onClose={toggleArchiveModal} />
      )}
      {showMoveModal && (
        <LegModalMoveCampaigns flight={flight} legId={legId} onClose={toggleMoveModal} />
      )}
      {showRemoveModal && (
        <LegModalRemove
          flight={flight}
          legId={legId}
          onClose={toggleRemoveModal}
          removeLeg={removeLeg}
        />
      )}
    </>
  );
}

export default observer(Leg);
