import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode, Ref } from 'react';
import React, { forwardRef, useContext } from 'react';
import type { ActionMeta, ValueType } from 'react-select';
import type ReactSelect from 'react-select';

import type { User } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import type { IListOptions, IObject } from '@feathr/rachis';

import * as styles from './UserSelect.css';

interface IUserSelectProps {
  className?: string;
  isClearable?: boolean;
  isLoading?: boolean;
  label?: ReactNode;
  onClear: () => void;
  placeholder?: string;
  /** Additional query options */
  filters?: IObject;
  queryOptions?: Partial<IListOptions>;
}

export interface IUserSelectMultiProps extends IUserSelectProps {
  isMulti: true;
  onChange: (value?: string[]) => void;
  value?: string[];
}

export interface IUserSelectSingleProps extends IUserSelectProps {
  isMulti?: false;
  onChange: (value?: string) => void;
  value?: string;
}

function UserSelect(
  {
    className,
    isLoading,
    isMulti = false,
    label,
    onChange,
    onClear,
    placeholder,
    filters = { is_archived__ne: true },
    queryOptions,
    value,
    ...additionalProps
  }: IUserSelectMultiProps | IUserSelectSingleProps,
  ref: Ref<ReactSelect<User>>,
): JSX.Element {
  const { Users } = useContext(StoresContext);

  const users = Users.list(
    {
      only: ['email', 'id', 'name', 'picture'],
      pagination: {
        page: 0,
        page_size: 1000,
      },
      ordering: ['name', 'email'],
      filters: filters,
    },
    queryOptions,
  );

  function formatOptionLabel(optionValue: User): ReactNode {
    return optionValue.get('name', '').trim() || optionValue.get('email');
  }

  function handleChange(newValue: ValueType<User>, action: ActionMeta<User>): void {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        if (newValue.length) {
          (onChange as IUserSelectMultiProps['onChange'])((newValue as User[]).map((v) => v.id));
        } else {
          onClear();
        }
      } else if (newValue) {
        (onChange as IUserSelectSingleProps['onChange'])(
          newValue ? (newValue as User).id! : undefined,
        );
      } else {
        onClear();
      }
    }
  }

  const selectedOption = value
    ? Array.isArray(value)
      ? users.models.filter((option) => value.includes(option.id))
      : users.models.find((option) => option.id === value)
    : undefined;

  return (
    <Select<User>
      {...additionalProps}
      formatOptionLabel={formatOptionLabel}
      innerRef={ref}
      isLoading={users.isPending}
      isMulti={isMulti}
      label={label}
      name={'user-select'}
      onChange={handleChange}
      onClear={onClear}
      options={users.models}
      placeholder={placeholder || 'Select users...'}
      value={selectedOption}
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(forwardRef(UserSelect));
