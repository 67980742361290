import { computed } from 'mobx';

import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export type TTagContext = 'account' | 'event' | 'partner' | 'person' | 'person_segment';

export interface ITag extends IBaseAttributes {
  name: string;

  /**
   * When a user imports a contact list, a tag is autogenerated and applied to all persons created or updated by that import.
   *
   * This autogenerated tag is marked as internal upon creation to allow for easy identification on the frontend.
   * This enables the option to hide these tags from view if necessary.
   */
  internal: boolean;
  context?: TTagContext;
}

export class Tag extends Model<ITag> {
  public readonly className = 'Tag';

  public get constraints(): TConstraints<ITag> {
    return {
      name: {
        length: {
          maximum: 64,
          tooLong: '^Tag name must be less than 64 characters in length.',
        },
      },
    };
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Tag';
  }
}

export class Tags extends Collection<Tag> {
  public getClassName(): string {
    return 'tags';
  }

  public getModel(attributes: Partial<ITag>): Tag {
    return new Tag(attributes);
  }
}
