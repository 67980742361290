import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCrow, faDove, faFeather } from '@fortawesome/pro-regular-svg-icons';
import type { TFunction } from 'i18next';

import { ELicensePackage, EPackageTiers } from '@feathr/blackbox';
import { cssVar } from '@feathr/hooks';

export const packageLicenseToLabelMap = (t: TFunction, packageLicense: ELicensePackage): string => {
  const map: Record<ELicensePackage, string> = {
    // Finch
    [ELicensePackage.Finch]: t('Finch'),
    [ELicensePackage.FinchLicense]: t('Finch License'),
    [ELicensePackage.FinchLicenseExtended]: t('Finch License - Extended'),
    [ELicensePackage.FinchLegacy]: t('Finch Legacy'),
    [ELicensePackage.FinchLegacyLicense]: t('Finch Legacy License'),

    // Phoenix
    [ELicensePackage.Phoenix]: t('Phoenix'),
    [ELicensePackage.PhoenixLicense]: t('Phoenix License'),
    [ELicensePackage.PhoenixLicenseExtended]: t('Phoenix License - Extended'),

    // Falcon
    [ELicensePackage.Falcon]: t('Falcon'),
    [ELicensePackage.FalconLicense]: t('Falcon License'),
    [ELicensePackage.FalconLicenseExtended]: t('Falcon License - Extended'),

    // Enterprise
    [ELicensePackage.EnterpriseFlamingo]: t('Enterprise Flamingo'),
    [ELicensePackage.EnterpriseMarketingSeat]: t('Enterprise Marketing Seat'),
    [ELicensePackage.EnterpriseUnlimitedMarketingSeats]: t('Enterprise Unlimited Marketing Seats'),
    [ELicensePackage.EnterpriseMonetizationSeat]: t('Enterprise Monetization Seat'),
    [ELicensePackage.EnterpriseUnlimitedMonetizationSeats]: t(
      'Enterprise Unlimited Monetization Seats',
    ),
    [ELicensePackage.EnterpriseFalcon]: t('Enterprise Falcon'),
    [ELicensePackage.EnterpriseFalconSeat]: t('Enterprise Falcon Seat'),
    [ELicensePackage.EnterpriseFalconUnlimitedSeats]: t('Enterprise Falcon Unlimited Seats'),

    // Feathr Platform
    [ELicensePackage.FeathrPlatformBase]: t('Feathr Platform - Base'),
    [ELicensePackage.FeathrPlatformStarter]: t('Feathr Platform - Starter'),
    [ELicensePackage.FeathrPlatformStarterPro]: t('Feathr Platform - Starter'),
    [ELicensePackage.FeathrPlatformEssential]: t('Feathr Platform - Essential'),
    [ELicensePackage.FeathrPlatformEssentialPro]: t('Feathr Platform - Essential Pro'),
    [ELicensePackage.FeathrPlatformAdvanced]: t('Feathr Platform - Advanced'),
    [ELicensePackage.FeathrPlatformAdvancedPro]: t('Feathr Platform - Advanced Pro'),
    [ELicensePackage.FeathrPlatformEnterprise]: t('Feathr Platform - Enterprise'),
    [ELicensePackage.FeathrPlatformEnterprisePro]: t('Feathr Platform - Enterprise Pro'),
  };

  return map[packageLicense] ?? t('Invalid license');
};

export const packageTiersToLabelMap = (t: TFunction, packageTier?: EPackageTiers): string => {
  const map: Record<EPackageTiers, string> = {
    [EPackageTiers.EnterpriseFalcon]: t('Enterprise Falcon'),
    [EPackageTiers.Finch]: t('Finch'),
    [EPackageTiers.Falcon]: t('Falcon'),
    [EPackageTiers.Phoenix]: t('Phoenix'),
    [EPackageTiers.Base]: t('Base'),
    [EPackageTiers.Starter]: t('Starter'),
    [EPackageTiers.Essential]: t('Essential'),
    [EPackageTiers.Advanced]: t('Advanced'),
    [EPackageTiers.Enterprise]: t('Enterprise'),
  };

  return packageTier ? map[packageTier] : t('None');
};
export const packageLicenseTierToLabelMap = (
  t: TFunction,
  packageLicense: ELicensePackage,
): string => {
  const map: Partial<Record<ELicensePackage, string>> = {
    // Finch Tier
    [ELicensePackage.Finch]: packageTiersToLabelMap(t, EPackageTiers.Finch),
    [ELicensePackage.FinchLicenseExtended]: packageTiersToLabelMap(t, EPackageTiers.Finch),
    [ELicensePackage.FinchLegacyLicense]: packageTiersToLabelMap(t, EPackageTiers.Finch),
    [ELicensePackage.FinchLicense]: packageTiersToLabelMap(t, EPackageTiers.Finch),

    // Falcon Tier
    [ELicensePackage.Falcon]: packageTiersToLabelMap(t, EPackageTiers.Falcon),
    [ELicensePackage.FalconLicense]: packageTiersToLabelMap(t, EPackageTiers.Falcon),
    [ELicensePackage.EnterpriseFalconSeat]: packageTiersToLabelMap(t, EPackageTiers.Falcon),
    [ELicensePackage.EnterpriseFalconUnlimitedSeats]: packageTiersToLabelMap(
      t,
      EPackageTiers.Falcon,
    ),

    // Phoenix Tier
    [ELicensePackage.Phoenix]: packageTiersToLabelMap(t, EPackageTiers.Phoenix),
    [ELicensePackage.PhoenixLicense]: packageTiersToLabelMap(t, EPackageTiers.Phoenix),
    [ELicensePackage.PhoenixLicenseExtended]: packageTiersToLabelMap(t, EPackageTiers.Phoenix),

    // Platform Tier
    [ELicensePackage.FeathrPlatformBase]: packageTiersToLabelMap(t, EPackageTiers.Base),
    [ELicensePackage.FeathrPlatformStarter]: packageTiersToLabelMap(t, EPackageTiers.Starter),
    [ELicensePackage.FeathrPlatformStarterPro]: packageTiersToLabelMap(t, EPackageTiers.Starter),
    [ELicensePackage.FeathrPlatformEssential]: packageTiersToLabelMap(t, EPackageTiers.Essential),
    [ELicensePackage.FeathrPlatformEssentialPro]: packageTiersToLabelMap(
      t,
      EPackageTiers.Essential,
    ),
    [ELicensePackage.FeathrPlatformAdvanced]: packageTiersToLabelMap(t, EPackageTiers.Advanced),
    [ELicensePackage.FeathrPlatformAdvancedPro]: packageTiersToLabelMap(t, EPackageTiers.Advanced),
    [ELicensePackage.FeathrPlatformEnterprise]: packageTiersToLabelMap(t, EPackageTiers.Enterprise),
    [ELicensePackage.FeathrPlatformEnterprisePro]: packageTiersToLabelMap(
      t,
      EPackageTiers.Enterprise,
    ),
  };

  // if the packageLicense is not in the map, return the label
  return map[packageLicense] ?? packageLicenseToLabelMap(t, packageLicense);
};

export const packageLicenseToIconMap = (packageLicense?: ELicensePackage): IconDefinition => {
  const map: Record<ELicensePackage, IconDefinition> = {
    // Finch
    [ELicensePackage.Finch]: faDove,
    [ELicensePackage.FinchLicense]: faDove,
    [ELicensePackage.FinchLicenseExtended]: faDove,
    [ELicensePackage.FinchLegacy]: faDove,
    [ELicensePackage.FinchLegacyLicense]: faDove,

    // Phoenix
    [ELicensePackage.Phoenix]: faFeather,
    [ELicensePackage.PhoenixLicense]: faFeather,
    [ELicensePackage.PhoenixLicenseExtended]: faFeather,

    // Falcon
    [ELicensePackage.Falcon]: faCrow,
    [ELicensePackage.FalconLicense]: faCrow,
    [ELicensePackage.FalconLicenseExtended]: faCrow,

    // Flamingo
    [ELicensePackage.EnterpriseFlamingo]: faCrow,

    // Enterprise
    [ELicensePackage.EnterpriseMarketingSeat]: faFeather,
    [ELicensePackage.EnterpriseUnlimitedMarketingSeats]: faFeather,
    [ELicensePackage.EnterpriseMonetizationSeat]: faFeather,
    [ELicensePackage.EnterpriseUnlimitedMonetizationSeats]: faFeather,

    // Enterprise Falcon
    [ELicensePackage.EnterpriseFalcon]: faFeather,
    [ELicensePackage.EnterpriseFalconSeat]: faFeather,
    [ELicensePackage.EnterpriseFalconUnlimitedSeats]: faFeather,

    // Feathr Platform
    [ELicensePackage.FeathrPlatformBase]: faFeather,
    [ELicensePackage.FeathrPlatformStarter]: faFeather,
    [ELicensePackage.FeathrPlatformStarterPro]: faFeather,
    [ELicensePackage.FeathrPlatformEssential]: faFeather,
    [ELicensePackage.FeathrPlatformEssentialPro]: faFeather,
    [ELicensePackage.FeathrPlatformAdvanced]: faFeather,
    [ELicensePackage.FeathrPlatformAdvancedPro]: faFeather,
    [ELicensePackage.FeathrPlatformEnterprise]: faFeather,
    [ELicensePackage.FeathrPlatformEnterprisePro]: faFeather,
  };
  return packageLicense ? map[packageLicense] : faFeather;
};

export const packageLicenseColorMap = (packageLicense?: ELicensePackage): string => {
  const map: Record<ELicensePackage, string> = {
    // Finch
    [ELicensePackage.Finch]: cssVar('--color-primary-3'),
    [ELicensePackage.FinchLicense]: cssVar('--color-primary-3'),
    [ELicensePackage.FinchLicenseExtended]: cssVar('--color-primary-3'),
    [ELicensePackage.FinchLegacy]: cssVar('--color-primary-3'),
    [ELicensePackage.FinchLegacyLicense]: cssVar('--color-primary-3'),

    // Phoenix
    [ELicensePackage.Phoenix]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.PhoenixLicense]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.PhoenixLicenseExtended]: cssVar('--color-brand-yellow-dark'),

    // Falcon
    [ELicensePackage.Falcon]: cssVar('--color-brand-purple'),
    [ELicensePackage.FalconLicense]: cssVar('--color-brand-purple'),
    [ELicensePackage.FalconLicenseExtended]: cssVar('--color-brand-purple'),

    // Flamingo
    [ELicensePackage.EnterpriseFlamingo]: cssVar('--color-brand-purple-light'),

    // Enterprise
    [ELicensePackage.EnterpriseMarketingSeat]: cssVar('--color-brand-orange'),
    [ELicensePackage.EnterpriseUnlimitedMarketingSeats]: cssVar('--color-brand-orange'),
    [ELicensePackage.EnterpriseMonetizationSeat]: cssVar('--color-brand-orange'),
    [ELicensePackage.EnterpriseUnlimitedMonetizationSeats]: cssVar('--color-brand-orange'),

    // Enterprise Falcon
    [ELicensePackage.EnterpriseFalcon]: cssVar('--color-brand-orange'),
    [ELicensePackage.EnterpriseFalconSeat]: cssVar('--color-brand-orange'),
    [ELicensePackage.EnterpriseFalconUnlimitedSeats]: cssVar('--color-brand-orange'),

    // Feathr Platform
    [ELicensePackage.FeathrPlatformBase]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformStarter]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformStarterPro]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformEssential]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformEssentialPro]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformAdvanced]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformAdvancedPro]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformEnterprise]: cssVar('--color-brand-yellow-dark'),
    [ELicensePackage.FeathrPlatformEnterprisePro]: cssVar('--color-brand-yellow-dark'),
  };
  return packageLicense ? map[packageLicense] : cssVar('--color-primary-3');
};
