import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { set } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import type { IEditableField } from '@feathr/blackbox';
import type { Event } from '@feathr/blackbox';
import {
  Button,
  Card,
  Checkbox,
  Fieldset,
  Input,
  NumberInput,
  Select,
  Tooltip,
} from '@feathr/components';
import { FieldOption } from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './PartnerField.css';

interface IFieldProps {
  field: IEditableField;
  onRemove: (f_key: string) => void;
  event: Event;
}

const defaultPartnerFields = [
  {
    collection: 'Partner',
    data_type: 'str',
    form_type: 'image',
    is_default: true,
    f_key: 'logo',
    u_key: 'Logo',
    id: 'logo',
  },
  {
    collection: 'Partner',
    data_type: 'str',
    form_type: 'text',
    is_default: true,
    f_key: 'name',
    u_key: 'Name',
    id: 'name',
    required: true,
  },
  {
    collection: 'Partner',
    data_type: 'str',
    form_type: 'text',
    is_default: true,
    f_key: 'email',
    u_key: 'Primary email',
    id: 'email',
  },
  {
    collection: 'Partner',
    data_type: 'str',
    form_type: 'text',
    is_default: true,
    f_key: 'website',
    u_key: 'Website',
    id: 'website',
  },
  {
    collection: 'Partner',
    data_type: 'str',
    form_type: 'textarea',
    is_default: true,
    f_key: 'description',
    u_key: 'Description',
    id: 'description',
  },
];

function PartnerField({ field, onRemove, event }: IFieldProps) {
  const { CustomFields } = useContext(StoresContext);
  const customFields = CustomFields.list({
    filters: { collection: 'Partner' },
    pagination: {
      page: 0,
      page_size: 1000,
    },
  });
  const alreadySelected = (event.get('editable_partner_field_list') as IEditableField[]).map(
    (f) => f.f_key,
  );
  const fieldOptions = [...defaultPartnerFields, ...customFields.models.map((f) => f.toJS())];
  const filteredOptions = fieldOptions.filter(
    (f) => f.f_key === field.f_key || !alreadySelected.includes(f.f_key),
  );
  const formTypeOptions = [
    { id: 'text', name: 'Text' },
    { id: 'textarea', name: 'Long Text' },
    { id: 'image', name: 'Image' },
    { id: 'number', name: 'Number' },
    { id: 'date', name: 'Date' },
    { id: 'list', name: 'List' },
    { id: 'checkbox', name: 'Checkbox' },
  ];
  const isName = field.f_key === 'name';

  function handleLabelChange(newValue?: string) {
    set(field, 'label', newValue);
    event.setAttributeDirty('editable_partner_field_list');
  }

  function handleDescriptionChange(newValue?: string) {
    set(field, 'description', newValue);
    event.setAttributeDirty('editable_partner_field_list');
  }

  function handleCharLimitChange(newValue?: number) {
    set(field, 'character_limit', newValue);
    event.setAttributeDirty('editable_partner_field_list');
  }

  return (
    <Card
      actions={[
        <Tooltip key={'remove'} title={'Remove'}>
          <Button disabled={isName} onClick={() => onRemove(field.f_key)} type={'naked'}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>,
      ]}
      className={styles.fieldCard}
      secondary={true}
    >
      <Fieldset>
        <Select
          components={{ Option: FieldOption }}
          getOptionLabel={(option) => option.u_key}
          isLoading={customFields.isPending}
          label={'Field'}
          name={'partner-field-select'}
          onSelectSingle={(fieldOption) => {
            set(field, 'f_key', fieldOption.f_key);
            set(field, 'u_key', fieldOption.u_key);
            set(field, 'label', fieldOption.u_key);
            set(field, 'is_default', fieldOption.is_default);
            set(field, 'data_type', fieldOption.data_type);
            event.setAttributeDirty('editable_partner_field_list');
          }}
          options={filteredOptions}
          value={fieldOptions.find((f) => f.f_key === field.f_key)}
        />
        <Input
          label={'Label'}
          onChange={handleLabelChange}
          type={'text'}
          value={field.label || field.u_key}
        />
        <Input
          label={'Description'}
          onChange={handleDescriptionChange}
          type={'text'}
          value={field.description}
        />
        <Select
          label={'Form Input Type'}
          name={'partner-field-type'}
          onSelectSingle={(option) => {
            set(field, 'form_type', option.id);
            event.setAttributeDirty('editable_partner_field_list');
          }}
          options={formTypeOptions}
          value={formTypeOptions.find((option) => option.id === field.form_type)}
        />
        <Checkbox
          label={'Disabled'}
          onChange={() => {
            set(field, 'read_only', !field.read_only);
            event.setAttributeDirty('editable_partner_field_list');
          }}
          value={field.read_only}
        />
        <Checkbox
          disabled={isName}
          helpPlacement={'bottom'}
          helpText={
            'If checked, partners will have to include some value for this field in order to save changes to their information.'
          }
          label={'Required'}
          onChange={() => {
            set(field, 'required', !field.required);
            event.setAttributeDirty('editable_partner_field_list');
          }}
          value={field.required || isName}
        />
        {['text', 'textarea'].includes(field.form_type) && (
          <NumberInput
            helpText={
              'The maximum length of the value of the field in characters. Leave blank or set to 0 to set no limit.'
            }
            label={'Character Limit'}
            onChange={handleCharLimitChange}
            value={field.character_limit}
          />
        )}
      </Fieldset>
    </Card>
  );
}

export default observer(PartnerField);
