import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Font } from '@feathr/blackbox';
import { ArchiveModalV1, ContextMenu } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

interface IOptionsColumnProps {
  font: Font;
}

function FontOptions({ font }: Readonly<IOptionsColumnProps>): JSX.Element {
  const rootStore = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <StoresContext.Provider value={rootStore}>
          <ContextMenu.Item
            link={localUrl(font.getItemUrl('edit'))}
            prefix={getIconForAction('edit')}
          >
            {t('Edit')}
          </ContextMenu.Item>
          <ContextMenu.Item
            onClick={toggleArchiveModalOpen}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Delete')}
          </ContextMenu.Item>
        </StoresContext.Provider>
      </ContextMenu>
      {isArchiveModalOpen && (
        <ArchiveModalV1
          message={t('Are you sure you want to delete this font?')}
          method={'delete'}
          model={font}
          onClose={toggleArchiveModalOpen}
          t={t}
          title={t('Delete Font')}
        />
      )}
    </>
  );
}

export default observer(FontOptions);
