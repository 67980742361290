import debounce from 'debounce-promise';
import { observable, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  EmailVerification,
  IPinpointPartnerMessage,
  PinpointPartnerMessage,
} from '@feathr/blackbox';
import { EPinpointRequestStatus } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { Button, ButtonValid, EmailSelect, Fieldset, Form, Input, toast } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, flattenError, flattenErrors, useDebounce } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IButtonProps {
  message: PinpointPartnerMessage;
  onNext: () => void;
  emailVerification?: EmailVerification;
}
interface IProps extends Omit<IButtonProps, 'emailVerification'> {
  disabled?: boolean;
  onPrevious: () => void;
}

interface IErrors extends TValidateGrouped {
  from_address?: string[];
  from_name?: string[];
  status?: string[];
}

export function validateStepTwo(
  message: PinpointPartnerMessage,
  emailVerification?: EmailVerification,
): IErrors {
  const messageErrors = message.validate<IErrors>(
    ['from_address', 'from_name'],
    false,
    'grouped',
  ).errors;
  if (Object.keys(messageErrors).length) {
    return messageErrors;
  }

  const emailVerificationErrors =
    emailVerification?.validate<IErrors>(['status'], false, 'grouped').errors ??
    (observable({}) as IErrors);

  const status = emailVerification?.get('status') ?? 'Unverified';

  if (!emailVerificationErrors.status) {
    emailVerificationErrors.status = [] as string[];
  }

  if (status === 'Success') {
    // Do nothing.
  } else if (status === 'Pending') {
    emailVerificationErrors.status.push('Your email is still pending verification.');
  } else {
    emailVerificationErrors.status.push('Your email is unverified.');
  }

  return emailVerificationErrors;
}

const NextStepButton = observer(
  ({ message, emailVerification, onNext }: IButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const errors = validateStepTwo(message, emailVerification);
    return (
      <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  },
);

function EventPartnersMessageStepTwo({
  disabled = false,
  message,
  onNext,
  onPrevious,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { EmailVerifications } = useContext(StoresContext);
  const [fromAddress, setFromAddress] = useDebounce(message.get('from_address'));
  const [emailVerification, setEmailVerification] = useState<EmailVerification | undefined>();
  const [hadFocusFromAddress, setHadFocusFromAddress] = useState(false);
  const [isPending, setIsPending] = useState(true);

  const getEmailStatus = useCallback(
    debounce(async (fromAddress: string) => {
      await when(() => !message.isPending);

      setIsPending(true);
      const emailVerifications = EmailVerifications.list(
        { filters: { email: fromAddress } },
        { reset: true },
      );
      await when(() => !emailVerifications.isPending);
      setIsPending(false);
      const newEmailVerification =
        !emailVerifications.isErrored && emailVerifications.models.length === 1
          ? emailVerifications.models[0]
          : undefined;
      setEmailVerification(newEmailVerification);

      return newEmailVerification;
    }, DEFAULT_DEBOUNCE_WAIT),
    [EmailVerifications],
  );

  const validationErrors = validateStepTwo(message, emailVerification);
  const emailStatus = emailVerification?.get('status') ?? 'Unverified';

  useEffect(() => {
    getEmailStatus(fromAddress).then((newEmailVerification) => {
      const patch: Partial<IPinpointPartnerMessage> = {
        from_name: message.get('from_name'),
      };
      if (newEmailVerification && newEmailVerification.get('status') === 'Success') {
        if (
          message.get('from_address') === message.initialAttributes.from_address &&
          message.initialAttributes.from_name
        ) {
          // Resetting to original email, so restore changed from_name
          patch.from_name =
            message.initialAttributes.from_name || newEmailVerification.get('from_name');
          patch.address = message.initialAttributes.address || newEmailVerification.get('address');
        } else {
          // We can safely overwrite from_name, address
          patch.from_name = newEmailVerification.get('from_name');
          patch.address = newEmailVerification.get('address');
        }
        message.set(patch);

        // Use patch to compare because message will still be awaiting update.
      }
    });
  }, [getEmailStatus, fromAddress, message]);

  function createOption(inputValue: string): ISelectOption {
    return { name: inputValue, id: inputValue };
  }

  async function loadOptions(inputValue: string): Promise<ISelectOption[]> {
    const data = EmailVerifications.list({
      filters: inputValue ? { email: { $regex: inputValue, $options: 'i' } } : {},
      ordering: ['email'],
    });
    await when(() => !data.isPending);
    return data.models.map((model) => {
      const email = model.get('email');
      return { id: email, name: email };
    });
  }

  function handleBlurFromAddress(): void {
    setHadFocusFromAddress(true);
  }

  function handleChangeFromAddress(email?: string): void {
    setFromAddress(email ?? '');
    message.set({ from_address: email });
  }

  async function handleVerify(): Promise<void> {
    const updatedEmailVerification = await getEmailStatus(fromAddress);
    const updatedEmailStatus = updatedEmailVerification?.get('status') ?? 'Unverified';
    if (updatedEmailStatus === 'Success') {
      toast(t('This email address has been verified.'), { type: ToastType.SUCCESS });
      return;
    }

    if (updatedEmailStatus === 'Pending') {
      // Status was updated by getEmailStatus().
      toast(t('Check your email for a verification link.'), { type: ToastType.INFO });
      return;
    }

    const model = EmailVerifications.create({ email: message.get('from_address') });
    const response = await EmailVerifications.add(model);
    if (response.isErrored) {
      toast(t('There was a problem trying to send a verification email.'), {
        type: ToastType.ERROR,
      });
    } else {
      setEmailVerification(response);
      const isVerified = response.get('status') === EPinpointRequestStatus.Success;
      toast(
        isVerified
          ? t('Your email address {{fromAddress}} is verified', {
              fromAddress: message.get('from_address'),
            })
          : t(
              'A verification link has been sent to {{fromAddress}}. This link will expire in 24 hours.',
              { fromAddress: message.get('from_address') },
            ),
        { type: ToastType.INFO },
      );
    }
  }

  async function handleResend(): Promise<void> {
    if (emailVerification) {
      const response = await emailVerification.resend();
      if (emailVerification.isErrored) {
        toast(t('There was a problem trying to resend a verification email.'), {
          type: ToastType.ERROR,
        });
        // eslint-disable-next-line no-console
        console.error(response);
      } else {
        toast(
          t(
            'A verification link has been resent to {{email}}. This link will expire in 24 hours.',
            { email: message.get('from_address') },
          ),
          { type: ToastType.INFO },
        );
      }
    }
  }

  return (
    <Form
      actions={[
        <Button key={'previous'} name={'previous_step'} onClick={onPrevious}>
          Previous
        </Button>,
        <NextStepButton
          emailVerification={emailVerification}
          key={'next'}
          message={message}
          onNext={onNext}
        />,
      ]}
      description={
        <Trans t={t}>
          <p>
            Please provide an email address you'd like to use for this partner message. All emails
            sent to your partners or recipient list will originate from this email address.
          </p>
        </Trans>
      }
      label={t('Edit Message: Sender Info')}
    >
      <Fieldset>
        <EmailSelect
          createOption={createOption}
          disabled={disabled}
          helpText={t("Enter the address you'd like to send emails from.")}
          isLoading={message.isPending || isPending}
          label={t('From email address')}
          loadOptions={loadOptions}
          name={'from_address'}
          onBlur={handleBlurFromAddress}
          onChange={handleChangeFromAddress}
          onVerify={handleVerify}
          required={true}
          status={
            // Success maps to Verified in this component
            emailStatus === 'Success' ? 'Verified' : emailStatus
          }
          t={t}
          validationError={
            hadFocusFromAddress ? flattenError(validationErrors.from_address) : undefined
          }
          value={message.get('from_address')}
        />
        <Input
          attribute={'from_name'}
          disabled={disabled}
          helpPlacement={'bottom'}
          helpText={
            <Trans t={t}>
              The "Friendly" component of the email sender. Shows as the name in email inboxes. Use
              either your personal name or a recognizable company name. Here are some{' '}
              <a
                href={
                  'https://www.linkedin.com/pulse/20141121154623-168033089-what-makes-a-friendly-from-a-comprehensive-guide-to-email-from-names/'
                }
                target={'_blank'}
              >
                tips on how to pick a good "from name"
              </a>
              .
            </Trans>
          }
          label={t('From name')}
          model={message}
          name={'from_name'}
          required={true}
          type={'text'}
        />
        {!!emailVerification && emailStatus !== 'Success' && (
          <Button name={'resend_verification'} onClick={handleResend} type={'link'}>
            {t('Resend verification email')}
          </Button>
        )}
      </Fieldset>
    </Form>
  );
}

export default observer(EventPartnersMessageStepTwo);
