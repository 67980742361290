import { Campaign } from './campaign';
import type { TLandingPageCampaign } from './types';
import { CampaignClass } from './types';

export class LandingPageCampaign extends Campaign<TLandingPageCampaign> {}

// Function that allows type narrowing for Landing Page Campaigns.
export function isLandingPageCampaign(campaign: Campaign): campaign is LandingPageCampaign {
  return campaign.get('_cls') === CampaignClass.LandingPage;
}
