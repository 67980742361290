import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IPerson, Person, Segment } from '@feathr/blackbox';
import { DebouncedInput, Input, Table, toast } from '@feathr/components';
import { StoresContext, useUser } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';
import type { IListParams } from '@feathr/rachis';

import DataConfigure from '../DataConfigure';
import { filterPersonColumns } from '../PersonColumns';
import RefreshController from '../RefreshController';

import * as styles from './PersonsTable.css';

export type TVariant = 'default' | 'known' | 'anonymous';

interface IProps {
  segment: Segment;
  variant?: TVariant;
}

const defaultPersonColumnIds: Record<TVariant, string[]> = {
  default: [
    'name',
    'email',
    'date_last_seen',
    'reachable',
    'seen_count',
    'geoip.country_code',
    'options',
  ],
  known: [
    'name',
    'email',
    'occupation',
    'companies',
    'phone',
    'date_last_seen',
    'seen_count',
    'options',
  ],
  anonymous: [
    'name',
    'reachable',
    'date_created',
    'date_last_seen',
    'seen_count',
    'geoip.country_code',
    'options',
  ],
};

function PersonsTable({ segment, variant = 'default' }: IProps): JSX.Element {
  const { CustomFields, Persons } = useContext(StoresContext);
  const [columnIds, setColumnIds] = useState(defaultPersonColumnIds[variant]);
  const [keywords, setKeywords] = useState<string | undefined>(undefined);
  const [reset, setReset] = useState(false);
  const [timeout, setTimeout] = useState<number | undefined>();
  const [shouldAutoRefresh, toggleAutoRefresh] = useToggle(false);
  const user = useUser();
  const { t } = useTranslation();

  // When variant changes, reset columnIds to the current variant's defaults.
  useEffect(() => {
    setColumnIds(defaultPersonColumnIds[variant]);
  }, [variant]);

  useEffect(() => {
    // Stop resetting as soon as we have reloaded.
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (!shouldAutoRefresh) {
      return;
    }

    // TODO: Create reusable hook for polling
    function poll(): void {
      setReset(true);
      // 60000ms = 1min, that's how often we want to auto-refresh the table
      setTimeout(window.setTimeout(poll, 60000, setReset, setTimeout));
    }

    if (timeout === undefined) {
      try {
        poll();
      } catch (e) {
        toast(t('There was an error updating your people. Please refresh the page.'), {
          type: ToastType.ERROR,
        });
      }
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [t, timeout, setTimeout, shouldAutoRefresh]);

  // Some users may have checked off columns to hide them, so we need to make sure these are always shown.
  const alwaysShownColumns = ['name', 'options'];

  useEffect(() => {
    if (!user.isPending) {
      const userColumns = user.getSetting('persons_column_ids');
      if (userColumns && userColumns.length > 0) {
        setColumnIds([...userColumns, ...alwaysShownColumns]);
      }
      setColumnIds(defaultPersonColumnIds[variant]);
    }
  }, [user, variant]);

  const fields = CustomFields.list({
    filters: {
      collection: 'Person',
    },
  });

  function handleDebouncedSearchChange(newValue?: string): void {
    setKeywords(newValue);
  }

  function handleRefresh(): void {
    setReset(true);
  }

  function onChangeSettings(newColumnIds: string[]): void {
    user.setSetting('persons_column_ids', newColumnIds);
    setColumnIds(newColumnIds);
    user.patchDirty();
  }

  const filterElements = [
    <>
      <DebouncedInput<string> defaultValue={keywords} onChange={handleDebouncedSearchChange}>
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            className={styles.input}
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by name, email, title, or company...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
    <>
      <DataConfigure columnIds={columnIds} mode={'persons'} onChange={onChangeSettings} />
      <RefreshController
        handleRefresh={handleRefresh}
        shouldRefresh={shouldAutoRefresh}
        toggleRefresh={toggleAutoRefresh}
      />
    </>,
  ];

  const params = {
    keywords,
    predicates: toJS(segment.get('predicates', [])),
    mode: segment.get('mode'),
    lookback_mode: segment.get('lookback_mode'),
    lookback_value: segment.get('lookback_value'),
    exclude: ['breadcrumbs'],
  } as IListParams<IPerson>;

  if (segment.get('parent_segment')) {
    params['parent_segment'] = segment.get('parent_segment');
  }

  // TODO: Add 'x of y results' to table
  return (
    <Table<Person>
      collection={Persons}
      columns={filterPersonColumns(columnIds, fields.models, t)}
      filterElements={filterElements}
      filterLabel={t('Quick search')}
      initialSort={[{ id: 'date_last_seen', desc: true }]}
      listOptions={{ url: Persons.url('page'), reset }}
      params={params}
    />
  );
}

export default observer(PersonsTable);
