import type { JSX } from 'react';
import React from 'react';

import IntegrationsCard from './IntegrationsCard';
import type { INativeIntegrationCardProps } from './NativeIntegrations';

import bizzaboLogo from 'images/zapier_brands/blizzabo.png';
import bloomerang from 'images/zapier_brands/bloomerang.png';
import classyLogo from 'images/zapier_brands/classy.png';
import cventLogo from 'images/zapier_brands/cvent.png';
import eventbriteLogo from 'images/zapier_brands/eventbrite.png';
import fundraiseUpLogo from 'images/zapier_brands/fundraise_up.png';
import higherLogicLogo from 'images/zapier_brands/higher_logic.png';
import hopinLogo from 'images/zapier_brands/hopin.png';
import hubspotLogo from 'images/zapier_brands/hubspot.png';
import mailchimpLogo from 'images/zapier_brands/mailchimp.png';
import salesforceLogo from 'images/zapier_brands/salesforce.png';
import virtuousLogo from 'images/zapier_brands/virtuous.png';
import wordpressLogo from 'images/zapier_brands/wordpress.png';
import zapierLogo from 'images/zapier_brands/zapier.png';

export interface IZapierIntegrationsCardProps extends INativeIntegrationCardProps {
  helpDesk: string;
  /** The secondary URL. It shows the user that they can can create actions from this integration with Feathr. */
  integrationOptionsURL: string;
}

const zapierIntegrations: IZapierIntegrationsCardProps[] = [
  {
    name: 'Classy',
    url: 'https://zapier.com/apps/classy/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/classy/integrations/feathr',
    logo: classyLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/26647125396119-How-to-Integrate-Feathr-and-Classy',
  },
  {
    name: 'Cvent',
    url: 'https://zapier.com/apps/cvent/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/cvent',
    logo: cventLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460251374103-How-to-Integrate-Feathr-and-Cvent',
  },
  {
    name: 'Bizzabo',
    url: 'https://zapier.com/apps/bizzabo/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/bizzabo',
    logo: bizzaboLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460349533719-How-to-Integrate-Feathr-and-Bizzabo',
  },
  {
    name: 'Bloomerang',
    url: 'https://zapier.com/apps/bloomerang/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/bloomerang',
    logo: bloomerang,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460449117719-How-to-Integrate-Feathr-and-Bloomerang',
  },
  {
    name: 'Eventbrite',
    url: 'https://zapier.com/apps/eventbrite/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/eventbrite',
    logo: eventbriteLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460511822359-How-to-Integrate-Feathr-and-Eventbrite',
  },
  {
    name: 'Fundraise Up',
    url: 'https://zapier.com/apps/fundraiseup/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/fundraiseup/integrations/feathr',
    logo: fundraiseUpLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/26647136098711-How-to-Integrate-Feathr-and-FundraiseUp  ',
  },
  {
    name: 'Higher Logic',
    url: 'https://zapier.com/apps/higher-logic/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/higher-logic/integrations/feathr',
    logo: higherLogicLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/26648130619287-How-to-Integrate-Feathr-and-Higher-Logic',
  },
  {
    name: 'Hopin',
    url: 'https://zapier.com/apps/hopin/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/hopin',
    logo: hopinLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460711843863-How-to-Integrate-Feathr-and-Hopin',
  },
  {
    name: 'HubSpot',
    url: 'https://zapier.com/apps/hubspot/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/hubspot',
    logo: hubspotLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9455026528535-How-to-Integrate-Feathr-and-Hubspot',
  },
  {
    name: 'Mailchimp',
    url: 'https://zapier.com/apps/mailchimp/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/mailchimp',
    logo: mailchimpLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9455019907223-How-to-Integrate-Feathr-and-Mailchimp',
  },
  {
    name: 'Salesforce',
    url: 'https://zapier.com/apps/salesforce/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/salesforce',
    logo: salesforceLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460248409879-How-to-Integrate-Feathr-and-Salesforce',
  },
  {
    name: 'Virtuous',
    url: 'https://zapier.com/apps/virtuous-crm/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/virtuous-crm/integrations/feathr',
    logo: virtuousLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/26647119300759-How-to-Integrate-Feathr-and-Virtuous',
  },
  {
    name: 'Wordpress',
    url: 'https://zapier.com/apps/wordpress/integrations',
    integrationOptionsURL: 'https://zapier.com/apps/feathr/integrations/wordpress',
    logo: wordpressLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460665698455-How-to-Integrate-Feathr-and-Wordpress',
  },
];

const allOtherZapierIntegrations: INativeIntegrationCardProps = {
  name: 'All Integrations',
  url: 'https://zapier.com/apps/feathr',
  logo: zapierLogo,
};

function ZapierIntegrationCards(): JSX.Element {
  return (
    <>
      {zapierIntegrations.map((integration) => (
        <IntegrationsCard externalLink={true} item={integration} key={integration.name} />
      ))}
      <IntegrationsCard externalLink={true} item={allOtherZapierIntegrations} />
    </>
  );
}

export default ZapierIntegrationCards;
