import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { CSSProperties, JSX } from 'react';
import React, { useEffect, useState } from 'react';

import type {
  IAdTagBaseCreative,
  ICreativeAttributes,
  IDisplayCreative,
  IImageCreative,
  TPartnerMessageEmailCreative,
  TReferralBannerCreative,
} from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { Postscribe } from '@feathr/components';

import * as styles from './CreativeThumbnail.css';

import noImg from '@feathr/extender/images/no-img.png';

interface ICustomCSSProperties extends CSSProperties {
  '--l-height'?: string;
  '--l-real-width'?: string;
  '--l-scale'?: number;
  '--l-size'?: string;
  '--l-width'?: string;
}

function formatAdTag(adtag: string, clickURL: string): string {
  return adtag
    .replace(/@FEATHR_CLICK@/g, clickURL)
    .replace(/@FEATHR_CLICK_ESC@/g, encodeURIComponent(clickURL))
    .replace(/@FEATHR_TRACK@/g, '');
}

interface IBaseCreativeProps {
  className?: string;
  height?: number | string;
  size?: number | string;
  width?: number | string;
}

interface IMediaCreativeProps extends IBaseCreativeProps {
  previewButton?: JSX.Element;
  url: string;
}

function parseSize(size: string | number): string {
  if (typeof size === 'number') {
    return `${size}px`;
  }
  if (size.endsWith('%')) {
    return size;
  }
  return `${size}px`;
}

function ImageCreativeThumbnail({
  className,
  height,
  size,
  url,
  previewButton,
  width,
}: Readonly<IMediaCreativeProps>): JSX.Element {
  const style: ICustomCSSProperties = {};

  if (height) {
    style['--l-height'] = parseSize(height);
  }
  if (size) {
    style['--l-size'] = parseSize(size);
  }
  if (width) {
    style['--l-width'] = parseSize(width);
  }

  return (
    <div className={classNames(styles.media, className)} style={style}>
      <img alt={''} src={url} />
      {!!previewButton && (
        <>
          <div className={styles.mask}></div>
          {previewButton}
        </>
      )}
    </div>
  );
}

function VideoCreativeThumbnail({
  className,
  height,
  previewButton,
  size,
  url,
  width,
}: Readonly<IMediaCreativeProps>): JSX.Element {
  const ref = React.createRef<HTMLImageElement>();

  useEffect(() => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');
    video.addEventListener('canplaythrough', () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      const img = ref.current;
      if (ctx && img) {
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        img.src = canvas.toDataURL('image/png');
      }
    });
    video.addEventListener('loadedmetadata', () => {
      video.currentTime = video.duration / 2;
    });
    video.crossOrigin = 'Anonymous';
    video.src = url;
  }, [ref, url]);

  const style: ICustomCSSProperties = {};
  if (height) {
    style['--l-height'] = parseSize(height);
  }
  if (size) {
    style['--l-size'] = parseSize(size);
  }
  if (width) {
    style['--l-width'] = parseSize(width);
  }

  return (
    <div className={classNames(styles.media, className)} style={style}>
      <img alt={''} ref={ref} src={noImg} />
      {!!previewButton && (
        <>
          {/* Mask to display 80%  black layer between image and button */}
          <div className={styles.mask}></div>
          {previewButton}
        </>
      )}
    </div>
  );
}

function CreativeErrorThumbnail({
  className,
  height,
  size,
  width,
}: Readonly<IBaseCreativeProps>): JSX.Element {
  const style: ICustomCSSProperties = {};
  if (height) {
    style['--l-height'] = parseSize(height);
  }
  if (size) {
    style['--l-size'] = parseSize(size);
  }
  if (width) {
    style['--l-width'] = parseSize(width);
  }

  return (
    <div className={classNames(styles.media, className)} style={style}>
      <FontAwesomeIcon
        className={classNames(styles.warning, styles.creativeError, className)}
        icon={faCircleExclamation}
      />
    </div>
  );
}

interface IAdtagProps extends IBaseCreativeProps {
  adtag: string;
  realHeight: number;
  realWidth: number;
}

function AdTagCreativeThumbnail({
  adtag,
  className,
  height,
  realHeight,
  realWidth,
  width,
  size,
}: Readonly<IAdtagProps>): JSX.Element {
  const [isPostscribeError, setIsPostscribeError] = useState(false);
  const scaleHeight = height && realHeight > +height ? +height / realHeight : 1;
  const scaleWidth = width && realWidth > +width ? +width / realWidth : 1;
  const extreme = Math.max(realHeight, realWidth);

  let scaleSize = 1;
  if (typeof size === 'number' && size !== 0) {
    scaleSize = extreme > size ? size / extreme : 1;
  }
  const scaleFactor = Math.min(scaleHeight, scaleSize, scaleWidth);

  /*
   * We have to pass in the "real" width of the creative so we can calculate 50% of the width
   * of the creative after the scale factor is applied. The regular width is insufficient since
   * it describes a maximum bound for the width, not the width of the creative itself.
   */
  const style: ICustomCSSProperties = {
    '--l-real-width': `${realWidth}px`,
  };
  if (height) {
    style['--l-height'] = parseSize(height);
  }
  if (scaleFactor < 1) {
    style['--l-scale'] = scaleFactor;
  }
  if (size) {
    style['--l-size'] = parseSize(size);
  }
  if (width) {
    style['--l-width'] = parseSize(width);
  }

  function onPostscribeError(): void {
    setIsPostscribeError(true);
  }

  return isPostscribeError ? (
    <CreativeErrorThumbnail className={className} height={height} size={size} width={width} />
  ) : (
    <div className={classNames(styles.wrapper, className)}>
      <Postscribe
        className={styles.postscribe}
        html={formatAdTag(adtag, '')}
        onPostscribeError={onPostscribeError}
        style={style}
      />
    </div>
  );
}

interface IProps {
  className?: string;
  creative: ICreativeAttributes;
  height?: number | string;
  previewButton?: JSX.Element;
  size?: number | string;
  width?: number | string;
}

function CreativeThumbnail({
  className,
  creative,
  height,
  previewButton,
  size,
  width,
}: Readonly<IProps>): JSX.Element {
  // If adtag is empty, use default noImg.
  if (
    (creative._cls === CreativeClass.DisplayAdTag ||
      creative._cls === CreativeClass.DisplayBannersnack) &&
    (creative as IAdTagBaseCreative).adtag
  ) {
    const adtCrv = creative as IAdTagBaseCreative;
    return (
      <AdTagCreativeThumbnail
        adtag={adtCrv.preview_adtag || adtCrv.adtag}
        className={className}
        height={height}
        realHeight={adtCrv.height}
        realWidth={adtCrv.width}
        size={size}
        width={width}
      />
    );
  }

  if (
    creative._cls === CreativeClass.DisplayVideo ||
    creative._cls === CreativeClass.FacebookVideo
  ) {
    return (
      <VideoCreativeThumbnail
        className={className}
        height={height}
        previewButton={previewButton}
        size={size}
        url={(creative as IDisplayCreative).url || noImg}
        width={width}
      />
    );
  }

  let url = noImg;
  switch (creative._cls) {
    case CreativeClass.ReferralPage:
      url = `${BLACKBOX_URL}creatives/${creative.id}/thumbnail?w=200&h=150`;
      break;

    case CreativeClass.ReferralBanner:
      const bannerCrv = creative as TReferralBannerCreative;
      url = `${BLACKBOX_URL}creatives/${bannerCrv.id}/thumbnail?w=${bannerCrv.width}&h=${bannerCrv.height}`;
      break;

    case CreativeClass.ReferralEmail:
      url = `${BLACKBOX_URL}creatives/${creative.id}/thumbnail?w=200&h=150`;
      break;

    case CreativeClass.PartnerMessageEmail:
      const messageCrv = creative as TPartnerMessageEmailCreative;
      url = `${BLACKBOX_URL}creatives/${messageCrv.id}/thumbnail?w=200&h=150`;
      break;

    default:
      url = (creative as IImageCreative).url;
  }

  return (
    <ImageCreativeThumbnail
      className={className}
      height={height}
      previewButton={previewButton}
      size={size}
      url={url || noImg}
      width={width}
    />
  );
}

export default CreativeThumbnail;
