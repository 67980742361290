/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AsyncValidateOption } from 'validate.js/validate';

import type { IValidateGlobalOptions } from '.';

export interface IValidateAsyncConstraint {
  fn: (value: any, model: any) => Promise<string[] | undefined>;
}

async function async(
  value: any,
  constraints: IValidateAsyncConstraint,
  key: string,
  options: AsyncValidateOption,
  globalOptions: IValidateGlobalOptions,
): Promise<string[] | null | undefined> {
  const { fn } = constraints;
  return fn(value, globalOptions.model);
}

export default async;
