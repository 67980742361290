import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMousePointer, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { components } from 'react-select';

import { Select } from '@feathr/components';

interface IProps {
  mode: 'persons' | 'breadcrumbs';
  onChangeMode: (mode: 'persons' | 'breadcrumbs') => void;
  label?: string;
}

type TMode = 'persons' | 'breadcrumbs';

interface IModeOption {
  id: TMode;
  name: string;
}

const modeOptions: IModeOption[] = [
  { id: 'persons', name: 'People' },
  { id: 'breadcrumbs', name: 'Activity' },
];

const modeToIconMap = new Map<'persons' | 'breadcrumbs', IconProp>([
  ['persons', faUser],
  ['breadcrumbs', faMousePointer],
]);

function ModeSingleValue(props: any) {
  const icon = modeToIconMap.get(props.data.id);
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{props.data.name}</span>
        </div>
      </div>
    </components.SingleValue>
  );
}

function ModeOption(props: any) {
  const icon = modeToIconMap.get(props.data.id);
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{props.data.name}</span>
        </div>
      </div>
    </components.Option>
  );
}

export default function DataToggle(props: IProps) {
  const { onChangeMode, label } = props;
  return (
    <>
      <Select
        components={{ Option: ModeOption, SingleValue: ModeSingleValue }}
        label={label}
        name={'data-toggle'}
        onSelectSingle={(value: IModeOption | null | undefined) => {
          if (value) {
            onChangeMode(value.id);
          }
        }}
        options={modeOptions}
        value={modeOptions.find((option) => option.id === props.mode)}
      />
    </>
  );
}
