import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';

import type { ICampaignAttributes, IPartner, Template } from '@feathr/blackbox';
import { CampaignClass, RecipientType, TemplateClass } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { AsyncSelect, Fieldset, Form, Input, Select, toast } from '@feathr/components';
import PersonSelect from '@feathr/extender/components/PersonSelect';
import { CampaignIconOption, PartnerOption } from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';
import type { TConstraints, TValidateGrouped } from '@feathr/rachis';
import { validate } from '@feathr/rachis';

import * as styles from './TemplateSendTestForm.css';

interface IValidateFormProps {
  fromEmail?: string;
  fromName?: string;
  invitesCampaignId?: string;
  isPinpointEmail: boolean;
  personId?: string;
  partnerId?: string;
  recipientTypeSelection?: RecipientType;
  subject?: string;
  toEmail?: string;
}

export interface ITemplateSendTestFormErrors extends TValidateGrouped {
  fromEmail?: string[];
  fromName?: string[];
  invitesCampaignId?: string[];
  partnerId?: string[];
  personId?: string[];
  recipientTypeSelection?: string[];
  subject?: string[];
  toEmail?: string[];
}

interface IProps {
  /** Form actions */
  actions?: ReactNode;
  children: (
    form: JSX.Element,
    validate: () => ITemplateSendTestFormErrors | undefined,
    send: () => Promise<boolean>,
  ) => JSX.Element;
  template: Template;
  recipientType?: RecipientType;
}

interface IValidateFormProps {
  fromEmail?: string;
  fromName?: string;
  invitesCampaignId?: string;
  isPinpointEmail: boolean;
  personId?: string;
  partnerId?: string;
  recipientTypeSelection?: RecipientType;
  subject?: string;
  toEmail?: string;
}

export interface ITemplateSendTestFormErrors extends TValidateGrouped {
  fromEmail?: string[];
  fromName?: string[];
  invitesCampaignId?: string[];
  partnerId?: string[];
  personId?: string[];
  recipientTypeSelection?: string[];
  subject?: string[];
  toEmail?: string[];
}

interface IProps {
  /** Form actions */
  actions?: ReactNode;
  children: (
    form: JSX.Element,
    validate: () => ITemplateSendTestFormErrors | undefined,
    send: () => Promise<boolean>,
  ) => JSX.Element;
  template: Template;
  recipientType?: RecipientType;
}

export const RecipientTypeOptions: ISelectOption[] = [
  { id: RecipientType.Person, name: 'Person' },
  { id: RecipientType.Partner, name: 'Partner' },
];

export function validateTemplateSendTestForm({
  fromEmail,
  fromName,
  toEmail,
  recipientTypeSelection,
  subject,
  isPinpointEmail,
}: Readonly<IValidateFormProps>): ITemplateSendTestFormErrors | undefined {
  // Check recipient type (optional)

  const formConstraints: TConstraints<IValidateFormProps> = {
    fromEmail: {
      // Always required
      presence: {
        allowEmpty: false,
        message: 'From email address is required',
      },
      email: {
        message: 'From email must be valid ',
      },
    },
    fromName: {
      // Always required
      presence: {
        allowEmpty: false,
        message: 'From name is required',
      },
    },
    toEmail: {
      // Always required
      presence: {
        allowEmpty: false,
        message: 'To email address is required',
      },
      email: {
        message: 'To email address must be valid',
      },
    },
    subject: {
      // Always required
      presence: {
        allowEmpty: false,
        message: 'Subject is required',
      },
    },
  };

  /*
   * For pinpoint email, use invitesCampaignId, partnerId, personId
   * For other email types, use campaignId = invitesCampaignId, partnerId, personId
   */
  if (!isPinpointEmail || recipientTypeSelection === RecipientType.Partner) {
    /*
     * Check partnerId (optional)
     * Check invitesCampaignId (optional)
     */
  } else if (recipientTypeSelection === RecipientType.Person) {
    // Check personId (optional)
  }

  const errors: ITemplateSendTestFormErrors = validate(
    { fromEmail, fromName, toEmail, subject },
    formConstraints,
    { format: 'grouped' },
  );

  return errors;
}

function TemplateSendTestForm({
  actions,
  children,
  recipientType,
  template,
}: Readonly<IProps>): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();

  const [recipientTypeSelection, setRecipientTypeSelection] = useState<RecipientType | undefined>(
    recipientType,
  );
  const [invitesCampaignId, setInvitesCampaignId] = useState<string | undefined>();
  const [partnerId, setPartnerId] = useState<string | undefined>();
  const [personId, setPersonId] = useState<string | undefined>();
  const [fromEmail, setFromEmail] = useState<string | undefined>();
  const [fromName, setFromName] = useState<string | undefined>();
  const [toEmail, setToEmail] = useState<string | undefined>();

  const { Campaigns, Partners } = useContext(StoresContext);

  const isPinpointEmail = template.get('_cls') === TemplateClass.PinpointEmail;

  async function loadCampaignOptions(inputValue: string): Promise<ICampaignAttributes[]> {
    const campaigns = Campaigns.list({
      filters: {
        name__icontains: inputValue,
        event: template.get('event') || eventId,
        _cls__in: [CampaignClass.Referral],
      },
      pagination: {
        page: 0,
        page_size: 20,
      },
    });
    await when(() => !campaigns.isPending);
    return campaigns.models.map((cpn) => cpn.toJS());
  }

  async function loadPartnerOptions(inputValue: string): Promise<IPartner[]> {
    const partners = Partners.list({
      filters: {
        name__icontains: inputValue,
        _parent: template.get('event') || eventId,
        participation: invitesCampaignId ?? undefined,
      },
      pagination: {
        page: 0,
        page_size: 20,
      },
    });
    await when(() => !partners.isPending);
    return partners.models.map((partner) => partner.toJS());
  }

  async function sendPreview(): Promise<boolean> {
    try {
      await template.send(
        {
          from_: fromEmail ?? '',
          from_name: fromName ?? '',
          email: toEmail ?? '',
        },
        isPinpointEmail ? undefined : invitesCampaignId,
        isPinpointEmail ? invitesCampaignId : undefined,
        partnerId,
        personId,
      );
      toast(`A preview is on its way to ${toEmail}.`);
      return true;
    } catch (error) {
      toast(`A preview failed to send to ${toEmail}.`);
      return false;
    }
  }

  function handleSelectRecipientType(selection: ISelectOption): void {
    setRecipientTypeSelection(selection.id as RecipientType);
    handleSelectPerson();
    handleClearPartner();
  }

  function handleClearRecipientType(): void {
    setRecipientTypeSelection(undefined);
    handleSelectPerson();
    handleClearPartner();
  }

  function handleSelectCampaign(option: ICampaignAttributes): void {
    setInvitesCampaignId(option.id);
  }

  function handleClearCampaign(): void {
    setInvitesCampaignId(undefined);
  }

  function handleSelectPartner(option: IPartner): void {
    setPartnerId(option.id);
  }

  function handleClearPartner(): void {
    setPartnerId(undefined);
  }

  function handleSelectPerson(newValue?: string): void {
    setPersonId(newValue);
  }

  function handleChangeFromAddress(newValue?: string): void {
    setFromEmail(newValue);
  }

  function handleChangeFromName(newValue?: string): void {
    setFromName(newValue);
  }

  function handleChangeSubject(newValue?: string): void {
    template.set({
      subject: newValue,
    });
  }

  function handleChangeToAddress(newValue?: string): void {
    setToEmail(newValue);
  }

  function handleValidateForm(): ITemplateSendTestFormErrors | undefined {
    return validateTemplateSendTestForm({
      fromEmail,
      fromName,
      invitesCampaignId,
      isPinpointEmail,
      partnerId,
      personId,
      recipientTypeSelection,
      subject: template.get('subject'),
      toEmail,
    });
  }

  const validationErrors = handleValidateForm();

  const form = (
    <Form actions={actions} label={'Send Test Email'}>
      <Fieldset>
        {isPinpointEmail && !recipientType && (
          <Select
            defaultOptions={true}
            helpText={'The type of recipient to use for merge data.'}
            isClearable={true}
            label={'Merge data source'}
            name={'merge-data-source'}
            onClear={handleClearRecipientType}
            onSelectSingle={handleSelectRecipientType}
            options={RecipientTypeOptions}
            placeholder={'Merge data source'}
            validationError={validationErrors?.recipientTypeSelection}
            value={
              recipientTypeSelection
                ? RecipientTypeOptions.find((option) => option.id === recipientTypeSelection)
                : undefined
            }
            wrapperClassName={styles.select}
          />
        )}
        {(!isPinpointEmail || recipientTypeSelection === RecipientType.Partner) && (
          <>
            <AsyncSelect
              components={{ Option: PartnerOption }}
              defaultOptions={true}
              helpText={
                'This recipient is simply used to provide data to merge into the email template. The email will be sent to the Recipient Email Address you provide below.'
              }
              isClearable={true}
              label={'Recipient'}
              loadOptions={loadPartnerOptions}
              name={'partner-select'}
              onClear={handleClearPartner}
              onSelectSingle={handleSelectPartner}
              placeholder={'Recipient'}
              validationError={validationErrors?.partnerId}
              value={partnerId ? Partners.get(partnerId).toJS() : undefined}
              wrapperClassName={styles.select}
            />
            <AsyncSelect
              components={{ Option: CampaignIconOption }}
              defaultOptions={true}
              helpText={'The campaign to use for merge data.'}
              isClearable={true}
              label={'Campaign'}
              loadOptions={loadCampaignOptions}
              name={'campaign-select'}
              onClear={handleClearCampaign}
              onSelectSingle={handleSelectCampaign}
              placeholder={'Campaign'}
              value={invitesCampaignId ? Campaigns.get(invitesCampaignId).toJS() : undefined}
              wrapperClassName={styles.select}
            />
          </>
        )}
        {recipientTypeSelection === RecipientType.Person && (
          <PersonSelect
            helpText={
              'This recipient is simply used to provide data to merge into the email template. The email will be sent to the Recipient Email Address you provide below.'
            }
            isClearable={true}
            label={'Recipient'}
            name={'recipient-select'}
            onChange={handleSelectPerson}
            placeholder={'Recipient'}
            validationError={validationErrors?.personId}
            value={personId}
          />
        )}
        <Input
          helpText={'Email address used as the reply-to for this message.'}
          label={'From email address'}
          onChange={handleChangeFromAddress}
          placeholder={'your@email.com'}
          required={true}
          type={'text'}
          validationError={validationErrors?.fromEmail}
          value={fromEmail}
        />
        <Input
          helpText={
            'The "Friendly" component of the email sender. Shows as the name in the recipient\'s email address.'
          }
          label={'From name'}
          onChange={handleChangeFromName}
          placeholder={'Your name - Your company'}
          required={true}
          type={'text'}
          validationError={validationErrors?.fromName}
          value={fromName}
        />
        <Input
          helpText={'The email address to send the preview message to.'}
          label={'Recipient email address'}
          onChange={handleChangeToAddress}
          placeholder={'exhibitor@hotmail.com'}
          required={true}
          type={'text'}
          validationError={validationErrors?.toEmail}
          value={toEmail}
        />
        <Input
          helpText={'The subject of the email'}
          label={'Subject'}
          onChange={handleChangeSubject}
          placeholder={'Check it out!'}
          required={true}
          type={'text'}
          validationError={validationErrors?.subject}
          value={template.get('subject')}
        />
      </Fieldset>
    </Form>
  );

  return <>{children(form, handleValidateForm, sendPreview)}</>;
}

export default observer(TemplateSendTestForm);
