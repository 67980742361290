import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { EmailVerification, PinpointEmailBaseCampaign, Template } from '@feathr/blackbox';
import { ButtonValid } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';

import { validateStepConfigureDetails } from './PinpointEmailCampaignStepConfigureDetails';

interface IButtonProps {
  campaign: PinpointEmailBaseCampaign;
  emailVerification?: EmailVerification;
  onNext: () => void;
  template?: Template;
}

function NextStepButton({
  campaign,
  emailVerification,
  onNext,
  template,
}: Readonly<IButtonProps>): JSX.Element {
  const { t } = useTranslation();
  const errors = validateStepConfigureDetails(campaign, emailVerification, template);
  return (
    <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
}

export default observer(NextStepButton);
