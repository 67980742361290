import { concatPath, moment, TimeFormat } from '@feathr/hooks';
import type { TConstraints } from '@feathr/rachis';

import { PinpointEmailBaseCampaign } from './pinpoint_email_base';
import type { IPinpointPartnerMessage } from './types';
import { CampaignState } from './types';

export class PinpointPartnerMessage extends PinpointEmailBaseCampaign<IPinpointPartnerMessage> {
  public get constraints(): TConstraints<IPinpointPartnerMessage> {
    return {
      date_send_start: {
        datetime: (_: string, attributes: Partial<IPinpointPartnerMessage>) => {
          if (attributes.send_schedule === 'now') {
            return undefined;
          }
          if (
            attributes.state &&
            [CampaignState.Draft, CampaignState.Stopped].includes(attributes.state)
          ) {
            return {
              earliest: moment.utc().add(15, 'minutes'),
              message: `^Start time must be at least 15 minutes in the future`,
            };
          }
          return undefined;
        },
        presence: (_: string, attributes: Partial<IPinpointPartnerMessage>) => {
          if (attributes.send_schedule === 'now') {
            return undefined;
          }
          return {
            allowEmpty: false,
            message: '^Start time cannot be empty',
          };
        },
      },
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      from_address: {
        presence: {
          allowEmpty: false,
        },
        email: (...args: any[]) => {
          const attributes = args[1];
          const isEmpty = !attributes.from_address;
          if (isEmpty) {
            return undefined;
          }
          return {
            message: '^From address must be a valid email address.',
          };
        },
      },
      from_name: {
        presence: {
          allowEmpty: false,
          message: "^From Name can't be blank.",
        },
        format: {
          pattern: "[a-z0-9!#$%&*()\\-+=._?/ '‘]+",
          flags: 'i',
          message:
            "^From Name can only contain alphanumeric characters, and the following symbols: ! # $ % & * ( ) - + = . _ ? / ' . ‘",
        },
      },
      'consent.has_consent': {
        presence: {
          allowEmpty: false,
          message: '^You must provide consent to send this message.',
        },
        exclusion: {
          within: [false],
          message: '^You must provide consent to send this message.',
        },
      },
      'consent.user': {
        presence: {
          allowEmpty: false,
          message: '^Only a valid user may provide consent.',
        },
      },
      'consent.date_consented': {
        datetime: true,
        presence: {
          allowEmpty: false,
          message: '^You must provide a valid group or remove the goal.',
        },
      },
      'participation.mode': {
        presence: {
          allowEmpty: false,
          message: '^Please choose a partner selection mode.',
        },
      },
      'participation.partner_ids': (_: string, attributes: Partial<IPinpointPartnerMessage>) => {
        if (attributes.participation?.['mode'] === 'manual') {
          return {
            presence: {
              allowEmpty: false,
              message: '^You must specify at least one recipient if you choose "select partners".',
            },
          };
        }
        return undefined;
      },
      template_id: {
        presence: {
          allowEmpty: false,
        },
      },
      subject: {
        presence: { allowEmpty: false, message: '^Your partner message must have a subject.' },
      },
    };
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('event')}/partners/messages/${this.id}`, pathSuffix);
  }

  public getItemLabel(): string {
    if (this.get('name')) {
      return this.get('name');
    }
    const sendAt = this.get('date_send_start');
    return `Unnamed message${
      !!sendAt && ` (${moment.utc(sendAt).format(TimeFormat.shortDateTime)})`
    }`;
  }

  public get name(): string {
    return this.get('name', '').trim() || `Unnamed Message`;
  }

  // TODO: Add unit tests for PinpointPartnerMessage.isEditable
  public get isEditable(): boolean {
    return !(
      this.isPastStartDate &&
      [CampaignState.Published, CampaignState.Publishing].includes(
        this.get('state', CampaignState.Draft),
      )
    );
  }
}
