import type { JSX, ReactNode } from 'react';
import React from 'react';

import type { ICardProps } from '@feathr/components';
import { Card, Tooltip } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import type { INativeIntegrationCardProps } from './NativeIntegrations';
import type { IZapierIntegrationsCardProps } from './ZapierIntegrationCards';

import * as styles from './BaseIntegrationsCard.css';

import noImg from '@feathr/extender/images/no-img.png';

export interface IBaseIntegrationCardProps extends ICardProps {
  item: INativeIntegrationCardProps | IZapierIntegrationsCardProps;
  externalLink?: boolean;
  tooltip?: ReactNode;
}

/**
 * The base integrations card element that defines the consistent props and styles. Requires an item and optionally accepts any props for the Card component
 */
function BaseIntegrationsCard({
  item,
  externalLink,
  tooltip,
  ...additionalProps
}: IBaseIntegrationCardProps): JSX.Element {
  const { logo, url, name } = item;
  const localUrl = useLocalUrl();

  const card: JSX.Element = (
    <Card
      {...additionalProps}
      className={styles.root}
      cover={
        <span className={styles.icon}>
          <img alt={name} src={logo ?? noImg} />
        </span>
      }
      link={!externalLink ? localUrl(url) : undefined}
      title={name}
    />
  );

  const cardWithTooltip: JSX.Element = <Tooltip title={tooltip}>{card}</Tooltip>;

  if (!tooltip) {
    // If there is no tooltip, return the card
    return card;
  } else {
    // If there is a tooltip, return the card wrapped in a tooltip
    return cardWithTooltip;
  }
}

export default BaseIntegrationsCard;
