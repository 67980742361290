import { faImage } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Creative } from '@feathr/blackbox';
import {
  CampaignClass,
  CreativeClass,
  ValidDimensions,
  ValidFacebookDimensions,
  ValidFacebookVideoDimensions,
} from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { DebouncedInput, Input, Select, Table, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import CampaignSelect from '@feathr/extender/components/CampaignSelect';
import { StoresContext } from '@feathr/extender/state';

import AddCreativeButton from './AddCreativeButton';
import EventCreativesColumns from './EventCreativesColumns';

import * as styles from './EventCreativesPage.css';

interface IFilters {
  name__icontains?: string;
  _parent?: string;
  spec?: string;
}

function TypeSelectLabel({ name }: ISelectOption): JSX.Element {
  return (
    <>
      <FontAwesomeIcon icon={faImage} /> {name}
    </>
  );
}

function EventCreativesPage(): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();

  const [filters, setFilters] = useState<IFilters>({});

  const { Creatives } = useContext(StoresContext);

  const { t } = useTranslation();

  function handleDebouncedSearchChange(newValue?: string): void {
    setFilters({
      ...filters,
      name__icontains: newValue,
    });
  }

  const baseTypeOptions = Object.keys(ValidDimensions).map<ISelectOption>((key: string) => {
    const value = ValidDimensions[key];
    return {
      id: value.spec,
      name: value.name,
    };
  });
  const facebookTypeOptions = Object.keys(ValidFacebookDimensions).map<ISelectOption>(
    (key: string) => {
      const value = ValidFacebookDimensions[key];
      return {
        id: value.spec,
        name: value.name,
      };
    },
  );
  const facebookVideoTypeOptions = Object.keys(ValidFacebookVideoDimensions).map<ISelectOption>(
    (key: string) => {
      const value = ValidFacebookVideoDimensions[key];
      return {
        id: value.spec,
        name: value.name,
      };
    },
  );
  const typeOptions: ISelectOption[] = [
    ...baseTypeOptions,
    ...facebookTypeOptions,
    ...facebookVideoTypeOptions,
  ];

  const filterElements = [
    <>
      <Select
        filterOption={({ label }, inputValue): string => {
          return label.toLowerCase().includes(inputValue.toLowerCase());
        }}
        formatOptionLabel={TypeSelectLabel}
        isClearable={true}
        name={'creative-type'}
        onClear={(): void =>
          setFilters({
            ...filters,
            spec: undefined,
          })
        }
        onSelectSingle={({ id }): void => {
          setFilters({
            ...filters,
            spec: id,
          });
        }}
        options={typeOptions}
        placeholder={t('Type')}
        value={typeOptions.find((option) => {
          return option.id === filters.spec;
        })}
        wrapperClassName={styles.filter}
      />
      <CampaignSelect
        className={styles.filter}
        eventId={eventId}
        isMulti={false}
        onChange={(value): void => {
          setFilters({
            ...filters,
            _parent: value ? value : undefined,
          });
        }}
        onClear={(): void => {
          setFilters({ ...filters, _parent: undefined });
        }}
        placeholder={t('Campaign')}
        type={[
          CampaignClass.EmailList,
          CampaignClass.EmailListFacebook,
          CampaignClass.Facebook,
          CampaignClass.Lookalike,
          CampaignClass.SeedSegment,
          CampaignClass.Affinity,
          CampaignClass.MobileGeoFenceRetargeting,
          CampaignClass.MobileGeoFencing,
          CampaignClass.Search,
          CampaignClass.Segment,
        ]}
        value={filters._parent ? filters._parent : undefined}
      />
    </>,
    <>
      <DebouncedInput<string>
        defaultValue={filters.name__icontains}
        onChange={handleDebouncedSearchChange}
      >
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            onChange={onChangeLiveValue}
            placeholder={'Search by name...'}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  const actions = (
    <Toolbar>
      <AddCreativeButton />
    </Toolbar>
  );

  return (
    <Page actions={actions} title={'Creatives'}>
      <Table<Creative>
        collection={Creatives}
        columns={EventCreativesColumns()}
        filterElements={filterElements}
        filters={{
          ...filters,
          _cls__in: [
            CreativeClass.DisplayAdTag,
            CreativeClass.DisplayBannersnack,
            CreativeClass.DisplayImage,
            CreativeClass.DisplayVideo,
            CreativeClass.FacebookImage,
            CreativeClass.FacebookVideo,
          ],
          event: eventId,
        }}
        initialSort={[{ id: 'name' }]}
      />
    </Page>
  );
}

export default EventCreativesPage;
