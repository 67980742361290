import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { components } from 'react-select';

import type {
  Campaign,
  GeoFilter as GeoFilterModel,
  IGeoFilter,
  Targetable,
  Targeting,
} from '@feathr/blackbox';
import { TargetableClass } from '@feathr/blackbox';
import { AsyncSelect, Button, Card, Radios, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './GeoFilter.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  targetings: IObservableArray<Targeting>;
  onRemove: (targeting: Targeting) => void;
}

interface IFilters {
  is_archived__ne: boolean;
  name__icontains?: string;
}

function Option(props: any): JSX.Element {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className={styles.option}>
        <span>{data.name}</span>
        <dl>
          <dt>Kind</dt>
          <dd>{data.kind}</dd>
          {['Region', 'City'].includes(data.kind) && (
            <>
              <dt>Country</dt>
              <dd>{data.address.country}</dd>
            </>
          )}
          {['City'].includes(data.kind) && (
            <>
              <dt>Region</dt>
              <dd>{data.address.region}</dd>
            </>
          )}
        </dl>
      </div>
    </components.Option>
  );
}

function SingleValue(props: any): JSX.Element {
  const data = props.data as IGeoFilter;
  return (
    <components.SingleValue {...props}>
      <div className={styles.singleValue}>
        <span className={styles.singleValueLabel}>{data.name}</span>
        <dl className={styles.singleValueData}>
          <dt>Kind</dt>
          <dd>{data.kind}</dd>
          {['Region', 'City'].includes(data.kind) && (
            <>
              <dt>Country</dt>
              <dd>{data.address.country}</dd>
            </>
          )}
          {['City'].includes(data.kind) && (
            <>
              <dt>Region</dt>
              <dd>{data.address.region}</dd>
            </>
          )}
        </dl>
      </div>
    </components.SingleValue>
  );
}

function GeoFilter({ campaign, targeting, targetings, onRemove }: Readonly<IProps>): JSX.Element {
  const { GeoFilters, Targetables } = React.useContext(StoresContext);
  let targetable: Targetable | undefined;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  }
  const [, setFilterQuery] = React.useState('');
  let geofilter: GeoFilterModel | undefined;
  if (targetable && targetable.get('geo_filter')) {
    geofilter = GeoFilters.get(targetable.get('geo_filter')!);
  }
  const [isLoading, setIsLoading] = useState(false);

  function handleIncludedChange(newValue?: string): void {
    targeting.set({ included: newValue === 'included' });
  }

  async function loadOptions(inputValue: string): Promise<IGeoFilter[]> {
    setIsLoading(true);
    const filters: IFilters = {
      is_archived__ne: true,
    };

    if (inputValue) {
      filters.name__icontains = inputValue;
    }
    const geoFilters = GeoFilters.list({
      filters,
      pagination: {
        page: 0,
        page_size: 50,
      },
    });
    await when(() => !geoFilters.isPending);
    setIsLoading(false);
    return [
      ...geoFilters.models
        .filter(
          (gf) =>
            !targetings.find((t) => {
              const data = t.get('target_data');
              if (data) {
                return data === gf.id;
              }
              return false;
            }),
        )
        .map((gf) => gf.toJS()),
    ];
  }

  return (
    <>
      <Card
        actions={[
          <Tooltip key={'remove'} title={'Remove'}>
            <Button key={'remove_button'} onClick={() => onRemove(targeting)} type={'naked'}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
      >
        <AsyncSelect<IGeoFilter>
          cacheOptions={true}
          components={{ Option, SingleValue }}
          defaultOptions={true}
          isLoading={isLoading}
          loadOptions={loadOptions}
          name={'geo-filter'}
          onInputChange={(value: string) => setFilterQuery(value)}
          onSelectSingle={(option: IGeoFilter) => {
            const newGeoTargetable = Targetables.create({
              _cls: TargetableClass.geo,
              name: option.name,
              geo_filter: option.id,
              partner:
                campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
            });
            targeting.set({
              name: option.name,
              target_data: newGeoTargetable.get('id'),
            });
          }}
          placeholder={'Select a filter...'}
          value={geofilter ? (!geofilter.isPending ? geofilter.toJS() : undefined) : undefined}
        />
        <Radios
          className={styles.radios}
          onChange={handleIncludedChange}
          options={[
            { id: 'included', name: 'Included' },
            { id: 'excluded', name: 'Excluded' },
          ]}
          value={targeting.get('included') ? 'included' : 'excluded'}
        />
      </Card>
    </>
  );
}

export default observer(GeoFilter);
