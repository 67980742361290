import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel } from '@feathr/rachis';

export interface IUsageReport extends IBaseAttributes {
  has_active_facebook_integration: boolean;
  has_authorized_email_domain: boolean;
  last_campaign_publish_date: string;
  last_login_date: string;
  num_active_data_integrations: number;
  num_active_projects: number;
  num_completed_implementation_requests: number;
  num_contact_data_uploads: number;
  num_conversion_pixels: number;
  num_custom_fields: number;
  num_flights: number;
  num_identified_persons: number;
  num_launched_ad_campaigns: number;
  num_launched_campaigns: number;
  num_launched_campaigns_30_days: number;
  num_launched_campaigns_365_days: number;
  num_launched_campaigns_90_days: number;
  num_launched_email_campaigns: number;
  num_launched_email_campaigns_30_days: number;
  num_launched_email_campaigns_365_days: number;
  num_launched_email_campaigns_90_days: number;
  num_launched_invite_campaigns: number;
  num_launched_meta_campaigns: number;
  num_launched_meta_campaigns_30_days: number;
  num_launched_meta_campaigns_365_days: number;
  num_launched_meta_campaigns_90_days: number;
  num_live_campaigns: number;
  num_login_30_days: number;
  num_login_365_days: number;
  num_login_90_days: number;
  num_person_importers: number;
  num_reachable_people: number;
  total_display_media_spend: number;
  total_facebook_media_spend: number;
}

interface IReportOptions {
  // Exclude keys from the report object
  exclude?: Array<keyof IUsageReport>;
  // Return the object with labels instead of keys.
  labels?: boolean;
}

const keyToLabelMap = {
  has_active_facebook_integration: 'Has active facebook integration',
  has_authorized_email_domain: 'Has authorized email domain',
  last_campaign_publish_date: 'Last campaign publish date',
  last_login_date: 'Last login date',
  num_active_data_integrations: 'Total active data integrations',
  num_active_projects: 'Total active projects',
  num_completed_implementation_requests: 'Total completed implementation requests',
  num_contact_data_uploads: 'Total contact data uploads',
  num_conversion_pixels: 'Total conversion pixels',
  num_custom_fields: 'Total custom fields',
  num_flights: 'Total flights',
  num_identified_persons: 'Total identified persons',
  num_launched_ad_campaigns: 'Total published ad campaigns',
  num_launched_campaigns: 'Total published campaigns',
  num_launched_campaigns_30_days: 'Total published campaigns -- last 30 days',
  num_launched_campaigns_365_days: 'Total published campaigns -- last 365 days',
  num_launched_campaigns_90_days: 'Total published campaigns -- last 90 days',
  num_launched_email_campaigns: 'Total published email campaigns',
  num_launched_email_campaigns_30_days: 'Total published email campaigns -- last 30 days',
  num_launched_email_campaigns_365_days: 'Total published email campaigns -- last 365 days',
  num_launched_email_campaigns_90_days: 'Total published email campaigns -- last 90 days',
  num_launched_invite_campaigns: 'Total published invite campaigns',
  num_launched_meta_campaigns: 'Total published Meta campaigns',
  num_launched_meta_campaigns_30_days: 'Total published Meta campaigns -- last 30 days',
  num_launched_meta_campaigns_365_days: 'Total published Meta campaigns -- last 365 days',
  num_launched_meta_campaigns_90_days: 'Total published Meta campaigns -- last 90 days',
  num_live_campaigns: 'Total active campaigns',
  num_login_30_days: 'Total logins -- last 30 days',
  num_login_365_days: 'Total logins -- last 365 days',
  num_login_90_days: 'Total logins -- last 90 days',
  num_person_importers: 'Total person importers',
  num_reachable_people: 'Total reachable people',
  total_display_media_spend: 'Total display media spend',
  total_facebook_media_spend: 'Total Facebook media spend',
};

export class UsageReport extends DisplayModel<IUsageReport> {
  public readonly className = 'Usage';

  public get constraints(): TConstraints<IUsageReport> {
    return {};
  }

  constructor(attributes: Partial<IUsageReport> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/product_usage_reports/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Usage';
  }

  public getReport({
    exclude = [] as Array<keyof IUsageReport>,
    labels = false,
  }: IReportOptions = {}): Record<keyof IUsageReport, string | number | boolean> {
    const reportKeys = Object.keys(keyToLabelMap);
    return reportKeys
      .filter((key) => (exclude.length ? !exclude.includes(key) : key))
      .reduce((acc, key) => {
        acc[labels ? keyToLabelMap[key] : key] = this.get(key);
        return acc;
      }, {});
  }
}

export class UsageReports extends Collection<UsageReport> {
  public getClassName(): string {
    return 'product_usage_reports';
  }

  public getModel(attributes: Partial<IUsageReport>): UsageReport {
    return new UsageReport(attributes);
  }
}
