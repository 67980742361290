import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Domain, IDomainRecordsForwardRequest } from '@feathr/blackbox';
import { ERegistrars } from '@feathr/blackbox';
import { Form, Select } from '@feathr/components';
import { enumKeys } from '@feathr/hooks';

import DomainRecordsTable from './DomainRecordsTable';
import WebmasterEmailCompose from './WebmasterEmailCompose';

interface IProps {
  domain: Domain;
}

function DomainRecords({ domain }: IProps): JSX.Element {
  const { t } = useTranslation();

  const [recordsRequest, setRecordsRequest] = useState<IDomainRecordsForwardRequest>({
    format: ERegistrars.default,
    message: '',
    recipient: '',
  });

  const setDefaultMessage = useCallback(() => {
    const message = t(
      "Hi, I'm trying to authenticate our domain, {{emailDomain}}, with Feathr, but I don't have the ability to modify our DNS records.\n\nCan you help me add these records to our domain? If you have any trouble, reach out to Feathr support at support@feathr.co.",
      { emailDomain: domain.get('email_domain') },
    );
    setRecordsRequest((req) => ({ ...req, message }));
  }, [domain, t]);

  useEffect(() => {
    setDefaultMessage();
  }, [domain.isPending, setDefaultMessage]);

  function handleSelectDNSHost({ id }): void {
    setRecordsRequest({ ...recordsRequest, format: id });
    domain.set({ preferred_format: id });
    domain.patchDirty();
  }

  const enumToLabelMap: Record<ERegistrars, string> = {
    [ERegistrars.enom]: t('Enom'),
    [ERegistrars.godaddy]: t('GoDaddy'),
    [ERegistrars.namecheap]: t('Namecheap'),
    [ERegistrars.networksolutions]: t('Network Solutions'),
    [ERegistrars.tucows]: t('Tucows'),
    [ERegistrars.default]: t('Default format'),
  };

  const registrarOptions = enumKeys(ERegistrars).map((key) => ({
    id: key,
    name: enumToLabelMap[key],
  }));

  return (
    <>
      <Form label={t('Domain records')}>
        <Select
          helpText={t("Records will be formatted according to your DNS host's preferred format.")}
          label={t('Choose your domain name server (DNS) host')}
          name={'domain-records-registrar'}
          onSelectSingle={handleSelectDNSHost}
          options={registrarOptions}
          value={registrarOptions.find(
            ({ id }) => id === domain.get('preferred_format') && recordsRequest.format,
          )}
        />
      </Form>
      <WebmasterEmailCompose
        domain={domain}
        recordsRequest={recordsRequest}
        setRecordsRequest={setRecordsRequest}
      />
      <DomainRecordsTable domain={domain} format={recordsRequest.format} />
    </>
  );
}

export default observer(DomainRecords);
