import { faDesktopAlt, faMobileAlt, faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import type {
  ICampaignAttributes,
  IPartner,
  IPerson,
  IPersonsListParams,
  Template,
} from '@feathr/blackbox';
import { CampaignClass, RecipientType, TemplateClass } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  Alert,
  AlertType,
  AsyncSelect,
  Button,
  Collapse,
  Label,
  PlaceholderImage,
  Select,
  Toolbar,
  Tooltip,
} from '@feathr/components';
import {
  CampaignIconOption,
  PartnerOption,
  PersonOption,
  PersonSingleValue,
} from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';
import type { IListParams } from '@feathr/rachis';

import * as styles from './TemplatePreview.css';

export const RecipientTypeOptions: ISelectOption[] = [
  { id: RecipientType.Person, name: 'Person' },
  { id: RecipientType.Partner, name: 'Partner' },
];

interface IProps {
  visible: boolean;
  template: Template;
  className?: string;
  recipientType?: RecipientType;
}

function TemplatePreview({
  template,
  className,
  visible,
  recipientType,
}: Readonly<IProps>): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();

  const [previewURL, setPreviewURL] = useState('');
  const [mergemap, setMergeMap] = useState({});
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [recipientTypeSelection, setRecipientTypeSelection] = useState<RecipientType | undefined>(
    recipientType,
  );
  const [campaignId, setCampaignId] = useState<string | undefined>();
  const [partnerId, setPartnerId] = useState<string | undefined>();
  const [personId, setPersonId] = useState<string | undefined>();
  const [displayMode, setDisplayMode] = useState<string>('desktop');

  const { Campaigns, Partners, Persons } = useContext(StoresContext);

  async function loadCampaignOptions(inputValue: string): Promise<ICampaignAttributes[]> {
    const campaigns = Campaigns.list({
      filters: {
        name__icontains: inputValue,
        event: template.get('event') || eventId,
        _cls__in: [CampaignClass.Referral, CampaignClass.LandingPage],
      },
      pagination: {
        page: 0,
        page_size: 20,
      },
    });
    await when(() => !campaigns.isPending);
    return campaigns.models.map((cpn) => cpn.toJS());
  }

  function getPartnerParams(inputValue: string): Partial<IListParams<IPartner>> {
    return {
      filters: {
        name__icontains: inputValue,
        _parent: template.get('event') || eventId,
        participation: campaignId ?? undefined,
      },
    } as Partial<IListParams<IPartner>>;
  }

  async function loadPartnerOptions(inputValue: string): Promise<IPartner[]> {
    const partners = Partners.list({
      ...getPartnerParams(inputValue),
      pagination: {
        page: 0,
        page_size: 20,
      },
    });
    await when(() => !partners.isPending);
    return partners.models.map((partner) => partner.toJS());
  }

  function getPersonParams(inputValue: string): Partial<IPersonsListParams> {
    return {
      predicates: [
        {
          kind: 'attribute',
          attr_against: 'name',
          attr_type: 'string',
          comparison: 'wildcard',
          value: `*${inputValue}*`,
        },
        {
          kind: 'attribute',
          group: [
            {
              kind: 'attribute',
              attr_against: 'email',
              attr_type: 'string',
              comparison: 'wildcard',
              value: `*${inputValue}*`,
            },
            {
              kind: 'attribute',
              attr_against: 'email',
              attr_type: 'string',
              comparison: 'exists',
              value: '',
            },
          ],
        },
      ],
      mode: 'match_any',
      lookback_mode: 'unbounded',
    } as Partial<IPersonsListParams>;
  }

  async function loadPersonOptions(inputValue: string): Promise<IPerson[]> {
    const persons = Persons.list(
      {
        ...getPersonParams(inputValue),
        pagination: {
          page: 0,
          page_size: 20,
        },
      },
      {
        url: Persons.url('page'),
      },
    );
    await when(() => !persons.isPending);
    return persons.models.map((person) => person.toJS());
  }

  const cls = template.get('_cls');
  const isBanner = template.isBanner;
  const isPinpointEmail = cls === TemplateClass.PinpointEmail;

  const loadPreview = useCallback(async () => {
    if (template.get('bannersnack_enabled') && !(campaignId && partnerId)) {
      return;
    }
    setLoadingPreview(true);
    if (isBanner && template.get('bannersnack_enabled')) {
      Sentry.captureEvent({
        message: 'Preview bannersnack banner',
        level: 'log',
        extra: {
          accountId: template.get('account'),
          templateId: template.id,
        },
        tags: {
          account: template.get('account') || 'global',
          template: template.id,
        },
      });
    }
    const response = isPinpointEmail
      ? await template.preview(undefined, campaignId, partnerId, personId)
      : await template.preview(campaignId, undefined, partnerId, personId);
    setPreviewURL(response.preview_url);
    setLoadingPreview(false);
  }, [campaignId, isBanner, isPinpointEmail, partnerId, personId, template]);

  const loadMergemap = useCallback(async () => {
    const response = isPinpointEmail
      ? await template.mergemap(undefined, campaignId, partnerId, personId)
      : await template.mergemap(campaignId, undefined, partnerId, personId);
    setMergeMap(response.mergemap);
  }, [campaignId, isPinpointEmail, partnerId, personId, template]);

  // Update recipient type if changed externally
  useEffect(() => {
    setRecipientTypeSelection(recipientType);
  }, [recipientType]);

  // TODO: what is this for?
  useEffect(() => {
    if ((visible && !template.get('bannersnack_enabled')) || (partnerId && campaignId)) {
      loadPreview();
      loadMergemap();
    }
  }, [visible, template, partnerId, campaignId, loadPreview, loadMergemap]);

  function handleSelectRecipientType(selection: ISelectOption): void {
    setRecipientTypeSelection(selection.id as RecipientType);
    handleClearPerson();
    handleClearPartner();
  }

  function handleClearRecipientType(): void {
    setRecipientTypeSelection(undefined);
    handleClearPerson();
    handleClearPartner();
  }

  function handleSelectCampaign(campaign: ICampaignAttributes): void {
    setCampaignId(campaign.id);
  }

  function handleClearCampaign(): void {
    setCampaignId(undefined);
  }

  function handleSelectPartner(partner: IPartner): void {
    setPartnerId(partner.id);
  }

  function handleClearPartner(): void {
    setPartnerId(undefined);
  }

  function handleSelectPerson(person: IPerson): void {
    setPersonId(person.id);
  }

  function handleClearPerson(): void {
    setPersonId(undefined);
  }

  function handleClickReload(): void {
    loadPreview();
    loadMergemap();
  }

  function handleToggleAspect(): void {
    if (displayMode === 'desktop') {
      setDisplayMode('mobile');
    } else {
      setDisplayMode('desktop');
    }
  }

  /*
   * If recipientType then use recipientTypeSelection
   * if isPinpoint && !recipientType then use recipientTypeSelection
   * if !isPinpoint && !recipientType then RecipientType.Partner
   * when RecipientType.Partner then display campaign
   */

  const countParams = { filters: {}, pagination: { page_size: 1 } };
  const partnerCounter = Partners.list({ ...countParams, ...getPartnerParams('') });
  const personCounter = Persons.list(
    { ...countParams, ...getPersonParams('') },
    { url: Persons.url('page') },
  );
  const isCountPending =
    recipientTypeSelection === RecipientType.Person
      ? personCounter.isPending
      : partnerCounter.isPending;
  const count =
    recipientTypeSelection === RecipientType.Person
      ? personCounter.pagination.count
      : partnerCounter.pagination.count;

  return (
    <div className={classNames(styles.root, className)}>
      <Label>Merge Data Sources</Label>
      <Toolbar align={'left'}>
        {isPinpointEmail && !recipientType && (
          <Select
            aria-label={'Recipient type'}
            defaultOptions={true}
            isClearable={true}
            name={'recipient-type'}
            onClear={handleClearRecipientType}
            onSelectSingle={handleSelectRecipientType}
            options={RecipientTypeOptions}
            placeholder={'Recipient type'}
            value={
              recipientTypeSelection ? RecipientTypeOptions[recipientTypeSelection] : undefined
            }
            wrapperClassName={styles.select}
          />
        )}
        {recipientTypeSelection === RecipientType.Person && (
          <AsyncSelect
            aria-label={'Recipient'}
            components={{ Option: PersonOption, SingleValue: PersonSingleValue }}
            defaultOptions={true}
            isClearable={true}
            loadOptions={loadPersonOptions}
            name={'recipient'}
            onClear={handleClearPerson}
            onSelectSingle={handleSelectPerson}
            placeholder={'Recipient'}
            value={personId ? Persons.get(personId).toJS() : undefined}
            wrapperClassName={styles.select}
          />
        )}
        {(!isPinpointEmail || recipientTypeSelection === RecipientType.Partner) && (
          <AsyncSelect
            aria-label={'Recipient'}
            components={{ Option: PartnerOption }}
            defaultOptions={true}
            isClearable={true}
            loadOptions={loadPartnerOptions}
            name={'recipient'}
            onClear={handleClearPartner}
            onSelectSingle={handleSelectPartner}
            placeholder={'Recipient'}
            value={partnerId ? Partners.get(partnerId).toJS() : undefined}
            wrapperClassName={styles.select}
          />
        )}
        {/* NOTE: Campaign merge variable do not work for pinpoint templates. */}
        {!isPinpointEmail && recipientTypeSelection !== RecipientType.Person && (
          <AsyncSelect
            aria-label={'Campaign'}
            components={{ Option: CampaignIconOption }}
            defaultOptions={true}
            isClearable={true}
            loadOptions={loadCampaignOptions}
            name={'campaign'}
            onClear={handleClearCampaign}
            onSelectSingle={handleSelectCampaign}
            placeholder={'Campaign'}
            value={campaignId ? Campaigns.get(campaignId).toJS() : undefined}
            wrapperClassName={styles.select}
          />
        )}
        <Tooltip title={'Reload preview'}>
          <Button
            disabled={
              loadingPreview || (template.get('bannersnack_enabled') && !(partnerId && campaignId))
            }
            isLoading={loadingPreview}
            onClick={handleClickReload}
            type={'icon-outlined'}
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Tooltip>
        {!isBanner && (
          <Tooltip title={'Toggle preview mode'}>
            <Button onClick={handleToggleAspect} type={'icon-outlined'}>
              <FontAwesomeIcon icon={displayMode === 'desktop' ? faDesktopAlt : faMobileAlt} />
            </Button>
          </Tooltip>
        )}
      </Toolbar>
      {recipientTypeSelection && count < 1 && !isCountPending && (
        <Alert type={AlertType.warning}>
          {recipientTypeSelection === RecipientType.Person
            ? 'In order to preview this template with live merge data you will need to add a person to this project.'
            : 'In order to preview this template with live merge data you will need to add a partner to this project.'}
        </Alert>
      )}
      {isBanner && template.get('bannersnack_enabled') ? (
        <>
          {!(partnerId && campaignId) && <p>Please select a recipient and a campaign.</p>}
          {previewURL ? (
            <img
              alt={''}
              className={classNames(styles.frame)}
              data-name={'preview-image'}
              height={template.get('height')}
              src={previewURL}
              width={template.get('width')}
            />
          ) : (
            <PlaceholderImage
              height={template.get('height') || 250}
              name={'preview-placeholder'}
              width={template.get('width') || 300}
            />
          )}
        </>
      ) : (
        <iframe
          className={classNames(styles.frame, {
            [styles.mobile]: displayMode === 'mobile',
            [styles.page]: displayMode === 'desktop' && !isBanner,
          })}
          data-name={'preview'}
          height={isBanner ? template.get('height') : 700}
          scrolling={isBanner ? 'no' : 'yes'}
          src={previewURL}
          title={'Preview'}
          width={isBanner ? template.get('width') : undefined}
        />
      )}
      {!!mergemap && (
        <Collapse title={'Show merge data'}>
          <dl>
            {Object.entries(mergemap).map(([key, value]) => (
              <React.Fragment key={key}>
                <dt>{key}</dt>
                <dd>
                  <pre>{value as string}</pre>
                </dd>
              </React.Fragment>
            ))}
          </dl>
        </Collapse>
      )}
    </div>
  );
}

export default observer(TemplatePreview);
