import { isValid, parse } from 'psl';

interface IDomainValidationOptions {
  subdomain?: boolean;
}

export default function IDomainValidationOptions(
  value: string,
  { subdomain }: IDomainValidationOptions,
): string[] | null {
  const invalidEntryError = ['^Please provide a domain.'];
  const invalidDomainError = ['^Please provide a valid domain.'];
  const invalidSubdomainError = ['^Please provide a valid subdomain.'];

  if (!value) {
    return invalidEntryError;
  }

  const parsedValue = parse(value);

  if (subdomain) {
    // This specifically validates domains with only one subdomain. Update this code if necessary
    return !isValid(value) || !parsedValue.subdomain || parsedValue.subdomain.includes('.')
      ? invalidSubdomainError
      : null;
  }

  return !isValid(value) || parsedValue.subdomain ? invalidDomainError : null;
}
