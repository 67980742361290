import { toJS } from 'mobx';

import type { DripStepCampaign } from '@feathr/blackbox';
import { getNestedObjectDiff, useDeepCompareEffect } from '@feathr/hooks';

interface IUseSyncStepsWithStepSpecs {
  ephemeralCampaign: DripStepCampaign;
  handleSync: () => Promise<void>;
}

function useSyncStepsWithStepSpecs({
  ephemeralCampaign,
  handleSync,
}: Readonly<IUseSyncStepsWithStepSpecs>): void {
  const initialCampaignAttributes = toJS(ephemeralCampaign.initialAttributes);
  const currentCampaignAttributes = toJS(ephemeralCampaign.attributes);

  /*
   * We must get a diff of the attributes to determine when to trigger a sync.
   * Shadow attributes do not work in this scenario and a deep compare is needed.
   */
  const campaignAttributesDiff = getNestedObjectDiff(
    initialCampaignAttributes,
    currentCampaignAttributes,
  );

  // Sync the campaign & template
  useDeepCompareEffect(() => {
    if (ephemeralCampaign.isDirty) {
      handleSync();
    }
  }, [campaignAttributesDiff, ephemeralCampaign.isDirty]);
}

export default useSyncStepsWithStepSpecs;
