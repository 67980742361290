import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Campaign, IGoogleAdsKeyword } from '@feathr/blackbox';
import { ContextMenu, toast } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

interface IKeywordStatsOptionsProps {
  campaign: Campaign;
  isNegative: boolean;
  term: string;
}

function KeywordStatsOptions({
  campaign,
  isNegative,
  term,
}: Readonly<IKeywordStatsOptionsProps>): JSX.Element {
  const { t } = useTranslation();
  const existingKeywordThemes = campaign.get('keyword_themes');

  async function handleAdd(): Promise<void> {
    try {
      const existingNegativeKeywords = existingKeywordThemes
        .filter((keyword: IGoogleAdsKeyword) => keyword.negative === true)
        .map((keyword: IGoogleAdsKeyword) => keyword.display_name);
      const keyword = { display_name: term, negative: true, suggestion: false };
      if (!existingNegativeKeywords.includes(keyword.display_name)) {
        const keywords = [...existingKeywordThemes, keyword];
        campaign.set({ keyword_themes: keywords });
        await campaign.patchDirty();
      }
      toast(
        t('{{keyword}} has been added as a negative keyword for the campaign.', {
          keyword: term,
        }),
        { type: ToastType.SUCCESS },
      );
    } catch (error) {
      toast(t('Something went wrong while adding the negative keyword:\n{{error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  async function handleRemove(): Promise<void> {
    try {
      const newKeywords = existingKeywordThemes.filter(
        (keyword: IGoogleAdsKeyword) => keyword.display_name !== term || !keyword.negative,
      );
      campaign.set({ keyword_themes: newKeywords });
      await campaign.patchDirty();
      toast(
        t('{{keyword}} has been removed as a negative keyword for the campaign.', {
          keyword: term,
        }),
        { type: ToastType.SUCCESS },
      );
    } catch (error) {
      toast(t('Something went wrong while removing the negative keyword:\n{{error}}'), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <ContextMenu buttonType={'icon'} name={'keyword-stats-options'} position={'left-start'}>
      {!isNegative && (
        <ContextMenu.Item onClick={handleAdd} prefix={getIconForAction('add')}>
          {t('Add negative keyword')}
        </ContextMenu.Item>
      )}
      {isNegative && (
        <ContextMenu.Item
          onClick={handleRemove}
          prefix={getIconForAction('remove')}
          theme={'danger'}
        >
          {t('Remove negative keyword')}
        </ContextMenu.Item>
      )}
      <ContextMenu.Item
        link={`${campaign.getItemUrl()}/edit#step3`}
        prefix={getIconForAction('edit')}
      >
        {t('Edit keyword themes')}
      </ContextMenu.Item>
    </ContextMenu>
  );
}

export default KeywordStatsOptions;
