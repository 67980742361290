import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueType } from 'react-select';

import type { IExposureModel } from '@feathr/blackbox';
import type { Campaign } from '@feathr/blackbox';
import { CreatableSelect } from '@feathr/components';

import * as styles from './BaseBidSelect/BaseBidSelect.css';

interface IProps {
  campaign: Campaign;
}

interface IOption {
  value: string;
  label: string;
}

export const PERIOD_MAX = 2147483647;

function compareOption(inputValue: string, option: IOption): boolean {
  return inputValue === option.value;
}

function isValidNewOption(
  inputValue: string,
  _: ValueType<IOption>,
  options: readonly IOption[],
): boolean {
  const asNumber = Number(inputValue);
  return !(
    !inputValue ||
    !Number.isFinite(asNumber) ||
    !(asNumber > 0 && asNumber <= PERIOD_MAX) ||
    options.some((option) => compareOption(inputValue, option))
  );
}

function FreqPeriodSelect({ campaign }: IProps): JSX.Element {
  const { t } = useTranslation();
  const period = campaign.get('exposure_model').freq_period;

  function createOption(inputValue: string): IOption {
    const asNumeral = numeral(Number(inputValue));
    return {
      value: asNumeral.format('0'),
      label: t('{{count}} hour', { count: Number(inputValue) }),
    };
  }

  const onChangeFreqPeriod = (value: string | number): void => {
    let newPeriod;
    if (value === 'lifetime') {
      newPeriod = PERIOD_MAX;
    } else {
      newPeriod = Number(value) * 60;
    }
    campaign.set({
      exposure_model: {
        ...campaign.get('exposure_model'),
        freq_period: newPeriod > PERIOD_MAX ? PERIOD_MAX : newPeriod,
        intensity: 'custom',
      } as IExposureModel,
    });
  };

  function handleSelectSingle(option: IOption): void {
    onChangeFreqPeriod(option.value);
  }

  const lifeTimeOption: IOption = { value: 'lifetime', label: t('Lifetime') };
  const defaultOptions: IOption[] = [
    { value: '1', label: t('{{count}} hour', { count: 1 }) },
    { value: '8', label: t('{{count}} hour', { count: 8 }) },
    { value: '24', label: t('{{count}} hour', { count: 24 }) },
    lifeTimeOption,
  ];

  function formatCreateLabel(inputValue: string): string {
    return t('Use custom frequency period: {{count}} hour', {
      count: Number(inputValue),
    });
  }

  function getValue(period: number): IOption {
    if (period === PERIOD_MAX) {
      return lifeTimeOption;
    }

    if (period > PERIOD_MAX) {
      return lifeTimeOption;
    }

    return {
      value: String(Math.ceil(period / 60)),
      label: t('{{count}} hour', {
        count: Math.ceil(period / 60),
      }),
    };
  }

  return (
    <CreatableSelect<IOption>
      createOption={createOption}
      defaultOptions={defaultOptions}
      formatCreateLabel={formatCreateLabel}
      helpText={t(
        "The period of time after which a person's frequency cap is reset. Choosing 'Lifetime' means the frequency cap is the maximum number of impressions per person.",
      )}
      isValidNewOption={isValidNewOption}
      label={'Frequency period'}
      name={'frequency-period'}
      onSelectSingle={handleSelectSingle}
      value={getValue(period)}
      wrapperClassName={styles.wrapper}
    />
  );
}

export default observer(FreqPeriodSelect);
