import type { TConstraints } from '@feathr/rachis';

import type { IMonetizationAttributes } from './types';

export const monetizationConstraints: TConstraints<IMonetizationAttributes> = {
  monetization_value: {
    presence: {
      message: '^Sponsor package value cannot be empty',
      allowEmpty: false,
    },
    numericality: {
      greaterThan: 0,
      message: '^Sponsor package value should be greater than $0',
    },
  },
};
