import type { TFunction } from 'i18next';
import { computed } from 'mobx';
import moment from 'moment/moment';

import type { IBaseAttributes } from '@feathr/rachis';
import { DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { IBlackbaudRaisersEdgeIntegrationAttributes } from '../blackbaud_raisers_edge';
import type { IiMISIntegrationAttributes } from '../imis';

export enum EIntegrationConnectionState {
  connected = 'connected',
  disconnected = 'disconnected',
  failed = 'failed',
  notConnected = 'not_connected',
  paused = 'paused',
  updating_connection = 'updating_connection',
}

export enum EIntegrationSyncRule {
  bothWays = 'both_ways',
  downSync = 'down_sync',
  upSync = 'up_sync',
}

export enum EIntegrationSyncState {
  notSynced = 'not_synced',
  syncInProgress = 'sync_in_progress',
  syncComplete = 'sync_complete',
}

export type TIntegrationSyncRule = keyof typeof EIntegrationSyncRule;

export type TIntegrationDataType = 'str' | 'int' | 'float' | 'bool' | 'list' | 'date';

/** Person attributes that can be mapped to integration fields. */
export type TIntegrationPersonAttributes =
  | 'companies'
  | 'email'
  | 'external_id'
  | 'first_name'
  | 'last_name'
  | 'name'
  | 'occupation';

export enum EIntegrationTypes {
  BlackbaudRaisersEdge = 'blackbaud_raisers_edge',
  Facebook = 'facebook',
  GoogleAds = 'google',
  Imis = 'imis',
  Intacct = 'intacct',
  Salesforce = 'salesforce',
  Tradedesk = 'tradedesk',
}

export interface IIntegrationBaseMapping extends IBaseAttributes {
  custom_field: string | null;
  data_type: TIntegrationDataType | null;
  date_last_synced: string | null;
  default_field: unknown;
  /** iMIS: Sets a field as not-editable. Users cannot change the mapping. This currently is true only for email. */
  editable: boolean;
  integration: IiMISIntegrationAttributes['id'] | IBlackbaudRaisersEdgeIntegrationAttributes['id'];
  key: string;
  required: boolean;
  sync_rule: EIntegrationSyncRule;
}

export interface IIntegrationAttributes extends IBaseAttributes {
  account: string;
  /** The type for contact_mappings is unknown until implemented and overriden by an integration. */
  contact_mappings: unknown;
  /** The type for activity_mappings is unknown until implemented and overriden by an integration. */
  activity_mappings: unknown;
  created_by: string;
  date_created: string;
  date_last_down_synced?: string;
  date_updated: string;
  is_down_syncing: boolean;
  last_updated_by: string;
  state: EIntegrationConnectionState;
}

export const integrationStateTranslationMap = (
  t: TFunction,
): Record<EIntegrationConnectionState, string> => ({
  [EIntegrationConnectionState.connected]: t('Connected'),
  [EIntegrationConnectionState.disconnected]: t('Disconnected'),
  [EIntegrationConnectionState.failed]: t('Integration failed'),
  [EIntegrationConnectionState.notConnected]: t('Not Connected'),
  [EIntegrationConnectionState.paused]: t('Paused'),
  [EIntegrationConnectionState.updating_connection]: t('Updating Connection'),
});
export const integrationSyncStateTranslationMap = (
  t: TFunction,
): Record<EIntegrationSyncState, string> => ({
  [EIntegrationSyncState.notSynced]: t('Not synced'),
  [EIntegrationSyncState.syncInProgress]: t('Sync in progress'),
  [EIntegrationSyncState.syncComplete]: t('Sync complete'),
});

export const integrationStateTooltipTranslationMap = (
  t: TFunction,
): Record<EIntegrationConnectionState, string> => ({
  [EIntegrationConnectionState.connected]: t('Your integration is connected.'),
  [EIntegrationConnectionState.disconnected]: t('Your integration is disconnected.'),
  [EIntegrationConnectionState.failed]: t(
    'Something went wrong and your integration is in a failed state.',
  ),
  [EIntegrationConnectionState.notConnected]: t('Your integration is not connected.'),
  [EIntegrationConnectionState.paused]: t('Your integration is paused.'),
  [EIntegrationConnectionState.updating_connection]: t('Your integration connection is updating.'),
});

export const integrationSyncStateTooltipTranslationMap = (
  t: TFunction,
): Record<EIntegrationSyncState, string> => ({
  [EIntegrationSyncState.notSynced]: t('We have not been able to get your data yet.'),
  [EIntegrationSyncState.syncInProgress]: t(
    'We are currently getting your data. This may take a while.',
  ),
  [EIntegrationSyncState.syncComplete]: t('Your data is up to date.'),
});

export abstract class BaseIntegration<
  IAttributes extends IIntegrationAttributes,
> extends DisplayModel<IAttributes> {
  /** Returns a URL for this item in Blackbox */
  public getBackendItemUrl(): string {
    return `${this.collection?.url()}${this.id}`;
  }

  @computed
  public get integrationState(): EIntegrationConnectionState {
    const status = this.get('state');
    if (
      [
        EIntegrationConnectionState.connected,
        EIntegrationConnectionState.disconnected,
        EIntegrationConnectionState.failed,
        EIntegrationConnectionState.paused,
        EIntegrationConnectionState.updating_connection,
      ].includes(status)
    ) {
      return status;
    }

    return EIntegrationConnectionState.notConnected;
  }

  @computed
  public get isConnected(): boolean {
    /*
     * updating_connection represents a connected state where subscriptions
     * are provisioning. The integration is still connected in this state.
     */
    return (
      this.integrationState === EIntegrationConnectionState.connected ||
      this.integrationState === EIntegrationConnectionState.updating_connection
    );
  }

  @computed
  public get isPaused(): boolean {
    return this.integrationState === EIntegrationConnectionState.paused;
  }

  @computed
  public get isDisconnected(): boolean {
    return this.integrationState === EIntegrationConnectionState.disconnected;
  }

  @computed
  public get isDownSyncing(): boolean {
    return this.get('is_down_syncing');
  }

  @computed
  public get lastSynced(): string | undefined {
    return this.get('date_last_down_synced');
  }

  @computed
  public get syncState(): EIntegrationSyncState {
    const daysSinceLastSync: number = moment().diff(moment(this.lastSynced), 'days');

    if (!this.isDownSyncing && daysSinceLastSync > 1) {
      return EIntegrationSyncState.notSynced;
    } else if (!this.isDownSyncing && this.lastSynced !== undefined) {
      return EIntegrationSyncState.syncComplete;
    } else if (this.isDownSyncing) {
      return EIntegrationSyncState.syncInProgress;
    }
    return EIntegrationSyncState.notSynced;
  }

  /** Pause a connected integration via PATCH */
  public async pauseIntegration(): Promise<IIntegrationAttributes> {
    this.assertCollection(this.collection);

    const url = this.getBackendItemUrl();
    const response = await wretch<IIntegrationAttributes>(url, {
      method: 'PATCH',
      headers: this.collection.getHeaders(),
      body: JSON.stringify({ state: EIntegrationConnectionState.paused }),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  /** Disconnect a paused or connected integration via PATCH */
  public async disconnectIntegration(): Promise<IIntegrationAttributes> {
    this.assertCollection(this.collection);

    const url = this.getBackendItemUrl();
    const response = await wretch<IIntegrationAttributes>(url, {
      method: 'PATCH',
      headers: this.collection.getHeaders(),
      body: JSON.stringify({ state: EIntegrationConnectionState.disconnected }),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  /** Resume a paused integration via PATCH */
  public async resumeIntegration(): Promise<IIntegrationAttributes> {
    this.assertCollection(this.collection);

    const url = this.getBackendItemUrl();
    const response = await wretch<IIntegrationAttributes>(url, {
      method: 'PATCH',
      headers: this.collection.getHeaders(),
      body: JSON.stringify({ state: EIntegrationConnectionState.connected }),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }
}
