import validate from 'validate.js/validate';

export interface IPresenceConstraint {
  allowEmpty?: boolean;
  message?: string;
}

export interface IPresenceUnlessConstraint extends IPresenceConstraint {
  unless: ((attributes: Record<string, any>) => boolean) | string;
}

function presenceUnless(
  value: any,
  options: IPresenceUnlessConstraint,
  key: string,
  attributes: Record<string, any>,
) {
  const { unless } = options;

  // Only validate if depending field has value.
  if (typeof unless === 'function' ? unless(attributes) : !attributes[unless]) {
    return null;
  }

  return validate.validators.presence(value, options);
}

export default presenceUnless;
