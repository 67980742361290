import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import type { ActionMeta, ValueType } from 'react-select';
import type ReactSelect from 'react-select';

import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import { ELanguages, Languages } from '@feathr/locales';

import * as styles from './LanguageSelect.css';

interface ILanguageSelectOption extends ISelectOption {
  id: Languages;
}

interface ILanguageSelectProps {
  className?: string;
  isLoading?: boolean;
  onChange: (value?: Languages) => void;
  onClear: () => void;
  placeholder?: string;
  value?: Languages;
}

function LanguageSelect(
  {
    className,
    isLoading,
    onChange,
    onClear,
    placeholder,
    value,
    ...additionalProps
  }: ILanguageSelectProps,
  ref: Ref<ReactSelect<ILanguageSelectOption>>,
) {
  const languages = Languages.map(
    (key) => ({ id: key, name: ELanguages[key] }) as ILanguageSelectOption,
  );

  function handleChange(
    newValue: ValueType<ILanguageSelectOption>,
    action: ActionMeta<ILanguageSelectOption>,
  ) {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (newValue) {
        onChange((newValue as ILanguageSelectOption).id);
      } else {
        onClear();
      }
    }
  }

  const selectedOption = value ? languages.find((option) => option.id === value) : undefined;

  return (
    <Select<ILanguageSelectOption>
      {...additionalProps}
      innerRef={ref}
      isMulti={false}
      name={'language-select'}
      onChange={handleChange}
      onClear={onClear}
      options={languages}
      placeholder={placeholder || 'Select language...'}
      value={selectedOption}
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(forwardRef(LanguageSelect));
