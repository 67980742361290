import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, IConfig } from '@feathr/blackbox';
import { Button, ContextMenu, Toolbar } from '@feathr/components';
import CampaignCloneModal from '@feathr/extender/components/CampaignCloneModal';
import CampaignDisableModal from '@feathr/extender/components/CampaignDisableModal';
import type { IUseReportExportReturn } from '@feathr/extender/hooks';
import { useCampaign } from '@feathr/extender/hooks';
import { useLocalUrl } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import CampaignArchiveModal from '../CampaignArchiveModal';
import CampaignDeleteModal from '../CampaignDeleteModal';
import ReportActionsContextMenu from './ReportActionsContextMenu';

interface ICampaignActionsProps {
  children?: ReactNode;
  campaign: Campaign;
}
export interface ICampaignReportActionsProps
  extends ICampaignActionsProps,
    IUseReportExportReturn<IConfig> {}

type TCampaignActionsProps = ICampaignReportActionsProps | ICampaignActionsProps;

function isReportProps(obj: TCampaignActionsProps): obj is ICampaignReportActionsProps {
  return (obj as ICampaignReportActionsProps).config !== undefined;
}

function CampaignActions(props: Readonly<TCampaignActionsProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const { campaign, children } = props;
  const { allowed, disabled, hasWizard, modalHandlers, modalStates, text } = useCampaign({
    campaign,
  });

  function renderReportActions(): JSX.Element | null {
    if (!isReportProps(props)) {
      return null;
    }

    const { campaign, ...exportProps } = props;

    const menuItems = (
      <>
        <ContextMenu.Divider />
        <ContextMenu.Label>{t('Campaign')}</ContextMenu.Label>
        <ContextMenu.Item
          disabled={disabled.clone}
          onClick={modalHandlers.openCloneModal}
          prefix={getIconForAction('clone')}
          tooltip={text.disableClone}
        >
          {t('Duplicate')}
        </ContextMenu.Item>
        {/* Drafts can be deleted, published campaigns can be archived */}
        {allowed.delete ? (
          <ContextMenu.Item
            onClick={modalHandlers.openDeleteModal}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Delete')}
          </ContextMenu.Item>
        ) : (
          <ContextMenu.Item
            onClick={modalHandlers.openArchiveModal}
            prefix={getIconForAction('archive')}
            theme={'danger'}
          >
            {t('Archive')}
          </ContextMenu.Item>
        )}
      </>
    );

    return (
      <ReportActionsContextMenu menuItems={menuItems} model={campaign} {...exportProps}>
        <CampaignCloneModal
          campaign={campaign}
          closeCloneModal={modalHandlers.closeCloneModal}
          isCloneModalOpen={modalStates.isCloneModalOpen}
        />
        <CampaignArchiveModal
          campaign={campaign}
          closeArchiveModal={modalHandlers.closeArchiveModal}
          isArchiveModalOpen={modalStates.isArchiveModalOpen}
        />
        <CampaignDeleteModal
          campaign={campaign}
          closeDeleteModal={modalHandlers.closeDeleteModal}
          isDeleteModalOpen={modalStates.isDeleteModalOpen}
        />
      </ReportActionsContextMenu>
    );
  }

  return (
    <>
      <Toolbar>
        {renderReportActions()}
        {hasWizard && (
          <Button link={localUrl(campaign.getItemUrl('edit'))} prefix={getIconForAction('edit')}>
            {t('Edit')}
          </Button>
        )}

        {allowed.stop && (
          <Button
            disabled={disabled.stop}
            onClick={modalHandlers.openDisableModal}
            prefix={getIconForAction('stop')}
            tooltip={text.disableStop}
            tooltipPosition={'top-end'}
            type={'danger'}
          >
            {t('Stop campaign')}
          </Button>
        )}
        {children}
      </Toolbar>

      <CampaignDisableModal
        campaign={campaign}
        closeDisableModal={modalHandlers.closeDisableModal}
        isDisableModalOpen={modalStates.isDisableModalOpen}
      />
    </>
  );
}

export default observer(CampaignActions);
