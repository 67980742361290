import type { TFunction } from 'i18next';

import { EBREActivityMappings, EBREContactMappings } from '@feathr/blackbox';

export const RaisersEdgeContactLabelMap = (t: TFunction, field: string): string => {
  const map = {
    [EBREContactMappings.address]: t('Address'),
    [EBREContactMappings.age]: t('Age'),
    [EBREContactMappings.birthdate]: t('Birth date'),
    [EBREContactMappings.constituent_codes]: t('Constituent codes'),
    [EBREContactMappings.consecutive_years_given]: t('Consecutive years given'),
    [EBREContactMappings.date_added]: t('Date added'),
    [EBREContactMappings.date_modified]: t('Date modified'),
    [EBREContactMappings.date_of_first_gift]: t('Date of first gift'),
    [EBREContactMappings.date_of_last_gift]: t('Date of last gift'),
    [EBREContactMappings.deceased]: t('Deceased'),
    [EBREContactMappings.deceased_date]: t('Deceased date'),
    [EBREContactMappings.email]: t('Primary email'),
    [EBREContactMappings.emails]: t('Additional emails'),
    [EBREContactMappings.first]: t('First name'),
    [EBREContactMappings.first_gift_amount]: t('First gift amount'),
    [EBREContactMappings.former_name]: t('Former name'),
    [EBREContactMappings.fundraiser_status]: t('Fundraiser status'),
    [EBREContactMappings.gender]: t('Gender'),
    [EBREContactMappings.gives_anonymously]: t('Gives anonymously'),
    [EBREContactMappings.greatest_gift_amount]: t('Greatest gift amount'),
    [EBREContactMappings.id]: t('System record ID'),
    [EBREContactMappings.inactive]: t('Inactive'),
    [EBREContactMappings.last]: t('Last name'),
    [EBREContactMappings.last_gift_amount]: t('Last gift amount'),
    [EBREContactMappings.lookup_id]: t('Constituent ID'),
    [EBREContactMappings.marital_status]: t('Marital status'),
    [EBREContactMappings.middle]: t('Middle name'),
    [EBREContactMappings.name]: t('Full name'),
    [EBREContactMappings.phone]: t('Phone'),
    [EBREContactMappings.primary_addressee]: t('Primary addressee'),
    [EBREContactMappings.primary_salutation]: t('Primary salutation'),
    [EBREContactMappings.preferred_name]: t('Preferred name'),
    [EBREContactMappings.solicit_codes]: t('Solicit codes'),
    [EBREContactMappings.suffix]: t('Suffix'),
    [EBREContactMappings.suffix_2]: t('Suffix (secondary)'),
    [EBREContactMappings.title]: t('Title'),
    [EBREContactMappings.title_2]: t('Title (secondary)'),
    [EBREContactMappings.total_giving]: t('Total giving'),
    [EBREContactMappings.total_years_given]: t('Total years giving'),
    [EBREContactMappings.type]: t('Type'),
  };
  return map[field];
};

export const RaisersEdgeActivityLabelMap = (t: TFunction, field: string): string => {
  const map = {
    [EBREActivityMappings.gift_events]: t('Gift added'),
  };
  return map[field];
};

export const FeathrActivityLabelMap = (t: TFunction, field: string): string => {
  const map = {
    [EBREActivityMappings.gift_events]: t("Raiser's Edge NXT gift added"),
  };
  return map[field];
};
