import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Option } from 'react-select/src/filters';

import type { ISelectOption } from '@feathr/components';
import { Select } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import type { TTemplateGroup } from '@feathr/extender/styles/template';
import { templateGroupLabel } from '@feathr/extender/styles/template';

interface IGroupSelectOption extends ISelectOption {
  id: TTemplateGroup;
}

interface IProps {
  className?: string;
  onChange: (value?: TTemplateGroup) => void;
  value?: TTemplateGroup;
  wrapperClassName?: string;
}

function TemplateGroupSelect({
  className,
  onChange,
  value,
  wrapperClassName,
}: IProps): JSX.Element {
  useAccount();
  const { t } = useTranslation();

  function filterOption({ label }: Option, inputValue: string): boolean {
    return !!label.localeCompare(inputValue);
  }

  function onClearGroup(): void {
    onChange(undefined);
  }

  function onSelectGroup({ id }: IGroupSelectOption): void {
    onChange(id);
  }

  const groupOptions: IGroupSelectOption[] = [
    { id: 'default', name: templateGroupLabel('default', t) },
    { id: 'account', name: templateGroupLabel('account', t) },
    { id: 'project', name: templateGroupLabel('project', t) },
    { id: 'campaign', name: templateGroupLabel('campaign', t) },
  ];

  return (
    <Select<IGroupSelectOption>
      className={className}
      filterOption={filterOption}
      isClearable={true}
      name={'template-group-select'}
      onClear={onClearGroup}
      onSelectSingle={onSelectGroup}
      options={groupOptions}
      placeholder={t('Group')}
      value={groupOptions.find((option) => {
        return option.id === value;
      })}
      wrapperClassName={wrapperClassName}
    />
  );
}

export default TemplateGroupSelect;
