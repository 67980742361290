class dev {
  public static readonly isActive: boolean = process.env.NODE_ENV === 'development';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static printCaller(msg: any): string | undefined {
    const error = msg instanceof Error ? msg : new Error();
    const stackLine = error.stack?.split('\n')?.[3];
    if (stackLine) {
      // TODO: Fix translation from webpack-internal line numbers to source line numbers (this is good enough for now)
      const match = stackLine.match(/^\s*at\s+(.+)\s+\((.+):(\d+):(\d+)\)$/);
      if (match) {
        const [, functionName, path, line, column] = match;
        const fileName = path.split('/').pop() || path;
        return `@ ${functionName} (${fileName}:${line}:${column})`;
      }
    }
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static error(msg: any, ...args: any[]): void {
    if (dev.isActive) {
      const caller = dev.printCaller(msg);
      // eslint-disable-next-line no-console
      console.error(msg, caller, '\n', ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static log(msg: any, ...args: any[]): void {
    if (dev.isActive) {
      const caller = dev.printCaller(msg);
      // eslint-disable-next-line no-console
      console.log(msg, caller, ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static warn(msg: any, ...args: any[]): void {
    if (dev.isActive) {
      const caller = dev.printCaller(msg);
      // eslint-disable-next-line no-console
      console.warn(msg, caller, ...args);
    }
  }
}

export default dev;
