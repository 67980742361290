import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IBankAccountSource } from '@feathr/blackbox';
import type { Billable } from '@feathr/blackbox';
import { ACHIcon, Icon } from '@feathr/components';

import PaymentMethod from '../PaymentMethod';

import * as styles from './BankAccount.css';

interface IProps {
  billable: Billable;
}

function BankAccount({ billable }: Readonly<IProps>): JSX.Element | null {
  const { t } = useTranslation();
  const source = !billable.isPending
    ? (billable.get('stripe').source as IBankAccountSource | undefined)
    : undefined;
  if (source === undefined) {
    return null;
  }

  const details = (
    <div className={styles.details}>
      <div>
        {t('Ending in')} {source.last4}
        {source.status === 'verified' && (
          <FontAwesomeIcon className={styles.verified} icon={faBadgeCheck} size={'1x'} />
        )}
      </div>
    </div>
  );

  return (
    <>
      <PaymentMethod
        icon={<Icon className={styles.icon} icon={ACHIcon} name={'ACHIcon'} />}
        label={details}
      />
    </>
  );
}

export default observer(BankAccount);
