import { computed, makeObservable } from 'mobx';

import { moment } from '@feathr/hooks';
import type {
  Attributes,
  DeepObservable,
  IBaseAttributes,
  IMetadata,
  IWretchResponseValid,
  TConstraints,
} from '@feathr/rachis';
import { Collection, isWretchError, Model, wretch } from '@feathr/rachis';

import type { TUnitOfTimeConversions } from './conversions';
import type { IFacebookError, IToSAccepted } from './facebook_ad_accounts';
import { feathrFacebookErrorMap } from './facebook_ad_accounts';
import type { TFlagsRecord } from './flags';
import { EIntegrationTypes } from './integrations';
import type { IStats, TAttributionModel } from './stats';
import { EUserRoleIDs } from './user_roles';
import type { User } from './users';

export enum Access {
  Facebook = 'Facebook',
}

export enum FacebookAccessTokenHealth {
  ok = 'ok',
  expired = 'expired',
}

export enum FacebookIntegrationState {
  not_connected = 'Not Connected',
  no_page_selected = 'No Page Selected',
  page_selected = 'Page Selected',
  access_token_pending = 'Access Token Pending',
  payment_method_pending = 'Payment Method Pending',
  ad_account_pending = 'Ad Account Pending',
  failed = 'Failed',
}

export interface IFacebook extends Record<string, string | undefined | IToSAccepted> {
  id?: string;
  name: string;
  user_id: string;
  username: string;
  access_token: string;
  business_manager_access_token: string;
  access_token_health: FacebookAccessTokenHealth;
  state: FacebookIntegrationState;
  date_token_expiration?: string;
  pixel_id?: string;
  tos_accepted: IToSAccepted;
}
export enum ELicensePackage {
  /*
   * These will not be sold past 2024 Q1. After that, they will expire and must
   * be migrated to the new license model.
   *
   * The following are deprecated and should not be used.
   */
  /** @deprecated */
  Finch = 'Finch',
  /** @deprecated */
  FinchLegacy = 'Finch Legacy',
  /** @deprecated */
  Phoenix = 'Phoenix',
  /** @deprecated */
  FalconLicenseExtended = 'Falcon License - Extended',
  /** @deprecated */
  FinchLicenseExtended = 'Finch License - Extended',
  /** @deprecated */
  PhoenixLicenseExtended = 'Phoenix License - Extended',
  /** @deprecated */
  EnterpriseMarketingSeat = 'Enterprise Marketing Seat',
  /** @deprecated */
  EnterpriseUnlimitedMarketingSeats = 'Enterprise Unlimited Marketing Seats',
  /** @deprecated */
  EnterpriseMonetizationSeat = 'Enterprise Monetization Seat',
  /** @deprecated */
  EnterpriseUnlimitedMonetizationSeats = 'Enterprise Unlimited Monetization Seats',
  /** @deprecated */
  Falcon = 'Falcon',
  /** @deprecated */
  EnterpriseFlamingo = 'Enterprise Flamingo',
  /** @deprecated */
  EnterpriseFalcon = 'Enterprise Falcon',
  /** @deprecated */
  EnterpriseFalconSeat = 'Enterprise Falcon Seat',
  /** @deprecated */
  EnterpriseFalconUnlimitedSeats = 'Enterprise Falcon Unlimited Seats',
  /** @deprecated */
  FalconLicense = 'Falcon License',
  /** @deprecated */
  FinchLicense = 'Finch License',
  /** @deprecated */
  FinchLegacyLicense = 'Finch Legacy License',
  /** @deprecated */
  PhoenixLicense = 'Phoenix License',

  /*
   * The new license packages from 2024 Q1 onwards.
   */
  FeathrPlatformBase = 'Feathr Platform - Base',
  FeathrPlatformStarter = 'Feathr Platform - Starter',
  FeathrPlatformStarterPro = 'Feathr Platform - Starter Pro',
  FeathrPlatformEssential = 'Feathr Platform - Essential',
  FeathrPlatformEssentialPro = 'Feathr Platform - Essential Pro',
  FeathrPlatformAdvanced = 'Feathr Platform - Advanced',
  FeathrPlatformAdvancedPro = 'Feathr Platform - Advanced Pro',
  FeathrPlatformEnterprise = 'Feathr Platform - Enterprise',
  FeathrPlatformEnterprisePro = 'Feathr Platform - Enterprise Pro',
}

export enum EPackageTiers {
  // Legacy Package Tier
  Finch = 'Finch',
  Falcon = 'Falcon',
  EnterpriseFalcon = 'Enterprise Falcon',
  Phoenix = 'Phoenix',
  // Platform Package Tier
  Base = 'Base',
  Starter = 'Starter',
  Essential = 'Essential',
  Advanced = 'Advanced',
  Enterprise = 'Enterprise',
}

export enum ELicensePackageAddOns {
  // Only for: Feathr Platform - Essential
  PlusFiveThousandAdditionalKnownPeople = '+5,000 Additional Known People (Essential)',

  // Only for: Feathr Platform - Advanced
  PlusTenThousandAdditionalKnownPeople = '+10,000 Additional Known People (Advanced)',
  PlusOneHundredThousandAdditionalKnownPeople = '+100,000 Additional Known People (Advanced)',
}

export interface IPeriod {
  start: string;
  end: string;
}

/**
 * Interface representing a grace period to add to the license start and end dates.
 */
export interface IGracePeriod {
  /** Number of days to add to the beginning of the license. Defaults to 0. */
  start: number;
  /** Number of days to add to the end of the license. Defaults to 0. */
  end: number;
}

export interface IService {
  bill?: string;
  id?: string;
  grace_period: IGracePeriod;
  opportunity_line_item_id?: string;
  is_paid: boolean;
  item_id: string;
  name: string;
  note?: string;
  opp_id: string;
  period: IPeriod;
  price: number;
  quantity: number;
}

export interface IPackage extends IService {
  cw_date?: string;
  name: ELicensePackage;
}

export interface IAddOn extends IService {
  /*
   * Add-ons are not interchangeable. The +5000 Additional Known People (Essential) product code can only be offered to
   * customers with "Feathr Platform - Essential". Similarly, the +10000 Additional Known People (Advanced) product code
   * can only be offered to customers with "Feathr Platform - Advanced".
   *
   * Why? The +5000 Additional Known People (Essential) product code and the +10000 Additional Known People (Advanced)
   * product code will have the same price point, but one gives double the benefit (as the name suggests).
   * This is a perk of being on Feathr Platform - Advanced.
   */
  name: ELicensePackageAddOns;
}

export interface ILicense {
  active_package?: IPackage;
  active_services?: IService[];
  add_ons: IAddOn[];
  autorenew: boolean;
  billable?: string;
  discounts: IService[];
  note?: string;
  packages: IPackage[];
  reference_id?: string;
  services: IService[];
}

export type IOpportunityLineItem = IService | IPackage | IAddOn;

export interface IAccountUserRole {
  /** The ID of the user */
  user: string;
  /** The ID of the user's role */
  role: string;
}

interface IMappingProps {
  CategoryTaxonomyId: number;
  CategoryId: number;
  ExternalId: string;
}

export interface ITradeDeskCategory extends Record<string, unknown> {
  CategoryId: number;
  Children: ITradeDeskCategory[];
  ExternalId: number;
  IsSensitive: boolean;
  MappingInformation: IMappingProps[];
  Name: string;
  ParentCategoryId: number | null;
  Path: string;
}

export interface ITTDSubAdvertiser {
  subadv_id: string;
  id?: string;
  logo?: string;
  name?: string;
  url?: string;
}

export interface ITTDAdvertiser {
  advertiser_category_id?: number;
  advertiser_category?: string;
  subadvertisers?: ITTDSubAdvertiser[];
  /** Optional ID because TTD parent is possibly undefined */
  id?: string;
}

export const advertiserConstraints = {
  name: {
    presence: {
      allowEmpty: false,
    },
  },
  url: {
    url: {
      message: '^Website URL must be a valid URL.',
    },
  },
  logo: {
    url: {
      message: '^Please upload a logo file or provide a logo URL.',
    },
  },
};

export const CAMPAIGN_MIN_BUDGET = 20;
export const CAMPAIGN_MAX_BUDGET = 10000;

export type TEmailHealth = 'healthy' | 'review' | 'suspended';

interface IAccountSettings {
  require_mfa?: boolean;
  campaigns_max_budget?: number;
  confirmed_bbrenxt_marketplace_sync?: boolean;
}

export interface IAccount extends IBaseAttributes {
  attribution_model: TAttributionModel;
  csm: string;
  date_created: string;
  date_last_modified: string;
  description?: string;
  domain_allow_list?: string[];
  email_health: TEmailHealth;
  email_preferences_alias: string;
  facebook: IFacebook;
  flags: TFlagsRecord;
  integrations: EIntegrationTypes[];
  ip_filters: string[];
  is_agency: boolean;
  /** For reading license is ILicense, but for writing license is Partial<ILicense> */
  license: ILicense;
  logo: string;
  media_validation: boolean;
  multi_convert_cooldown_unit: TUnitOfTimeConversions;
  multi_convert_cooldown_value: number;
  name: string;
  settings: IAccountSettings;
  stats: IStats;
  /**
   * The strategic advisor assigned to this account. Strategists provide
   * advice on campaign ideas, optimizations, campaign health reviews,
   * and advanced training topics.
   */
  strategist?: string;
  ttd?: ITTDAdvertiser;
  url: string;
  /** Formerly ACL */
  user_roles: IAccountUserRole[];
}

export class Account extends Model<IAccount> {
  public readonly className = 'Account';
  public collection: Accounts<this> | null = null;

  public static defaults: Partial<IAccount> = {
    email_health: 'healthy',
    integrations: [EIntegrationTypes.Tradedesk],
    license: {
      add_ons: [],
      autorenew: false,
      discounts: [],
      packages: [],
      services: [],
    },
  };

  public get constraints(): TConstraints<IAccount> {
    return {
      'settings.campaigns_max_budget': {
        numericality: {
          greaterThanOrEqualTo: CAMPAIGN_MIN_BUDGET,
          lessThanOrEqualTo: CAMPAIGN_MAX_BUDGET,
        },
      },
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      logo: {
        presence: {
          allowEmpty: false,
          message: '^The Trade Desk requires a logo.',
        },
        url: true,
      },
      url: {
        presence: {
          allowEmpty: false,
        },
        url: true,
      },
      domain_allow_list: {
        list: {
          presence: {
            allowEmpty: false,
            message: '^Domain cannot be blank.',
          },
          urlWithoutProtocol: true,
        },
        duplicates: {
          maximum: 0,
          message: '^Domain must be unique.',
        },
      },
      multi_convert_cooldown_value: {
        presence: {
          allowEmpty: false,
          message: '^Cooldown value cannot be blank.',
        },
        numericality: {
          greaterThan: 0,
          message: '^Cooldown value must be a number greater than 0.',
        },
      },
    };
  }

  constructor(attributes: Partial<IAccount> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<IAccount> {
    return Account.defaults;
  }

  @computed
  public get activePackage(): IPackage | undefined {
    return this.get('license').active_package ?? undefined;
  }

  @computed
  public get hasValidLicensePackageName(): boolean {
    if (!this.activePackage) {
      return false;
    }

    const { name: activePackageName } = this.activePackage;

    const validLicensePackageNames = Object.values(ELicensePackage);
    return activePackageName ? validLicensePackageNames.includes(activePackageName) : false;
  }

  @computed
  public get hasActivePackage(): boolean {
    return !!(this.activePackage && this.hasValidLicensePackageName);
  }

  @computed
  public get activeServices(): IService[] {
    return this.get('license').active_services ?? [];
  }

  @computed
  public get addOns(): IAddOn[] {
    return this.get('license').add_ons ?? [];
  }

  @computed
  public get adminUserIds(): string[] {
    return this.get('user_roles', [])
      .filter((userRole) => userRole.role === EUserRoleIDs.Admin)
      .map((role) => role.user);
  }

  // This license package is considered the "base" package and has access to the basic features and essential pro features.
  @computed
  public get isPlatformBase(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return this.activePackage.name === ELicensePackage.FeathrPlatformBase;
  }

  /*
   * These license packages are considered "platform starter" and have access to the basic features.
   * The "platform starter" packages have identical feature access and limits as "platform essential".
   */
  @computed
  public get isPlatformStarter(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return [
      ELicensePackage.FeathrPlatformStarter,
      ELicensePackage.FeathrPlatformStarterPro,
    ].includes(this.activePackage.name);
  }

  /*
   * These license packages are considered "platform essential" and have access to the basic features.
   * The "platform starter" packages have identical feature access and limits as "platform essential".
   */
  @computed
  public get isPlatformEssential(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return (
      [
        // Platform Essential <<Basic>>
        ELicensePackage.FeathrPlatformEssential,
        // Platform Essential <<Pro>>
        ELicensePackage.FeathrPlatformEssentialPro,
      ].includes(this.activePackage.name) || this.isPlatformStarter
    );
  }

  // These license packages are considered "platform advanced" and have access to the advanced features
  @computed
  public get isPlatformAdvanced(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return [
      // Platform Advanced <<Basic>>
      ELicensePackage.FeathrPlatformAdvanced,
      // Platform Advanced <<Pro>>
      ELicensePackage.FeathrPlatformAdvancedPro,
      // Platform Enterprise <<Basic>>
    ].includes(this.activePackage.name);
  }

  // These license packages are considered "platform enterprise" and have access to the enterprise features
  @computed
  public get isPlatformEnterprise(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return [
      // Platform <<Enterprise>> Basic
      ELicensePackage.FeathrPlatformEnterprise,
      // Platform <<Enterprise>> Pro
      ELicensePackage.FeathrPlatformEnterprisePro,
    ].includes(this.activePackage.name);
  }

  // The <<Basic>> license packages have access to the basic features
  @computed
  public get isBasic(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return [
      // Platform <<Essential>> Basic
      ELicensePackage.FeathrPlatformEssential,
      // Platform <<Advanced>> Basic
      ELicensePackage.FeathrPlatformAdvanced,
      // Platform <<Enterprise>> Basic
      ELicensePackage.FeathrPlatformEnterprise,
    ].includes(this.activePackage.name);
  }

  // The <<Pro>> license packages have access to the pro features
  @computed
  public get isPro(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return [
      // Platform <<Base>> (has access to essential pro features)
      ELicensePackage.FeathrPlatformBase,
      // Platform <<Essential>> Pro
      ELicensePackage.FeathrPlatformEssentialPro,
      // Platform <<Advanced>> Pro
      ELicensePackage.FeathrPlatformAdvancedPro,
      // Platform <<Enterprise>> Pro
      ELicensePackage.FeathrPlatformEnterprisePro,
    ].includes(this.activePackage.name);
  }

  // These are the license packages that have access to the <<Feathr Platform>>
  @computed
  public get isAnyFeathrPlatform(): boolean {
    return (
      // Platform Base
      this.isPlatformBase ||
      // Platform Starter Basic and Pro
      this.isPlatformStarter ||
      // Platform Essential Basic and Pro
      this.isPlatformEssential ||
      // Platform Advanced Basic and Pro
      this.isPlatformAdvanced ||
      // Platform Enterprise Basic and Pro
      this.isPlatformEnterprise
    );
  }

  /** @deprecated */
  @computed
  public get isEnterprise(): boolean {
    return [
      ELicensePackage.EnterpriseFalcon,
      ELicensePackage.EnterpriseFlamingo,
      ELicensePackage.EnterpriseMarketingSeat,
      ELicensePackage.EnterpriseMonetizationSeat,
      ELicensePackage.EnterpriseUnlimitedMarketingSeats,
      ELicensePackage.EnterpriseUnlimitedMonetizationSeats,
      ELicensePackage.EnterpriseFalconSeat,
      ELicensePackage.EnterpriseFalconUnlimitedSeats,
      // Platform Enterprise is not Enterprise
    ].includes(this.activePackage?.name ?? ELicensePackage.Finch);
  }

  /** @deprecated */
  @computed
  public get isEnterpriseUnlimited(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return [
      ELicensePackage.EnterpriseUnlimitedMarketingSeats,
      ELicensePackage.EnterpriseUnlimitedMonetizationSeats,
      ELicensePackage.EnterpriseFalconUnlimitedSeats,
      // Platform Enterprise is not Enterprise Unlimited
    ].includes(this.activePackage.name);
  }

  /** @deprecated */
  @computed
  public get isPhoenix(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return (
      this.isAnyFeathrPlatform ||
      [
        ELicensePackage.Phoenix,
        ELicensePackage.PhoenixLicenseExtended,
        ELicensePackage.PhoenixLicense,
      ].includes(this.activePackage.name)
    );
  }

  /** @deprecated */
  @computed
  public get isFalcon(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return (
      this.isPhoenix ||
      this.isEnterprise ||
      this.isAnyFeathrPlatform ||
      [
        ELicensePackage.Falcon,
        ELicensePackage.FalconLicense,
        ELicensePackage.FalconLicenseExtended,
      ].includes(this.activePackage.name)
    );
  }

  /** @deprecated */
  @computed
  public get isFinch(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return (
      this.isAnyFeathrPlatform ||
      [
        ELicensePackage.Finch,
        ELicensePackage.FinchLegacy,
        ELicensePackage.FinchLicense,
        ELicensePackage.FinchLicenseExtended,
      ].includes(this.activePackage.name)
    );
  }

  /** @deprecated */
  @computed
  public get isFinchLegacy(): boolean {
    if (!this.activePackage) {
      return false;
    }
    return (
      this.isFalcon ||
      [ELicensePackage.FinchLegacy, ELicensePackage.FinchLegacyLicense].includes(
        this.activePackage.name,
      )
    );
  }

  @computed
  public get primaryBillableId(): string | undefined {
    return this.get('license').billable ?? undefined;
  }

  @computed
  public get hasServices(): boolean {
    const services = this.activeServices;
    return !!services && services.length > 0;
  }

  @computed
  public get hasPixelImplementation(): boolean {
    const services = this.activeServices.filter((service) =>
      [
        'I_042', // AutoPilot
        'I_039', // CoPilot 1
        'I_040', // CoPilot 2
        'I_041', // CoPilot 3
        'I_304', // CoPilot - Extended
        'I_305', // Campaign Management Services
        'I_303', // Creative Migration Pack
        'I_306', // Data Integration Services
        'I_045', // Data Integration Services - Legacy
        'I_037', // Platform Implementation & Onboarding
        'I_044', // Dispatcher - Setup Only
        'I_207', // Enterprise Legacy Monetization Services
        'I_043', // Training services
      ].includes(service.item_id),
    );
    return (!!services && services.length > 0) || this.isPro;
  }

  @computed
  public get hasInvites(): boolean {
    // Finch and Finch Legacy do not have invites.
    return this.isAnyFeathrPlatform || this.isFalcon || this.isEnterprise || this.isPhoenix;
  }

  @computed
  public get hasMonetization(): boolean {
    if (!this.activePackage) {
      return false;
    }
    // Finch, Finch Legacy, and Enterprise Flamingo do not have monetization.
    if (
      [
        ELicensePackage.Finch,
        ELicensePackage.FinchLegacy,
        ELicensePackage.EnterpriseFlamingo,
      ].includes(this.activePackage.name)
    ) {
      return false;
    }

    const { isAnyFeathrPlatform, isFalcon, isEnterprise, isPhoenix } = this;
    return isAnyFeathrPlatform || isFalcon || isEnterprise || isPhoenix;
  }

  // TODO: This should be moved off Account.ts
  @computed
  public get getFacebookAccessToken(): string | undefined {
    const facebook = this.get('facebook') as IFacebook;
    if (!facebook) {
      return undefined;
    }
    return facebook.business_manager_access_token;
  }

  public validateFacebookIntegration(): IFacebookError[] {
    const errors: IFacebookError[] = [];
    const integration = this.get('facebook');

    if (!integration) {
      errors.push(feathrFacebookErrorMap.account_integration_not_found);
      return errors;
    }
    const dateTokenExpiration = moment.utc(integration.date_token_expiration, moment.ISO_8601);
    const healthyToken =
      integration.access_token_health === FacebookAccessTokenHealth.ok &&
      dateTokenExpiration.isAfter(moment.utc());
    if (!healthyToken) {
      errors.push(feathrFacebookErrorMap.token_expired);
      return errors;
    }
    if (integration.state !== FacebookIntegrationState.page_selected) {
      errors.push(feathrFacebookErrorMap.account_integration_not_ready);
      return errors;
    }
    return errors;
  }

  public getAdvertiser(advId: string): ITTDSubAdvertiser | undefined {
    return (this.get('ttd', { subadvertisers: [] }).subadvertisers || []).find(
      (subadv) => subadv.subadv_id === advId,
    );
  }

  public get emailPreferencesPreviewUrl(): string {
    return `${POLO_URL}email_preferences?a_id=${this.id}&is_preview=1`;
  }

  /*
   * Account can add project if:
   * 1. User is sudoer (CSM or superuser)
   * 2. Account is Essential or Advanced (any package)
   * 3. Account is not Enterprise
   * 4. Account is Enterprise Unlimited
   */
  public canAddProject(user: User): boolean {
    if (!user) {
      return false;
    }
    return (
      user.isSudoer || this.isAnyFeathrPlatform || this.isEnterpriseUnlimited || !this.isEnterprise
    );
  }

  /*
   * Returns a basic list of integrations on the account. In the Anhinga,
   * integration controllers add and remove the integration name from this list.
   * By default, all accounts have the Tradedesk integration.
   */
  public get integrations(): EIntegrationTypes[] {
    return this.get('integrations', []);
  }

  /*
   * Returns a list of integrations that conflict with the current integration.
   * For example, if the account has iMIS then it can't have Blackbaud Raiser's Edge NXT.
   * If the account has Blackbaud Raiser's Edge NXT then it can't have iMIS.
   * This is because both integrations would be trying to sync the same data.
   */
  @computed
  public get conflictingIntegrations(): EIntegrationTypes[] {
    const conflictingIntegrations: EIntegrationTypes[] = [];

    // If the account has iMIS then it can't have Blackbaud Raiser's Edge NXT.
    if (this.integrations.includes(EIntegrationTypes.Imis)) {
      conflictingIntegrations.push(EIntegrationTypes.BlackbaudRaisersEdge);
    }

    // If the account has Blackbaud Raiser's Edge NXT then it can't have iMIS.
    if (this.integrations.includes(EIntegrationTypes.BlackbaudRaisersEdge)) {
      conflictingIntegrations.push(EIntegrationTypes.Imis);
    }
    return conflictingIntegrations;
  }

  @computed
  public get hasConflictingIntegrations(): boolean {
    return this.conflictingIntegrations.length > 0;
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Account';
  }

  public async updateLicense(
    patch: IOpportunityLineItem,
  ): Promise<IWretchResponseValid<IAccount, IMetadata>> {
    this.assertCollection(this.collection);
    return this.collection.updateLicense(this.id, patch);
  }

  public async getIndustryCategories(): Promise<
    IWretchResponseValid<ITradeDeskCategory[], IMetadata>
  > {
    this.assertCollection(this.collection);
    return this.collection.getIndustryCategories();
  }

  public async updateIndustryCategory(
    industryCategoryName: string,
    industrySubCategoryName: string | undefined,
  ): Promise<IWretchResponseValid<IAccount, IMetadata>> {
    this.assertCollection(this.collection);
    return this.collection.updateIndustryCategory(industryCategoryName, industrySubCategoryName);
  }

  public getSetting<K extends keyof IAccountSettings>(
    attribute: K,
  ): DeepObservable<IAccountSettings[K]>;

  public getSetting<K extends keyof IAccountSettings>(
    attribute: K,
    defaultValue: Exclude<IAccountSettings[K], undefined>,
  ): DeepObservable<Exclude<IAccountSettings[K], undefined>>;

  public getSetting<K extends keyof IAccountSettings>(key: K, defaultValue?: unknown): unknown {
    const settings: IAccountSettings = this.get('settings', {});
    return settings[key] ?? defaultValue;
  }

  public setSetting<K extends keyof IAccountSettings>(key: K, value: IAccountSettings[K]): void {
    const settings: IAccountSettings = this.get('settings', {});
    this.set({ settings: { ...settings, [key]: value } });
  }
}

export class Accounts<Model extends Account = Account> extends Collection<Model> {
  public getModel(attributes: Partial<Partial<Attributes<Model>>>): Model {
    return new Account(attributes) as Model;
  }

  public getClassName(): string {
    return 'accounts';
  }

  public url(variant: string, value?: string, method?: string): string;

  public url(variant?: string, value?: string, method?: string): string {
    if (variant === 'license') {
      return `${this.getHostname()}accounts/${value}/${variant}`;
    } else if (variant === 'ttd' && method === 'GET') {
      return `${this.getHostname()}${variant}/industry_categories`;
    } else if (variant === 'ttd' && method === 'PATCH') {
      return `${this.getHostname()}accounts/${variant}`;
    }
    return super.url();
  }

  public async updateLicense(
    accountId: string,
    patch: IOpportunityLineItem,
  ): Promise<IWretchResponseValid<IAccount, IMetadata>> {
    const url = this.url('license', accountId);
    const headers = this.getHeaders();
    const response = await wretch<IAccount>(url, {
      headers,
      body: JSON.stringify(patch),
      method: 'PATCH',
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response;
  }

  public async getIndustryCategories(): Promise<
    IWretchResponseValid<ITradeDeskCategory[], IMetadata>
  > {
    const url = this.url('ttd', '', 'GET');
    const headers = this.getHeaders();
    const response = await wretch<ITradeDeskCategory[]>(url, { headers, method: 'GET' });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response;
  }

  public async updateIndustryCategory(
    industryCategoryName: string,
    industrySubCategoryName: string | undefined,
  ): Promise<IWretchResponseValid<IAccount, IMetadata>> {
    const url = this.url('ttd', '', 'PATCH');
    const headers = this.getHeaders();
    const response = await wretch<IAccount>(url, {
      headers,
      body: JSON.stringify({
        category_name: industryCategoryName,
        subcategory_name: industrySubCategoryName,
      }),
      method: 'PATCH',
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response;
  }
}
