import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, reaction, runInAction, set, when } from 'mobx';
import { Observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ITrackedLink, Redirect, RedirectDomain, TrackedLinkCampaign } from '@feathr/blackbox';
import { Button, Card, CreatableSelect, Fieldset, Form, Input, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, flattenError } from '@feathr/hooks';
import type { TConstraints } from '@feathr/rachis';
import { validate as validatejs } from '@feathr/rachis';

interface IRedirectProps {
  campaign: TrackedLinkCampaign;
  link: ITrackedLink;
  onRemove: (redirect: Redirect) => Promise<void>;
}

interface IUTMSourceOption {
  label: string;
  value: string;
  medium?: string;
}

function TrackedLinkRedirect({ campaign, link, onRemove }: IRedirectProps): JSX.Element {
  const { RedirectDomains, Redirects } = useContext(StoresContext);
  const [existingRedirect, setExistingRedirect] = useState<boolean>(false);
  const { t } = useTranslation();

  const defaultSourceOptions = [
    {
      label: t('Email Marketing'),
      value: 'email',
      medium: 'email',
    },
    {
      label: t('Paid Search'),
      value: 'search',
      medium: 'search',
    },
    {
      label: t('LinkedIn'),
      value: 'linkedin',
      medium: 'social',
    },
    {
      label: t('Twitter'),
      value: 'twitter',
      medium: 'social',
    },
    {
      label: t('Facebook'),
      value: 'facebook',
      medium: 'social',
    },
    {
      label: t('Instagram'),
      value: 'instagram',
      medium: 'social',
    },
    {
      label: t('Meta'),
      value: 'meta',
      medium: 'social',
    },
  ];
  const sourceOptions = useLocalObservable<IUTMSourceOption[]>(() => defaultSourceOptions);

  const redirect = Redirects.get(link.redirect_id);
  const domain = RedirectDomains.get(campaign.get('domain_id'));

  useEffect(
    () =>
      reaction(
        () => [redirect.get('short_code'), campaign.get('domain_id')],
        async () => {
          const redirects = Redirects.list({
            filters: {
              id__ne: redirect.id,
              short_code: redirect.get('short_code'),
              domain: campaign.get('domain_id'),
            },
          });
          await when(() => !redirects.isPending);
          setExistingRedirect(redirects.models.length > 0);
        },
        { delay: DEFAULT_DEBOUNCE_WAIT },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () =>
      reaction(
        () => [
          get(link, 'original_url'),
          get(link, 'utm_source'),
          get(link, 'utm_term'),
          get(link, 'utm_content'),
          get(link, 'utm_medium'),
        ],
        () => {
          try {
            const url = new URL(get(link, 'original_url'));
            const searchParams = new URLSearchParams({
              utm_campaign: campaign.name,
              utm_source: get(link, 'utm_source'),
              utm_medium: get(link, 'utm_medium'),
              utm_term: get(link, 'utm_term'),
              utm_content: get(link, 'utm_content'),
              cpn_id: campaign.id,
              e_id: campaign.get('event'),
            } as Record<string, string>);
            if (url.search) {
              url.search = `${url.search}&${searchParams.toString()}`;
            } else {
              url.search = `?${searchParams.toString()}`;
            }
            redirect.set({ url: url.href });
          } catch (error) {
            // Do nothing
          }
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function handleRemove(): Promise<void> {
    return onRemove(redirect);
  }

  // Get constraints to validate a single tracked link.
  const linkConstraint = campaign.constraints.tracked_links!.array!;

  return (
    <Card
      actions={[
        <Tooltip key={'remove'} title={t('Delete Link')}>
          <Button onClick={handleRemove} type={'naked'}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>,
      ]}
    >
      <Form label={t('Tracked Link Configuration')}>
        <Fieldset>
          <Observer>
            {(): JSX.Element => {
              function handleUrlChange(newValue?: string): void {
                runInAction(() => {
                  set(link, { original_url: newValue });
                });
                campaign.setAttributeDirty('tracked_links');
              }

              const errorsA: Record<'original_url', string[]> | undefined = validatejs.validate(
                link,
                linkConstraint,
              );
              return (
                <Input
                  helpText={t(
                    'The page where you want people to go. In order for the campaign to work properly, make sure this page has a working Feathr Super Pixel.',
                  )}
                  label={t('URL')}
                  onChange={handleUrlChange}
                  required={true}
                  type={'text'}
                  validationError={flattenError(errorsA?.original_url)}
                  value={link.original_url}
                />
              );
            }}
          </Observer>
          <Observer>
            {(): JSX.Element => {
              const shortCodeValidation = validateShortCode(
                redirect,
                domain,
                link.original_url?.trim(),
              ).slice();

              return (
                <Input
                  attribute={'short_code'}
                  helpText={t(
                    'Customize the short url used to redirect traffic to your site. Keep it short and recognizable.',
                  )}
                  label={t('Short link')}
                  model={redirect}
                  placeholder={'short-link'}
                  prefix={`https://${domain.get('domain')}/`}
                  required={true}
                  type={'text'}
                  validationError={
                    existingRedirect ? t('This short link is not available.') : shortCodeValidation
                  }
                />
              );
            }}
          </Observer>
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const [UTMSourceHadFocus, setUTMSourceHadFocus] = useState(false);

              function handleUTMSourceBlur(): void {
                setUTMSourceHadFocus(true);
              }

              function handleUTMSourceSelect(option: IUTMSourceOption): void {
                set(link, { utm_source: option.value, utm_medium: option.medium || '' });
                campaign.setAttributeDirty('tracked_links');
              }

              function isValidNewUTMSourceOption(inputValue: string): boolean {
                return !!(
                  inputValue &&
                  /^[A-Za-z0-9]{3,24}$/g.test(inputValue) &&
                  !sourceOptions.map((opt) => opt.value).includes(inputValue)
                );
              }

              function createUTMSourceOption(inputValue: string): IUTMSourceOption {
                const option = {
                  value: inputValue,
                  label: inputValue,
                };
                set(link, { utm_source: option.value, utm_medium: '' });
                campaign.setAttributeDirty('tracked_links');
                return option;
              }

              function formatCreateLabel(inputValue: string): ReactNode {
                return t('Use custom UTM source: "{{value}}"', { value: inputValue });
              }

              const errorsB: Record<'utm_source', string[]> | undefined = validatejs.validate(
                link,
                linkConstraint,
              );
              return (
                <CreatableSelect<IUTMSourceOption>
                  createOption={createUTMSourceOption}
                  defaultOptions={sourceOptions}
                  formatCreateLabel={formatCreateLabel}
                  helpText={
                    <Trans t={t}>
                      <p>
                        This is used to categorize traffic in the campaign report. Multiple links
                        that use the same source will be grouped together.
                        <strong>
                          You can type in a custom UTM source or choose from the list.
                        </strong>
                      </p>
                    </Trans>
                  }
                  isValidNewOption={isValidNewUTMSourceOption}
                  label={t('UTM source')}
                  name={'utm-source'}
                  onBlur={handleUTMSourceBlur}
                  onSelectSingle={handleUTMSourceSelect}
                  options={sourceOptions}
                  required={true}
                  validationError={
                    UTMSourceHadFocus ? flattenError(errorsB?.utm_source) : undefined
                  }
                  value={
                    sourceOptions.find((option) => get(link, 'utm_source') === option.value) || {
                      label: link.utm_source,
                      value: link.utm_source,
                    }
                  }
                />
              );
            }}
          </Observer>
          <Observer>
            {(): JSX.Element => {
              function handleUtmMediumChange(newValue?: string): void {
                set(link, 'utm_medium', newValue);
                campaign.setAttributeDirty('tracked_links');
              }

              const errorsC: Record<'utm_medium', string[]> | undefined = validatejs.validate(
                link,
                linkConstraint,
              );
              return (
                <>
                  {['email', 'search', 'facebook', 'linkedin', 'twitter'].includes(
                    link.utm_source,
                  ) ? (
                    <Input
                      disabled={true}
                      helpText={t(
                        'Identifies what type of link was used, such as cost per click or email.',
                      )}
                      key={get(link, 'utm_medium')}
                      label={t('UTM medium')}
                      type={'text'}
                      validationError={flattenError(errorsC?.utm_medium)}
                      value={get(link, 'utm_medium')}
                    />
                  ) : (
                    <Input
                      helpText={t(
                        'Identifies what type of link was used, such as cost per click or email.',
                      )}
                      label={t('UTM medium')}
                      onChange={handleUtmMediumChange}
                      required={true}
                      type={'text'}
                      validationError={flattenError(errorsC?.utm_medium)}
                      value={get(link, 'utm_medium')}
                    />
                  )}
                </>
              );
            }}
          </Observer>
          <Observer>
            {(): JSX.Element => {
              function handleUtmTermChange(newValue?: string): void {
                set(link, { utm_term: newValue });
                campaign.setAttributeDirty('tracked_links');
              }

              return (
                <Input
                  helpText={t(
                    'Identifies search terms. Mostly useful when the link is used as a destination for a paid search campaign.',
                  )}
                  label={t('UTM term')}
                  onChange={handleUtmTermChange}
                  optional={true}
                  type={'text'}
                  value={link.utm_term}
                />
              );
            }}
          </Observer>
          <Observer>
            {(): JSX.Element => {
              function handleUtmContentChange(newValue?: string): void {
                set(link, { utm_content: newValue });
                campaign.setAttributeDirty('tracked_links');
              }

              return (
                <Input
                  helpText={t(
                    'Identifies what specifically was clicked to bring the user to the site, such as a social post or a text link in a blog.',
                  )}
                  label={t('UTM content')}
                  onChange={handleUtmContentChange}
                  optional={true}
                  type={'text'}
                  value={link.utm_content}
                />
              );
            }}
          </Observer>
        </Fieldset>
      </Form>
    </Card>
  );
}

export function validateShortCode(
  redirect: Redirect,
  domain: RedirectDomain,
  url: string,
): string[] {
  const errors = redirect.validate(['short_code']).errors;

  // One-off validation since the actually useful url is not part of Redirect.
  const constraints: TConstraints<{ shortUrl: string }> = {
    shortUrl: {
      equality: {
        attribute: 'url',
        message: '^URL and Short link cannot be the same.',
        comparator: function (shortUrl: string, fullUrl: string) {
          return shortUrl !== fullUrl;
        },
      },
    },
  };
  const additionalErrors: string[] =
    validatejs.validate(
      {
        shortUrl: `https://${domain.get('domain')}/${redirect.get('short_code')?.trim()}`,
        url: url?.trim(),
      },
      constraints,
      // @ts-ignore: we're passing in an additional property here because we use it in our custom validators
      { format: 'flat', onlyCheckDirty: false, model: redirect },
    ) ?? [];
  return [...errors.slice(), ...additionalErrors.slice()];
}

export default TrackedLinkRedirect;
