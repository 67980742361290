import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { EmailVerification } from '@feathr/blackbox';
import { ArchiveModalV1, ContextMenu } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

interface IOptionsColumnProps {
  email: EmailVerification;
}

function EmailOptions({ email }: Readonly<IOptionsColumnProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item
          link={localUrl(email.getItemUrl('edit'))}
          prefix={getIconForAction('edit')}
        >
          {t('Edit')}
        </ContextMenu.Item>
        <ContextMenu.Item
          onClick={toggleArchiveModalOpen}
          prefix={getIconForAction('delete')}
          theme={'danger'}
        >
          {t('Delete')}
        </ContextMenu.Item>
      </ContextMenu>
      {isArchiveModalOpen && (
        <ArchiveModalV1
          confirmButtonText={t('Archive email')}
          message={t('Are you sure you want to archive this email?')}
          method={'archive'}
          model={email}
          onClose={toggleArchiveModalOpen}
          t={t}
          title={t('Archive Email')}
        />
      )}
    </>
  );
}

export default observer(EmailOptions);
