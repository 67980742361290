import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

import type { IStats } from './stats';

export interface IEmailSendRequest extends IBaseAttributes {
  from_: string;
  from_name: string;
  to: string;
  mode: 'segment' | 'single';
  subject: string;
  date_created: string;
  date_sent: string;
  state: string;
  date_to_send: string;
  target: string;
  parent: string;
  campaign: string;
  template: string;
  stats: IStats & {
    num_sends: number;
    num_errors: number;
  };
}

export class EmailSendRequest extends Model<IEmailSendRequest> {
  public readonly className = 'EmailSendRequest';

  public get constraints(): TConstraints<IEmailSendRequest> {
    return {
      subject: {
        presence: {
          allowEmpty: false,
        },
      },
      mode: {
        inclusion: {
          within: ['single', 'segment'],
        },
      },
      to: (value: string, attributes: any) => {
        if (attributes.mode === 'single') {
          return { presence: { allowEmpty: false }, email: true };
        }
        return undefined;
      },
    };
  }

  public getDefaults(): Partial<IEmailSendRequest> {
    return {
      share_with_event: false,
    };
  }
}

export class EmailSendRequests extends Collection<EmailSendRequest> {
  public getModel(attributes: Partial<IEmailSendRequest>) {
    return new EmailSendRequest(attributes);
  }

  public getClassName() {
    return 'email_send_requests';
  }
}
