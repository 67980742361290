import { Goal } from '@feathr/blackbox';

interface IGoalsStatus {
  goalsAreValid: boolean;
  goalsAreDirty: boolean;
}

export function getGoalsStatus(childModels: Goal[]): IGoalsStatus {
  return {
    goalsAreValid: childModels.every((model) => model instanceof Goal && model.isValid()),
    goalsAreDirty: childModels.some((model) => model.isDirty || model.isEphemeral),
  };
}
