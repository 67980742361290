import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IDripCampaignStepSpec } from '@feathr/blackbox';
import { CampaignState, ELinkedCampaignSetAction } from '@feathr/blackbox';
import { CardV2 as Card, EmailTemplateSelect, Label, Well } from '@feathr/components';

import useEphemeralCampaign from '../useEphemeralCampaign';

import * as styles from './PreviewAutomation.css';

interface IPreviewAutomationProps {
  dripCampaignId: string;
  step: IDripCampaignStepSpec;
}

function PreviewAutomation({
  dripCampaignId,
  step,
}: Readonly<IPreviewAutomationProps>): JSX.Element {
  const { t } = useTranslation();

  const { campaign, template } = useEphemeralCampaign({ dripCampaignId, step });
  const isDraft = campaign.get('state') === CampaignState.Draft;

  // TODO: Move this text to a shared hook
  const stepTargetingMap = {
    [ELinkedCampaignSetAction.previous_email_delivered]: t('Send to everyone'),
    [ELinkedCampaignSetAction.not_opened_previous_email]: t('Not opened the previous email'),
    [ELinkedCampaignSetAction.opened_previous_email]: t('Opened the previous email'),
    [ELinkedCampaignSetAction.not_clicked_link_previous_email]: t('Not clicked the previous email'),
    [ELinkedCampaignSetAction.clicked_link_previous_email]: t('Clicked the previous email'),
  };

  const stepTargetingDelayTooltipMap = {
    [ELinkedCampaignSetAction.previous_email_delivered]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.not_opened_previous_email]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.opened_previous_email]: t(
      'Set the amount of time that should pass between opening the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.not_clicked_link_previous_email]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.clicked_link_previous_email]: t(
      'Set the amount of time that should pass between clicking the previous email and sending this email',
    ),
  };
  const delayValue = `${campaign.get('delay_value')} ${campaign.get('delay_unit')}`;
  const setAction = campaign.get('set_action');

  return (
    <>
      <Card.Content>
        <Label weight={'semibold'}>{t('Automation')}</Label>
        <div className={styles.wells}>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row
              description={(setAction ? stepTargetingMap[setAction] : undefined) ?? t('Not set')}
              label={t('Step targeting')}
              tooltip={'Select who should be sent the email in this step'}
            />
            <Well.Row
              description={delayValue}
              label={t('Delay from delivery')}
              tooltip={setAction ? stepTargetingDelayTooltipMap[setAction] : undefined}
            />
          </Well>
        </div>
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        <EmailTemplateSelect
          description={template?.get('preview_text')}
          imageUrl={isDraft && template ? template.get('thumbnail_url') : undefined}
          isLoading={template?.isPending}
          previewUrl={template && !isDraft ? campaign.getTemplatePreviewUrl() : undefined}
          readOnly={true}
          subtitle={campaign.get('subject')}
          title={template ? campaign.get('from_address') : t('No template selected')}
        />
      </Card.Content>
    </>
  );
}

export default observer(PreviewAutomation);
