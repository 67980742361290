import { Flex, Stack } from '@mantine/core';
import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { autorun, runInAction, set, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { computedFn } from 'mobx-utils';
import numeral from 'numeral';
import type { JSX, ReactNode } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastType } from 'react-toastify';

import type {
  Account,
  Billable,
  Campaign,
  Event,
  IExposureSettings,
  IStripe,
  Segment,
  Targetable,
  Targeting,
  TSource,
  User,
} from '@feathr/blackbox';
import {
  CampaignClass,
  CampaignState,
  getMaxTargetValue,
  getMinDuration,
  getMinStartDate,
} from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  CardV2 as Card,
  Collapse,
  DatePicker,
  EAlertV2Type as EAlertType,
  EmptyState,
  Fieldset,
  Form,
  Label,
  NumberInput,
  toast,
  Well,
} from '@feathr/components';
import PaymentMethodForm from '@feathr/extender/App/Settings/Billing/BillablePage/PaymentMethodForm';
import BillingSource from '@feathr/extender/components/BillingSource';
import { StoresContext, useFlags, useLocalUrl, useUser } from '@feathr/extender/state';
import { currencyFormatter } from '@feathr/extender/utils';
import {
  cssVar,
  flattenErrors,
  moment,
  momentToDate,
  TimeFormat,
  timezoneAbbr,
  useToggle,
} from '@feathr/hooks';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import BaseBidSelect from './BaseBidSelect';
import { getTargetables, getTargetSegments } from './CampaignEditStepTwo';
import FreqCapSelect from './FreqCapSelect';
import FreqPeriodSelect from './FreqPeriodSelect';
import RecommendedBudget from './RecommendedBudget';

import * as styles from './CampaignEditStepFive.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  account: Account;
  event: Event;
  billable?: Billable;
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
}

function setSource(billable: Billable, source: TSource): void {
  const stripe = billable.get('stripe');
  billable.set({ stripe: { ...stripe, source, _token: source.id } });
}

const getAudienceSize: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  const cls: CampaignClass = campaign.get('_cls');
  if ([CampaignClass.Segment, CampaignClass.Facebook].includes(cls)) {
    return segments.map((s) => s.reachable).reduce((acc, c) => acc + c, 0);
  }
  if ([CampaignClass.EmailList, CampaignClass.EmailListFacebook].includes(cls)) {
    return targetables.reduce((acc, t) => {
      acc += t.get('num_unique_emails') || 0;
      return acc;
    }, 0);
  }

  // We use these numbers to scale the recommend budget by small, medium, and large expected audiences sizes
  switch (cls) {
    // Campaigns that target a specific audience are huge, so we limit the audience size to 100k to prevent an offensive recommended budget
    case CampaignClass.Lookalike:

    case CampaignClass.SeedSegment:

    case CampaignClass.Affinity: {
      return 100000;
    }

    // Search and Geo-like campaigns have smaller audiences on the order of 10s of thousands.
    case CampaignClass.Search:

    case CampaignClass.MobileGeoFencing:

    case CampaignClass.MobileGeoFenceRetargeting: {
      return 10000;
    }

    default: {
      // 1000 for default since first party groups tend to be pretty small
      return 1000;
    }
  }
});

export function getDays(campaign: Campaign): number {
  let days = 1;
  const dateStart = campaign.get('date_start');
  const dateEnd = campaign.get('date_end');
  if (!(dateStart && dateEnd)) {
    return days;
  }
  const startMoment = moment.utc(dateStart, moment.ISO_8601);
  const endMoment = moment.utc(dateEnd, moment.ISO_8601);
  if (startMoment.isBefore(endMoment)) {
    const startDay = startMoment.startOf('day');
    const endDay = endMoment.startOf('day');
    while (startDay.isBefore(endDay)) {
      startDay.add(1, 'day');
      days += 1;
    }
  }
  return days;
}

function getMinBudget(campaign: Campaign): number {
  const days = getDays(campaign);
  const isMonetization = campaign.get('parent_kind') === 'partner';
  if (isMonetization) {
    return days * 4000;
  }

  return days * 5;
}

const getRecommendedImpressions: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  const audienceSize = getAudienceSize(campaign, segments, targetables);
  const reach = 0.015;
  const freqCap = campaign.get('exposure_model').freq_cap;
  const days = getDays(campaign);
  return audienceSize * reach * freqCap * days;
});

/*
 * TODO: Pull this from a backend endpoint when it is available
 *
 * The recommended budget will almost always be the same as the minimum budget on
 * dev environments because it depends on reachable audience. To test recommended
 * budget, you must generally test with production, utilizing a target that has
 * reachable people inside of it. Selecting a target with 0 reachable people will
 * always end up with a recommended budget equal to the minimum budget.
 */
const getRecommendedBudget: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  const audienceSize = getAudienceSize(campaign, segments, targetables);
  const reach = 0.015;
  const freqCap = campaign.get('exposure_model').freq_cap;
  const cpi = campaign.get('exposure_settings', {} as IExposureSettings).base_bid! / 1000;
  const days = getDays(campaign);
  const minBudget = getMinBudget(campaign);
  const result = Math.max(audienceSize * reach * freqCap * cpi * days, minBudget);
  // Limit to 2 decimals.
  return parseFloat(result.toFixed(2));
});

function getRecommendedBid(size: number): number {
  /*
   * This will be revisited at a later date. Intentionally leaving the multiple
   * if statements that evaluate to the same number.
   */
  if (size > 20000) {
    return 5;
  }
  if (size < 20000 && size > 5000) {
    return 5;
  }
  return 10;
}

export function validateStepFive(
  campaign: Campaign,
  event: Event,
  billable: Billable | undefined,
  account: Account,
  user?: User,
  noMaxBudget?: boolean,
): ICampaignValidationErrors {
  const monetization = campaign.get('parent_kind') === 'partner';

  const attributes = ['date_start', 'date_end', 'state', '_cls'];
  if (monetization) {
    attributes.push('monetization_value');
  }

  const errors = {
    budget: [] as string[],
    date_start: [] as string[],
    date_end: [] as string[],
    monetization_value: [] as string[],
    /*
     * We have to pass along state and _cls so they show up in the attributes provided
     * to the date_start validator
     */
    ...(toJS(campaign.validate(attributes, false, 'grouped').errors) as Record<string, string[]>),
  };

  const facebook = [CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(
    campaign.get('_cls'),
  );
  const canEditBudget = !!campaign.get('date_start') && !!campaign.get('date_end');
  const endDate = campaign.get('date_end');
  const isDraft = campaign.get('state') === CampaignState.Draft;
  const budget = campaign.get('exposure_settings').target_value || 0;
  const billing = event.get('billing');
  const balance = billing?.balance ?? 0;
  const isComplete = moment.utc(endDate).isBefore(moment.utc());
  const hasPaymentMethod =
    billable && !billable.isPending && billable.get('stripe')
      ? !!billable.get('stripe').source
      : false;
  const minBudget = getMinBudget(campaign);
  const spend: number = campaign.get('total_stats')?.spend || 0;
  if (!billable) {
    errors.budget.push(
      'You need to add a billing configuration to this project in order to publish a campaign that incurs media spend.',
    );
  }
  const exposureSettingsHaveChanged = campaign.isAttributeDirty('exposure_settings');
  /*
   *  Allow editing goals once spend has exceeded budget.
   *  https://github.com/Feathr/shrike/issues/1998
   *
   *  This previously did not take into account the state of the campaign,
   *  allowing the user to set a zero budget, save as draft, reload the
   *  page and publish with a zero budget.
   */
  const addErrorsAfterPublishedIfBudgetIsTouched = !isDraft && exposureSettingsHaveChanged;

  // Prevent budgets less than the minimum budget.
  if (
    budget < minBudget &&
    !monetization &&
    canEditBudget &&
    (addErrorsAfterPublishedIfBudgetIsTouched || isDraft)
  ) {
    errors.budget.push(
      `Budget must be at least $${numeral(minBudget).format(
        '0,0.00',
      )} to fulfill this campaign for its duration.`,
    );
  }

  /*
   * Prevent user from setting a budget below campaign spend.
   * Do not include draft campaigns because a draft campaign
   * should never have spend associated with it.
   */
  if (budget < spend && !monetization && addErrorsAfterPublishedIfBudgetIsTouched) {
    errors.budget.push(
      `Budget must be at least $${numeral(spend).format(
        '0,0.00',
      )} to account for the amount already spent.`,
    );
  }

  /*
   *We can ignore max budget under the following circumstances:
   *- The noMaxBudget feature flag is set
   */
  if (!noMaxBudget) {
    const maxBudget = getMaxTargetValue(account.getSetting('campaigns_max_budget'), monetization);
    if (budget > maxBudget) {
      if (monetization) {
        errors.budget.push(
          `The number of impressions cannot exceed ${numeral(maxBudget).format('0,0')}.`,
        );
      } else {
        errors.budget.push(
          `The budget cannot exceed ${numeral(maxBudget).format('$0,0')} (account-wide setting).`,
        );
      }
    }
  }

  if (
    !isComplete &&
    !hasPaymentMethod &&
    budget - spend > balance &&
    account.get('media_validation') &&
    !monetization &&
    !facebook
  ) {
    const suffix =
      'Consider adding funds to your project or adding a payment method to your project billing configuration.';
    errors.budget.push(`
      Because you have no configured payment method, budget must be less than your project media credit balance
      and current campaign spend (${numeral(spend + balance).format('$0,0.00')}). ${suffix}`);
  } else if (
    !isComplete &&
    !hasPaymentMethod &&
    balance <= 0 &&
    account.get('media_validation') &&
    !!monetization
  ) {
    /*
     * TODO: Don't do this because negative balance isn't actually bad.
     * errors.budget.push(`
     *   You must configure a payment method or have a positive media credit balance in order to publish a
     *   monetization campaign.`);
     */
  }
  return errors;
}

const NextStepButton = observer(
  ({ campaign, event, billable, account, onNext }: Omit<IProps, 'onPrev' | 'targetings'>) => {
    const flags = useFlags();
    const user = useUser();

    const validationErrors = validateStepFive(
      campaign,
      event,
      billable,
      account,
      user,
      flags.noMaxBudget,
    );
    return (
      <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
        Next
      </ButtonValid>
    );
  },
);

function CampaignEditStepFive({
  account,
  campaign,
  event,
  billable,
  onNext,
  onPrev,
  targetings,
}: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { Segments, Targetables } = useContext(StoresContext);
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();

  const segments = getTargetSegments(targetings, Segments);
  const targetables = getTargetables(targetings, Targetables);
  const validationErrors = validateStepFive(
    campaign,
    event,
    billable,
    account,
    user,
    flags.noMaxBudget,
  );
  const monetization = campaign.get('parent_kind') === 'partner';
  const dateStart = moment.utc(campaign.get('date_start'), TimeFormat.isoDateTime);
  const dateEnd = moment.utc(campaign.get('date_end'), TimeFormat.isoDateTime);
  const canEditBudget = !!campaign.get('date_start') && !!campaign.get('date_end');
  const now = moment.utc();
  const [recBudget, setRecBudget] = React.useState<number>(0);
  const [minBudget, setMinBudget] = React.useState<number>(getMinBudget(campaign));
  const [adding, toggleAdding] = useToggle(false);
  const isDraft = campaign.get('state') === CampaignState.Draft;
  const isFacebook = [CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(
    campaign.get('_cls'),
  );
  const isComplete = dateEnd.isBefore(now);
  const isStarted = dateStart.isBefore(now);
  React.useEffect(() => {
    return autorun(() => {
      const audienceSize = getAudienceSize(campaign, segments, targetables);

      /*
       * Only set the recommended budget if the dates have been selected
       * since the recommendation is based on the duration of the campaign.
       *
       * Reset to 0 if the user clears a date.
       */
      const recommendedBudget = canEditBudget
        ? getRecommendedBudget(campaign, segments, targetables)
        : 0;
      const recommendedBid = getRecommendedBid(audienceSize);
      const minimumValidBudget = canEditBudget ? getMinBudget(campaign) : 0;

      if (!monetization) {
        const exposureSettings = campaign.get('exposure_settings');
        if (exposureSettings && !exposureSettings.custom_target && isDraft) {
          runInAction((): void => {
            set(exposureSettings, { target_value: minimumValidBudget });
          });
          campaign.setAttributeDirty('exposure_settings');
        }
        if (exposureSettings && !exposureSettings.custom_bid && isDraft) {
          runInAction((): void => {
            set(exposureSettings, {
              base_bid: recommendedBid,
              max_bid: recommendedBid * 2,
            });
          });
          campaign.setAttributeDirty('exposure_settings');
        }
      }
      setRecBudget(recommendedBudget);
      setMinBudget(minimumValidBudget);
    });
  }, [campaign, isDraft, monetization, segments, targetables, canEditBudget]);

  function handleTargetValueChange(newValue?: number): void {
    const exposureSettings = campaign.get('exposure_settings');
    if (exposureSettings) {
      runInAction((): void => {
        set(exposureSettings, {
          target_value: newValue ?? 0,
          /*
           * Once the user sets a custom target, we no longer modify the values in the
           * budget input based on date selection.
           */
          custom_target: true,
        });
      });
      campaign.setAttributeDirty('exposure_settings');
    }
  }

  function handleSetRecommendedBudget(): void {
    handleTargetValueChange(recommendationRaw);
  }

  function handleMonetizationValueChange(newValue?: number): void {
    campaign.set({ monetization_value: newValue ?? 0 });
  }

  const helpDeskLink = (): JSX.Element | null => {
    let prompt = '';
    let url = '';
    if (campaign.get('_cls') === CampaignClass.Segment) {
      prompt = 'how to set up Billing Configurations';
      url = 'https://help.feathr.co/hc/en-us/articles/360037323074-Feathr-Billing-Basics';
    } else {
      return null;
    }

    return (
      <p>
        <a href={url} target={'_blank'}>
          Check out our help desk to learn {prompt}.
        </a>
      </p>
    );
  };

  let description: ReactNode;
  if (isFacebook) {
    description = (
      <p>
        Here you will set the duration and budget for your campaign. Once you set the duration,
        Feathr will suggest a budget for you based on the duration, size of the audience and
        campaign type.
      </p>
    );
  } else if (!monetization) {
    description = (
      <>
        <p>
          Here you will set the duration and budget for your campaign. Once you set the duration,
          Feathr will suggest a budget for you based on the duration, size of the audience and
          campaign type.
        </p>
        <p>
          Feathr tries to spend enough each day to meet your campaign budget by the end of the
          campaign, but sometimes this is not possible due to limited audience availability.
        </p>
        {helpDeskLink()}
      </>
    );
  } else {
    description = (
      <>
        <p>Here you will set the duration and target impressions for your campaign.</p>
        <p>
          Feathr tries to bid enough each day to meet your campaign impression target by the end of
          the campaign, but sometimes this is not possible due to limited audience availability.
        </p>
        {helpDeskLink()}
      </>
    );
  }

  const billing = event.get('billing');
  const balance = billing?.balance || 0;
  const budget = campaign.get('exposure_settings').target_value || 0;
  const spend = campaign.get('total_stats')?.spend || 0;

  // Time stamps
  const startTimeStamp = campaign.get('date_start');
  const endTimeStamp = campaign.get('date_end');

  // Moment objects
  const startMoment = moment.utc(startTimeStamp).local();
  const endMoment = moment.utc(endTimeStamp).local();

  // ISO formatted timestamps
  const isoStartTimestamp =
    startTimeStamp && moment.utc(startTimeStamp).format(TimeFormat.isoDateTime);
  const isoEndTimestamp = endTimeStamp && moment.utc(endTimeStamp).format(TimeFormat.isoDateTime);

  function handleOnChangeSendStart(newTimestamp?: string): void {
    campaign.set({
      date_start: newTimestamp,
    });
  }

  function handleOnChangeDateEnd(newTimestamp?: string): void {
    campaign.set({
      date_end: newTimestamp,
    });
  }

  const overspendText = monetization
    ? t(
        'Due to standard bidding dynamics, campaigns may under or overreach their impression target by ~5%',
      )
    : t('Due to standard bidding dynamics, campaigns may overspend by ~5%');

  const target = getRecommendedImpressions(campaign, segments, targetables);
  const formattedTarget = numeral(target).format('0,0');
  const formattedBudget = numeral(recBudget).format('$0,0.00');
  const recommendationRaw = monetization ? target : recBudget ?? 0;
  const minBudgetFormatted = numeral(getMinBudget(campaign)).format('$0,0');

  /*
   * Only show the recommended target for monetization campaigns.
   * Do not show it if it's 0 (generally due to audience selection)
   */
  const targetRecommendation =
    monetization && target > 0
      ? t('Recommended target: {{target}}', { target: formattedTarget })
      : undefined;

  const alertMap = {
    [EAlertType.warning]: {
      title: t("The budget you've set will limit the performance of this campaign. "),
      description: t(
        'We highly recommend a budget of at least {{formattedBudget}} for the best results.',
        { formattedBudget },
      ),
      type: EAlertType.warning,
      name: 'alert_recommended_budget',
    },
    [EAlertType.danger]: {
      title: t('This campaign cannot be published with the set budget. '),
      description: t('The minimum budget required to publish this campaign is {{min}}.', {
        min: minBudgetFormatted,
      }),
      type: EAlertType.danger,
      name: 'alert_min_budget',
    },
  };

  const targetValue = campaign.get('exposure_settings').target_value ?? 0;

  let alertProps: null | { title: string; description: string; type: EAlertType; name: string } =
    null;
  if (targetValue < minBudget) {
    alertProps = alertMap[EAlertType.danger];
  } else if (targetValue < recommendationRaw) {
    alertProps = alertMap[EAlertType.warning];
  }

  function handleClearDate(type: 'end' | 'start'): () => void {
    return function () {
      campaign.set({ [`date_${type}`]: undefined });
    };
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton
          account={account}
          billable={billable}
          campaign={campaign}
          event={event}
          key={'next'}
          onNext={onNext}
        />,
      ]}
      className={styles.root}
      description={description}
      label={'Edit Campaign: Budget & Duration'}
    >
      <Card width={'narrow'}>
        <Card.Header title={'Dates'} />
        <Card.Content addVerticalGap={true}>
          <Flex gap={cssVar('--spacing-4')} justify={'space-between'}>
            <DatePicker
              autoComplete={'off'}
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={!isDraft && isStarted}
              isClearable={true}
              isFullWidth={true}
              label={t('Start date')}
              minDate={momentToDate(getMinStartDate(campaign.get('_cls')))}
              name={'date_start'}
              onClear={handleClearDate('start')}
              onDateStrChange={handleOnChangeSendStart}
              showTimeSelect={true}
              suffix={timezoneAbbr(startMoment.toDate())}
              timeIntervals={5}
              validationError={validationErrors.date_start}
              value={isoStartTimestamp}
            />
            <DatePicker
              autoComplete={'off'}
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={!isDraft && isComplete}
              isClearable={true}
              isFullWidth={true}
              label={t('End date')}
              minDate={startMoment ? momentToDate(startMoment.add(getMinDuration())) : undefined}
              name={'date_end'}
              onClear={handleClearDate('end')}
              onDateStrChange={handleOnChangeDateEnd}
              showTimeSelect={true}
              suffix={timezoneAbbr(endMoment.toDate())}
              timeIntervals={5}
              validationError={validationErrors.date_end}
              value={isoEndTimestamp}
            />
          </Flex>
        </Card.Content>
      </Card>
      <Card width={'narrow'}>
        <Card.Header title={'Edit Budget'} />
        <Card.Content addVerticalGap={true}>
          <Flex align={'center'} gap={cssVar('--spacing-4')} justify={'space-between'}>
            {!canEditBudget && (
              <EmptyState
                description={'Select a start and end date to access budget selection.'}
                label={'No dates selected'}
                theme={'slate'}
                width={'full'}
              />
            )}
            {!monetization && canEditBudget && (
              <RecommendedBudget
                budget={formattedBudget}
                isLoading={!recBudget}
                onClick={handleSetRecommendedBudget}
              />
            )}
            {canEditBudget && (
              <NumberInput
                additionalContent={targetRecommendation}
                className={classNames({ [styles.budgetInput]: !monetization })}
                disabled={!canEditBudget}
                helpPlacement={'top'}
                helpText={overspendText}
                label={monetization ? t('Impressions') : t('Budget')}
                min={monetization ? 10 : minBudget}
                name={'budget'}
                onChange={handleTargetValueChange}
                prefix={monetization ? null : '$'}
                validationError={validationErrors.budget}
                value={campaign.get('exposure_settings').target_value}
              />
            )}
          </Flex>

          {alertProps && !monetization && canEditBudget && (
            <Alert
              className={styles.marginBuster}
              description={alertProps.description}
              name={alertProps.name}
              title={alertProps.title}
              type={alertProps.type}
            />
          )}

          {monetization && (
            <NumberInput
              helpText={t(
                'How much is this campaign worth to you? Usually this is how much your partner paid to you to run the campaign. This value is only used to provide reporting context.',
              )}
              label={t('Sponsor package value')}
              min={0}
              name={'monetization_value'}
              onChange={handleMonetizationValueChange}
              optional={true}
              prefix={'$'}
              required={true}
              validationError={validationErrors.monetization_value}
              value={campaign.get('monetization_value')}
            />
          )}

          {!isFacebook && (
            <Collapse title={'Advanced Options'}>
              <p>
                These settings are preset for optimal performance based on your audience size and
                the campaign type. There are some cases where you may want to adjust them, but
                typically the preset values should be used.
              </p>
              <Fieldset direction={'column'}>
                <FreqCapSelect campaign={campaign} />
                <BaseBidSelect campaign={campaign} />
              </Fieldset>
              <FreqPeriodSelect campaign={campaign} />
            </Collapse>
          )}
        </Card.Content>
      </Card>

      {!isFacebook && (
        <Card>
          <Card.Header title={'Payment Details'} />
          {!adding ? (
            <Card.Content addVerticalGap={true}>
              <Flex align={'center'} gap={cssVar('--spacing-4')} justify={'space-between'}>
                {!billable ? (
                  <Alert
                    className={styles.marginBuster}
                    title={
                      <>
                        You will not be able to publish this campaign until you&nbsp;
                        <Link target={'_blank'} to={localUrl('/settings/billing/configurations')}>
                          set up a billing configuration
                        </Link>
                        &nbsp;and&nbsp;
                        <Link
                          target={'_blank'}
                          to={localUrl(event.getItemUrl('/settings/billing/edit'))}
                        >
                          attach it to this project
                        </Link>
                        .
                      </>
                    }
                    type={EAlertType.danger}
                  />
                ) : !billable.isPending && billable.get('stripe', {} as IStripe).source ? (
                  <div>
                    <Label>{t('Payment method')}</Label>
                    <BillingSource
                      billable={billable}
                      key={billable.get('stripe').source!.id}
                      source={billable.get('stripe').source!}
                    />
                  </div>
                ) : (
                  budget - spend > balance && (
                    <Stack>
                      <div>
                        <p>{t('Payment method has not been set.')}</p>
                        <Button onClick={toggleAdding}>{t('Add payment method')}</Button>
                      </div>
                      <Alert
                        className={styles.marginBuster}
                        title={t(
                          'Changing the payment method above will affect the Billing Configuration for the whole Project.',
                        )}
                        type={EAlertType.info}
                      />
                    </Stack>
                  )
                )}
                {balance > 0 && (
                  <Well className={styles.mediaCreditBalance} layout={'vertical'}>
                    <Label
                      className={styles.marginBuster}
                      tooltip={t(
                        'This number represents the amount of money you have already funded on this project (in USD).',
                      )}
                    >
                      {t('Media Credit Balance')}
                    </Label>
                    <span className={styles.bigNumber} data-name={'media_credit_balance'}>
                      {currencyFormatter.format(Math.max(balance, 0))}
                    </span>
                  </Well>
                )}
              </Flex>
            </Card.Content>
          ) : (
            !!billable &&
            !billable.isPending && (
              <PaymentMethodForm>
                {(onSave, element): JSX.Element => {
                  async function handleSave(): Promise<void> {
                    if (!billable || billable.isPending) {
                      return;
                    }

                    try {
                      const updatedSource = await onSave();
                      setSource(billable, updatedSource);
                      await billable.save();
                      toggleAdding();
                    } catch (error) {
                      const message =
                        error instanceof Error
                          ? error.message
                          : 'An error occurred while trying to add a payment method.';
                      toast(message, { type: ToastType.ERROR });
                    }
                  }

                  return (
                    <>
                      <Card.Content>{element}</Card.Content>
                      <Card.Actions>
                        <Button onClick={toggleAdding} type={'naked'}>
                          {t('Cancel')}
                        </Button>
                        <Button onClick={handleSave}>{t('Add')}</Button>
                      </Card.Actions>
                    </>
                  );
                }}
              </PaymentMethodForm>
            )
          )}
        </Card>
      )}
    </Form>
  );
}

export default observer(CampaignEditStepFive);
