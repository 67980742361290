import { useEffect, useRef } from 'react';

import { useId } from '@feathr/hooks';

import type { Collection } from './collection';
import type { IBaseAttributes, Model } from './model';

function useInfiniteList<C extends Collection<Model<A>>, A extends IBaseAttributes>(
  collection: C,
  listId?: string,
): [C['list'], () => void] {
  const generatedId = useId();
  const realListId = listId ?? generatedId;

  function list(
    params: Parameters<C['list']>[0] = {},
    options: Parameters<C['list']>[1] = {},
  ): ReturnType<C['list']> {
    return collection.list(params, options, realListId) as ReturnType<C['list']>;
  }

  const clear = useRef(() => {
    collection.apiCachePages.delete(realListId);
  });

  useEffect(() => {
    return () => {
      collection.apiCachePages.delete(realListId);
    };
  }, [collection.apiCachePages, realListId]);

  return [list, clear.current];
}

export default useInfiniteList;
