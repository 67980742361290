import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IiMISContactMapping, ImisIntegration } from '@feathr/blackbox';
import { ContextMenu, toast } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISMappingOptionsProps {
  original: IiMISContactMapping;
  integration: ImisIntegration;
}

function ImisMappingOptions({ original, integration }: IiMISMappingOptionsProps): JSX.Element {
  const { t } = useTranslation();

  async function handleClickDeleteMenuItem(): Promise<void> {
    try {
      // TODO: Refactor to use ContactMapping.delete() as part of #2651.
      await integration.deleteContactMapping(original.id);
      toast(t('Successfully deleted mapping.'), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    } catch (error) {
      toast(t('Failed to delete mapping.'), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item
          disabled={original.required}
          onClick={handleClickDeleteMenuItem}
          prefix={getIconForAction('delete')}
          theme={'danger'}
        >
          {t('Delete')}
        </ContextMenu.Item>
      </ContextMenu>
    </>
  );
}

export default observer(ImisMappingOptions);
