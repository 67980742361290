import DOMPurify from 'dompurify';

// Add a hook to make all links open a new window
DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
  // Set all elements owning target to target=_blank
  if ('target' in node) {
    // Prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    (node as Element).setAttribute('rel', 'noopener noreferrer');
  }
});

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

export default DOMPurify;
