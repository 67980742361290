import validate from 'validate.js/validate';

/**
 * Validate a collection of attribute => value objects against a set of constraints
 *
 * Used to validate a collection of attributes (as opposed to 'list' which is used to validate an array value of a single attribute)
 *
 */
function array(
  attributes: Array<Record<string, any>>,
  constraints: Record<string, any>,
): Array<string | undefined> | null {
  if (!attributes) {
    return null;
  }
  const errors = attributes.reduce<Array<string | undefined>>((acc, item) => {
    const error: string = validate(item, constraints);
    acc.push(error);
    return acc;
  }, []);

  return errors.some((error) => error !== undefined) ? errors : null;
}

export default array;
