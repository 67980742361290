import debounce from 'lodash.debounce';
import type { Dispatch } from 'react';
import { useRef, useState } from 'react';

export const DEFAULT_DEBOUNCE_WAIT = 600;

export function useDebounce<S>(
  initialState: S | (() => S),
  wait?: number,
): [S, Dispatch<S>, Dispatch<S>];

export function useDebounce<S = undefined>(
  wait?: number,
): [S | undefined, Dispatch<S | undefined>, Dispatch<S | undefined>];

export function useDebounce<S>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState?: any,
  wait = DEFAULT_DEBOUNCE_WAIT,
):
  | [S, Dispatch<S>, Dispatch<S>]
  | [S | undefined, Dispatch<S | undefined>, Dispatch<S | undefined>] {
  const [value, setValue] = useState(initialState);

  const debouncedSetValue = useRef(
    debounce((newValue: S) => {
      setValue(newValue);
    }, wait),
  );

  return [value, debouncedSetValue.current, setValue];
}
