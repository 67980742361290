import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreatableSelect, Form, SaveButtonValid } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount } from '@feathr/extender/state';
import { flattenError } from '@feathr/hooks';
import { validate } from '@feathr/rachis';

// https://regex101.com/r/dD0Mh9/1
const ipv4Format = /^(\d{1,3}\.){3}\d{1,3}(\/(\d|[1-2]\d|3[0-2]))?$/;

interface IIPFilterOption {
  label: string;
  value: string;
}

interface ICreateIPFilterOption extends IIPFilterOption {
  create: boolean;
}

export function validateForm(ipFilters: string[]): Record<'ip_filters', string[]> {
  const addresses = ipFilters.map((address) => ({ label: address, value: address }));
  const errors = validate(
    { ip_filters: addresses },
    {
      ip_filters: {
        array: {
          value: {
            presence: { allowEmpty: false },
            format: {
              pattern: ipv4Format,
            },
          },
        },
        length: {
          minimum: 1,
          message: '^Please provide at least 1 IP address.',
        },
      },
    },
  );
  return errors || {};
}

function IPFilteringPage(): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();

  const description = t(
    `Here, you can configure Feathr to stop tracking web users according to their IP address. This will impact all campaigns by not counting views and clicks from those users. This is typically done to avoid spending money serving ads to yourself or people in your organization. However, users already targeted by a campaign will continue to receive ads until the targeting is changed or their cookies expire. It is also important to note that IP addresses are network-specific, so if you add your office IP Address, you may still see ads if you connect to your home network from your work computer. For more information, visit this `,
  );
  const helpText =
    t(`You may input as many IP Addresses as you'd like. Hit Return/Enter after each address to add it and start the next one. Valid IP address (e.g., 64.238.188.206) or CIDR address (e.g., 64.238.188.206/31) formats are
accepted.`);

  function isValidAddress(address: string): boolean {
    return validate({ address }, { address: { format: { pattern: ipv4Format } } }) === undefined;
  }

  function handleCreateOption(inputValue: string): ICreateIPFilterOption {
    account.set({ ip_filters: [...account.get('ip_filters'), inputValue] });
    return { label: inputValue, value: inputValue, create: true };
  }

  function handleFormatCreateLabel(inputValue: string): string {
    if (inputValue.split(',').length <= 1) {
      return t('Add IP Address: {{ipAddress}}', { ipAddress: inputValue });
    } else {
      return t('Add IP Addresses: {{ipAddress}}', { ipAddress: inputValue });
    }
  }

  function handleNoIPFilterOptionsMessage({ inputValue }: { inputValue: string }): string {
    if (inputValue.length) {
      return t('Not a valid IP address');
    }
    return t('Start typing an IP address');
  }

  function handleSelectMultiIPFilters(newValues: IIPFilterOption[]): void {
    account.set({ ip_filters: newValues.map((newValue) => newValue.value) });
  }

  const errors = validateForm(account.get('ip_filters'));

  return (
    <Page
      actions={[
        <SaveButtonValid
          errors={flattenError(errors)}
          key={'saveButton'}
          method={'patch'}
          model={account}
        />,
      ]}
      title={t('IP Filtering')}
    >
      <Form label={t('IP Filtering Form')}>
        {description}
        <a
          href={
            'https://help.feathr.co/hc/en-us/articles/360041179234-How-to-stop-seeing-Feathr-ads'
          }
          target={'_blank'}
        >
          help article
        </a>
        .
        <CreatableSelect
          createOption={handleCreateOption}
          defaultOptions={[] as Array<{ value: string; label: string }>}
          formatCreateLabel={handleFormatCreateLabel}
          helpPlacement={'bottom'}
          helpText={helpText}
          isLoading={account.isPending}
          isMulti={true}
          isValidNewOption={isValidAddress}
          label={t('Filtered IP Addresses')}
          name={'filtered-ip-addresses'}
          noOptionsMessage={handleNoIPFilterOptionsMessage}
          onSelectMulti={handleSelectMultiIPFilters}
          placeholder={t('Add an IP address')}
          validationError={flattenError(errors.ip_filters)}
          value={account
            .get('ip_filters', [])
            .map((filter: string) => ({ label: filter, value: filter }))}
        />
      </Form>
    </Page>
  );
}

export default observer(IPFilteringPage);
