import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Nav, NavItem, NavMenu } from '@feathr/components';
import EventSwitcher from '@feathr/extender/components/EventSwitcher';
import { useAccount, useLocalUrl } from '@feathr/extender/state';

import EventContentNav from './EventContentNav';
import EventMarketingNav from './EventMarketingNav';
import EventPartnersNav from './EventPartnersNav';
import EventSettingsNav from './EventSettingsNav';

import styles from './EventNav.css';

function EventNav(): JSX.Element {
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();

  return (
    <>
      <EventSwitcher className={styles.select} />
      {!!eventId && (
        <Nav indented={true} label={'Project navigation'} type={'vertical'}>
          <NavMenu
            hasDefaultChild={true}
            menu={<EventMarketingNav />}
            to={localUrl(`/projects/${eventId}/campaigns`)}
          >
            {t('Marketing')}
          </NavMenu>
          <NavItem to={localUrl(`/projects/${eventId}/flights`)}>{t('Flights')}</NavItem>
          {account.isFalcon && (
            <NavMenu menu={<EventPartnersNav />} to={localUrl(`/projects/${eventId}/partners`)}>
              {t('Partners')}
            </NavMenu>
          )}
          <NavMenu menu={<EventContentNav />} to={localUrl(`/projects/${eventId}/content`)}>
            {t('Content')}
          </NavMenu>
          <NavItem to={localUrl(`/projects/${eventId}/report`)}>{t('Report')}</NavItem>
          <NavMenu menu={<EventSettingsNav />} to={localUrl(`/projects/${eventId}/settings`)}>
            {t('Project Settings')}
          </NavMenu>
        </Nav>
      )}
    </>
  );
}

export default observer(EventNav);
