import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { ICustomField } from '@feathr/blackbox';
import { FieldDataType } from '@feathr/blackbox';
import {
  Button,
  CreatableSelect,
  DatePicker,
  Fieldset,
  Input,
  NumberInput,
  Select,
} from '@feathr/components';
import { FieldOption, FieldSingleValue } from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';
import { fieldDataTypeLabel } from '@feathr/extender/styles/fieldDataType';

import type { ICustomDataKeyValuePair } from '../../EventPartnerPage';

import styles from './PartnerCustomDataInputs.css';

export interface ISelectElementOption {
  id: JSX.Element | (({ val }: IListProps) => JSX.Element);
  name: string;
}

interface IProps {
  customData: IObservableArray<ICustomDataKeyValuePair>;
  datumKey: string;
  value: string | number | boolean | Date | string[] | undefined;
  handleFieldChange: (datumKey: string, newField: string) => void;
  handleValueChange: (
    datumKey: string,
    newValue: string | number | boolean | Date | string[] | undefined,
  ) => void;
}

interface IListProps {
  val: string[];
}

const booleanOptions = [
  { name: 'True', id: true },
  { name: 'False', id: false },
];

function PartnerCustomDataInputs({
  customData,
  handleFieldChange,
  handleValueChange,
  datumKey,
  value,
}: IProps): JSX.Element {
  const { CustomFields } = useContext(StoresContext);
  const { t } = useTranslation();

  const customFields = CustomFields.list({
    filters: {
      collection: 'Partner',
      is_archived__ne: true,
    },
    pagination: { page_size: 1000 },
  });
  let input: JSX.Element | (({ val }: IListProps) => JSX.Element) | undefined;
  const dataKeys = customData.map((datum) => datum.key);
  const customDatum = customFields.models.find((cf) => cf.get('u_key') === datumKey);

  function handleStringChange(newValue?: string): void {
    handleValueChange(datumKey, newValue ?? '');
  }

  const textInput = (
    <Input id={'textInput'} onChange={handleStringChange} type={'text'} value={value as string} />
  );

  function handleNumberChange(newValue?: number): void {
    handleValueChange(datumKey, newValue ?? 0);
  }

  const numberInput = <NumberInput onChange={handleNumberChange} value={value as number} />;

  function handleSelectBoolean(option): void {
    handleValueChange(datumKey, option.id);
  }

  const booleanInput = (
    <Select
      controlled={true}
      defaultValue={booleanOptions.find((option) => option.id === value)}
      name={'partner-data-boolean-input'}
      onSelectSingle={handleSelectBoolean}
      options={booleanOptions}
    />
  );

  function handleSelectDate(date: Date | null | undefined): void {
    // Even though type is Date, it can actually still be empty
    handleValueChange(datumKey, date?.toDateString());
  }

  const datePicker = (
    <DatePicker
      onSelect={handleSelectDate}
      selected={value ? new Date(value as string) : undefined}
      showCalendarIcon={true}
    />
  );

  // TODO: Refactor ListInput into a separate component
  const ListInput = ({ val }: IListProps): JSX.Element => {
    // Populate options from every partner's custom data lists?
    const listValues = customData
      .filter((datum) => datum.value instanceof Array && datum.value.length > 0)
      .map((obj) => obj.value) as string[][];
    const defaultOptions =
      listValues.length > 0
        ? listValues
            .reduce((acc, curr) => {
              const values = [...curr].filter((v) => !acc.includes(v));
              return acc.concat(values);
            })
            .map((str) => ({ label: str, value: str }))
        : [];

    function handleCreateOption(inputValue: string): { label: string; value: string } {
      return { label: inputValue, value: inputValue };
    }

    function formatCreateLabel(fieldName: string): string {
      return t('Create field: {{fieldName}}', { fieldName });
    }

    function handleSelectMulti(options): void {
      handleValueChange(
        datumKey,
        options.map((option) => option.value),
      );
    }

    function handleSelectSingle(option): void {
      val.push(option.value);
      handleValueChange(
        datumKey,
        val.map((v) => v),
      );
    }

    return (
      <CreatableSelect
        createOption={handleCreateOption}
        defaultOptions={defaultOptions}
        defaultValue={defaultOptions.filter((option) => val.includes(option.value))}
        formatCreateLabel={formatCreateLabel}
        isMulti={true}
        name={'partner-data-list-input'}
        onSelectMulti={handleSelectMulti}
        onSelectSingle={handleSelectSingle}
      />
    );
  };

  function handleSelectDataType(option): void {
    input = option.id;
  }

  const selectDataType = (
    <Select<ISelectElementOption>
      name={'partner-data-type'}
      onSelectSingle={handleSelectDataType}
      options={[
        { name: fieldDataTypeLabel(FieldDataType.str, t), id: textInput },
        { name: fieldDataTypeLabel(FieldDataType.date, t), id: datePicker },
        { name: fieldDataTypeLabel(FieldDataType.bool, t), id: booleanInput },
        { name: fieldDataTypeLabel(FieldDataType.float, t), id: numberInput },
        { name: fieldDataTypeLabel(FieldDataType.list, t), id: ListInput },
      ]}
      placeholder={t('Select data type')}
    />
  );

  switch (customDatum ? customDatum.get('data_type') : FieldDataType.str) {
    case FieldDataType.str:
      input = textInput;
      break;

    case FieldDataType.date:
      input = datePicker;
      break;

    case FieldDataType.int:
      input = numberInput;
      break;

    case FieldDataType.float:
      input = numberInput;
      break;

    case FieldDataType.bool:
      input = booleanInput;
      break;

    case FieldDataType.list:
      input = <ListInput val={value !== undefined ? (value as string[]) : []} />;
      break;

    default:
      input = undefined;
  }

  function getOptionLabel(option: ICustomField): string {
    return option.u_key;
  }

  function handleSelectCustomField(option): void {
    handleFieldChange(datumKey, option.u_key);
  }

  function handleClickRemove(): void {
    const selectedField = customData.find((datum) => datum.key === datumKey);
    if (selectedField) {
      customData.remove(selectedField!);
    }
  }

  return (
    <>
      <Fieldset direction={'column'}>
        <Select<ICustomField>
          components={{ SingleValue: FieldSingleValue, Option: FieldOption }}
          getOptionLabel={getOptionLabel}
          id={'datumSelect'}
          isDisabled={customFields.isPending}
          isLoading={customFields.isPending}
          name={'partner-data-field'}
          onSelectSingle={handleSelectCustomField}
          options={customFields.models
            .filter((customField) => !dataKeys.includes(customField.get('u_key')))
            .map((c) => c.toJS())}
          placeholder={t('Please select a field')}
          value={customDatum && customDatum.toJS()}
          wrapperClassName={styles.selectWrapper}
        />
        {input !== undefined ? input : selectDataType}
        <Button
          className={styles.button}
          disabled={input === undefined}
          onClick={handleClickRemove}
          prefix={<FontAwesomeIcon icon={faTrash} />}
          title={t('Remove Custom Data')}
        />
      </Fieldset>
    </>
  );
}

export default observer(PartnerCustomDataInputs);
