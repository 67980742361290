import { runInAction, set, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import type { ValueType } from 'react-select';
import type { Option } from 'react-select/src/filters';

import type {
  CustomField,
  ICustomField,
  IDefaultField,
  IImportColumn,
  IRequestField,
} from '@feathr/blackbox';
import { defaultPartnerAttributes, FieldCollection, FieldDataType } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import {
  CollectionFieldOption,
  FieldOption,
} from '@feathr/extender/components/SelectOptions/FieldOption';
import { StoresContext, useAccount, useRole } from '@feathr/extender/state';

import DataRequestBreadcrumbAttributes from './DataRequestBreadcrumbAttributes';
import DataRequestPersonAttributes from './DataRequestPersonAttributes';

const attributesMap = {
  [FieldCollection.Partner]: defaultPartnerAttributes,
  [FieldCollection.Person]: DataRequestPersonAttributes,
  [FieldCollection.Breadcrumb]: DataRequestBreadcrumbAttributes,
};

interface IProps {
  customField?: CustomField;
  disabled?: boolean;
  object?: IImportColumn | IRequestField;
  contexts: FieldCollection[];
  label?: string;
  filterOption?: (option: Option) => boolean;
  onCreate?: (option: CustomField) => void;
  onSelect?: (option: ICustomField) => void;
  helpText?: React.ReactNode;
  isOptOut?: boolean;
}

function CustomFieldSelect({
  contexts,
  customField,
  disabled = false,
  filterOption,
  helpText,
  isOptOut = false,
  object,
  onCreate,
  onSelect,
  label,
}: IProps): JSX.Element {
  const account = useAccount();
  const { hasSegments } = useRole();
  const { CustomFields } = useContext(StoresContext);
  /**
   * Adding new default fields requires updating 3 places: DataRequestPersonAttributes, DefaultPersonAttributes and defaults in importers.getUsedCustomFields()
   * TODO: Refactor to use a single source of truth for default fields
   */
  const defaultFields = contexts.reduce((acc, context) => {
    return acc.concat(attributesMap[context]);
  }, [] as IDefaultField[]);

  if (isOptOut) {
    const index = defaultFields.findIndex((field) => field.id === 'pp_opt_outs.all');
    defaultFields.splice(index, 1);
  }

  const value = customField
    ? customField.toJS()
    : object
      ? defaultFields.find((field) => field.id === object.feathr_attr)
      : undefined;
  const loadOptions = async (inputValue: string) => {
    const customFields = CustomFields.list({
      filters: {
        collection__in: contexts,
        is_archived__ne: true,
        u_key__icontains: inputValue,
      },
      pagination: {
        page_size: 20,
      },
    });
    await when(() => !customFields.isPending);
    if (account.isFalcon) {
      const options = [
        ...defaultFields.filter((attr) =>
          attr.u_key.toLowerCase().includes(inputValue.toLowerCase()),
        ),
        ...customFields.models.map((cf) => cf.toJS()),
      ];

      const createNewField = { id: 'create', u_key: 'Create new field' } as IDefaultField;

      /*
       * Add "create new field" option to the beginning of the options
       * if the user has full segment access.
       */
      if (hasSegments) {
        options.unshift(createNewField);
      }

      return options;
    }
    return [
      ...defaultFields.filter((attr) =>
        attr.u_key.toLowerCase().includes(inputValue.toLowerCase()),
      ),
      ...customFields.models.map((cf) => cf.toJS()),
    ];
  };
  const onSelectSingle = (option: ICustomField): void => {
    if (option.id === 'create') {
      const field = CustomFields.create({
        u_key: '',
        collection: contexts[0],
        data_type: FieldDataType.str,
      });
      if (object) {
        runInAction(() => {
          set(object, {
            feathr_attr: field.id,
            attr_type: FieldDataType.str,
            u_key: '',
          });
        });
      }
      if (onCreate) {
        onCreate(field);
      }
    } else {
      if (object) {
        runInAction(() => {
          set(object, {
            feathr_attr: option.id,
            attr_type: option.data_type,
            u_key: option?.u_key,
          });
        });
      }
      if (onSelect) {
        onSelect(option);
      }
    }
  };
  return (
    <AsyncSelect<ICustomField>
      components={{ Option: contexts.length > 1 ? CollectionFieldOption : FieldOption }}
      defaultOptions={true}
      disabled={disabled}
      filterOption={filterOption}
      getOptionLabel={(option) => option.u_key}
      getOptionValue={(option) => option.id!}
      helpText={helpText}
      id={'customFieldSelect'}
      label={label}
      loadOptions={loadOptions}
      name={'custom-field-select'}
      onSelectSingle={onSelectSingle}
      placeholder={
        contexts.includes(FieldCollection.Partner)
          ? 'Select partner field...'
          : 'Select custom field...'
      }
      value={value as ValueType<ICustomField>}
    />
  );
}

export default observer(CustomFieldSelect);
