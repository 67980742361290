import debounce from 'debounce-promise';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import type { ValueType } from 'react-select';

import type {
  CustomField,
  ICustomField,
  IDefaultField,
  IiMISContactMapping,
  ImisIntegration,
  TIntegrationPersonAttributes,
} from '@feathr/blackbox';
import { defaultPartnerAttributes, FieldCollection } from '@feathr/blackbox';
import type { IAsyncSelectProps } from '@feathr/components';
import { AsyncSelect } from '@feathr/components';
import ImisPersonAttributes from '@feathr/extender/App/Settings/Account/ImisIntegrationPage/ImisSyncWizard/ImisPersonAttributes';
import DataRequestBreadcrumbAttributes from '@feathr/extender/components/CustomFieldSelect/DataRequestBreadcrumbAttributes';
import {
  CollectionFieldOption,
  FieldOption,
} from '@feathr/extender/components/SelectOptions/FieldOption';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, useId } from '@feathr/hooks';
import type { ListResponse } from '@feathr/rachis';

const attributesMap = {
  [FieldCollection.Partner]: defaultPartnerAttributes,
  [FieldCollection.Person]: ImisPersonAttributes,
  [FieldCollection.Breadcrumb]: DataRequestBreadcrumbAttributes,
};

interface IProps extends Partial<IAsyncSelectProps<ICustomField>> {
  contexts: FieldCollection[];
  object: IiMISContactMapping;
  integration: ImisIntegration;
}

function ImisCustomFieldSelect({
  contexts,
  object,
  integration,
  ...additionalProps
}: IProps): JSX.Element {
  const { CustomFields } = useContext(StoresContext);
  const defaultFields: IDefaultField[] = contexts.reduce((acc, context) => {
    return acc.concat(attributesMap[context]);
  }, []);
  const [mappings, setMappings] = useState<IiMISContactMapping[] | undefined>(undefined);

  useEffect(() => {
    async function getMappings(): Promise<void> {
      // TODO: Refactor to use ContactMappings.list() as part of #2651.
      const mappings = await integration.getContactMappings();
      setMappings(mappings);
    }

    getMappings();
  }, [integration]);

  const value: IDefaultField | CustomField | undefined = object.custom_field
    ? CustomFields.get(object.custom_field)
    : defaultFields.find(({ id }) => id === object.default_field);

  const currentlyMappedFields: Array<string | null> =
    mappings?.map(
      (mapping: IiMISContactMapping) => mapping.default_field ?? mapping.custom_field,
    ) ?? [];

  // TODO: Remove the Address field from the list of options since it a pseudo-field that should only be available for the iMIS Address Field
  const loadOptions = async (inputValue: string): Promise<Array<IDefaultField | ICustomField>> => {
    const customFields: ListResponse<CustomField> = CustomFields.list({
      filters: {
        collection__in: contexts,
        is_archived__ne: true,
        u_key__icontains: inputValue,
      },
      pagination: {
        page_size: 20,
      },
    });
    await when(() => !customFields.isPending);
    return [
      ...defaultFields.filter((attribute: IDefaultField) =>
        attribute.u_key.toLowerCase().includes(inputValue.toLowerCase()),
      ),
      ...customFields.models.map((customField: CustomField) => customField.toJS()),
    ]
      .filter(
        // Remove fields that are already mapped except for the currently mapped field
        (field: ICustomField | IDefaultField) =>
          !currentlyMappedFields?.includes(field.id as TIntegrationPersonAttributes) ||
          field.id === object.default_field,
      )
      .filter(
        // Keep only fields with the same data type as the object
        (field: ICustomField | IDefaultField) => {
          // If the data_type is a number type, allow both to display
          if (field.data_type === 'int' || field.data_type === 'float') {
            return object.data_type === 'int' || object.data_type === 'float';
          }
          return field.data_type === object.data_type;
        },
      );
  };

  const debouncedLoadOptions = debounce(loadOptions, DEFAULT_DEBOUNCE_WAIT);

  function getOptionLabel(option: ICustomField): string {
    return option.u_key || option.attributes.u_key;
  }

  function getOptionValue({ id }: ICustomField): string {
    return id as string;
  }

  return (
    <AsyncSelect<ICustomField>
      {...additionalProps}
      components={{ Option: contexts.length > 1 ? CollectionFieldOption : FieldOption }}
      defaultOptions={true}
      defaultValue={value as ValueType<ICustomField>}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      key={useId({ ephemeral: true })}
      loadOptions={debouncedLoadOptions}
      name={'imis-custom-field'}
    />
  );
}

export default observer(ImisCustomFieldSelect);
