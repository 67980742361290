import { nanoid } from 'nanoid';
import { useState } from 'react';

interface IProps {
  /** If true, the id will be ephemeral and will be regenerated on every render. Defaults to false. */
  ephemeral?: boolean;
}

/**
 * Custom hook to generate a unique ID, either ephemeral or persistent.
 */
export function useId({ ephemeral = false }: IProps = {}): string {
  const [persistentId] = useState(() => `uid-${nanoid(12)}`);

  // If ephemeral is true, generate a new ID on every render by calling nanoid().
  const generatedId = ephemeral ? `uid-${nanoid(12)}` : persistentId;

  return generatedId;
}
