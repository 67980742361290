import moment from 'moment';

import { TimeFormat } from '@feathr/hooks';
import { isWretchError, type TConstraints, wretch } from '@feathr/rachis';

import { DisplayCampaign } from './display';
import type {
  IGoogleAdsLocationSuggestion,
  IGoogleAdsSmartCampaign,
  IGoogleAdsSuggestProps,
  TGoogleAdsSuggestions,
} from './types';
import { CampaignClass } from './types';

export class GoogleAdsSmartCampaign extends DisplayCampaign<IGoogleAdsSmartCampaign> {
  public get constraints(): TConstraints<IGoogleAdsSmartCampaign> {
    return {
      ...super.constraints,
      destination_url: {
        url: {
          message: '^Website must be a valid URL.',
        },
        presence: {
          allowEmpty: false,
          message: "^Website can't be empty.",
        },
      },
      business_name: {
        presence: {
          allowEmpty: false,
          message: "^Business name can't be empty.",
        },
      },
      keyword_themes: {
        presence: {
          allowEmpty: false,
        },
      },
      'exposure_settings.target_cap_daily': {
        presence: {
          message: '^Daily average budget cannot be empty.',
          allowEmpty: false,
        },
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 329,
          message: '^Daily average budget must be greater than $0 and less than or equal to $329.',
        },
      },
      /*
       * Override the default date_start constraints from baseConstraints
       */
      date_start: {
        presence: {
          allowEmpty: false,
          message: '^Start date cannot be empty.',
        },
      },
      // Override the default date_end constraints from baseConstraints
      date_end: {
        datetime: (value: string, attributes: any) => {
          if (attributes.date_start) {
            const dateStart = moment.utc(attributes.date_start, TimeFormat.isoDateTime);
            const dateEnd = moment.utc(value, TimeFormat.isoDateTime);
            const today = moment.utc('', TimeFormat.isoDateTime);
            const endStartDiff = dateEnd.diff(dateStart);
            if (dateEnd.isBefore(today)) {
              return {
                earliest: today.format(TimeFormat.isoDateTime),
                message: '^End date must not be in the past.',
              };
            }
            /*
             * Campaigns can end on the same day as they start
             * Invalid end dates are before start date and not on start date
             */
            if (dateEnd.isBefore(dateStart) && endStartDiff !== 0) {
              return {
                earliest: dateStart.format(TimeFormat.isoDateTime),
                message: '^End date must be on or after the start date.',
              };
            }
          }
          return true;
        },
        /*
         * Campaigns can run indefinitely to use up that free Google money
         * allowEmpty: true was not working and falling back to some invisible validation
         */
        presence: undefined,
      },
    };
  }

  public getDefaults(): Partial<IGoogleAdsSmartCampaign> {
    return {
      ...super.getDefaults(),
      _cls: CampaignClass.GoogleAdsSmart,
    };
  }

  public async suggest<T extends TGoogleAdsSuggestions>({
    type = 'ad',
    businessName,
    destinationUrl,
    keywordThemes,
  }: IGoogleAdsSuggestProps): Promise<T> {
    this.assertCollection(this.collection);

    const variant = `google.suggest.${type}` as const;
    const url = this.collection.url(variant, this.id);

    const response = await wretch<T>(url, {
      method: 'POST',
      body: JSON.stringify({
        business_name: businessName,
        destination_url: destinationUrl,
        keyword_themes: keywordThemes,
      }),
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public async getLocations(searchTerm: string): Promise<IGoogleAdsLocationSuggestion[]> {
    this.assertCollection(this.collection);

    const url = this.collection.url('google.location', searchTerm);

    const response = await wretch<IGoogleAdsLocationSuggestion[]>(url, {
      method: 'GET',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public get totalViews(): number {
    const { num_views: numViews = 0 } = this.get('total_stats') || {};
    return numViews;
  }

  public get totalClicks(): number {
    const { num_clicks: numClicks = 0 } = this.get('total_stats') || {};
    return numClicks;
  }
}
