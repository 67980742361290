import { useAccount, useFlags } from '@feathr/extender/state';

/** Check to see if a customer has access to drip campaigns */
function useDripPermissions(): boolean {
  const { showDripCampaigns = false } = useFlags();
  const account = useAccount();

  return showDripCampaigns || account.isAnyFeathrPlatform;
}

export default useDripPermissions;
