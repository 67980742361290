// Constants
const MIN_LENGTH_FOR_NSN = 2;
const MAX_LENGTH_FOR_NSN = 17;
const VALID_DIGITS = '0-9\uFF10-\uFF19\u0660-\u0669\u06F0-\u06F9';
const DASHES = '-\u2010-\u2015\u2212\u30FC\uFF0D';
const SLASHES = '\uFF0F/';
const DOTS = '\uFF0E.';
const WHITESPACE = ' \u00A0\u00AD\u200B\u2060\u3000';
const BRACKETS = '()\uFF08\uFF09\uFF3B\uFF3D\\[\\]';
const TILDES = '~\u2053\u223C\uFF5E';
const VALID_PUNCTUATION = `${DASHES}${SLASHES}${DOTS}${WHITESPACE}${BRACKETS}${TILDES}`;
const PLUS_CHARS = '+\uFF0B';

export interface IPhoneConstraint {
  message?: string;
}

const PHONE_NUMBER_PATTERN = new RegExp(`^[${PLUS_CHARS}]?[${VALID_DIGITS}${VALID_PUNCTUATION}]+$`);

function isViablePhoneNumber(number: string): boolean {
  if (!PHONE_NUMBER_PATTERN.test(number)) {
    return false;
  }

  const digitsOnly = number.replace(new RegExp(`[^${VALID_DIGITS}]`, 'g'), '');
  return digitsOnly.length >= MIN_LENGTH_FOR_NSN && digitsOnly.length <= MAX_LENGTH_FOR_NSN;
}

function phone(value: string, options: IPhoneConstraint = {}): string | undefined {
  if (!value) {
    return undefined;
  }

  if (isViablePhoneNumber(value)) {
    return undefined;
  }
  return options.message ? options.message : `'${value}' is not a valid phone number`;
}

export default phone;
