import { faRefresh } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Domain } from '@feathr/blackbox';
import {
  domainStatusToColorMap,
  domainStatusToLabelMap,
  EPinpointRequestStatus,
} from '@feathr/blackbox';
import {
  Button,
  Chip,
  EMouseLeaveDelay,
  Fieldset,
  Form,
  Spinner,
  toast,
  Tooltip,
  Value,
} from '@feathr/components';
import { useFlags } from '@feathr/extender/state';

import * as styles from './DomainDetails.css';

interface IProps {
  domain: Domain;
}

async function poll(domain: Domain, setTimeout: (timeout: number) => void): Promise<void> {
  await domain.reload();
  if (!domain.isErrored) {
    setTimeout(window.setTimeout(poll, 1000, domain, setTimeout));
  }
}

function DomainDetails({ domain }: IProps): JSX.Element {
  const flags = useFlags();
  const { t } = useTranslation();
  const [timeout, setTimeout] = useState<number | undefined>();

  useEffect(() => {
    if (timeout === undefined) {
      poll(domain, setTimeout);
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [domain, timeout, setTimeout]);

  if (domain.isPending) {
    return <Spinner />;
  }

  const contentStatus = domain.contentDomainStatus;
  const emailStatus = domain.emailSendingStatus(flags.bypassMX);

  async function handleClickRefresh(): Promise<void> {
    try {
      await domain.refresh();
      toast(t('Your email domain verification process has been restarted.'), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(
        t('There was an error refreshing your domain. Please contact support.', {
          type: ToastType.ERROR,
        }),
      );
    }
  }

  const emailTooltipMap = {
    Success: t('This domain, and any sender from it, are ready to be used in email campaigns.'),
    Failed: (
      <Trans t={t}>
        Add CNAME and mail records for this domain to your DNS host and then{' '}
        <Button onClick={handleClickRefresh} type={'link'}>
          refresh it
        </Button>
        .
      </Trans>
    ),
    'N/A': t("This domain isn't setup to be used for email."),
  };

  return (
    <Form label={t('Domain information')}>
      <Fieldset direction={'column'}>
        <Value
          className={styles.status}
          label={t('Content serving status')}
          value={
            <Chip
              isLoading={domain.isPending}
              theme={domainStatusToColorMap[contentStatus]}
              tooltip={
                domain.isContentServingReady
                  ? t(
                      'This domain can be used to serve pages, invite pages, and partner dashboards.',
                    )
                  : t(
                      'Add CNAME records for this domain to your DNS host to use it for serving content.',
                    )
              }
            >
              {domainStatusToLabelMap[contentStatus]}
            </Chip>
          }
        />
        <Value
          className={styles.status}
          label={t('Email sending status')}
          value={
            <div className={styles.emailStatusContainer}>
              <Chip
                isLoading={domain.isPending}
                mouseLeaveDelay={EMouseLeaveDelay.Link}
                theme={domainStatusToColorMap[emailStatus]}
                tooltip={emailTooltipMap[emailStatus]}
              >
                {domainStatusToLabelMap[emailStatus]}
              </Chip>
              {emailStatus === EPinpointRequestStatus.Failed && (
                <Tooltip
                  title={t(
                    'Restart the email domain verification process. This may take up to 24 hours to complete.',
                  )}
                >
                  <Button className={styles.refresh} onClick={handleClickRefresh} type={'link'}>
                    {t('Refresh')} <FontAwesomeIcon icon={faRefresh} size={'lg'} />
                  </Button>
                </Tooltip>
              )}
            </div>
          }
        />
        <Value
          className={styles.status}
          label={t('DKIM status')}
          value={
            <Chip
              isLoading={domain.isPending}
              theme={domainStatusToColorMap[domain.get('dkim')?.status || 'N/A']}
            >
              {domainStatusToLabelMap[domain.get('dkim')?.status || 'N/A']}
            </Chip>
          }
        />
        <Value
          className={styles.status}
          label={t('MX status')}
          value={
            <Chip
              isLoading={domain.isPending}
              theme={domainStatusToColorMap[domain.get('mx')?.status || 'N/A']}
            >
              {domainStatusToLabelMap[domain.get('mx')?.status || 'N/A']}
            </Chip>
          }
        />
      </Fieldset>
    </Form>
  );
}

export default observer(DomainDetails);
