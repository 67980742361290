import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import type {
  Account,
  Billable,
  Campaign,
  DisplayCreative,
  Event,
  Goal,
  Segment,
  Targetable,
  Targeting,
  User,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { Spinner, Step, Steps, Wizard } from '@feathr/components';
import { StoresContext, useAccount, useFlags, useUser } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { ListResponse, Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary/CampaignSummary';
import SaveCampaignButton from '../SaveCampaignButton/index';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import { validateStepValue } from '../StepValue';
import createCampaignStore, { CampaignContext } from './campaignEditContext';
import CampaignEditStepFive, { validateStepFive } from './CampaignEditStepFive';
import CampaignEditStepFour, { validateStepFour } from './CampaignEditStepFour';
import CampaignEditStepOne, { validateStepOne } from './CampaignEditStepOne';
import CampaignEditStepSeven from './CampaignEditStepSeven';
import CampaignEditStepThree, { getGeoFilters, validateStepThree } from './CampaignEditStepThree';
import CampaignEditStepTwo, {
  getTargetables,
  getTargetSegments,
  validateStepTwo,
} from './CampaignEditStepTwo';

interface IProps {
  campaign: Campaign;
}

export interface ICampaignDetails {
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
  targetingSegments?: Segment[];
  targetables?: Targetable[];
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  creatives: IObservableArray<DisplayCreative>;
  billable?: Billable;
  account: Account;
  event: Event;
  monetizationValue?: number;
}

function validate(
  details: ICampaignDetails,
  user?: User,
  noMaxBudget?: boolean,
): ICampaignValidationErrors {
  const targetables =
    details.campaign.get('cls') === CampaignClass.Segment
      ? details.targetingSegments
      : details.targetables;
  const one = validateStepOne(details.campaign);
  const two = flattenErrors(validateStepTwo(details.campaign, details.targetings, targetables));
  const three = validateStepThree(details.targetings);
  const four = validateStepFour(details.campaign, details.creatives);
  const five = validateStepFive(
    details.campaign,
    details.event,
    details.billable,
    details.account,
    user,
    noMaxBudget,
  );
  const six = validateStepGoals(details.goals, details.goalSegments);
  const seven = details.campaign.isMonetization ? validateStepValue(details.campaign) : undefined;

  const validationErrors: ICampaignValidationErrors = {
    name: one,
    targets: two,
    filters: three,
    creatives: four,
    duration: [...(five.date_end ?? []), ...(five.date_end ?? [])],
    budget: five.budget,
    goals: six.goals,
  };

  if (details.campaign.isMonetization) {
    // Only pinpoint monetization campaigns use goals OR monetization value, all other campaigns can have both.
    validationErrors['monetization_value'] = seven?.monetization_value ?? [];
  }
  return validationErrors;
}

const getCompletedStep = (
  {
    account,
    campaign,
    creatives,
    billable,
    event,
    goals,
    goalSegments,
    targetings,
    targetingSegments,
    targetables,
  }: ICampaignDetails,
  hideFilters: boolean,
  user?: User,
  noMaxBudget?: boolean,
): number => {
  if (validateStepOne(campaign).length) {
    return 0;
  }
  const stepTwoArgThree =
    campaign.get('_cls') === CampaignClass.Segment ? targetingSegments : targetables;
  if (
    targetings.length === 0 ||
    flattenErrors(validateStepTwo(campaign, targetings, stepTwoArgThree)).length
  ) {
    return 1;
  }
  if (!hideFilters && validateStepThree(targetings).length) {
    return 2;
  }
  if (validateStepFour(campaign, creatives).length) {
    return 3;
  }
  if (
    flattenErrors(validateStepFive(campaign, event, billable, account, user, noMaxBudget)).length
  ) {
    return 4;
  }
  if (campaign.isMonetization && flattenErrors(validateStepValue(campaign)).length) {
    return 5;
  } else if (flattenErrors(validateStepGoals(goals, goalSegments)).length) {
    return 5;
  }
  return 6;
};

function CampaignEdit({ campaign }: IProps): JSX.Element {
  const { Creatives, Billables, Events, Goals, Segments, Targetings, Targetables } =
    React.useContext(StoresContext);
  const account = useAccount();
  const location = useLocation();
  const flags = useFlags();
  const user = useUser();

  const foundStep = /#step(\d+)$/.exec(location.hash);
  const defaultStep = foundStep ? +foundStep[1] - 1 : undefined;

  const [currentStep, setCurrentStep] = useState(defaultStep ?? 0);
  const [completedStep, setCompletedStep] = useState<number | undefined>(defaultStep);
  const [didCompleteStep, setDidCompleteStep] = useState<boolean>(false);

  const event = Events.get(campaign.get('event'));
  const billing = event.get('billing');
  const billable =
    !event.isPending && billing && billing.billable_id
      ? Billables.get(billing.billable_id)
      : undefined;

  const targetings = Targetings.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
    pagination: { page_size: 1000 },
  });
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  // Campaigns only have DisplayCreatives.
  const creatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  }) as ListResponse<DisplayCreative>;
  const hideFilters =
    campaign.get('_cls') === CampaignClass.MobileGeoFencing ||
    campaign.get('_cls') === CampaignClass.MobileGeoFenceRetargeting;

  const doCompleteStep = (): void => {
    if (
      !event.isPending &&
      !targetings.isPending &&
      !goals.isPending &&
      !creatives.isPending &&
      !didCompleteStep
    ) {
      const goalSegments = getGoalSegments(goals.models, Segments);
      const targetingSegments = getTargetSegments(targetings.models, Segments);
      const targetables = getTargetables(targetings.models, Targetables);
      Promise.all([
        ...goalSegments.map((s) => when(() => !s.isPending)),
        ...targetingSegments.map((s) => when(() => !s.isPending)),
        ...targetables.map((t) => when(() => !t.isPending)),
      ]).then(() => {
        const step = getCompletedStep(
          {
            campaign,
            targetings: targetings.models,
            targetingSegments: getTargetSegments(targetings.models, Segments),
            targetables: getTargetables(targetings.models, Targetables),
            goals: goals.models,
            goalSegments: getGoalSegments(goals.models, Segments),
            creatives: creatives.models,
            billable,
            account,
            event,
          },
          hideFilters,
          user,
          flags.noMaxBudget,
        );
        setCurrentStep(defaultStep || step);
        setCompletedStep(step);
        setDidCompleteStep(true);
      });
    }
  };
  useEffect(doCompleteStep, [
    account,
    billable,
    billable?.isPending,
    campaign,
    creatives.isPending,
    creatives.models,
    defaultStep,
    didCompleteStep,
    event,
    event.isPending,
    flags.noMaxBudget,
    goals.isPending,
    goals.models,
    hideFilters,
    Segments,
    Targetables,
    targetings.isPending,
    targetings.models,
    user,
  ]);

  function onNext(): void {
    const nextStep = currentStep + (hideFilters && currentStep === 1 ? 2 : 1);
    setCurrentStep(nextStep);
    if (nextStep > (completedStep ?? 0)) {
      setCompletedStep(nextStep);
    }
  }

  function onPrev(): void {
    const prevStep = currentStep - (hideFilters && currentStep === 3 ? 2 : 1);
    setCurrentStep(prevStep);
  }

  if (
    event.isPending ||
    targetings.isPending ||
    goals.isPending ||
    creatives.isPending ||
    completedStep === undefined
  ) {
    return <Spinner />;
  }

  const stepsItems = [
    <Step key={0} stepIndex={0} title={'Name'} />,
    <Step key={1} stepIndex={1} title={'Targets'} />,
    <Step key={2} stepIndex={2} title={'Filters (optional)'} />,
    <Step key={3} stepIndex={3} title={'Creatives'} />,
    <Step key={4} stepIndex={4} title={'Budget'} />,
    <Step key={5} stepIndex={5} title={'Conversion Tracking'} />,
    <Step key={6} stepIndex={6} title={'Review'} />,
  ];
  if (hideFilters) {
    // Remove filters.
    stepsItems.splice(2, 1);
  }

  const steps = (
    <Steps completed={completedStep} current={currentStep} onChange={setCurrentStep}>
      {stepsItems}
    </Steps>
  );

  const grandchildModels: Model[] = [
    ...getTargetSegments(targetings.models, Segments),
    ...getGoalSegments(goals.models, Segments),
    ...getGeoFilters(targetings.models, Targetables),
  ];
  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [
    ...getTargetables(targetings.models, Targetables),
    ...targetings.models.slice(),
    ...creatives.models.slice(),
    ...goals.models.slice(),
  ];

  function buttonValidate(): ICampaignValidationErrors {
    const targetingSegments = getTargetSegments(targetings.models, Segments);
    const targetables = getTargetables(targetings.models, Targetables);
    const goalSegments = getGoalSegments(goals.models, Segments);
    return validate(
      {
        account,
        billable,
        campaign,
        creatives: creatives.models,
        event,
        goals: goals.models,
        goalSegments,
        targetables,
        targetings: targetings.models,
        targetingSegments,
      },
      user,
      flags.noMaxBudget,
    );
  }

  const actions = [
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      grandchildModels={grandchildModels}
      key={'save'}
      shouldChangeState={false}
      validate={
        [CampaignState.Published, CampaignState.Publishing].includes(campaign.get('state'))
          ? buttonValidate
          : undefined
      }
    />,
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      disabled={completedStep < 5 || undefined}
      // Disabled publish if not all steps are completed.
      grandchildModels={grandchildModels}
      key={'changeState'}
      shouldChangeState={true}
      validate={buttonValidate}
    />,
  ];

  const campaignStore = createCampaignStore({ targetings });

  return (
    <CampaignContext.Provider value={campaignStore}>
      <div data-appcues-campaign={campaign.get('_cls')}>
        <Wizard actions={actions} layout={'vertical'} steps={steps}>
          {currentStep === 0 && <CampaignEditStepOne campaign={campaign} onNext={onNext} />}
          {currentStep === 1 && (
            <CampaignEditStepTwo
              campaign={campaign}
              onNext={onNext}
              onPrev={onPrev}
              targetings={targetings.models}
            />
          )}
          {currentStep === 2 && (
            <CampaignEditStepThree
              campaign={campaign}
              onNext={onNext}
              onPrev={onPrev}
              targetings={targetings.models}
            />
          )}
          {currentStep === 3 && (
            <CampaignEditStepFour
              campaign={campaign}
              creatives={creatives.models}
              onNext={onNext}
              onPrev={onPrev}
            />
          )}
          {currentStep === 4 && (
            <CampaignEditStepFive
              account={account}
              billable={billable}
              campaign={campaign}
              event={event}
              onNext={onNext}
              onPrev={onPrev}
              targetings={targetings.models}
            />
          )}
          {currentStep === 5 && (
            <StepGoals campaign={campaign} goals={goals.models} onNext={onNext} onPrev={onPrev} />
          )}
          {currentStep === 6 && (
            <CampaignEditStepSeven
              account={account}
              billable={billable}
              campaign={campaign}
              creatives={creatives.models}
              event={event}
              goals={goals.models}
              onPrev={onPrev}
              targets={targetings.models}
              validate={validate}
            />
          )}
        </Wizard>
      </div>
    </CampaignContext.Provider>
  );
}

export default observer(CampaignEdit);
