import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export interface IRedirectDomain extends IBaseAttributes {
  readonly id: string;
  domain: string;
  is_verified: boolean;
  is_secure: boolean;
}

export class RedirectDomain extends Model<IRedirectDomain> {
  public readonly className = 'RedirectDomain';

  public get constraints(): TConstraints<IRedirectDomain> {
    return {
      domain: {
        presence: { allowEmpty: false, message: '^Domain cannot be blank' },
        format: {
          pattern: /([a-z0-9-]+\.)+[a-z]{2,}/,
          flags: 'i',
          message: '^Please enter a valid domain name.',
        },
      },
    };
  }
}

export class RedirectDomains extends Collection<RedirectDomain> {
  public getModel(attributes = {}): RedirectDomain {
    return new RedirectDomain(attributes);
  }

  public getClassName(): string {
    return 'redirect_domains';
  }
}
