import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign } from '@feathr/blackbox';
import { CardV2 as Card, Input, Layout } from '@feathr/components';
import ConsentCapture from '@feathr/extender/components/ConsentCapture';
import { useCampaign } from '@feathr/extender/hooks';
import { useUser } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import EmailCampaignSendWindow from '../../EmailCampaignSendWindow';
import { validateDetails } from '../DripCampaignEdit.utils';

interface IDetailsStepProps {
  campaign: DripCampaign;
}

function DetailsStep({ campaign }: Readonly<IDetailsStepProps>): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const validationErrors = validateDetails(campaign);
  const { disabled } = useCampaign({ campaign });

  const triggerStep =
    campaign.get('step_specs').length > 0 ? campaign.get('step_specs')[0] : undefined;
  const timeToSend = triggerStep?.time_to_send;
  const isTimeSubtype = triggerStep?.subtype === 'time';

  function handleChangeConsent(newValue = false): void {
    if (newValue) {
      campaign.set({
        consent: {
          user: user!.id,
          has_consent: true,
          date_consented: moment.utc().format(TimeFormat.isoDateTime),
        },
      });
    } else {
      campaign.set({
        consent: {
          has_consent: false,
        },
      });
    }
  }

  return (
    <Layout>
      <>
        <Card width={'full'}>
          <Card.Header
            description={t(
              'People who meet the triggers of this campaign during the specified time frame will receive your message. The maximum time window is one year.',
            )}
            title={t('Send Window')}
          />
          <Card.Content addVerticalGap={true}>
            <EmailCampaignSendWindow
              campaign={campaign}
              isDisabled={disabled.edit}
              isTimeSubtype={isTimeSubtype}
              timeToSend={timeToSend}
              validationErrors={validationErrors}
            />
          </Card.Content>
        </Card>

        <Card width={'full'}>
          <Card.Header title={t('Information')} />
          <Card.Content>
            <Input
              attribute={'name'}
              disabled={disabled.edit}
              label={t('Campaign name')}
              model={campaign}
              required={true}
              type={'text'}
            />
          </Card.Content>
        </Card>
        <Card width={'full'}>
          <Card.Header title={t('Verify & Publish')} />
          <Card.Content>
            <ConsentCapture
              alertDescription={t(
                'I understand that if my campaigns exhibit abnormally high bounce or complaint rates, it may result in my account being placed under temporary review or suspension.',
              )}
              alertTitle={t(
                'I attest that I am sending this email to recipients who have signed up and specifically requested to receive email about this topic from me or my organization.',
              )}
              disabled={disabled.edit}
              label={t(
                'Please type "AGREE" in the box below to verify the above statement (case-sensitive).',
              )}
              onChange={handleChangeConsent}
              value={campaign.get('consent', { has_consent: false }).has_consent}
            />
          </Card.Content>
        </Card>
      </>
    </Layout>
  );
}

export default observer(DetailsStep);
