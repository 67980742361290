import type { WithT } from 'i18next';
import { useTranslation } from 'react-i18next';

import { CampaignClass } from '@feathr/blackbox';

interface IUseCampaignDescriptionMap extends WithT {
  campaignDescriptionMap: Map<CampaignClass, string>;
}

function useCampaignDescriptionMap(): IUseCampaignDescriptionMap {
  const { t } = useTranslation();

  const campaignDescriptionMap = new Map<CampaignClass, string>([
    [
      CampaignClass.Segment,
      t('Retarget specific groups of your existing audience with ads across the web'),
    ],
    [
      CampaignClass.EmailList,
      t('Match a list of emails to cookies that you can then target with ads'),
    ],
    [
      CampaignClass.Search,
      t(
        'Build and target an audience of people who have searched for terms related to what your organization provides',
      ),
    ],
    [
      CampaignClass.MobileGeoFencing,
      t('Target mobile devices at a specific location during a specific time'),
    ],
    [
      CampaignClass.MobileGeoFenceRetargeting,
      t('Target mobile devices that were at a specific location some time in the past'),
    ],
    [
      CampaignClass.Lookalike,
      t(
        'Based on one of your existing groups, we will expand your audience with people who are similar',
      ),
    ],
    [
      CampaignClass.Affinity,
      t(
        'Target an affinity audience based upon our library of available industry, demographic, and market-related audiences.',
      ),
    ],
    [
      CampaignClass.SeedSegment,
      t(
        'Based on one of your existing groups, we will expand your audience with people who are similar',
      ),
    ],
    [CampaignClass.Referral, t('Distribute your Referral Marketing collateral to your Partners')],
    [
      CampaignClass.LandingPage,
      t(
        'Create a conversion-optimized landing page to use as a destination for someone interacting with your ads',
      ),
    ],
    [
      CampaignClass.Conversation,
      t(
        'Launch a website survey that helps you learn about your visitors and create hyper-focused groups',
      ),
    ],
    [CampaignClass.Facebook, t('Retarget existing audiences with ads on Facebook and Instagram.')],
    [
      CampaignClass.EmailListFacebook,
      t(
        'Reach a specific audience on Facebook and Instagram by targeting ads to a list of email addresses.',
      ),
    ],
    [
      CampaignClass.TrackedLink,
      t(
        'Track in Feathr the impact of your other marketing efforts by creating and distributing tracked links.',
      ),
    ],
    [
      CampaignClass.Drip,
      t(
        'Send a series of scheduled email messages to a targeted group of your audience over a set period of time.',
      ),
    ],
    [
      CampaignClass.PinpointEmail,
      t('Send a single, scheduled email message to a targeted group of your audience.'),
    ],
    [
      CampaignClass.SmartPinpointEmail,
      t(
        'Send an email to a target group as a response to a target behavior or at a target send time.',
      ),
    ],
    [
      CampaignClass.AutoPinpointEmail,
      t('Send an email to people who perform a trigger of your choice.'),
    ],
    [
      CampaignClass.GoogleAdsSmart,
      t(
        'Attract donors, raise awareness for your organization, and recruit volunteers with advertising on Google Search.',
      ),
    ],
  ]);

  return { campaignDescriptionMap, t };
}

export default useCampaignDescriptionMap;
