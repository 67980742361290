import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { TFunction } from 'i18next';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';
import type { Option } from 'react-select/src/filters';

import type {
  IPredicate,
  TComparison,
  TPinpointSubtype,
  TPredicateKind,
  User,
} from '@feathr/blackbox';
import { CampaignClass, FieldDataType } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { DatePicker, Input, NumberInput, Popover, Select, Spinner } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { fieldDataTypeIcon } from '@feathr/extender/styles/fieldDataType';
import { cssVar, moment, TimeFormat } from '@feathr/hooks';

import type { ICollectionOption } from '../PredicateIdValueSelect';
import PredicateIdValueSelect from '../PredicateIdValueSelect';
import PredicateListValueSelect from '../PredicateListValueSelect';
import type { IRelativeDateOptions } from '../Predicates/utils';
import { getDateComparisonOptionsMap, getRelativeDateOptionsMap } from '../Predicates/utils';
import PredicateTextValueInput from '../PredicateTextValueInput';

import styles from './PredicateInputs.css';

// delete this and its css in #3595
interface IProps {
  attrOptionsIsLoading: boolean;
  breadcrumbAttrOptions: IAttrOption[];
  disabled?: boolean;
  group?: true;
  kind?: TPredicateKind;
  personAttrOptions?: IAttrOption[];
  predicate: IPredicate;
  filterContext:
    | 'campaign'
    | 'flight'
    | 'segment'
    | 'campaignGoal'
    | 'flightGoal'
    | 'facebookCampaign'
    | 'pinpoint';
  excludeIds?: string[];
  triggers?: IPredicate[];
  mode?: 'match_all' | 'match_any';
  subtype?: TPinpointSubtype;
}

export interface IAttrOption {
  helpText: string;
  id: string;
  kind: 'Person' | 'Breadcrumb';
  name: string;
  type: FieldDataType;
}

interface IComparisonOption {
  id: TComparison;
  name: string;
}

function toPredicateAttrType(
  fieldType: FieldDataType,
): 'string' | 'boolean' | 'list' | 'date' | 'integer' | 'float' {
  switch (fieldType) {
    case FieldDataType.str:
      return 'string';

    case FieldDataType.int:
      return 'integer';

    case FieldDataType.bool:
      return 'boolean';

    default:
      return fieldType;
  }
}

function toPredicateKind(
  fieldKind: 'Person' | 'Breadcrumb',
  filterContext: string,
): 'attribute' | 'activity' | 'update' {
  if (fieldKind === 'Person') {
    return filterContext === 'pinpoint' ? 'update' : 'attribute';
  }
  return 'activity';
}

function FieldOption(props: OptionProps<IAttrOption>): JSX.Element {
  const { name, type } = props.data;
  const icon = fieldDataTypeIcon(type);

  return (
    <components.Option {...props}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>{icon && <FontAwesomeIcon icon={icon} />}</div>
        <div className={styles.nameContainer}>
          <span>{name}</span>
        </div>
      </div>
    </components.Option>
  );
}

function ComparisonOption(props: any): JSX.Element {
  return (
    <components.Option {...props}>
      <span className={styles.comparison}>{props.data.name}</span>
    </components.Option>
  );
}

function getComparisons(attr: IAttrOption, t: TFunction): IComparisonOption[] {
  const baseOptions: IComparisonOption[] = [
    { id: 'exists', name: t('exists') },
    { id: 'nexists', name: t('does not exist') },
  ];
  const numberOptions: IComparisonOption[] = [
    ...baseOptions,
    { id: 'eq', name: t('equals') },
    { id: 'ne', name: t('does not equal') },
    { id: 'gt', name: t('is greater than') },
    { id: 'lt', name: t('is less than') },
  ];
  switch (attr.type) {
    case FieldDataType.str:
      if (attr.id.includes('_id')) {
        return [...baseOptions, { id: 'eq', name: t('is') }, { id: 'ne', name: t('is not') }];
      }
      return [
        ...baseOptions,
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
        { id: 'starts_with', name: t('starts with') },
        { id: 'wildcard', name: t('matches pattern') },
        { id: 'regexp', name: t('matches the expression') },
        { id: 'contains_substring', name: t('contains') },
      ];

    case FieldDataType.int:
      return numberOptions;

    case FieldDataType.float:
      return numberOptions;

    case FieldDataType.bool:
      return [
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
      ];

    case FieldDataType.date:
      return [
        ...baseOptions,
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
        { id: 'gt', name: t('is after') },
        { id: 'lt', name: t('is before') },
      ];

    case FieldDataType.list:
      return [
        ...baseOptions,
        { id: 'in', name: t('contains') },
        { id: 'nin', name: t('does not contain') },
      ];

    default:
      return baseOptions;
  }
}

function shouldShowValueInput(predicate: IPredicate): boolean {
  if (!predicate.comparison || ['exists', 'nexists'].includes(predicate.comparison)) {
    return false;
  }
  return true;
}

function getValueInput(
  predicate: IPredicate,
  excludeIds: string[],
  disabled: boolean,
  t: TFunction,
  filterContext: string,
  selectedRelativeTimeOptionId: string,
  setSelectedRelativeTimeOptionId: (id: string) => void,
  relativeDateOptionsMap: IRelativeDateOptions,
  user: User,
): JSX.Element | null {
  const userTimezone = user.get('timezone');

  function getPath(): string {
    const collectionMap = {
      activity: 'breadcrumbs',
      attribute: 'persons',
      update: 'persons',
    };
    return `${collectionMap[predicate.kind || 'activity']}/${predicate.attr_against}/values`;
  }

  function onSelect(selected: ICollectionOption | ICollectionOption[] | null): void {
    if (Array.isArray(selected)) {
      predicate.value = selected.map((option) => option.id!);
    } else if (selected) {
      predicate.value = selected.id;
    } else {
      predicate.value = undefined;
    }
  }

  function onChange(value: string | string[]): void {
    runInAction(() => {
      predicate.value = value;
    });
  }

  function onDateStrChange(value: string | undefined): void {
    runInAction(() => {
      predicate.value = value;
    });
  }

  function onChangeBoolean(value): void {
    runInAction(() => {
      predicate.value = value.id;
    });
  }

  function handleChangeString(newValue?: string): void {
    onChange(newValue ?? '');
  }

  function handleChangeNumber(newValue?: number): void {
    onChange(newValue?.toString() ?? '');
  }

  const collectionSelectOptions = {
    key: predicate.comparison,
    onSelect,
    naked: true,
    placeholder: t('missing value'),
  };

  // Construct allowed classes for 'campaign is' dropdown
  function getAllowedClasses(filterContext: string): CampaignClass[] {
    const allowedClasses: CampaignClass[] = [
      CampaignClass.Conversation,
      CampaignClass.LandingPage,
      CampaignClass.EmailList,
      CampaignClass.Lookalike,
      CampaignClass.SeedSegment,
      CampaignClass.Affinity,
      CampaignClass.Segment,
      CampaignClass.Search,
      CampaignClass.MobileGeoFencing,
      CampaignClass.MobileGeoFenceRetargeting,
      CampaignClass.Facebook,
      CampaignClass.TrackedLink,
      CampaignClass.EmailListFacebook,
      CampaignClass.Referral,
      CampaignClass.PinpointEmail,
      CampaignClass.SmartPinpointEmail,
    ];

    /**
     * Only allow autosend in dropdown for group builder and the targets
     * step of the campaign wizard. We want to exclude autosend campaigns
     * from being used in the trigger condition of other autosends.
     */
    if (['segment', 'campaign'].includes(filterContext)) {
      allowedClasses.push(CampaignClass.AutoPinpointEmail);
    }

    return allowedClasses;
  }

  // Construct optional filters for 'campaign is' dropdown
  function getOptionalFilters(excludeIds: string[]): Record<string, string[]> {
    return excludeIds.length ? { id__nin: excludeIds } : {};
  }

  const relativeDateOptions: ISelectOption[] = Object.values(relativeDateOptionsMap);

  function handleChangeDateNumberValue(
    value?: number,
    relativeTimeOption: string | undefined = undefined,
  ): void {
    if (!value) {
      return;
    }

    runInAction(() => {
      // If days ago is the selected option and the the user has not changed the dropdown
      const isDaysAgo = selectedRelativeTimeOptionId === '-' && relativeTimeOption === undefined;

      /*
       * Check that if the user has selected a different relative time option because we aren't
       * getting the updated value of `selectedRelativeTimeOptionId` right away, probably because
       * `getValueInput` is outside of the component lifecycle.
       */
      const hasSelectedDaysAgo = relativeTimeOption === '-';

      // Use absolute value to convert the number to positive so we can negate it appropriately
      const formattedValue = isDaysAgo || hasSelectedDaysAgo ? -Math.abs(value) : Math.abs(value);

      predicate.value = formattedValue;
    });
  }

  function handleRelativeTimeSelect({ id }: ISelectOption): void {
    const option = relativeDateOptions.find((option) => option.id === id);

    if (!option) {
      return;
    }

    setSelectedRelativeTimeOptionId(option.id);

    // Negate the value if the dropdown has changed
    handleChangeDateNumberValue(predicate.value as number, option.id);
  }

  switch (predicate.attr_against) {
    case 'e_id':
      return (
        <PredicateIdValueSelect
          collection={'Events'}
          disabled={disabled}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'cpn_id':
      return (
        <PredicateIdValueSelect
          collection={'Campaigns'}
          disabled={disabled}
          filters={{
            _cls__in: getAllowedClasses(filterContext),
            ...getOptionalFilters(excludeIds),
          }}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'pp_opt_outs.events':
      return (
        <PredicateIdValueSelect
          collection={'Events'}
          disabled={disabled}
          multi={true}
          name={'predicate_value'}
          value={predicate.value as string[] & IObservableArray}
          {...collectionSelectOptions}
        />
      );

    case 'pp_opt_outs.campaigns':
      return (
        <PredicateIdValueSelect
          collection={'Campaigns'}
          disabled={disabled}
          filters={{
            _cls__in: [
              CampaignClass.PinpointEmail,
              CampaignClass.SmartPinpointEmail,
              CampaignClass.AutoPinpointEmail,
            ],
          }}
          multi={true}
          name={'predicate_value'}
          value={predicate.value as string[] & IObservableArray}
          {...collectionSelectOptions}
        />
      );

    case 'p_id':
      return (
        <PredicateIdValueSelect
          collection={'Partners'}
          disabled={disabled}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'seg_id':
      return (
        <PredicateIdValueSelect
          collection={'Segments'}
          disabled={disabled}
          filters={{
            context: 'breadcrumb',
            is_conversion_segment: true,
          }}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'tag_id':
      return (
        <PredicateIdValueSelect
          collection={'Tags'}
          disabled={disabled}
          filters={{
            context: 'breadcrumb',
          }}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'tag_ids':
      return (
        <PredicateIdValueSelect
          collection={'Tags'}
          disabled={disabled}
          filters={{
            context: 'person',
          }}
          multi={true}
          name={'predicate_value'}
          value={predicate.value as IObservableArray<string> | undefined}
          {...collectionSelectOptions}
        />
      );

    default:
      switch (predicate.attr_type) {
        case 'string':
          return (
            <PredicateTextValueInput
              disabled={disabled}
              getPath={getPath}
              key={predicate.comparison}
              name={'predicate_value'}
              onChange={onChange}
              placeholder={t('missing value')}
              value={predicate.value as string}
            />
          );

        case 'list':
          return (
            <PredicateListValueSelect
              disabled={disabled}
              getPath={getPath}
              key={predicate.comparison}
              name={'predicate_value'}
              onChange={onChange}
              placeholder={t('missing value')}
              value={predicate.value as string[]}
            />
          );

        case 'date': {
          // Relative dates include `_r` in the comparison: eq_date_r, ne_date_r, gt_date_r, lt_date_r
          const isRelativeComparison = predicate.comparison?.includes('_r');
          // Exact date and time does not include `_` in the comparison: eq, ne, gt, lt
          const isExactDateAndTimeComparison = !predicate.comparison?.includes('_');
          const isTodaySelected = isRelativeComparison && predicate?.value === 0;

          // Don't show the date input today is selected
          if (isTodaySelected) {
            return null;
          }

          if (isRelativeComparison && predicate?.value !== 0) {
            const count = predicate.value !== undefined ? +predicate.value : undefined;
            const positiveCount = Math.abs(count ?? 0);
            const selectedOption = relativeDateOptions.find(
              (option) => option.id === selectedRelativeTimeOptionId,
            );

            return (
              <div className={styles.container}>
                <NumberInput
                  className={styles.numberInput}
                  disabled={disabled}
                  key={predicate.comparison}
                  name={'predicate_value'}
                  onChange={handleChangeDateNumberValue}
                  placeholder={t('Enter a value')}
                  value={positiveCount}
                  wrapperClassName={styles.numberInput}
                />
                <Select
                  className={classNames(styles.select, styles.time)}
                  disabled={disabled}
                  name={'predicate_value_relative'}
                  onChange={handleRelativeTimeSelect}
                  options={relativeDateOptions}
                  value={selectedOption}
                />
              </div>
            );
          }

          const selectedMoment = predicate.value
            ? moment.utc(predicate.value as string)
            : moment.utc();

          return (
            <DatePicker
              autoComplete={'off'}
              dateFormat={isExactDateAndTimeComparison ? 'MMMM d, yyyy h:mm aa' : 'MMMM d, yyyy'}
              disabled={isTodaySelected || disabled}
              // Prevent adding a min/max time since we can freely pick any date /time
              maxTime={undefined}
              minTime={undefined}
              onDateStrChange={onDateStrChange}
              placeholder={t('missing value')}
              showCalendarIcon={true}
              showTimeSelect={isExactDateAndTimeComparison}
              timeIntervals={5}
              timezone={userTimezone}
              value={selectedMoment.format(TimeFormat.isoDateTime)}
            />
          );
        }

        case 'integer':

        case 'float':
          return (
            <NumberInput
              disabled={disabled}
              key={predicate.comparison}
              onChange={handleChangeNumber}
              value={predicate.value as number | undefined}
              wrapperClassName={styles.numberInput}
            />
          );

        case 'boolean':
          const booleanOptions = [
            { id: true, name: t('True') },
            { id: false, name: t('False') },
          ];
          return (
            <Select
              disabled={disabled}
              key={predicate.comparison}
              name={'predicate_value'}
              onChange={onChangeBoolean}
              options={booleanOptions}
              value={
                predicate.value !== undefined
                  ? booleanOptions.find((v) => predicate.value === v.id)
                  : booleanOptions.find((v) => false === v.id)
              }
            />
          );

        default:
          return (
            <Input
              disabled={disabled}
              key={predicate.comparison}
              onChange={handleChangeString}
              placeholder={t('missing value')}
              type={'text'}
            />
          );
      }
  }
}

function PredicateInputs({
  attrOptionsIsLoading,
  breadcrumbAttrOptions,
  disabled = false,
  filterContext,
  group,
  kind,
  personAttrOptions = [],
  predicate,
  excludeIds = [],
  subtype,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  const dateComparisonOptionsMap = getDateComparisonOptionsMap(t);
  let dateComparisonOptions = Object.values(dateComparisonOptionsMap);

  const [dateTimeSelectOption, setDateTimeSelectOption] = useState<ISelectOption | undefined>(
    dateComparisonOptionsMap.exactDateAndTime,
  );

  const relativeDateOptionsMap = getRelativeDateOptionsMap(t, predicate.value as number);
  const [selectedRelativeTimeOptionId, setSelectedRelativeTimeOptionId] = useState<string>(
    relativeDateOptionsMap.daysFromNow.id,
  );

  const isTimeSubtype = subtype === 'time';

  // Set the proper value for the relative time option on initial render
  useEffect(() => {
    const hasRelativeDateSelection =
      predicate.attr_type === 'date' &&
      typeof predicate?.value === 'number' &&
      predicate?.value < 0;

    if (hasRelativeDateSelection) {
      setSelectedRelativeTimeOptionId(relativeDateOptionsMap.daysAgo.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Map the new predicates to their respective dropdown values
  useEffect(() => {
    if (predicate.comparison?.endsWith('_date_r')) {
      if (predicate.value === 0) {
        setDateTimeSelectOption(dateComparisonOptionsMap.today);
      } else {
        setDateTimeSelectOption(dateComparisonOptionsMap.relativeDate);
      }
    }

    if (predicate.comparison?.endsWith('_date')) {
      setDateTimeSelectOption(dateComparisonOptionsMap.exactDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTimeSelectComparison({ id }: ISelectOption): void {
    runInAction(() => {
      const option = dateComparisonOptions.find((option) => option.id === id);
      setDateTimeSelectOption(option);

      /*
       * Prevent excessive concatenation of comparison when clicking the same option.
       * Grab the first substring before the underscore.
       * ie: `gt_date_r` could turn into `gt_date_r_date` without this check.
       */
      let newComparison = predicate.comparison?.split('_').shift() as TComparison;

      if (id === 'date_time') {
        // Do nothing and use newComparison as is
      } else if (id === 'today') {
        newComparison = `${newComparison}_date_r` as TComparison;
        predicate.value = 0;
      } else {
        // Relative date `_date_r` and exact date `_date`
        newComparison = `${newComparison}${id}` as TComparison;

        // Default to one day selected for relative date to play nicely with today
        if (id === '_date_r') {
          predicate.value = 1;
        }
      }

      /*
       * Reset value to today's date when changing comparison unless it's a relative date.
       * Today is set to zero above.
       */
      if (id !== 'today' && id !== '_date_r') {
        predicate.value = new Date().toISOString();
      }

      predicate.comparison = newComparison;
    });
  }

  function onAttrSelect(attrOption: IAttrOption): void {
    runInAction(() => {
      predicate.attr_against = attrOption.id;
      predicate.attr_type = toPredicateAttrType(attrOption.type);
      predicate.kind = toPredicateKind(attrOption.kind, filterContext);

      /*
       * When the user clicks "date triggered" and chooses a date attribute,
       * select relative date as the default comparison
       */
      if (isTimeSubtype) {
        predicate.comparison = 'eq_date_r';
        predicate.value = 1;
      } else {
        const comparisons = getComparisons(attrOption, t);
        predicate.comparison = comparisons[0].id;
        predicate.value = predicate.attr_type === 'boolean' ? false : undefined;
      }
    });
  }

  function onComparisonSelect(comparisonOption: IComparisonOption): void {
    runInAction(() => {
      predicate.comparison = comparisonOption.id;
      predicate.value = predicate.attr_type === 'boolean' ? false : undefined;

      if (predicate.attr_type === 'date' && predicate.comparison === 'eq') {
        // Reset value to today when changing comparison.
        predicate.value = new Date().toISOString();
        // Reset date time select option to exact date and time
        setDateTimeSelectOption(dateComparisonOptionsMap.exactDateAndTime);
      }
    });
  }

  let optionGroups = [
    { label: t('Activity'), options: breadcrumbAttrOptions },
    { label: t('Person Attributes'), options: personAttrOptions },
  ];

  if (group && kind) {
    if (kind === 'activity') {
      optionGroups = [optionGroups[0]];
    } else if (kind === 'attribute') {
      optionGroups = [optionGroups[1]];
    }
  }

  /*
   * If trigger type is activity happened, only show activity options
   * If trigger type is field changed, only show person attribute options
   * If trigger type is date triggered, only show date options
   */
  if (filterContext === 'pinpoint') {
    if (subtype === 'update') {
      optionGroups = [optionGroups[1]];
    } else if (isTimeSubtype) {
      const dateAttributeTypes = optionGroups[1].options.filter((item) => {
        return item.type === FieldDataType.date;
      });

      // Only display person attribute date triggers for date triggered subtype
      optionGroups[0].options = [];
      optionGroups[1].options = dateAttributeTypes;
    } else {
      // Subtype is activity or undefined
      optionGroups = [optionGroups[0]];
    }
  }

  const attrValue = [...breadcrumbAttrOptions, ...personAttrOptions].find(
    (attrOption: IAttrOption) => {
      const attrMatch = predicate.attr_against === attrOption.id;
      const typeMatch = predicate.attr_type === toPredicateAttrType(attrOption.type);
      const kindMatch = predicate.kind === toPredicateKind(attrOption.kind, filterContext);
      return attrMatch && typeMatch && kindMatch;
    },
  );
  let comparisonOptions: IComparisonOption[] = [];
  let comparisonValue: any;
  if (attrValue) {
    comparisonOptions = getComparisons(attrValue, t);
    predicate.comparison === 'contains' && (predicate.comparison = 'in');
    comparisonValue = comparisonOptions.find(
      (compOption) => compOption.id === predicate.comparison,
    );
  }

  // If date triggered is selected, only show relative date/time type options
  if (isTimeSubtype) {
    dateComparisonOptions = dateComparisonOptions.filter(
      (option) => option.id.includes('_r') || option.id === 'today',
    );
  }

  function filterOption(option: Option, inputValue: string): boolean {
    if (filterContext.includes('facebook')) {
      return option.data.id === 'loc_url';
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  }

  const selectStyles = {
    /*
     * Margin-right here spaces the attr_against container from
     * the comparison operator container
     */
    container: (provided) => ({ ...provided, marginRight: cssVar('--spacing-1') }),
    menu: (provided) => ({ ...provided, width: 'auto' }),
    singleValue: (provided) => ({
      ...provided,
      transform: 'none',
      position: 'relative',
      top: 0,
      maxWidth: '100%',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      transform: 'none',
      position: 'relative',
      top: 0,
    }),
  };

  const input = attrValue ? (
    <Popover toggle={'onMouseOver'}>
      <span>
        <Select<IAttrOption>
          className={styles.select}
          components={{ Option: FieldOption }}
          disabled={disabled}
          filterOption={filterOption}
          name={'predicate_attr'}
          onSelectSingle={onAttrSelect}
          options={optionGroups}
          styles={selectStyles}
          value={attrValue}
        />
      </span>
      <span>{attrValue?.helpText}</span>
    </Popover>
  ) : (
    <span>
      <Select<IAttrOption>
        className={styles.select}
        components={{ Option: FieldOption }}
        disabled={disabled}
        filterOption={filterOption}
        name={'predicate_attr'}
        onSelectSingle={onAttrSelect}
        options={optionGroups}
        styles={selectStyles}
        value={attrValue}
      />
    </span>
  );

  /*
   * If we're using a date comparison, we need to split the comparison between the
   * two dropdowns to determine what kind of date it is: relative, exact, time etc.
   */
  const dateComparison = comparisonOptions.find(
    ({ id }) => id === predicate.comparison?.split('_').shift(),
  );

  return (
    <>
      {attrOptionsIsLoading ? (
        <div className={styles.spinner}>
          <Spinner size={14} />
        </div>
      ) : (
        input
      )}
      {predicate.attr_against && (
        <>
          <Select
            className={styles.select}
            components={{ Option: ComparisonOption }}
            /*
             * Disable the comparison select if is time subtype
             * because "is" is the only option
             */
            disabled={disabled || isTimeSubtype}
            name={'predicate_comparison'}
            onSelectSingle={onComparisonSelect}
            options={comparisonOptions}
            styles={selectStyles}
            value={predicate.attr_type === 'date' ? dateComparison : comparisonValue}
          />
          {/* Only show the new date predicate options for the is comparison */}
          {predicate.attr_type === 'date' && predicate?.comparison?.startsWith('eq') && (
            <Select
              className={styles.select}
              components={{ Option: ComparisonOption }}
              disabled={disabled}
              name={'predicate_comparison_date_time_type'}
              onSelectSingle={handleTimeSelectComparison}
              options={dateComparisonOptions}
              styles={selectStyles}
              value={dateTimeSelectOption}
            />
          )}
        </>
      )}
      {shouldShowValueInput(predicate) &&
        getValueInput(
          predicate,
          excludeIds,
          disabled,
          t,
          filterContext,
          selectedRelativeTimeOptionId,
          setSelectedRelativeTimeOptionId,
          relativeDateOptionsMap,
          user,
        )}
    </>
  );
}

export default observer(PredicateInputs);
