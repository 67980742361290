import { get, remove, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { ISelectOption } from '@feathr/components';
import { Card, Checkbox, Fieldset, Input, Select } from '@feathr/components';
import type { IBeePluginFormField } from '@feathr/extender/components/BeeEditor/defaults';

import * as styles from './TemplateEditor.css';

interface IProps {
  field: IBeePluginFormField;
}

const typeOptions = [
  { id: 'text', name: 'Text' },
  { id: 'email', name: 'Primary email' },
  { id: 'tel', name: 'Phone' },
  { id: 'url', name: 'URL' },
  { id: 'textarea', name: 'Multiline text' },
  { id: 'number', name: 'Number' },
  { id: 'checkbox', name: 'Checkbox' },
  { id: 'hidden', name: 'Hidden' },
];

function FormFieldConfig({ field }: Readonly<IProps>): JSX.Element {
  const fieldType = get(field, 'type');

  function handleRequiredChange(newValue?: boolean): void {
    set(field, 'attributes', {
      ...(field.attributes || {}),
      required: !!newValue,
    });
  }

  function handleLabelChange(newValue?: string): void {
    set(field, 'label', newValue);
  }

  function handlePlaceholderChange(newValue?: string): void {
    set(field, 'attributes', {
      ...(field.attributes || {}),
      placeholder: newValue,
    });
  }

  function handleValueChange(newValue?: string): void {
    set(field, 'attributes', {
      ...(field.attributes || {}),
      value: newValue,
    });
  }

  function handleResponseChange(newValue?: string): void {
    set(field, 'attributes', {
      ...(field.attributes || {}),
      'data-callback': newValue,
    });
  }

  function handleFieldTypeChange(option: ISelectOption): void {
    set(field, 'type', option.id);
    if (['checkbox', 'number', 'hidden'].includes(option.id)) {
      remove(field.attributes, 'placeholder');
    }
    if (['hidden'].includes(option.id)) {
      remove(field.attributes, 'label');
      remove(field.attributes, 'required');
    }
  }

  return (
    <Card
      className={styles.formFieldCard}
      title={field.attributes.id?.split('-')[1] || field.label}
    >
      <Fieldset>
        {fieldType !== 'submit' && (
          <Select
            label={'Field type'}
            name={'field-type-select'}
            onSelectSingle={handleFieldTypeChange}
            options={typeOptions}
            value={typeOptions.find((opt) => opt.id === fieldType)}
          />
        )}
        {!['hidden', 'submit'].includes(fieldType) && (
          <>
            <Input
              label={'Label'}
              onChange={handleLabelChange}
              type={'text'}
              value={get(field, 'label')}
            />
            <Checkbox
              label={'Required'}
              onChange={handleRequiredChange}
              value={get(field.attributes, 'required') || false}
            />
          </>
        )}
        {['text', 'textarea', 'url', 'email', 'tel'].includes(fieldType) && (
          <Input
            label={'Placeholder'}
            onChange={handlePlaceholderChange}
            type={'text'}
            value={get(field.attributes, 'placeholder')}
          />
        )}
        {['hidden', 'submit'].includes(fieldType) && (
          <Input
            label={'Value'}
            onChange={handleValueChange}
            type={'text'}
            value={get(field.attributes, 'value')}
          />
        )}
        {['submit'].includes(fieldType) && (
          <Input
            label={'Response'}
            onChange={handleResponseChange}
            type={'text'}
            value={get(field.attributes, 'data-callback')}
          />
        )}
      </Fieldset>
    </Card>
  );
}

export default observer(FormFieldConfig);
