import type { JSX } from 'react';
import React from 'react';

import type { IPerson } from '@feathr/blackbox';
import type { IAvatarV2Props } from '@feathr/components';
import { AvatarV2 as Avatar } from '@feathr/components';

import { contentWrapper, spaced } from '../SelectOptions.css';

interface IProps<T extends IPerson> {
  data: T;
  size?: IAvatarV2Props['size'];
}

function PersonContents<T extends IPerson>({
  data: { name, first_name: firstName, last_name: lastName, placeholder, email },
  size = 'medium',
}: Readonly<IProps<T>>): JSX.Element {
  const fullName =
    name?.trim() ||
    [firstName?.trim(), lastName?.trim()].filter(Boolean).join(' ').trim() ||
    placeholder?.name?.trim();

  return (
    <div className={contentWrapper}>
      <Avatar name={name} placeholder={placeholder} size={size} />
      <span className={spaced}>{fullName}</span>
      {!!email && <span>&lt;{email}&gt;</span>}
    </div>
  );
}

export default PersonContents;
