import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  BlackbaudRaisersEdgeIntegration as BREIntegration,
  EBREActivityMappings,
} from '@feathr/blackbox';
import { toast, Toggle } from '@feathr/components';
import { RaisersEdgeActivityLabelMap } from '@feathr/extender/styles/blackbaud_raisers_edge';

import SyncMappingCard from '../../SyncMappingCard';
import type { TActivityMapping } from '../../SyncMappingCard/SyncMappingCard';
import SyncActivitySelect from './SyncActivitySelect';

interface IProps {
  integration: BREIntegration;
}

function RaisersEdgeSyncActivity({ integration }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const allMappings = Object.entries(integration.get('downsync_activities')) as TActivityMapping[];

  async function handleToggleSyncing({
    activity,
    syncValue,
  }: {
    activity: keyof typeof EBREActivityMappings;
    syncValue: boolean;
  }): Promise<void> {
    if (syncValue === undefined) {
      return;
    }
    integration.set({
      downsync_activities: { ...integration.get('downsync_activities'), [activity]: syncValue },
    });
    try {
      await integration.patchDirty();
      toast(
        t(`"{{field}}" {{action}}`, {
          field: RaisersEdgeActivityLabelMap(t, activity),
          action: syncValue ? 'enabled' : 'disabled',
        }),
        {
          type: ToastType.SUCCESS,
        },
      );
    } catch (error) {
      toast(t('Something went wrong while updating the mapping:\n{{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <>
      {allMappings.map(([key, isEnabled]) => {
        function toggleSync(): void {
          handleToggleSyncing({ activity: key, syncValue: !isEnabled });
        }

        return (
          <SyncMappingCard
            controls={
              <Toggle
                disabled={integration.get('has_provisioning_gift_subscriptions')}
                label={t('Sync')}
                onChange={toggleSync}
                value={isEnabled}
              />
            }
            key={key}
            mapping={[key, isEnabled]}
            mappings={allMappings}
            target={<SyncActivitySelect mapping={[key, isEnabled]} mappings={allMappings} />}
          />
        );
      })}
    </>
  );
}

export default observer(RaisersEdgeSyncActivity);
