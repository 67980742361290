import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'debounce-promise';
import isEqual from 'lodash.isequal';
import { autorun, observable, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  EmailVerification,
  IAddress,
  IMergeField,
  IPinpointEmailCampaign,
  ITemplate,
  PinpointEmailBaseCampaign,
  TEmailVerificationStatus,
  Template,
} from '@feathr/blackbox';
import { CampaignClass, EPinpointRequestStatus, TemplateClass } from '@feathr/blackbox';
import type { ISelectOption, TEmailInputStatus } from '@feathr/components';
import {
  AddressInput,
  AlertV2 as Alert,
  Button,
  CardV2 as Card,
  Checkbox,
  EAlertV2Type as AlertType,
  EmailSelect,
  Fieldset,
  Form,
  Input,
  isAddressEmpty,
  SenderInformationWell,
  toast,
  Tooltip,
} from '@feathr/components';
// TODO: Move this component to @feathr/feathr/extender/components
import TemplateMergefieldDefaults from '@feathr/extender/App/TemplatePage/TemplateMetadataForm/TemplateMergefieldDefaults';
import EmailTemplateSelect from '@feathr/extender/components/EmailTemplateSelect';
import MergetagSelect from '@feathr/extender/components/MergetagSelect';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { flattenError, useDebounce } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';
import { validate } from '@feathr/rachis';

import NextStepButton from './NextStepButton';

import * as styles from './PinpointEmailCampaignStepConfigureDetails.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  template?: Template;
  onNext: () => void;
  // The following are not used by Autosend campaigns.
  isVerifiedDomainSender?: boolean;
}

interface IErrors extends TValidateGrouped {
  name?: string[];
  template?: string[];
  from_address?: string[];
  from_name?: string[];
  'address.company_name'?: string[];
  'address.premise1'?: string[];
  'address.locality'?: string[];
  'address.administrative_area_name'?: string[];
  'address.postal_code'?: string[];
  'address.country_code'?: string[];
  subject?: string[];
  status?: string[];
}

function isEmailVerificationEmpty(emailVerification?: EmailVerification): boolean {
  const fromName = emailVerification?.get('from_name');
  const address = emailVerification?.get('address') ?? ({} as IAddress);
  return !fromName || isAddressEmpty(address);
}

export function validateStepConfigureDetails(
  campaign: PinpointEmailBaseCampaign,
  emailVerification?: EmailVerification,
  template?: Template,
): IErrors {
  const errors: IErrors = {};
  const campaignErrors = campaign.validate(
    [
      'name',
      'subject',
      'from_address',
      'from_name',
      'address.company_name',
      'address.premise1',
      'address.locality',
      'address.administrative_area_name',
      'address.postal_code',
      'address.country_code',
    ],
    false,
    'grouped',
  ).errors;
  Object.keys(campaignErrors).forEach((key) => {
    errors[key] = campaignErrors[key];
  });

  const emailVerificationErrors =
    emailVerification?.validate<IErrors>(['status'], false, 'grouped').errors ??
    (observable({}) as IErrors);
  if (emailVerificationErrors.status === undefined) {
    emailVerificationErrors.status = [] as string[];
  }
  const status = emailVerification?.get('status') ?? 'Unverified';
  if (status === 'Success') {
    // Do nothing.
  } else if (status === 'Pending') {
    emailVerificationErrors.status.push('Your email is still pending verification.');
  } else {
    emailVerificationErrors.status.push('Your email is unverified.');
  }
  Object.keys(emailVerificationErrors).forEach((key) => {
    errors[key] = emailVerificationErrors[key];
  });

  const templateErrors: string[] = [];
  if (!template) {
    templateErrors.push('A template needs to be selected.');
  }
  if (template?.isEphemeral) {
    templateErrors.push('The template needs to be saved.');
  }
  if (template?.isDirty) {
    templateErrors.push('The template has unsaved changes and needs to be saved.');
  }
  if (templateErrors.length > 0) {
    errors.template = templateErrors;
  }

  return errors;
}

function PinpointEmailCampaignStepConfigureDetails({
  campaign,
  disabled,
  isVerifiedDomainSender = false,
  onNext,
  template,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { EmailVerifications, Templates } = useContext(StoresContext);
  const localUrl = useLocalUrl();

  const [copyFromVerification, setCopyFromVerification] = useState(false);
  const [emailVerification, setEmailVerification] = useState<EmailVerification | undefined>();
  const [fromAddress, setFromAddress] = useDebounce(campaign.get('from_address'));
  const [hadFocusFromAddress, setHadFocusFromAddress] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const [mergetagTemplate] = useState(
    Templates.create({ _cls: TemplateClass.PinpointEmail, account: campaign.get('account') }),
  );
  const [userAddressOverride, setUserAddressOverride] = useState(false);

  const initializePreviewText = useCallback(() => {
    // When empty, return undefined
    return template?.get('preview_text') || undefined;
  }, [template]);

  const [previewText, setPreviewText] = useState<string | undefined>(initializePreviewText());

  useEffect(() => {
    if (template) {
      setPreviewText(initializePreviewText());
    }
  }, [template, initializePreviewText]);

  useEffect(() => {
    // The template subject needs to be updated whenever the campaign subject changes so that it is aware of any new merge tags
    return autorun(() => {
      const templateId = campaign.get('template_id');
      const subject = campaign.get('subject');
      if (templateId) {
        const template = Templates.get(templateId);
        template.set({ subject });
      }
    });
  }, [Templates, campaign]);

  // Callback function for EmailTemplateSelect
  function onPreChangeTemplate(
    template: Readonly<Template>,
    patch: Readonly<Partial<ITemplate>>,
  ): Partial<ITemplate> {
    /*
     * When preview text is set, update template.preview_text
     * When preview text is empty, update it from template.preview_text
     */
    if (previewText) {
      return { ...patch, preview_text: previewText };
    } else {
      setPreviewText(template.get('preview_text'));
      return patch;
    }
  }

  const handleSelectOption = useCallback(
    (option: IMergeField) => {
      const subject = campaign.get('subject', '');
      campaign.set({ subject: subject.concat(option.value) });
    },
    [campaign],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEmailStatus = useCallback(
    debounce(async (fromAddress: string) => {
      await when(() => !campaign.isPending);

      setIsPending(true);
      const emailVerifications = EmailVerifications.list(
        { filters: { email: fromAddress } },
        { reset: true },
      );
      await when(() => !emailVerifications.isPending);
      setIsPending(false);

      if (!emailVerifications.models.length) {
        const error: string[] | undefined = validate.single(fromAddress, {
          presence: { allowEmpty: false, message: 'Email address has to be valid.' },
          email: true,
        });

        if (!error) {
          const model = EmailVerifications.create({ email: fromAddress });
          await when(() => !model.isPending);
          const newEmailVerification = await EmailVerifications.add(model);
          await when(() => !newEmailVerification.isPending);

          setEmailVerification(newEmailVerification);
          return newEmailVerification;
        }
      }

      const newEmailVerification =
        !emailVerifications.isErrored && emailVerifications.models.length === 1
          ? emailVerifications.models[0]
          : undefined;
      setEmailVerification(newEmailVerification);

      return newEmailVerification;
    }, 500),
    [EmailVerifications],
  );

  const validationErrors = validateStepConfigureDetails(campaign, emailVerification, template);
  const emailStatus = emailVerification?.get('status') ?? 'Unverified';

  useEffect(() => {
    getEmailStatus(fromAddress)
      .then((newEmailVerification) => {
        const patch: Partial<IPinpointEmailCampaign> = {
          from_address: fromAddress,
          from_name: campaign.get('from_name'),
          address: campaign.get('address'),
        };
        if (newEmailVerification && newEmailVerification.get('status') === 'Success') {
          // If the from address has changed, update the campaign with the new verified address
          if (fromAddress !== campaign.get('from_address')) {
            patch.from_name = newEmailVerification.get('from_name');
            patch.address = newEmailVerification.get('address');
            setUserAddressOverride(false);
            // If the user has checked the checkbox to use the verified address, update the campaign with the verified address
          } else if (userAddressOverride && copyFromVerification) {
            patch.from_name = newEmailVerification.get('from_name');
            patch.address = newEmailVerification.get('address');
          }

          // only set copyFromVerification if it hasn't already been set by user checking or unchecking the checkbox
          if (!userAddressOverride) {
            if (
              patch.from_name === newEmailVerification.get('from_name') &&
              isEqual(patch.address, newEmailVerification.get('address'))
            ) {
              setCopyFromVerification(true);
            } else {
              setCopyFromVerification(false);
            }
          }
        }
        campaign.set(patch);
      })
      .catch((error) => {
        throw error;
      });
  }, [getEmailStatus, fromAddress, campaign, copyFromVerification, userAddressOverride]);

  function handleBlurFromAddress(): void {
    setHadFocusFromAddress(true);
  }

  function handleChangeFromAddress(email?: string): void {
    setFromAddress(email ?? '');
  }

  async function handleVerify(): Promise<void> {
    const updatedEmailVerification = await getEmailStatus(fromAddress);
    const updatedEmailStatus = updatedEmailVerification?.get('status') ?? 'Unverified';
    if (updatedEmailStatus === 'Success') {
      toast(t('This email address has been verified.'), { type: ToastType.SUCCESS });
      return;
    }

    if (updatedEmailStatus === 'Pending') {
      // Status was updated by getEmailStatus().
      toast(t('Check your email for a verification link.'), { type: ToastType.INFO });
      return;
    }

    const model = EmailVerifications.create({ email: campaign.get('from_address') });
    const response = await EmailVerifications.add(model);
    if (response.isErrored) {
      toast(t('There was a problem trying to send a verification email.'), {
        type: ToastType.ERROR,
      });
    } else {
      setEmailVerification(response);
      const isVerified = response.get('status') === EPinpointRequestStatus.Success;
      toast(
        isVerified
          ? t('Your email address {{fromAddress}} is verified', {
              fromAddress: campaign.get('from_address'),
            })
          : t(
              'A verification link has been sent to {{fromAddress}}. This link will expire in 24 hours.',
              { fromAddress: campaign.get('from_address') },
            ),
        { type: isVerified ? ToastType.SUCCESS : ToastType.INFO },
      );
    }
  }

  async function handleResend(): Promise<void> {
    if (emailVerification) {
      const response = await emailVerification.resend();
      if (emailVerification.isErrored) {
        toast(t('There was a problem trying to resend a verification email.'), {
          type: ToastType.ERROR,
        });
        // eslint-disable-next-line no-console
        console.error(response);
      } else {
        toast(
          t(
            'A verification link has been resent to {{fromAddress}}. This link will expire in 24 hours.',
            { fromAddress: campaign.get('from_address') },
          ),
          { type: ToastType.INFO },
        );
      }
    }
  }

  async function handleChangeCustom(newValue?: boolean): Promise<void> {
    setCopyFromVerification(newValue ?? false);
    setUserAddressOverride(true);
    if (newValue) {
      await when(() => !isPending);
      if (
        emailVerification &&
        emailVerification.get('status') === 'Success' &&
        !isEmailVerificationEmpty(emailVerification)
      ) {
        campaign.set({
          from_name: emailVerification.get('from_name'),
          address: emailVerification.get('address'),
        });
      }
    }
  }

  function handleChangePreviewText(newValue?: string): void {
    setPreviewText(newValue ?? '');
    if (template) {
      template.set({ preview_text: newValue });
    }
  }

  function createOption(inputValue: string): ISelectOption {
    return { name: inputValue, id: inputValue };
  }

  async function loadOptions(inputValue: string): Promise<ISelectOption[]> {
    const data = EmailVerifications.list({
      filters: inputValue ? { email: { $regex: inputValue, $options: 'i' } } : {},
      ordering: ['email'],
    });
    await when(() => !data.isPending);
    return data.models.map((model) => {
      const email = model.get('email');
      return { id: email, name: email };
    });
  }

  const hasDefaults = !isEmailVerificationEmpty(emailVerification);

  const isPartnerMessageCampaign = campaign.get('_cls') === CampaignClass.PinpointPartnerMessage;
  const isAutosend = campaign.get('_cls') === CampaignClass.AutoPinpointEmail;
  const shouldEnforceSendLimit = !isAutosend && !isVerifiedDomainSender;

  const emailStatusLabelMap: Record<TEmailVerificationStatus, TEmailInputStatus> = {
    Unverified: t('Unverified'),
    Pending: t('Pending'),
    Success: t('Verified'),
  };

  const fromEmailTooltip = t(
    "Please provide an email address you'd like to use for this campaign. All emails sent to your partners or recipient list will originate from this email address.",
  );

  return (
    <Form
      actions={[
        <NextStepButton
          campaign={campaign}
          emailVerification={emailVerification}
          key={'next'}
          onNext={onNext}
          template={template}
        />,
      ]}
      label={t('Edit Campaign: Sender Info')}
    >
      <Card>
        <Card.Header title={t('Campaign Information')} />
        <Card.Content>
          <Fieldset>
            <Input
              attribute={'name'}
              disabled={disabled}
              label={t('Campaign name')}
              model={campaign}
              name={'campaign_name'}
              required={true}
              type={'text'}
              validationError={flattenError(validationErrors.name)}
            />
          </Fieldset>
        </Card.Content>
      </Card>
      <Card>
        <Card.Header title={t('Email Information')} />
        <Card.Content addVerticalGap={true}>
          <EmailTemplateSelect
            campaign={campaign}
            context={'pinpoint'}
            disabled={disabled}
            onPreChangeTemplate={onPreChangeTemplate}
            template={template}
          />
          <Fieldset>
            {emailVerification &&
              emailVerification?.get('status') !== 'Success' &&
              shouldEnforceSendLimit && (
                <Alert
                  className={styles.alert}
                  description={t(
                    'You can still publish this campaign but we highly recommend authorizing your domain first. Doing so will improve security and deliverability.',
                  )}
                  title={t(
                    'The domain of the sender address for this campaign has not been validated for sending emails yet.',
                  )}
                  type={AlertType.info}
                >
                  <a href={localUrl('settings/account/domains')}>{t('Authorize domain')}</a>
                </Alert>
              )}
            <EmailSelect
              aria-label={t('From email address')}
              createOption={createOption}
              disabled={disabled}
              isLoading={campaign.isPending || isPending}
              label={
                <>
                  {t('From email address')}{' '}
                  <Tooltip title={fromEmailTooltip}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </>
              }
              loadOptions={loadOptions}
              name={'from_email_address'}
              onBlur={handleBlurFromAddress}
              onChange={handleChangeFromAddress}
              onVerify={handleVerify}
              required={true}
              status={emailStatusLabelMap[emailStatus]}
              t={t}
              validationError={
                hadFocusFromAddress ? flattenError(validationErrors.from_address) : undefined
              }
              value={campaign.get('from_address')}
            />
            {!!emailVerification && emailStatus !== 'Success' && (
              <Button name={'resend_email_verification'} onClick={handleResend} type={'link'}>
                {t('Resend verification email')}
              </Button>
            )}
          </Fieldset>
          {emailStatus === 'Success' && (
            <>
              {hasDefaults && (
                <Checkbox
                  disabled={disabled || campaign.isPending || isPending}
                  label={t('Use contact info from verified email address')}
                  name={'use_verified_contact_info'}
                  onChange={handleChangeCustom}
                  value={copyFromVerification}
                  wrapperClassName={styles.checkbox}
                />
              )}
              {!hasDefaults || !copyFromVerification ? (
                <Fieldset className={styles.addressGroup}>
                  <Input
                    attribute={'from_name'}
                    disabled={disabled}
                    label={t('From name')}
                    model={campaign}
                    name={'from_name'}
                    required={true}
                    type={'text'}
                  />
                  <AddressInput<PinpointEmailBaseCampaign>
                    attribute={'address'}
                    className={styles.address}
                    disabled={disabled}
                    isLoading={campaign.isPending}
                    model={campaign}
                    required={true}
                    t={t}
                  />
                </Fieldset>
              ) : (
                <SenderInformationWell
                  address={campaign.get('address')}
                  fromName={campaign.get('from_name')}
                  isLoading={campaign.isPending}
                  t={t}
                />
              )}
            </>
          )}
          <Fieldset>
            <Input
              attribute={'subject'}
              disabled={disabled}
              helpPlacement={'bottom'}
              helpText={t('The subject line for the email your audience will receive.')}
              label={t('Email subject')}
              model={campaign}
              name={'email_subject'}
              required={true}
              type={'text'}
            />
            <MergetagSelect
              disabled={disabled}
              helpPlacement={'bottom'}
              helpText={t(
                'You can use merge tags to personalize the subject line of your message. Choose the data you want to merge in from this dropdown to insert it at the end of your current subject line.',
              )}
              isPartnerMessageCampaign={isPartnerMessageCampaign}
              name={'merge_tags'}
              onChange={handleSelectOption}
              template={mergetagTemplate}
            />
            <Input
              disabled={disabled}
              helpPlacement={'bottom'}
              helpText={t(
                'The preview text that displays along with the subject line in some email clients.',
              )}
              label={t('Preview text')}
              onChange={handleChangePreviewText}
              optional={true}
              type={'text'}
              value={previewText}
            />
          </Fieldset>
        </Card.Content>
      </Card>
      {template && !!template.get('used_mergefields').length && (
        <TemplateMergefieldDefaults disabled={disabled} template={template} />
      )}
    </Form>
  );
}

export default observer(PinpointEmailCampaignStepConfigureDetails);
