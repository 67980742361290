import debounce from 'debounce-promise';
import { when } from 'mobx';
import type { JSX } from 'react';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IDefaultField } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import type { ISuggestion } from '@feathr/components';
import { Input, Popover, Suggestions } from '@feathr/components';
// TODO: Move DataRequestBreadcrumbAttributes.tsx and DataRequestPersonAttributes.tsx to a shared location
import DefaultBreadcrumbAttributes from '@feathr/extender/components/CustomFieldSelect/DataRequestBreadcrumbAttributes';
import DefaultPersonAttributes from '@feathr/extender/components/CustomFieldSelect/DataRequestPersonAttributes';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import * as styles from './RequestField.css';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  collections: FieldCollection[];
  includeDefaults: boolean;
  placeholder?: string;
}

function FieldSuggestions({
  value,
  onChange,
  collections,
  includeDefaults,
  placeholder,
}: Readonly<IProps>): JSX.Element {
  const { CustomFields } = useContext(StoresContext);
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const defaultFields: IDefaultField[] = [];
  const { t } = useTranslation();
  if (includeDefaults) {
    if (collections.includes(FieldCollection.Person)) {
      defaultFields.push(...(DefaultPersonAttributes as IDefaultField[]));
    }
    if (collections.includes(FieldCollection.Breadcrumb)) {
      defaultFields.push(...DefaultBreadcrumbAttributes);
    }
  }

  const getSuggestions = useRef(
    debounce(async (query: string = '') => {
      setIsLoading(true);
      try {
        const response = CustomFields.list({
          filters: {
            u_key__icontains: query,
            collection__in: collections,
          },
        });
        await when(() => !response.isPending || response.isErrored);
        if (response.isErrored) {
          throw response.error;
        }
        const defaultSuggestions = defaultFields.filter((field) =>
          field.u_key.toLowerCase().includes(query.toLowerCase()),
        );
        setSuggestions([
          ...defaultSuggestions.map((field) => ({ value: field.u_key, label: field.u_key })),
          ...response.models.map((field) => ({
            value: field.get('u_key'),
            label: field.get('u_key'),
          })),
        ]);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }, DEFAULT_DEBOUNCE_WAIT),
  );

  function handleFeathrFieldChange(newValue?: string): void {
    onChange(newValue ?? '');
    getSuggestions.current(newValue);
  }

  return (
    <Popover
      align={'start'}
      arrow={false}
      className={styles.popover}
      position={'bottom'}
      toggle={'onFocus'}
    >
      <Input
        helpText={t(
          'The field in Feathr you want the data captured from this form field to be stored in.',
        )}
        label={t('Feathr field')}
        onChange={handleFeathrFieldChange}
        placeholder={placeholder}
        type={'text'}
        value={value}
      />
      <Suggestions isLoading={isLoading} onClickSuggestion={onChange} suggestions={suggestions} />
    </Popover>
  );
}

export default FieldSuggestions;
