import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EBlackbaudRaisersEdgeActivityDataTypes, type FieldDataType } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { FieldOption, FieldSingleValue } from '@feathr/extender/components/SelectOptions';
import { FeathrActivityLabelMap } from '@feathr/extender/styles/blackbaud_raisers_edge';

import type { TActivityMapping } from '../../SyncMappingCard/SyncMappingCard';

import * as styles from './SyncActivitySelect.css';

interface ISelectField {
  id: string;
  name: string;
  data_type: FieldDataType;
}

interface IProps {
  mapping: TActivityMapping;
  mappings: TActivityMapping[];
}

function SyncActivitySelect({ mapping: [key], mappings }: IProps): JSX.Element {
  const { t } = useTranslation();

  function getOptionLabel({ name }: ISelectField): string {
    return name;
  }

  function getOptionValue({ id }: ISelectField): string {
    return id;
  }

  const options = mappings.map(([key]) => ({
    id: key,
    name: FeathrActivityLabelMap(t, key),
    data_type: EBlackbaudRaisersEdgeActivityDataTypes[key],
  }));

  return (
    <Select
      components={{ Option: FieldOption, SingleValue: FieldSingleValue }}
      disabled={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      name={'blackbaud-raisers-edge-activity'}
      value={options.find(({ id }) => id === key)}
      wrapperClassName={styles.root}
    />
  );
}

export default observer(SyncActivitySelect);
