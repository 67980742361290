import { computed, makeObservable, when } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection } from '@feathr/rachis';
import { DisplayModel } from '@feathr/rachis';

export interface IFont extends IBaseAttributes {
  date_created: string;
  filename: string;
  key: string;
  mime?: string;
  name?: string;
}

export class Font extends DisplayModel<IFont> {
  public readonly className = 'Font';

  public get constraints(): TConstraints<IFont> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
        async: {
          fn: async (value: string | undefined, model: Font) => {
            if (!value) {
              return;
            }
            if (!model.collection) {
              return [
                '^Model does not have a collection and therefore validity cannot be determined.',
              ];
            }
            const results = model.collection.list({
              filters: { name__iexact: value, id__ne: model.id },
              pagination: { page_size: 1 },
            });
            await when(() => !results.isPending);
            if (results.pagination.count > 0) {
              return ['^Font with that name already exists'];
            }
            return undefined;
          },
        },
      },
      filename: {
        presence: {
          allowEmpty: false,
        },
      },
      key: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }

  constructor(attributes: Partial<IFont> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<IFont> {
    return {
      name: '',
      filename: '',
      key: '',
    };
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/settings/account/fonts/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Font';
  }
}

export class Fonts extends Collection<Font> {
  public getClassName(): string {
    return 'fonts';
  }

  public getModel(attributes: Partial<IFont>): Font {
    return new Font(attributes);
  }
}
