import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { components } from 'react-select';
import type { Option } from 'react-select/src/filters';

import type { IMergeField, Template } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { Select, toast, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './MergetagSelect.css';

interface IProps {
  placeholder?: string;
  isPartnerMessageCampaign?: boolean;
  template: Template;
  name: string;
  disabled?: boolean;
  helpText?: React.ReactNode;
  helpPlacement?: 'top' | 'bottom';
  tooltip?: React.ReactNode;
  onChange?: (option: IMergeField) => void;
}

function MergetagSelect({
  disabled = false,
  helpPlacement,
  helpText,
  template,
  name,
  tooltip,
  onChange,
  placeholder,
  isPartnerMessageCampaign,
}: IProps): JSX.Element {
  const { Campaigns, CustomFields } = useContext(StoresContext);
  const customFields =
    template.get('_cls') === TemplateClass.PinpointEmail
      ? CustomFields.list({
          filters: { collection__in: ['Person', 'Partner'] },
          pagination: {
            page: 0,
            page_size: 1000,
          },
        })
      : CustomFields.list({
          filters: { collection: 'Partner' },
          pagination: {
            page: 0,
            page_size: 1000,
          },
        });

  function filterOption(opt: Option, inputValue: string): boolean {
    return opt.label.toLowerCase().includes(inputValue.toLowerCase()) && opt.data.type === 'tag';
  }

  function handleSelectOption(option: IMergeField): void {
    navigator.clipboard.writeText(option.value).then(() => {
      toast(`Copied ${option.name} merge tag to clipboard.`, { type: 'success' });
    });
  }

  function FieldOption(props: any): JSX.Element {
    const { isCustom, name } = props.data;
    return (
      <components.Option {...props}>
        {isCustom ? (
          <>
            {name} <em> (custom data)</em>
          </>
        ) : (
          <>{name}</>
        )}
      </components.Option>
    );
  }

  function getOptionValue(opt: IMergeField): string {
    return opt.value;
  }

  const title = onChange ? 'Insert merge tag' : 'Copy merge tag';
  const options = customFields.isPending
    ? []
    : template.getMergefields(customFields.models, isPartnerMessageCampaign);
  const parsedOptions =
    template.get('parent_kind') === 'campaign' &&
    Campaigns.get(template.get('parent')).get('_cls') === CampaignClass.PinpointPartnerMessage
      ? options.filter((option) => !['first_name', 'last_name'].includes(option.defaultPath!))
      : options;

  return (
    <Select
      components={{ Option: FieldOption }}
      disabled={disabled}
      filterOption={filterOption}
      getOptionValue={getOptionValue}
      helpPlacement={helpPlacement}
      helpText={helpText}
      isLoading={customFields.isPending}
      label={
        tooltip ? (
          <>
            {title}&nbsp;
            <Tooltip title={tooltip}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </>
        ) : (
          <>{title}</>
        )
      }
      name={name}
      onSelectSingle={onChange ?? handleSelectOption}
      optional={true}
      options={parsedOptions}
      placeholder={placeholder}
      value={undefined}
      wrapperClassName={styles.mergetagselect}
    />
  );
}

export default observer(MergetagSelect);
