import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { Campaign } from '@feathr/blackbox';
import { CampaignState, isPinpointCampaign } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { ConfirmModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import EventSelect from '../EventSelect';

interface ICampaignCloneModalProps {
  campaign: Campaign;
  isCloneModalOpen: boolean;
  closeCloneModal: () => void;
}

function CampaignCloneModal({
  campaign,
  isCloneModalOpen,
  closeCloneModal,
}: Readonly<ICampaignCloneModalProps>): JSX.Element {
  const rootStore = React.useContext(StoresContext);
  const { Events } = rootStore;
  const history = useHistory();
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const [selectedProject, setselectedProject] = useState<ISelectOption>();
  const state = campaign.get('state');
  const isNotDraft = state !== CampaignState.Draft;
  const eventId = campaign.get('event');
  const eventName = eventId ? Events.get(eventId).name : '';

  const flightOrProject = campaign.get('flight') ? t('flight') : t('project');
  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  const alertPageLink = (
    <a href={flightOrProjectURL} target={'_blank'}>
      {t('View {{flightOrProject}} report', { flightOrProject })}
    </a>
  );

  // Select the current event anytime the clone modal is triggered
  useEffect(() => {
    if (!selectedProject) {
      setselectedProject({ id: eventId, name: eventName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloneModalOpen]);

  async function clone(): Promise<void> {
    try {
      const { Campaigns, Flights } = rootStore;
      const clonedCampaign: Campaign = await campaign.clone(
        selectedProject ? { event: selectedProject?.id } : {},
      );

      const flightId = campaign.get('flight');
      if (flightId) {
        await Flights.reload(flightId, ['legs']);
      }
      Campaigns.clearApiCache();
      history.push(localUrl(clonedCampaign.getItemUrl()));

      toast(
        t('Campaign "{{name}}" has been cloned to {{- eventName}}', {
          name: campaign.name,
          eventName: selectedProject?.name ?? eventName,
        }),
      );
      closeCloneModal();
    } catch (error: any) {
      toast(
        t('Encountered an error while cloning:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  function handleChangeEvent(event: ISelectOption): void {
    setselectedProject(event);
  }

  function handleClearEvent(): void {
    setselectedProject(undefined);
  }

  return (
    <ConfirmModal
      alertDescription={
        isNotDraft
          ? t(
              'To recalculate conversions to include the newly cloned campaign, select "Recalculate conversions" from the conversions table on this flight\'s report.',
              { flightOrProject },
            )
          : undefined
      }
      alertLinks={isNotDraft && alertPageLink}
      alertTitle={isNotDraft && t('Are you sure you want to clone this campaign?')}
      cancelButtonText={t('Cancel')}
      confirmButtonText={t('Clone')}
      onClose={closeCloneModal}
      onConfirm={clone}
      opened={isCloneModalOpen}
      t={t}
      title={t('Clone Campaign')}
    >
      <Stack>
        {isPinpointCampaign(campaign) && (
          <EventSelect
            helpText={t('This campaign will be cloned to the selected project.')}
            id={eventId}
            label={t('Project')}
            onChange={handleChangeEvent}
            onClear={handleClearEvent}
            placeholder={t('Select a project...')}
            value={selectedProject}
          />
        )}
        <CampaignSummary campaign={campaign} layout={'vertical'} />
      </Stack>
    </ConfirmModal>
  );
}

export default observer(CampaignCloneModal);
