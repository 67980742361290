import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { APIKey } from '@feathr/blackbox';
import { ArchiveModalV1, ContextMenu, CopyToClipboardButton } from '@feathr/components';
import { getIconForAction, useToggle } from '@feathr/hooks';
interface IOptionsColumnProps {
  apiKey: APIKey;
}

function APIKeyOptions({ apiKey }: IOptionsColumnProps): JSX.Element {
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <CopyToClipboardButton
          component={'ContextMenuItem'}
          successMessage={'Successfully copied API key to clipboard'}
          t={t}
          text={apiKey.get('key')}
        >
          {t('Copy API key to clipboard')}
        </CopyToClipboardButton>
        <ContextMenu.Item
          onClick={toggleArchiveModalOpen}
          prefix={getIconForAction('revoke')}
          theme={'danger'}
        >
          {t('Revoke')}
        </ContextMenu.Item>
      </ContextMenu>
      {isArchiveModalOpen && (
        <ArchiveModalV1
          confirmButtonText={t('Revoke key')}
          message={t('Are you sure you want to revoke this API key?')}
          method={'delete'}
          model={apiKey}
          onClose={toggleArchiveModalOpen}
          t={t}
          title={t('Revoke API key')}
        />
      )}
    </>
  );
}

export default APIKeyOptions;
