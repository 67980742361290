import validate from 'validate.js/validate';
/**
 * Validates an array of values against a set of constraints
 *
 * Used to validate an array value of a single attribute (as opposed to 'array' which is used to validate a collection)
 */

function list(
  values: Array<string | undefined> | null,
  constraints: Record<string, any>,
): Array<string | undefined> | null {
  if (!values) {
    return null;
  }

  const errors = values.reduce<Array<string | undefined>>((acc, value) => {
    const error: string = validate.single(value, constraints);
    acc.push(error);
    return acc;
  }, []);

  return errors.some((error) => error !== undefined) ? errors : null;
}

export default list;
