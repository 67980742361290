import type { DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef } from 'react';

import { DEFAULT_DEBOUNCE_WAIT } from './useDebounce';

function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  wait: number = DEFAULT_DEBOUNCE_WAIT,
  dependencies: any[] = [],
): T {
  const callbackRef = useRef<T>(callback);
  const debouncedRef = useRef<DebouncedFunc<T>>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    debouncedRef.current = debounce((...args: Parameters<T>) => {
      callbackRef.current(...args);
    }, wait);

    return () => {
      debouncedRef.current?.cancel();
    };
  }, [wait, ...dependencies]);

  const debouncedCallback = useCallback((...args: Parameters<T>) => {
    debouncedRef.current?.(...args);
  }, []) as T;

  return debouncedCallback;
}

export default useDebouncedCallback;
