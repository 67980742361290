import AggregateError from 'es-aggregate-error';

import type { JsonApiError } from './jsonapi';

export type TRachisEmpty = null;

export interface IRachisMessage extends Record<string, unknown> {
  message: {
    /** Unique identifier for this particular occurrence of the message. */
    id: string;
    /** Human-readable message. */
    detail: string;
    /** HTTP status code applicable to this message. */
    status: number;
  };
}

export class RachisError extends Error {
  /** Application specific error code, expressed as a string value. */
  public code: string;
  /** Unique identifier for this particular occurrence of the problem. */
  public id: string;
  /** Human-readable explanation specific to this occurrence of the problem. */
  public detail: string;
  /** HTTP status code applicable to this problem. */
  public status: number;

  constructor(code, detail, id, status, ...params) {
    super(...params);
    this.message = detail;

    // JSONAPI properties
    this.code = code;
    this.id = id;
    this.detail = detail;
    this.status = status;
  }
}

export interface IPaginationResponse {
  page_size: number;
  page: number;
  count: number;
  pages: number;
  distinct?: string;
}

export interface IMetadata {
  pagination: IPaginationResponse;
}

interface IWretchResponseBase<M extends IMetadata = IMetadata> {
  meta?: M;
}

export interface IWretchResponseValid<
  T extends Record<string, unknown> | Array<Record<string, unknown>> | null,
  M extends IMetadata = IMetadata,
> extends IWretchResponseBase<M> {
  data: T;
}

export interface IWretchResponseError extends IWretchResponseBase {
  error: AggregateError;
}

export const ERROR_SUCCESS_INVALID_JSON =
  'Fetch succeeded but response\'s content-type was not valid "application/json".';

/*
 * Wretch - it's a wrapped fetch!
 * The goal of this function is to catch anything that could possibly go
 * wrong during an async call to api server and return it as a result so
 * it can be dealt with in our observable/observer pattern.
 * @returns Promise<Record<string, unknown> | Error>
 */
async function wretch<
  T extends Record<string, unknown> | Array<Record<string, unknown>> | null,
  M extends IMetadata = IMetadata,
>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<IWretchResponseValid<T, M> | IWretchResponseError> {
  try {
    const response = await fetch(input, init);
    if (response.status === 401) {
      if (globalThis.feathrLogout) {
        await globalThis.feathrLogout();
      }
    } else if (response.status === 204) {
      // Action succeeded, but no data is returned.
      return Promise.resolve({ data: null } as IWretchResponseValid<T, M>);
    } else if (response.headers.get('Content-Type') !== 'application/json') {
      // Return type is not json.
      throw new AggregateError([], ERROR_SUCCESS_INVALID_JSON);
    } else if (response.ok) {
      try {
        const json = await response.json();
        return Promise.resolve(json as IWretchResponseValid<T, M>);
      } catch {
        throw new AggregateError([], ERROR_SUCCESS_INVALID_JSON);
      }
    }
    // Response was not ok; i.e. 404 or other http status error code.
    return await Promise.reject(response);
  } catch (err: unknown) {
    if (err instanceof Response) {
      // Fetch succeeded, but response was not ok; i.e. 404 or other http status error code.
      try {
        const json = await err.json();
        if (json.errors) {
          return {
            error: new AggregateError(
              json.errors.map(
                (e: JsonApiError) => new RachisError(e.code, e.detail, e.id, e.status),
              ),
              'Fetch returned an error code with errors.',
            ),
          } as IWretchResponseError;
        }
        /*
         * It should not be possible to get a valid error reponse from blackbox, but
         * json.errors is not set. This should never trigger.
         */
        return {
          error: new AggregateError([], 'Fetch returned an error code without listing errors.'),
        } as IWretchResponseError;
      } catch {
        /*
         * It should not be possible to get a valid error reponse from blackbox, but
         * invalid json. This should never trigger.
         */
        return {
          error: new AggregateError(
            [],
            'Fetch returned an error code and response was not valid JSON.',
          ),
        } as IWretchResponseError;
      }
    }
    // Handle any actual errors thrown by our try block above.
    return {
      error: err as AggregateError,
    } as IWretchResponseError;
  }
}

export function isWretchError(
  response:
    | IWretchResponseValid<Record<string, unknown> | Array<Record<string, unknown>> | null>
    | IWretchResponseError,
): response is IWretchResponseError {
  return 'error' in response;
}

export default wretch;
