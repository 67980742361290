import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { IConfig as IBaseConfig, IReportAttributes, ReportModel } from '@feathr/blackbox';
import { Select, Spinner, Tooltip } from '@feathr/components';
import type { IUseReportExportReturn } from '@feathr/extender/hooks';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import type { IReportProps } from '@feathr/report_components';
import { ReportDateRange } from '@feathr/report_components';

import * as styles from './ReportWrapper.css';

export interface IReportPageProps<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
> extends IUseReportExportReturn<IConfig> {
  hideCustomDateRange?: boolean;
  model: ReportModel<IAttributes>;
  Report: React.FunctionComponent<IReportProps>;
}

function ReportWrapper<IAttributes extends IReportAttributes, IConfig extends IBaseConfig>({
  hideCustomDateRange = false,
  config,
  model,
  Report,
  reportStart,
  reportEnd,
  startFormatted,
  endFormatted,
  mode,
  goals,
  hasGoals,
  handlers: exportHandlers,
}: IReportPageProps<IAttributes, IConfig>): JSX.Element {
  const { t } = useTranslation();
  const store = useContext(StoresContext);
  const localUrl = useLocalUrl();

  if (goals.isPending) {
    return <Spinner />;
  }

  return (
    <section>
      <div className={styles.toolbar}>
        {!hideCustomDateRange ? (
          <ReportDateRange<IAttributes>
            mode={mode}
            model={model}
            reportEnd={reportEnd}
            reportStart={reportStart}
            setMode={exportHandlers.setMode}
            setReportEnd={exportHandlers.setReportEnd}
            setReportStart={exportHandlers.setReportStart}
          />
        ) : (
          <Tooltip
            position={'bottom-start'}
            title={t('Email campaign reports are live / cumulative only.')}
          >
            <Select
              disabled={true}
              label={t('Stats type')}
              name={'stats-type'}
              placeholder={t('Live')}
              wrapperClassName={styles.selectWrapper}
            />
          </Tooltip>
        )}
      </div>
      <Report
        config={config}
        end={endFormatted}
        hasGoals={hasGoals}
        localUrl={localUrl}
        modelId={model.id}
        start={startFormatted}
        store={store}
      />
    </section>
  );
}

export default observer(ReportWrapper);
