import type { TConstraints } from '@feathr/rachis';

import { monetizationConstraints } from './monetization';
import { PinpointEmailBaseCampaign } from './pinpoint_email_base';
import type { IPinpointEmailCampaign } from './types';

export class PinpointEmailCampaign extends PinpointEmailBaseCampaign<IPinpointEmailCampaign> {
  /**
   * Single Send campaigns using an email address that does not belong to a verified Email Domain
   * will be limited to <= 1000 sends
   */
  public get constraints(): TConstraints<IPinpointEmailCampaign> {
    return {
      ...super.constraints,
      monetization_value: () => {
        if (this.isMonetization) {
          return monetizationConstraints.monetization_value;
        }
        return null;
      },
      // Pinpoint Email gets sent once, so no end date is needed
      date_send_end: undefined,
      send_schedule: {
        presence: {
          allowEmpty: false,
          message: '^Please choose a send time.',
        },
      },
    };
  }
}
