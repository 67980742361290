interface ICaseInsensitiveExclusionOptions {
  /**
   * An array of strings that the value should not match (case-insensitive).
   */
  within: string[];

  /**
   * An optional custom message to return when the value is found within the array.
   * If not provided, a default message will be used.
   */
  message?: string;
}

function caseInsensitiveExclusion(
  value: string | undefined,
  { within, message }: ICaseInsensitiveExclusionOptions,
): string | undefined {
  if (!value) {
    return;
  }
  const exclusions = within.map((item) => item.toLowerCase());
  if (exclusions.includes(value.toLowerCase())) {
    return message ?? `^Name "${value}" is reserved.`;
  }
  return undefined;
}

export default caseInsensitiveExclusion;
