import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IDripCampaignStepSpec } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { CardV2 as Card, EmailTemplateSelect, Label, Well } from '@feathr/components';
import TriggerSummary from '@feathr/extender/App/EventsPage/CampaignSummary/TriggerSummary';
import { useUser } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import useEphemeralCampaign from '../useEphemeralCampaign';

import * as styles from './PreviewInitialEnrollment.css';

interface IPreviewInitialEnrollmentProps {
  dripCampaignId: string;
  step: IDripCampaignStepSpec;
}

function PreviewInitialEnrollment({
  dripCampaignId,
  step,
}: Readonly<IPreviewInitialEnrollmentProps>): JSX.Element {
  const { t } = useTranslation();

  const user = useUser();

  const { campaign, template } = useEphemeralCampaign({ dripCampaignId, step });
  const isDraft = campaign.get('state') === CampaignState.Draft;

  const subTypeMap = {
    activity: t('Activity happened'),
    update: t('Field changed'),
    time: t('Date triggered'),
    not_set: t('Not set'),
  };

  const userTimezone = user.get('timezone');
  const subType = campaign.get('subtype') ?? 'not_set';
  const triggerType = subTypeMap[subType];
  const sendSchedule = campaign.get('send_schedule') ?? t('Not set');
  const formattedSendSchedule =
    !!sendSchedule.length && sendSchedule.charAt(0).toUpperCase() + sendSchedule.slice(1);
  const delayValue = `${campaign.get('delay_value')} ${campaign.get('delay_unit')}`;
  const cooldownValue = `${campaign.get('cooldown_value')} ${campaign.get('cooldown_unit')}`;
  const repeat = campaign.get('repeat');
  const timeToSend = campaign.get('time_to_send');
  const localTimeToSend = moment
    .utc(timeToSend)
    .tz(userTimezone)
    .format(TimeFormat.timeAndTimeZone);
  const sendCadence = repeat ? t('Send every time') : t('Send only the first time');

  return (
    <>
      <Card.Content>
        <Label weight={'semibold'}>{t('Initial enrollment')}</Label>
        <div className={styles.wells}>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row description={triggerType} label={t('Trigger type')} />
          </Well>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row
              description={
                <TriggerSummary
                  campaign={campaign}
                  showMinimal={true}
                  validationErrors={undefined}
                />
              }
              label={t('Trigger')}
            />
          </Well>
          <Well layout={'vertical'} theme={'white'}>
            {['activity', 'update'].includes(subType) && (
              <>
                <Well.Row description={formattedSendSchedule} label={t('Send schedule')} />
                <Well.Row description={delayValue} label={t('Delay timer')} />
              </>
            )}
            {subType === 'time' && timeToSend && (
              <Well.Row description={localTimeToSend} label={t('Time of day')} />
            )}

            <Well.Row description={sendCadence} label={t('Send cadence')} />
            {['activity', 'update'].includes(subType) && repeat && (
              <Well.Row description={cooldownValue} label={t('Cooldown period')} />
            )}
          </Well>
        </div>
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        <EmailTemplateSelect
          description={template?.get('preview_text')}
          imageUrl={isDraft && template ? template.get('thumbnail_url') : undefined}
          isLoading={template?.isPending}
          previewUrl={template && !isDraft ? campaign.getTemplatePreviewUrl() : undefined}
          readOnly={true}
          subtitle={campaign.get('subject')}
          title={template ? campaign.get('from_address') : t('No template selected')}
        />
      </Card.Content>
    </>
  );
}

export default observer(PreviewInitialEnrollment);
