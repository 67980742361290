import {
  faCheck,
  faDownload,
  faExclamationCircle,
  faPencil,
  faSpinnerThird,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import type { Campaign, PartnerExport, TPartnerExportState } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  AsyncSelect,
  Avatar,
  Button,
  ButtonValid,
  Card,
  Fieldset,
  Select,
  Time,
  toast,
} from '@feathr/components';
import {
  CampaignModelIconOption,
  CampaignSingleValue,
} from '@feathr/extender/components/SelectOptions/CampaignOption';
import { StoresContext } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import * as styles from './EventPartnerExportCard.css';

import noImg from 'images/no-img.png';

interface IFieldOption {
  value: string;
  label: string;
}

const fieldOptions: IFieldOption[] = [
  { value: 'external_id', label: 'External ID' },
  { value: 'name', label: 'Partner Name' },
  { value: 'email', label: 'Partner Email' },
  { value: 'description', label: 'Partner Description' },
  { value: 'website', label: 'Partner Website' },
  { value: 'logo', label: 'Partner Logo' },
  { value: 'dashboard_url', label: 'Partner Dashboard Link' },
  { value: 'partner_status', label: 'Active' },
  { value: 'profile_url', label: 'Partner Profile Link' },
  { value: 'partner_stats', label: 'Partner Stats' },
  { value: 'referral_page_links', label: 'Invite Page Links' },
  { value: 'partner_tags', label: 'Tags' },
  { value: 'partner_custom_data', label: 'Custom Data' },
];

interface IExportCardProps {
  partnerExport: PartnerExport;
}

const DownloadButton = ({ partnerExport }: IExportCardProps) => {
  return (
    <Button
      className={styles.button}
      href={`${BLACKBOX_URL}events/${partnerExport.get('event')}/partners/exports/${
        partnerExport.id
      }/download`}
      icon={<FontAwesomeIcon icon={faDownload} />}
      target={'_blank'}
    >
      Download export file
    </Button>
  );
};

const FinishButton = observer(({ partnerExport }: IExportCardProps) => {
  const { PartnerExports } = useContext(StoresContext);
  const fields: string[] = partnerExport.get('export_fields', []);

  async function onSave() {
    try {
      await PartnerExports.add(partnerExport);
      toast(
        'Partner export is in progress. You will be notified by email when the export is complete.',
        { type: 'success' },
      );
    } catch (e) {
      toast('There was an error creating the partner export.', { type: 'error' });
    }
  }

  const validationErrors = partnerExport.validate(
    ['event', 'export_fields', 'campaign'],
    false,
  ).errors;
  if (fields.includes('referral_page_links') && !partnerExport.get('campaign')) {
    validationErrors.push('You must choose a campaign to export referral page links.');
  }
  return (
    <ButtonValid className={styles.button} errors={validationErrors} onClick={onSave}>
      Export partners
    </ButtonValid>
  );
});

function EventPartnerExportCard({ partnerExport }: IExportCardProps) {
  const { Users, Campaigns } = useContext(StoresContext);
  const state: TPartnerExportState = partnerExport.get('state');
  const createdBy = Users.get(partnerExport.get('created_by'));
  const rows = partnerExport.get('rows', 0);
  const exportFields: string[] = partnerExport.get('export_fields', []);

  async function loadCampaignOptions(inputValue: string) {
    const campaigns = Campaigns.list({
      filters: {
        is_archived__ne: true,
        event: partnerExport.get('event'),
        _cls: CampaignClass.Referral,
        name__icontains: inputValue,
      },
    });
    await when(() => !campaigns.isPending);
    return campaigns.models;
  }

  function handleSelectCampaign(option: Campaign) {
    partnerExport.set({ campaign: option.id });
  }

  function getCampaignOptionLabel(option: Campaign) {
    return option.get('name');
  }

  function handleSelectFields(options: IFieldOption[]) {
    partnerExport.set({ export_fields: options.map((o) => o.value) });
  }

  function getFieldLabel(option: IFieldOption) {
    return option.label;
  }

  function getFieldValue(option: IFieldOption) {
    return option.value;
  }

  return (
    <Card className={styles.card}>
      <div className={styles.meta}>
        <Avatar image={createdBy.get('picture') || noImg} size={'large'} />
        <div className={styles.subdued}>
          {partnerExport.get('date_created') && (
            <Time format={TimeFormat.shortDate} timestamp={partnerExport.get('date_created')} />
          )}
        </div>
      </div>
      <p>
        {createdBy.get('name') || 'An unknown user'}{' '}
        {state ? 'exported partners.' : 'started a new export.'}
      </p>
      {rows > 0 && (
        <div className={styles.partners}>
          <b>Partners: </b>
          {partnerExport.get('rows')}
        </div>
      )}

      <FontAwesomeIcon
        className={classNames(
          styles.icon,
          { [styles.ready]: state === 'ready' },
          { [styles.failed]: state === 'failed' },
        )}
        icon={
          state
            ? {
                ready: faCheck,
                pending: faSpinnerThird,
                exporting: faSpinnerThird,
                failed: faExclamationCircle,
              }[state]
            : faPencil
        }
        spin={state === 'pending'}
      />
      <span>
        {state
          ? {
              ready: 'Ready',
              pending: 'Processing',
              exporting: 'Processing',
              failed: 'Failed',
            }[state]
          : 'Draft'}
      </span>
      <Fieldset>
        <AsyncSelect<Campaign>
          className={styles.campaigns}
          components={{ Option: CampaignModelIconOption, SingleValue: CampaignSingleValue }}
          defaultOptions={true}
          disabled={!!state}
          getOptionLabel={getCampaignOptionLabel}
          helpPlacement={'bottom'}
          helpText={
            state
              ? ''
              : 'It is only necessary to choose a campaign if you want to export invite page links. If chosen, the export will include only partners that are participating in the chosen campaign.'
          }
          label={'Campaign'}
          loadOptions={loadCampaignOptions}
          name={'campaign-select'}
          onSelectSingle={handleSelectCampaign}
          optional={true}
          placeholder={'No campaign selected.'}
          value={
            partnerExport.get('campaign') ? Campaigns.get(partnerExport.get('campaign')) : undefined
          }
        />
        <Select
          disabled={!!partnerExport.get('state')}
          getOptionLabel={getFieldLabel}
          getOptionValue={getFieldValue}
          isMulti={true}
          label={'Export fields'}
          name={'export-fields'}
          onSelectMulti={handleSelectFields}
          options={fieldOptions}
          placeholder={'No fields selected.'}
          value={fieldOptions.filter((option) => exportFields.includes(option.value))}
        />
      </Fieldset>
      {state === 'ready' && <DownloadButton partnerExport={partnerExport} />}
      {!state && <FinishButton partnerExport={partnerExport} />}
    </Card>
  );
}

export default observer(EventPartnerExportCard);
