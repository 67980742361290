import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons';
import { Group } from '@mantine/core';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import * as styles from './TemplateUnsavedChangesPrompt.css';

interface IProps {
  onDiscard: () => Promise<void> | void;
  onSave: () => Promise<void> | void;
}

function TemplateUnsavedChangesPrompt({ onDiscard, onSave }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  function handleDiscard(): Promise<void> | void {
    return onDiscard();
  }

  function handleSave(): Promise<void> | void {
    return onSave();
  }

  return (
    <div className={styles.root}>
      <h3>{t('You have unsaved changes')}</h3>
      <p>{t('Would you like to save them before leaving?')}</p>
      <Group gap={cssVar('--spacing-2')} justify={'center'}>
        <Button onClick={handleDiscard}>{t('Discard changes')}</Button>
        <Button onClick={handleSave} prefix={<Icon icon={faFloppyDisk} />} type={'primary'}>
          {t('Save changes')}
        </Button>
      </Group>
    </div>
  );
}

export default TemplateUnsavedChangesPrompt;
